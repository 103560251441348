import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Popover, Tooltip, Button } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import dashboardStore from '../stores/DashboardStore';
import Actions from '../actions/actions';
import { Action } from '../actions/actions';
import AlertDialogStore from '../stores/AlertDialogStore';

const GraphPropertiesMenu = observer((): JSX.Element => {
  const styles = {
    graphPropertiesButton: {
      padding: '0',
      fontSize: '14px',
      display: 'flex',
      width: '200px',
      justifyContent: 'left',
    },
    iconButton: {
      padding: '0',
      fontSize: '14px',
      margin: '1px',
    },
    graphPropertiesMenu: {
      padding: '0',
      fontSize: '10px',
      margin: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      alignItems: 'left',
    },
  };

  // State for graph properties menu
  const [showTooltip, setShowTooltip] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Define actions for graph properties menu
  const graphActions: Action[] = [
    Actions.uniqueActions.deleteGraph,
    Actions.uniqueActions.duplicateGraph,
  ];

  // Convert graph action to button
  const graphActionToButton = (action: Action): JSX.Element => {
    const handleDeleteGraph = () => {
      AlertDialogStore.yesNoAlert(
        'Are you sure you want to delete this graph?',
        dashboardStore.deleteCurrentGraph,
      );
    };

    return (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        key={action.shortLabel}
        sx={{ textTransform: 'none', margin: '2px' }}
        disabled={action.disabled}
        onClick={() => {
          if (action === Actions.uniqueActions.deleteGraph) {
            handleDeleteGraph();
          } else {
            action.action();
          }
          setPopoverOpen(false);
        }}
      >
        {action.shortLabel}
      </Button>
    );
  };

  return (
    <Box sx={styles.graphPropertiesButton}>
      {/*Graph properties button*/}
      <Tooltip open={showTooltip} title="Graph Options" arrow>
        <IconButton
          sx={styles.iconButton}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setPopoverOpen(true);
          }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      {/*Graph properties pop over menu*/}
      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/*Modify Graph Field*/}
        <Box sx={styles.graphPropertiesMenu}>
          {/*Duplicate and delete buttons*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'right',
              marginBottom: '10px',
              justifyContent: 'right',
            }}
          >
            {graphActions.map((action) => graphActionToButton(action))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
});

export default GraphPropertiesMenu;
