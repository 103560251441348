import * as THREE from 'three';

import { IExtents } from '../types/app';
import { numberFormatter } from '../utils/utils';
import { DRiskCSS2DRenderer } from './DRiskCSS2DRenderer';
import { AxisLabelCSSObject } from './AxisLabelCSSObject';

import ViewStore from '../stores/ViewStore';

export default class GridEngine {
  private viewStore: ViewStore;
  private scene: THREE.Scene;
  private camera: THREE.Camera;
  private renderer: THREE.Renderer;

  private labelContainer: HTMLDivElement;
  private labelRenderer: DRiskCSS2DRenderer;

  private rulerMaterial: THREE.LineBasicMaterial;
  private majorGridMaterial: THREE.LineBasicMaterial;
  private minorGridMaterial: THREE.LineBasicMaterial;

  private rulerLines: {
    t: THREE.Line;
    r: THREE.Line;
  };

  private grids: {
    major: THREE.LineSegments;
    minor: THREE.LineSegments;
  };

  private gridXLabels: AxisLabelCSSObject[] = [];
  private gridYLabels: AxisLabelCSSObject[] = [];

  constructor(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    viewStore: ViewStore,
    labelContainer: HTMLDivElement,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.viewStore = viewStore;
    this.labelContainer = labelContainer;

    this.rulerMaterial = new THREE.LineBasicMaterial({
      color: 0x808080,
      linewidth: 1,
    });
    this.majorGridMaterial = new THREE.LineBasicMaterial({
      color: 0xd0d0d0,
      linewidth: 1,
    });
    this.minorGridMaterial = new THREE.LineBasicMaterial({
      color: 0xe0e0e0,
      linewidth: 1,
    });

    this.rulerLines = {
      t: new THREE.Line(new THREE.BufferGeometry(), this.rulerMaterial),
      r: new THREE.Line(new THREE.BufferGeometry(), this.rulerMaterial),
    };
    this.initRulers();

    this.labelRenderer = new DRiskCSS2DRenderer();

    this.initLabels();

    /**
     * Note: need to init the labels first because the grid uses the label renderer
     */
    this.grids = {
      major: new THREE.LineSegments(
        new THREE.BufferGeometry(),
        this.majorGridMaterial,
      ),
      minor: new THREE.LineSegments(
        new THREE.BufferGeometry(),
        this.minorGridMaterial,
      ),
    };
    this.initGrid();
  }

  private createGridLabelDiv = (): HTMLDivElement => {
    const div = document.createElement('div');
    div.className = 'grid-number';
    div.textContent = '';
    return div;
  };

  private initGrid = () => {
    // Grids

    this.grids.minor.frustumCulled = false;
    this.scene.add(this.grids.minor);

    this.grids.major.frustumCulled = false;
    this.grids.major.renderOrder = 1;
    this.scene.add(this.grids.major);

    // Labels

    for (let n = 0; n < 21; n++) {
      const labelX = new AxisLabelCSSObject(this.createGridLabelDiv());
      labelX.horizontal = true;
      labelX.alignLeft = true;
      labelX.frustumCulled = false;
      this.scene.add(labelX);
      this.gridXLabels.push(labelX);

      const labelY = new AxisLabelCSSObject(this.createGridLabelDiv());
      labelY.horizontal = false;
      labelY.alignLeft = true;
      labelY.frustumCulled = false;
      this.scene.add(labelY);
      this.gridYLabels.push(labelY);
    }
  };

  private initRulers = () => {
    this.rulerLines.t.frustumCulled = false;
    this.rulerLines.t.renderOrder = 2;
    this.rulerLines.r.frustumCulled = false;
    this.rulerLines.r.renderOrder = 2;

    this.scene.add(this.rulerLines.t);
    this.scene.add(this.rulerLines.r);
  };

  private initLabels = () => {
    this.labelRenderer.setSize(
      this.labelContainer.clientWidth,
      this.labelContainer.clientHeight,
    );
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.top = '0';
    this.labelContainer.appendChild(this.labelRenderer.domElement);
  };

  handleResize = (width: number, height: number) => {
    this.labelRenderer.setSize(width, height);
  };

  private floorToNearestPowerOfBase = (n: number, base: number): number => {
    return Math.pow(base, Math.floor(Math.log(n) / Math.log(base)));
  };

  /**
   * The points in x and y for the grid lines given the increment and extents.
   */
  private gridTics = (
    extents: IExtents,
    incrementX: number,
    incrementY: number,
    total: number,
  ): { x: number[]; y: number[] } => {
    const pointsX: number[] = [];
    const pointsY: number[] = [];

    // Calculate the center point of the range
    const centerX = (extents.xMin + extents.xMax) / 2;
    const centerY = (extents.yMin + extents.yMax) / 2;

    // Calculate the starting point for the grid lines
    const startX = Math.floor(centerX / incrementX) * incrementX;
    const startY = Math.floor(centerY / incrementY) * incrementY;

    for (
      let x = startX - (incrementX * total) / 2;
      x <= startX + (incrementX * total) / 2;
      x += incrementX
    ) {
      pointsX.push(x);
    }

    for (
      let y = startY - (incrementY * total) / 2;
      y <= startY + (incrementY * total) / 2;
      y += incrementY
    ) {
      pointsY.push(y);
    }

    return { x: pointsX, y: pointsY };
  };

  private update = () => {
    /**
     * todo: fade the minor grid and labels in to match the major grid
     * as we zoom (rather than snapping)
     *
     * Note that we don't want to early return here if showGrid and showAxes are
     * false because we need to clear the grid and labels.
     */

    const maxMajorTics = 20; // The most tics we'll show on a single axis
    const minorTicsPerMajorTic = 4;

    const outer = this.viewStore.outerDimensionsInDataSpace;
    const inner = this.viewStore.innerDimensionsInDataSpace;

    const majorIncrementX = this.floorToNearestPowerOfBase(
      (outer.xMax - outer.xMin) / 2,
      this.viewStore.datetimeFormat === true ? 3.5 : 10,
    );
    const majorIncrementY = this.floorToNearestPowerOfBase(
      (outer.yMin - outer.yMax) / 2,
      10,
    );

    const majorGridTics = this.gridTics(
      outer,
      majorIncrementX,
      majorIncrementY,
      maxMajorTics,
    );

    const minorGridTics = this.gridTics(
      outer,
      majorIncrementX / minorTicsPerMajorTic,
      majorIncrementY / minorTicsPerMajorTic,
      maxMajorTics * minorTicsPerMajorTic,
    );

    const majorGridPoints: THREE.Vector3[] = [];
    const minorGridPoints: THREE.Vector3[] = [];

    this.gridXLabels.forEach((label) => (label.element.textContent = ''));
    this.gridYLabels.forEach((label) => (label.element.textContent = ''));

    /**
     * Rulers with labels
     */

    if (this.viewStore.showRulers) {
      const xRulerCenter =
        0.5 * (outer.yMax + inner.yMax) * this.viewStore.yAxisScale;
      const xRulerIn = inner.yMax * this.viewStore.yAxisScale;
      const xRulerOut = outer.yMax * this.viewStore.yAxisScale;

      const yRulerCenter =
        0.5 * (outer.xMin + inner.xMin) * this.viewStore.xAxisScale;
      const yRulerIn = inner.xMin * this.viewStore.xAxisScale;
      const yRulerOut = outer.xMin * this.viewStore.xAxisScale;

      // Ruler lines
      this.rulerLines.t.geometry.setFromPoints([
        new THREE.Vector3(outer.xMin * this.viewStore.xAxisScale, xRulerIn, 0),
        new THREE.Vector3(outer.xMax * this.viewStore.xAxisScale, xRulerIn, 0),
      ]);

      this.rulerLines.r.geometry.setFromPoints([
        new THREE.Vector3(yRulerIn, outer.yMin * this.viewStore.yAxisScale, 0),
        new THREE.Vector3(yRulerIn, outer.yMax * this.viewStore.yAxisScale, 0),
      ]);

      majorGridTics.x.forEach((x, i) => {
        const scaledX = x * this.viewStore.xAxisScale;
        majorGridPoints.push(new THREE.Vector3(scaledX, xRulerIn, 0));
        majorGridPoints.push(new THREE.Vector3(scaledX, xRulerOut, 0));

        const intrinsic = this.viewStore.toIntrinsicCoordinates({
          x,
          y: xRulerCenter,
        });

        if (this.viewStore.datetimeFormat === true) {
          const date = new Date(intrinsic.x);
          this.gridXLabels[i].element.textContent = date.toLocaleDateString();
        } else {
          this.gridXLabels[i].element.textContent = numberFormatter(
            intrinsic.x,
          );
        }
        this.gridXLabels[i].position.set(scaledX, xRulerCenter, 0);
      });
      majorGridTics.y.forEach((y, i) => {
        const scaledY = y * this.viewStore.yAxisScale;
        majorGridPoints.push(new THREE.Vector3(yRulerIn, scaledY, 0));
        majorGridPoints.push(new THREE.Vector3(yRulerOut, scaledY, 0));

        const intrinsic = this.viewStore.toIntrinsicCoordinates({
          x: yRulerCenter,
          y,
        });
        this.gridYLabels[i].element.textContent = numberFormatter(intrinsic.y);
        this.gridYLabels[i].position.set(yRulerCenter, scaledY, 0);
      });
    } else {
      // clear the ruler lines
      this.rulerLines.t.geometry.setFromPoints([]);
      this.rulerLines.r.geometry.setFromPoints([]);
    }

    /**
     * Grid
     */

    if (this.viewStore.showGrid) {
      majorGridTics.x.forEach((x) => {
        majorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            outer.yMin * this.viewStore.yAxisScale,
            0,
          ),
        );
        majorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            inner.yMax * this.viewStore.yAxisScale,
            0,
          ),
        );
      });
      majorGridTics.y.forEach((y) => {
        majorGridPoints.push(
          new THREE.Vector3(
            inner.xMin * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
        majorGridPoints.push(
          new THREE.Vector3(
            outer.xMax * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
      });
      minorGridTics.x.forEach((x) => {
        minorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            outer.yMin * this.viewStore.yAxisScale,
            0,
          ),
        );
        minorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            inner.yMax * this.viewStore.yAxisScale,
            0,
          ),
        );
      });
      minorGridTics.y.forEach((y) => {
        minorGridPoints.push(
          new THREE.Vector3(
            inner.xMin * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
        minorGridPoints.push(
          new THREE.Vector3(
            outer.xMax * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
      });

      // Note: we only show the minor tics when the full grid is on
      minorGridTics.x.forEach((x) => {
        minorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            inner.yMax * this.viewStore.yAxisScale,
            0,
          ),
        );
        minorGridPoints.push(
          new THREE.Vector3(
            x * this.viewStore.xAxisScale,
            outer.yMax * this.viewStore.yAxisScale,
            0,
          ),
        );
      });
      minorGridTics.y.forEach((y) => {
        minorGridPoints.push(
          new THREE.Vector3(
            outer.xMin * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
        minorGridPoints.push(
          new THREE.Vector3(
            inner.xMin * this.viewStore.xAxisScale,
            y * this.viewStore.yAxisScale,
            0,
          ),
        );
      });
    }
    this.grids.major.geometry.setFromPoints(majorGridPoints);
    this.grids.minor.geometry.setFromPoints(minorGridPoints);
  };

  render = (): void => {
    /**
     * Note: We have to update the grid on render, not in a reaction
     * so that the it's updated in the same render loop as the
     * camera. Otherwise, the grid will 'jitter' as the camera
     * moves.
     */
    this.update();
    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);
  };

  destroy(): void {
    // todo: clean up reactions
  }
}
