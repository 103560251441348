import { makeAutoObservable } from 'mobx';
import dashboardStore from './DashboardStore';
import {
  TransformationType,
  NodeLabelTransformationType,
  MergeNodesTransformationType,
  ConvertNodesTransformationType,
  TransformOperation,
  EdgeValueTransformation,
} from '../types/app';
import { NodeStore } from './NodeStore';

export type SectionState = 'normal' | 'selected' | 'disabled';

export class Transformation {
  name: string;
  parameters: Record<string, number | boolean | string> | undefined;
  type: string;
  constructor(name: string) {
    this.name = name;
    this.parameters = undefined;
    this.type = 'General';
  }
}

export class Linear extends Transformation {
  constructor(m: number, c: number, division: boolean = false) {
    super('Linear');
    this.parameters = { m, c, division };
    this.type = 'EdgeWeight';
  }
}

export class Headers extends Transformation {
  constructor(op: TransformOperation) {
    super('Headers');
    this.parameters = { op };
    this.type = 'EdgeWeight';
  }
}

export class Replace extends Transformation {
  constructor(from: string, to: string) {
    super('Replace');
    this.parameters = { from, to };
    this.type = 'NodeLabel';
  }
}

export class CategoricalToContinuous extends Transformation {
  constructor() {
    super('CategoricalToContinuous');
    this.type = 'ConvertNodes';
    this.parameters = {};
  }
}

export class ContinuousToCategorical extends Transformation {
  constructor() {
    super('ContinuousToCategorical');
    this.type = 'ConvertNodes';
    this.parameters = {};
  }
}

export interface TransformationStore {
  open: boolean;
  title: string;
  transformation: Transformation;
  execute: () => void;
}

/**
 * Observable data and behavior for the Node Label transformations:
 * - Split
 * - Replace
 */
export class NodeLabelStore implements TransformationStore {
  transformationType: TransformationType = 'NodeLabel';
  title = 'On Node Label';
  open = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _inPlace = false;
  get inPlace(): boolean {
    return this._inPlace;
  }
  set inPlace(newInPlace: boolean) {
    this._inPlace = newInPlace;
  }

  private _type: NodeLabelTransformationType = 'Replace';
  get type(): NodeLabelTransformationType {
    return this._type;
  }
  set type(newState: NodeLabelTransformationType) {
    this._type = newState;
  }

  private _value = '';
  get value(): string {
    return this._value;
  }
  set value(newState: string) {
    this._value = newState;
  }

  private _from = '';
  get from(): string {
    return this._from;
  }
  set from(newState: string) {
    this._from = newState;
  }

  private _to = '';
  get to(): string {
    return this._to;
  }
  set to(newState: string) {
    this._to = newState;
  }

  get transformation(): Transformation {
    return {
      type: this.transformationType,
      name: this.type,
      parameters:
        this.type === 'Replace'
          ? { from: this.from, to: this.to }
          : { c: this.value },
    };
  }

  execute = async (): Promise<void> => {
    if (!dashboardStore.currentViewStore) {
      return;
    }
    return dashboardStore.transformNodeAndOpenView(
      this.transformation,
      this._inPlace,
    );
  };

  get disabled(): boolean {
    return !dashboardStore.currentGraphStore?.anyNodesSelected;
  }
}

export class NodeConversionStore implements TransformationStore {
  transformationType: TransformationType = 'convertNodes';
  title = 'Convert Nodes';
  open = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _inPlace = false;
  get inPlace(): boolean {
    return this._inPlace;
  }
  set inPlace(newInPlace: boolean) {
    this._inPlace = newInPlace;
  }

  private _type: ConvertNodesTransformationType = 'toCategorical';
  get type(): ConvertNodesTransformationType {
    return this._type;
  }
  set type(newState: ConvertNodesTransformationType) {
    this._type = newState;
  }

  get transformation(): Transformation {
    return {
      type: this.transformationType,
      name: this.type,
      parameters: undefined,
    };
  }

  execute = async (): Promise<void> => {
    if (!dashboardStore.currentViewStore) {
      return;
    }
    if (this.type === 'toCategorical') {
      return dashboardStore.transformNodeAndOpenView(
        new ContinuousToCategorical(),
        this._inPlace,
      );
    }
    if (this.type === 'toContinuous') {
      return dashboardStore.transformEdges(
        new CategoricalToContinuous(),
        this._inPlace,
      );
    }
  };

  get disabled(): boolean {
    return !dashboardStore.currentGraphStore?.anyNodesSelected;
  }
}

export class EdgeValueStore implements TransformationStore {
  transformationType: TransformationType = 'edgeValue';
  title = 'On Edge Value';
  open = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _inPlace = false;
  get inPlace(): boolean {
    return this._inPlace;
  }
  set inPlace(newInPlace: boolean) {
    this._inPlace = newInPlace;
  }

  /**
   * Edge Value transformation
   */

  private _type: EdgeValueTransformation = 'numeric';
  get type(): EdgeValueTransformation {
    return this._type;
  }
  set type(newState: EdgeValueTransformation) {
    this._type = newState;
  }
  /**
   * Operation types
   */
  private _operationType: TransformOperation = 'add';
  get operationType(): TransformOperation {
    return this._operationType;
  }
  set operationType(newState: TransformOperation) {
    this._operationType = newState;
  }

  private _value = 0;
  get value(): number {
    return this._value;
  }
  set value(newState: number) {
    this._value = newState;
  }

  firstHeaderNode = new NodeStore();
  secondHeaderNode = new NodeStore();

  get isDivisionOrSubtractionHeader(): boolean {
    return (
      (this.operationType == 'subtract' || this.operationType == 'divide') &&
      this.type == 'headers'
    );
  }

  get transformation(): Transformation {
    return {
      type: this.transformationType,
      name: this.type,
      parameters: { value: this.value, operation: this.operationType },
    };
  }

  execute = (): void => {
    if (!dashboardStore.currentViewStore) {
      return;
    }
    if (this.type === 'numeric') {
      let transformation;
      switch (this.operationType) {
        case 'add':
          transformation = new Linear(1, this.value);
          break;
        case 'subtract':
          transformation = new Linear(1, -this.value);
          break;
        case 'multiply':
          transformation = new Linear(this.value, 0);
          break;
        case 'divide':
          transformation = new Linear(1 / this.value, 0, true);
          break;
      }
      dashboardStore.transformEdges(transformation, this._inPlace);
    }
    if (this.type === 'headers') {
      dashboardStore.transformEdges(
        new Headers(this.operationType),
        this._inPlace,
      );
    }
  };

  get disabled(): boolean {
    return (
      !dashboardStore.currentGraphStore?.anyNodesSelected ||
      (this.type === 'numeric' && this.value === 0)
    );
  }
}

class MergeNodesStore implements TransformationStore {
  transformationType: TransformationType = 'mergeNodes';
  title = 'Merge Nodes';
  open = false;

  constructor() {
    makeAutoObservable(this);
  }

  private _type: MergeNodesTransformationType = 'deduplicate';
  get type(): MergeNodesTransformationType {
    return this._type;
  }
  set type(newState: MergeNodesTransformationType) {
    this._type = newState;
  }

  get transformation(): Transformation {
    return {
      type: this.transformationType,
      name: this.type,
      parameters: undefined,
    };
  }

  execute = (): void => {
    if (!dashboardStore.currentViewStore) {
      return;
    }
    if (this.type === 'deduplicate') {
      dashboardStore.mergeNodes(this.type);
    }
    if (this.type === 'merge') {
      dashboardStore.mergeNodes(this.type);
    }
  };
}

class TransformationsDrawerStore {
  nodeLabelStore = new NodeLabelStore();
  nodeConversionStore = new NodeConversionStore();
  edgeValueStore = new EdgeValueStore();
  mergeNodesStore = new MergeNodesStore();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Transformation drawer state
   */
  private _open = false;
  get open(): boolean {
    return this._open;
  }
  set open(newState: boolean) {
    this._open = newState;
  }

  /**
   * Transformation types
   */
  private _transformationType: TransformationType = 'edgeValue';
  get transformationType(): TransformationType {
    return this._transformationType;
  }
  set transformationType(newState: TransformationType) {
    this._transformationType = newState;
  }
}
export default TransformationsDrawerStore;
