import React, { useEffect, createRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDrag, useDrop } from 'react-dnd';

import {
  Box,
  ClickAwayListener,
  IconButton,
  Tooltip,
  Slide,
  useTheme,
} from '@mui/material';
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  FilterList,
} from '@mui/icons-material';

import { DragAndDropTypes } from '../types/dragAndDropTypes';

import ViewStore from '../stores/ViewStore';
import dashboardStore from '../stores/DashboardStore';
import Actions from '../actions/actions';
import { Action } from '../actions/actions';

import { numberFormatter } from '../utils/utils';

import ShrinkToFitInput from './lib/ShrinkToFitInput';
import ViewToolbar from './ViewToolbar';
import FilterSelector from './FilterSelector';
import ThreeView from '../three/ThreeView';
import HelperToolTip from './HelpToolTip';
import { LoadingBackdropOf } from './LoadingBackdrop';
import { textEllipsis } from '../styles/common';

interface ViewProps {
  viewStore: ViewStore;
}

interface DragItem {
  id: string;
  type: typeof DragAndDropTypes;
}

/**
 * Convert an action as defined in actions.tsx into a clickable icon.
 */
export const actionToButton = (
  action: Action,
  customHighlightFunction?: () => boolean,
): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);
  const highlighted = customHighlightFunction
    ? customHighlightFunction()
    : action.highlighted;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip
        open={showTooltip}
        title={action.longLabel + ' ' + action.commandString}
        arrow
      >
        <Box display="flex" justifyContent="center">
          <IconButton
            color={highlighted ? 'primary' : 'default'}
            sx={{
              padding: '0',
              backgroundColor: 'transparent',
              fontSize: 'inherit',
            }}
            disabled={action.disabled}
            onClick={action.action}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => {
              setShowTooltip(false);
            }}
          >
            {action.icon}
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

/**
 * The non-three.js component for a view. Everything outside of the canvas.
 */
const View = observer(({ viewStore }: ViewProps): JSX.Element => {
  /**
   * Ref to the box (<div>) that the ThreeView will append to.
   */
  const bodyContainer = createRef<HTMLDivElement>();
  const canvasContainer = createRef<HTMLDivElement>();

  const [editedLabel, setEditedLabel] = useState<string>(viewStore.label);

  const [editedXNodeLabel, setEditedXNodeLabel] = useState<string>(
    viewStore.xNode?.label || '',
  );
  const [editingXNodeLabel, setEditingXNodeLabel] = useState<boolean>(false);

  const [editedYNodeLabel, setEditedYNodeLabel] = useState<string>(
    viewStore.yNode?.label || '',
  );
  const [editingYNodeLabel, setEditingYNodeLabel] = useState<boolean>(false);

  // called only once when the view is created
  useEffect(() => {
    if (!canvasContainer.current) return;
    viewStore.threeView = new ThreeView(viewStore, canvasContainer.current);
    return () => {
      viewStore.close();
    };
  }, []);

  useEffect(() => {
    setEditedLabel(viewStore.label);
  }, [viewStore.label]);

  useEffect(() => {
    setEditedXNodeLabel(viewStore.xNode?.label || '');
  }, [viewStore.xNode?.label]);

  useEffect(() => {
    setEditedYNodeLabel(viewStore.yNode?.label || '');
  }, [viewStore.yNode?.label]);

  /**
   * There's an issue with chrome's native dragging where it takes the node labels
   * and grid axis labels into account when creating the drag preview. This causes
   * the preview to be too large. For now, we're just not using the preview.
   *
   * If the issue gets fixed replace the first line with:
   *
   *   const [, drag, preview] = useDrag(() => ({
   *
   * and add ref={preview} to the element that you want to actually drag (likely the
   * container).
   *
   * See: https://github.com/react-dnd/react-dnd/issues/832
   *
   * Note that this will likely go away when we convert node and axis labels to
   * three.js objects (and not DOM elements)
   */
  const [, drag] = useDrag(() => ({
    type: DragAndDropTypes.VIEW,
    item: { id: viewStore.id },
    canDrag: () => dashboardStore.openViews.length > 1,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
    end: () => {
      dashboardStore.dragHoverViewId = undefined;
    },
  }));

  const [, drop] = useDrop(() => ({
    accept: DragAndDropTypes.VIEW,
    drop: (item: DragItem, monitor) => {
      if (!monitor.isOver() || item.id === viewStore.id) {
        return;
      }
      dashboardStore.dropView(item.id, viewStore.id);
    },
    hover: (item: DragItem, monitor) => {
      if (!monitor.isOver()) {
        return;
      }
      if (item.id === viewStore.id) {
        dashboardStore.dragHoverViewId = undefined;
        return;
      }
      dashboardStore.dragHoverViewId = viewStore.id;
    },
  }));

  /**
   * Mouse events are handled here and passed to the ThreeView.
   */

  const handleMouseEvents = (event: React.MouseEvent) => {
    if (!canvasContainer?.current || !viewStore.threeView) return;
    viewStore.threeView.handleMouseEvents(event);
  };

  const handleWheel = (event: React.WheelEvent) => {
    viewStore.threeView?.handleZoom(event);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    viewStore.threeView?.handleClickAway(event);
  };

  /**
   * View Label Editing
   */

  const handleLabelChange = (value: string) => {
    setEditedLabel(value);
  };

  const handleLabelKeydown = (event: React.KeyboardEvent) => {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Enter') {
      input.blur();
    } else if (event.key === 'Escape') {
      // Revert to the original label
      setEditedLabel(viewStore.label);
    }
    event.stopPropagation();
  };

  const handleLabelBlur = () => {
    viewStore.userChangeLabel(editedLabel);
  };

  /**
   * X and Y Node Label Editing
   */

  const handleXYNodeLabelChange = (value: string, axis: 'x' | 'y') => {
    if (axis === 'x') {
      setEditedXNodeLabel(value);
    } else {
      setEditedYNodeLabel(value);
    }
  };

  const handleXYNodeLabelKeydown = (
    event: React.KeyboardEvent,
    axis: 'x' | 'y',
  ) => {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Enter') {
      input.blur();
    } else if (event.key === 'Escape') {
      // Revert to the original label
      if (axis === 'x') {
        setEditedXNodeLabel(viewStore.xNode?.label || '');
        setEditingXNodeLabel(false);
      } else {
        setEditedYNodeLabel(viewStore.yNode?.label || '');
        setEditingYNodeLabel(false);
      }
    }
    event.stopPropagation();
  };

  const handleXYNodeLabelBlur = (axis: 'x' | 'y') => {
    if (axis === 'x') {
      viewStore.userChangeXYNodeLabel(editedXNodeLabel, 'x');
      setEditingXNodeLabel(false);
    } else {
      viewStore.userChangeXYNodeLabel(editedYNodeLabel, 'y');
      setEditingYNodeLabel(false);
    }
  };

  const cursor = 'auto';
  const selectedDotSize = '0.5em';

  const theme = useTheme();
  const rulerDim = 20.5;

  const styles = {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      label: 'view-container',
      overflow: 'hidden',
    },
    dropTarget: {
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: `${theme.shape.borderRadius}px`,
      position: 'absolute',
      display: viewStore.dragHovered ? 'hidden' : 'none',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 30,
      pointerEvents: 'none',
    },
    header: {
      display: 'flex',
      position: 'relative',
      height: '34px',
      overflow: 'hidden',
      '&:hover':
        dashboardStore.openViews.length > 1
          ? {
              cursor: 'grab',
            }
          : {},
      label: 'view-header',
      padding: 0,
    },
    iconButton: {
      padding: '0',
      backgroundColor: 'transparent',
      fontSize: '18px',
    },
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={styles.container}
        onMouseDown={() => (dashboardStore.currentViewStore = viewStore)}
        ref={drop}
      >
        {/* Drop target border */}
        <Box sx={styles.dropTarget}>&nbsp;</Box>

        {/* Header */}
        <Box
          key="header"
          sx={styles.header}
          onMouseUp={handleMouseEvents}
          ref={drag}
        >
          {/* Title */}
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              overflow: 'hidden',
              textAlign: 'center',
              justifyContent: 'center',
              paddingTop: '6px',
            }}
          >
            {/* Selected Dot */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
              }}
            >
              {!viewStore.current ? null : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: selectedDotSize,
                    height: selectedDotSize,
                    bgcolor: theme.palette.success.light,
                    borderRadius: '50%',
                  }}
                ></Box>
              )}
            </Box>

            <Box
              sx={{ padding: 0 }}
              onContextMenu={(event) =>
                dashboardStore.openContextMenu(
                  { left: event.pageX, top: event.pageY + 10 },
                  'view',
                )
              }
            >
              <ShrinkToFitInput
                value={editedLabel}
                name="view-label"
                onChange={handleLabelChange}
                onKeyDown={handleLabelKeydown}
                onBlur={handleLabelBlur}
              />
            </Box>
          </Box>

          {/* Close button */}
          <Box
            sx={{
              display: 'flex',
              width: 24,
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            {actionToButton(Actions.uniqueActions.closeView)}
          </Box>
        </Box>

        {/* Body */}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          {/* X Axis Label */}
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              padding: '0 4px 0 4px',
              border: '1px solid #ccc',
              borderBottom: 'none',
              borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
              bottom: 0,
              height: `${rulerDim}px`,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 30,
              fontSize: 12,
              maxWidth: editingXNodeLabel ? '100%' : '70%',
              minWidth: '16px',
              overflow: 'hidden',
              cursor: 'pointer',
              label: 'x-axis-label',
            }}
            onContextMenu={(event) =>
              dashboardStore.openContextMenu(
                { left: event.pageX + 10, top: event.pageY + 10 },
                'xNode',
              )
            }
            onClick={() => setEditingXNodeLabel(true)}
            onMouseUp={handleMouseEvents}
          >
            {editingXNodeLabel ? (
              <ShrinkToFitInput
                value={editedXNodeLabel}
                name="x-axis-label-shrink-to-fit"
                autoFocus={true}
                onChange={(event) => handleXYNodeLabelChange(event, 'x')}
                onBlur={() => handleXYNodeLabelBlur('x')}
                onKeyDown={(event) => handleXYNodeLabelKeydown(event, 'x')}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  ...textEllipsis({ lines: 1 }),
                }}
              >
                {editedXNodeLabel}
              </Box>
            )}
          </Box>

          {/* Y Axis Label */}
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              padding: '0 4px 0 4px',
              border: '1px solid #ccc',
              borderTop: 'none',
              borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
              // Note: need to use the dims because we're setting the width relative to the canvas height
              maxWidth: editingYNodeLabel
                ? `${viewStore.bottom - viewStore.top - rulerDim}px`
                : `${(viewStore.bottom - viewStore.top - rulerDim) * 0.7}px`,
              minWidth: '16px',
              top: '50%',
              left: 0,
              height: `${rulerDim}px`,
              transform: 'rotate(-90deg) translateX(-50%)',
              transformOrigin: 'top left',
              zIndex: 30,
              cursor: 'pointer',
              label: 'y-axis-label-absolute',
            }}
            onContextMenu={(event) =>
              dashboardStore.openContextMenu(
                { left: event.pageX + 10, top: event.pageY + 10 },
                'yNode',
              )
            }
            onClick={() => setEditingYNodeLabel(true)}
            onMouseUp={handleMouseEvents}
          >
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                fontSize: 12,
                label: 'y-axis-label-editing',
              }}
            >
              {editingYNodeLabel ? (
                <ShrinkToFitInput
                  value={editedYNodeLabel}
                  name="y-axis-label-shrink-to-fit"
                  autoFocus={true}
                  onChange={(event) => handleXYNodeLabelChange(event, 'y')}
                  onBlur={() => handleXYNodeLabelBlur('y')}
                  onKeyDown={(event) => handleXYNodeLabelKeydown(event, 'y')}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    label: 'y-axis-label-static',
                    ...textEllipsis({ lines: 1 }),
                  }}
                >
                  {editedYNodeLabel}
                </Box>
              )}
            </Box>
          </Box>

          {/* Filter Icon */}
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              zIndex: 10,
              label: 'filter-icon',
              border: `1px solid ${
                viewStore.filterNodes.length > 0 ? '#1976d2' : '#ccc'
              }`,
              borderRight: 'none',
              borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
              backgroundColor: 'white',
            }}
            onMouseUp={handleMouseEvents}
          >
            <IconButton
              sx={styles.iconButton}
              color={viewStore.filterNodes.length > 0 ? 'primary' : 'default'}
              onClick={() =>
                (viewStore.filterSelectorOpen = !viewStore.filterSelectorOpen)
              }
            >
              <FilterList />
            </IconButton>
          </Box>

          {/* Bottom Left filler */}
          {viewStore.showRulers ? (
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: '#f1f1f1',
                left: 0,
                bottom: 0,
                height: `${rulerDim}px`,
                width: `${rulerDim}px`,
                zIndex: 10,
                border: '1px solid #ccc',
                borderBottom: 'none',
                borderLeft: 'none',
              }}
            >
              &nbsp;
            </Box>
          ) : null}

          {/* Toolbar icon */}
          <Tooltip title={'Show / Hide the Toolbar'} placement="top" arrow>
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 4,
                zIndex: 10,
                width: `${rulerDim}px`,
                height: `30px`,
                border: `1px solid ${theme.palette.grey[400]}`,
                borderLeft: 'none',
                borderRight: viewStore.toolbarOpen
                  ? 'none'
                  : `1px solid ${theme.palette.grey[400]}`,
                borderRadius: viewStore.toolbarOpen
                  ? 0
                  : `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
                label: 'toolbar-icon',
                boxShadow: 1,
              }}
              onMouseUp={handleMouseEvents}
            >
              <IconButton
                sx={{
                  padding: '0',
                  margin: '0',
                  backgroundColor: 'transparent',
                  fontSize: '18px',
                }}
                color={viewStore.toolbarOpen ? 'primary' : 'default'}
                onClick={() => (viewStore.toolbarOpen = !viewStore.toolbarOpen)}
              >
                {viewStore.toolbarOpen ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            </Box>
          </Tooltip>

          {/* Toolbar */}
          <Slide in={viewStore.toolbarOpen} direction="right">
            <Box
              sx={{
                position: 'absolute',
                left: `${rulerDim}px`,
                top: 4,
                zIndex: 20,
              }}
            >
              <ViewToolbar viewStore={viewStore} />
            </Box>
          </Slide>

          {/* FilterSelector */}
          <Slide in={viewStore.filterSelectorOpen} direction="left">
            <Box
              sx={{
                position: 'absolute',
                right: '22px',
                top: '50%',
                zIndex: 20,
              }}
            >
              <FilterSelector viewStore={viewStore} />
            </Box>
          </Slide>

          {/* Canvas */}
          <HelperToolTip
            inAppHelpItem={dashboardStore.onboardingHelpStore.getItemByIndex(2)}
            inAppHelpStore={dashboardStore.onboardingHelpStore}
            allowOpen={viewStore.current}
            childrenSx={{ flexGrow: 1 }} // This will allow the tooltip to take up the entire space of the canvas
            tooltipSx={{
              left: dashboardStore.openViews.length === 1 ? '-95%' : '0%',
            }} // This will allow the tooltip to be positioned inside the canvas if there's only one single view
          >
            <Box
              {...{ ref: bodyContainer }}
              sx={{
                display: 'block',
                flexGrow: 1,
                cursor: cursor,
                backgroundColor: theme.palette.grey[100],
                position: 'relative',
                zIndex: 3,
                overflow: 'hidden',
                label: 'view-body',
              }}
            >
              <Box
                {...{ ref: canvasContainer }}
                onMouseDown={handleMouseEvents}
                onMouseMove={handleMouseEvents}
                onMouseUp={handleMouseEvents}
                onMouseOver={handleMouseEvents}
                onMouseOut={handleMouseEvents}
                onContextMenu={(e) => e.preventDefault()}
                onWheel={handleWheel}
              ></Box>
            </Box>
          </HelperToolTip>
        </Box>
        {/* Footer */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '12px',
            fontSize: '9px',
            padding: '0 2px 0 2px',
            label: 'view-footer',
          }}
          onMouseUp={handleMouseEvents}
        >
          {/* Nodes/Edges */}
          <Box
            sx={{
              minWidth: '100px',
              margin: '0 0 0 20px',
            }}
          >
            Nodes: {numberFormatter(viewStore.totalSelectedNodes)} /{' '}
            {numberFormatter(viewStore.totalNodes)}
          </Box>
          <Box>
            Edges: {numberFormatter(viewStore.totalSelectedEdges)} /{' '}
            {numberFormatter(viewStore.totalEdges)}
          </Box>

          {/* x/y */}
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 0,
            }}
          >
            <Box>x:</Box>
            <Box sx={{ minWidth: '60px' }}>
              {viewStore.datetimeFormat
                ? new Date(
                    viewStore.currentMouseCoordinatesIntrinsic.x,
                  ).toLocaleDateString()
                : numberFormatter(viewStore.currentMouseCoordinatesIntrinsic.x)}
            </Box>
            <Box>y:</Box>
            <Box sx={{ minWidth: '60px' }}>
              {numberFormatter(viewStore.currentMouseCoordinatesIntrinsic.y)}
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
});

const ViewWithBackdrop = observer(({ viewStore }: ViewProps): JSX.Element => {
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <View viewStore={viewStore} />
      {viewStore.loadingBackdropStore.backdropOpen && (
        <LoadingBackdropOf
          loadingBackdropStore={viewStore.loadingBackdropStore}
        />
      )}
    </Box>
  );
});

export default ViewWithBackdrop;
