import { computed, makeObservable, observable } from 'mobx';
import { Action } from '../actions/actions';

/**
 * Mobx store for a generic Menu Item
 */
export default class MenuStore {
  type: 'item' | 'divider';
  action: Action | null;
  label: string;
  disabled = () => false;
  hover = false; // todo: not currently used

  parent?: MenuStore;
  get isRoot() {
    return !this.parent;
  }

  items: MenuStore[] = [];

  /**
   * open means that the _children_ are open/visible
   * not the menu item itself
   */
  _open = false;
  get open() {
    return this._open;
  }
  set open(value: boolean) {
    this._open = value;
  }

  /**
   * Flag to specify if the menu is at the right of the screen. If so,
   * we draw any children to the left of the parent.
   */
  private _atRight = false;
  get atRight() {
    if (this.parent && this.parent.atRight) {
      return true;
    }
    return this._atRight;
  }
  set atRight(value: boolean) {
    this._atRight = value;
  }

  // width = 0;
  position: { left: number; top: number } = {
    left: 0,
    top: 0,
  };

  constructor(
    type: 'item' | 'divider' = 'item',
    action: Action | null = null,
    label = '',
    disabled: (() => boolean) | null = null,
    items: MenuStore[] = [],
  ) {
    this.type = type;
    this.items = items;
    this.action = action;

    if (action) {
      this.label = action.shortLabel;
      this.disabled = action.disabledFunction;
    } else {
      this.label = label;
      this.disabled = disabled ? disabled : () => false;
    }

    makeObservable(this, {
      open: computed,
      _open: observable,
      position: observable,
      type: observable,
    });
  }

  static actionToMenuStore(action: Action, parent?: MenuStore) {
    const menuStore = new MenuStore('item', action, '', null);
    parent && (menuStore.parent = parent);
    return menuStore;
  }
}
