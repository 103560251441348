/**
 * The service methods should be pure, static functions that handle
 * retry, timeout and errors and parse the JSON if they succeed, set
 * the ErrorStore if they fail and return the API type (or actual
 * type) needed by the caller.
 */

import { fetchAuthSession } from 'aws-amplify/auth';
import { list, getUrl } from 'aws-amplify/storage';
import { record } from 'aws-amplify/analytics';

import {
  APIGraph,
  APIUploadHeader,
  APIUploadMethod,
  ColorByData,
  ColorType,
  GraphAccess,
  GraphDiff,
  GraphId,
  GraphMetaData,
  GraphResponse,
  GroupByStatisticType,
  MergeNodesTransformationType,
  NodeId,
  SimianRequestType,
  SortType,
  SupportedSQLDbs,
  Uuid,
  ViewId,
  SQLPreviewResult,
} from '../types/app';
import { VIEW_X_AXIS, VIEW_Y_AXIS } from '../Constants';
import ErrorStore, { ErrorStorer } from '../stores/ErrorStore';
import { Transformation } from '../stores/TransformationsDrawerStore';
import * as wasm from 'wasm_service';
import { deserializeGraphDiff } from '../utils/graphDiffHelper';
import { dbTypeFromURL } from '../utils/utils';

export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:5001';

await wasm.default();

type RetryOptions = RequestInit & {
  timeout?: number;
  retries?: number;
  authToken?: string;
};

export default class APIService {
  static ErrorStore: ErrorStorer = ErrorStore;

  /**
   * Wraps built-in fetch with an AbortController so we can time
   * the fetch out.
   *
   * @param options.timeout Time in ms to allow before aborting
   */
  private static async _fetchWithTimeout(
    resource: RequestInfo | URL,
    options: RetryOptions | undefined = {},
  ): Promise<Response | undefined> {
    const { timeout = 30_000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => {
      this.ErrorStore.setError(`Request timed out after ${timeout / 1000}s`);
      controller.abort();
    }, timeout);

    let response;
    try {
      response = await fetch(resource, {
        ...options,
        signal: controller.signal,
      });
    } catch (e) {
      // We want to return undefined on error
    }
    clearTimeout(id);
    return response;
  }

  private static async handleErrorResponse(
    response: Response,
    authToken: string,
  ): Promise<void> {
    const msg: string = await response.text();
    if (msg.trim().length === 0) {
      // Handle server error with no message
      APIService.handleEmptyServerError(response, authToken);
    } else {
      // Handle other errors
      this.ErrorStore.setError(msg);
    }
  }

  private static async handleEmptyServerError(
    response: Response,
    authToken: string,
  ): Promise<void> {
    // The following causes javascript compiler out-of-memory error
    //
    // const graphId: GraphId = dashboardStore.currentGraphStore.id;
    //

    const commsErrMsg = `Server connection failed (error code ${response.status}). Please try again later.`;
    const reloadErrMsg = `Please try reloading the page. Server connection failed (error code ${response.status})`;

    // Extract the GraphId from the url rather than from the dashboard
    const start = response.url.indexOf('graphs/') + 'graphs/'.length;
    if (start === -1) {
      this.ErrorStore.setError(commsErrMsg);
      return;
    }
    const graphId: GraphId = response.url.substring(start, start + 36);

    // Check if the graph is still running
    const runningResponse = await fetch(
      `${SERVER_URL}/v3/graphs/${graphId}/running`,
      { method: 'GET', headers: { Authorization: authToken } },
    );
    if (!runningResponse?.ok) {
      this.ErrorStore.setError(commsErrMsg);
      return;
    }
    const isRunning: boolean = await runningResponse.json();
    this.ErrorStore.setError(isRunning ? commsErrMsg : reloadErrMsg);
  }

  /**
   * Retry a fetch the number of times specified in options
   * and return undefined on error.
   *
   * @param options.retries Number of times to retry before erroring.
   * @param options.timeout Time to allow per retry (passed to _fetchWithTimeout())
   */
  private static async _fetchWithRetry(
    resource: RequestInfo | URL,
    options: RetryOptions | undefined = {},
    timeout: boolean = true,
    displayServerError: boolean = true,
  ): Promise<Response | undefined> {
    let authToken = options.authToken;
    if (!authToken) {
      try {
        authToken =
          (await fetchAuthSession()).tokens?.idToken?.toString() ?? '';
      } catch (error) {
        this.ErrorStore.setError('Could not get session.');
        return;
      }
    }
    if (!options.headers) {
      options.headers = {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip, deflate, br',
        Authorization: authToken,
      };
    } else if (!options.headers || !('Authorization' in options.headers)) {
      options.headers = {
        ...options.headers,
        Authorization: authToken,
      };
    }

    const { retries = 1 } = options;
    let response: Response | undefined;

    for (let tries = 0; tries < retries; tries++) {
      try {
        // todo: use exponential backoff
        response = timeout
          ? await APIService._fetchWithTimeout(resource, { ...options })
          : await fetch(resource, { ...options });
        if (response && response.ok) return response;
        if (response && displayServerError) {
          // API Error message
          await APIService.handleErrorResponse(response, authToken);
        }
        console.warn('fetch error', response);
      } catch (e) {
        if (tries < retries - 1)
          console.warn('Fetch aborted, trying again', resource);
        else console.warn('Fetch Failed!', resource);
      }
    }
  }

  static buildUrl(baseURL: string, params: Record<string, string>): string {
    const url = new URL(baseURL);
    // Append query parameters to the URL
    for (const key in params) {
      url.searchParams.set(key, params[key]);
    }
    return url.toString();
  }

  /**
   * Get all available graphs
   */
  static async getGraphs(): Promise<APIGraph[] | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs`,
      { method: 'GET' },
    );

    if (!response) {
      this.ErrorStore.setError('Could not get graphs.');
      return;
    }
    return await response.json();
  }

  /**
   * load a graph
   */
  static async load(graphId: GraphId, authToken = ''): Promise<boolean> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/load`,
      {
        method: 'GET',
        timeout: 120_000,
        authToken,
      },
    );

    if (!response) {
      this.ErrorStore.setError('Could not load graph.');
      return false;
    }

    record({
      name: 'graphLoaded',
      attributes: { graphId: graphId },
    });

    return response.ok;
  }

  /**
   * Create a graph.
   */
  static async newGraph(name: string): Promise<GraphId | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs?name=${name}`,
      {
        method: 'POST',
      },
    );
    if (!response || !response.ok) {
      this.ErrorStore.setError('Could not create graph.');
      return;
    }
    return await response.json();
  }

  /**
   * Clone a graph.
   */
  static async cloneGraph(
    graphId: GraphId,
    name?: string,
  ): Promise<GraphMetaData | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/clone`,
      {
        method: 'POST',
        timeout: 120_000,
        body: JSON.stringify({ name: name }),
      },
    );
    if (!response || !response.ok) {
      const msg = (await response?.text()) ?? 'Could not clone graph.';
      this.ErrorStore.setError(msg);
      return;
    }
    return await response.json();
  }

  static async clonePublicGraph(
    graphId: GraphId,
    name?: string,
  ): Promise<GraphMetaData | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/public/graphs/${graphId}/clone`, // requires sign-up
      {
        method: 'POST',
        timeout: 120_000,
        body: JSON.stringify({ name: name }),
      },
    );
    if (!response || !response.ok) {
      const msg = (await response?.text()) ?? 'Could not clone public graph.';
      console.error(msg);
      this.ErrorStore.setError(msg);
      return;
    }
    return await response.json();
  }

  static async makeGraphPublic(graphId: GraphId): Promise<boolean> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/make-public`, // requires graph level auth
      {
        method: 'PUT',
      },
    );
    if (!response || !response.ok) {
      this.ErrorStore.setError('Could not make graph public.');
      return false;
    }
    return true;
  }

  /**
   * Create a view.
   */
  static async createView(
    graphId: GraphId,
    label: string,
    xLabel: string = VIEW_X_AXIS,
    yLabel: string = VIEW_Y_AXIS,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    let params = {};
    if (label) {
      params = { label: label, xLabel: xLabel, yLabel: yLabel };
    }
    const url = APIService.buildUrl(
      `${SERVER_URL}/v3/graphs/${graphId}/views`,
      params,
    );
    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
    });

    if (!response) {
      this.ErrorStore.setError('Error creating view.');
      return;
    }

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  /**
   * Tools
   */
  static async spatial(
    graphId: GraphId,
    nodes: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=spatial`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: nodes,
        }),
      },
    );

    if (!response) {
      this.ErrorStore.setError('Error making spatial view.');
      return;
    }

    record({
      name: 'spatialViewCreated',
      attributes: { graphId: graphId },
    });

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async barplot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=barplot`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );

    if (!response) {
      return undefined;
    }

    record({
      name: 'barPlotCreated',
      attributes: { graphId: graphId },
    });

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async histogram(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=histogram`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );

    if (!response) {
      return undefined;
    }

    record({
      name: 'histogramCreated',
      attributes: { graphId: graphId },
    });

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async scatterPlot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=scatterPlot`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );

    if (!response) {
      return undefined;
    }

    record({
      name: 'scatterPlotCreated',
      attributes: { graphId: graphId },
    });

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async plot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=plot`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );
    if (!response) {
      return undefined;
    }
    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async pcaEmbedding(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=pca`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'pcaEmbeddingCreated',
      attributes: { graphId: graphId },
    });

    const [returnedViewId, diffJson] = await response.json();
    return [returnedViewId, deserializeGraphDiff(diffJson)];
  }

  static async tsneEmbedding(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/custom-view?viewType=tsne`,
      {
        method: 'POST',
        body: JSON.stringify({
          selectedNodeIds: selectedNodeIds,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'tsenEmbeddingCreated',
      attributes: { graphId: graphId },
    });

    const taskIdJson: { task_id: Uuid } = await response.json();
    const taskId: Uuid = taskIdJson.task_id;

    return taskId;
  }

  static async sortBy(
    graphId: GraphId,
    viewId: ViewId,
    selectedNodeIds: NodeId[],
    sortType: SortType,
  ): Promise<undefined | GraphDiff> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/sort-nodes?sortType=${sortType}`,
      {
        method: 'POST',
        body: JSON.stringify({
          viewId: viewId,
          selectedNodes: selectedNodeIds,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'nodesSorted',
      attributes: { graphId: graphId },
    });

    return deserializeGraphDiff(await response.json());
  }

  static async groupByStatistic(
    graphId: GraphId,
    categoricalNodeId: NodeId,
    groupByStatisticType: GroupByStatisticType,
    numericalNodeId?: NodeId,
  ): Promise<undefined | [NodeId, ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/group-nodes-by?groupByStatisticType=${groupByStatisticType}`,
      {
        method: 'POST',
        body: JSON.stringify({
          categoricalNodeId: categoricalNodeId,
          numericalNodeId: numericalNodeId,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'groupByStatisticsCalculated',
      attributes: { graphId: graphId },
    });

    const [newHeaderId, viewId, diffJson] = await response.json();
    return [newHeaderId, viewId, deserializeGraphDiff(diffJson)];
  }

  static async getCorrelations(
    graphId: GraphId,
    featureNodes: NodeId[],
  ): Promise<undefined | [[number, number], string[], number[]][]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/calculate-all-correlations`,
      {
        method: 'POST',
        body: JSON.stringify({
          featureNodes: featureNodes,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'correlationCalculated',
      attributes: { graphId: graphId },
    });

    const correlations = await response.json();
    return correlations;
  }

  static async getAnomalyView(
    graphId: GraphId,
    featureNodes: NodeId[],
  ): Promise<Uuid | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/get-anomalies/view`,
      {
        method: 'POST',
        body: JSON.stringify({
          featureNodes: featureNodes,
        }),
      },
      true,
      false,
    );

    if (!response) {
      return;
    }
    const packedResponse = await response.json();

    // In this case, no anomalies were found.
    if (!packedResponse) {
      return;
    }

    const taskIdJson: { task_id: Uuid } = packedResponse;
    const taskId: Uuid = taskIdJson.task_id;
    return taskId;
  }

  static async calculateCorrelation(
    graphId: GraphId,
    featureNodes: NodeId[],
  ): Promise<undefined | [ViewId[], GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/calculate-correlation`,
      {
        method: 'POST',
        body: JSON.stringify({
          featureNodes: featureNodes,
        }),
      },
    );
    if (!response) {
      return undefined;
    }

    record({
      name: 'correlationCalculated',
      attributes: { graphId: graphId },
    });

    const [viewIds, diffJson] = await response.json();
    return [viewIds, deserializeGraphDiff(diffJson)];
  }

  /**
   * Apply K-Means clustering on a given view
   */
  static async viewClustering(
    graphId: GraphId,
    viewId: ViewId,
    numClusters: number | undefined,
    selectedNodeIds: NodeId[] | undefined,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    const url = APIService.buildUrl(
      `${SERVER_URL}/v3/graphs/${graphId}/views/${viewId}/clustering`,
      numClusters !== undefined ? { nClusters: numClusters.toString() } : {},
    );
    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: JSON.stringify({ selectedNodes: selectedNodeIds }),
    });

    if (!response) {
      return;
    }
    const [newViewId, diffJson] = await response.json();
    return [newViewId, deserializeGraphDiff(diffJson)];
  }

  static async colorBy(
    graphId: GraphId,
    colorType: ColorType,
    selectedNodeIds?: NodeId[],
    viewId?: ViewId,
    colorByNodeId?: NodeId,
    reversed?: boolean,
  ): Promise<GraphDiff | undefined> {
    const colorData: ColorByData = {};
    switch (colorType) {
      case 'group':
        if (selectedNodeIds && selectedNodeIds.length > 0) {
          colorData.group = { selectedNodes: selectedNodeIds };
        } else if (viewId) {
          colorData.group = { viewId: viewId };
        } else {
          throw new Error('Missing required parameters for colorBy group.');
        }
        break;
      case 'spatialGroup':
        if (!viewId) return;
        colorData.spatialGroup = { viewId: viewId };
        if (selectedNodeIds && selectedNodeIds.length > 0) {
          colorData.spatialGroup.selectedNodes = selectedNodeIds;
        }
        break;
      case 'density':
        if (!viewId) return;
        colorData.density = { viewId: viewId };
        if (selectedNodeIds && selectedNodeIds.length > 0) {
          colorData.density.selectedNodes = selectedNodeIds;
        }
        break;
      case 'node':
        if (!colorByNodeId || reversed === undefined) return;
        colorData.node = { nodeId: colorByNodeId, reversed: reversed };
        if (selectedNodeIds && selectedNodeIds.length > 0) {
          colorData.node.selectedNodes = selectedNodeIds;
        } else if (viewId) {
          colorData.node.viewId = viewId;
        }
        break;
      case 'spectral':
        if (!viewId) return;
        colorData.spectral = { viewId: viewId };
        if (selectedNodeIds && selectedNodeIds.length > 0) {
          colorData.spectral.selectedNodes = selectedNodeIds;
        }
        break;
      default:
        throw new Error(`Unknown color type: ${colorType}`);
    }
    const jsonBody = JSON.stringify(colorData);
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/color-by`,
      {
        method: 'POST',
        body: jsonBody,
      },
    );
    if (!response) {
      return;
    }
    return deserializeGraphDiff(await response.json());
  }

  static async sizeBy(
    graphId: GraphId,
    nodeId: NodeId,
    reverse: boolean,
  ): Promise<GraphDiff | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/size-by`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodeId: nodeId,
          reverse: reverse,
        }),
      },
    );
    if (!response) {
      return;
    }
    return deserializeGraphDiff(await response.json());
  }

  static async receiveBinaryGraphDiff(response: Response): Promise<GraphDiff> {
    // Read the entire byte array from the stream
    const bytesData = await response.arrayBuffer();

    // Cast data type
    const uint8ArrayData = new Uint8Array(bytesData);

    // Deserialize binary data
    const diff: GraphDiff = wasm.diff_from_bytes(uint8ArrayData);
    return diff;
  }

  static async receiveGraphResponse(
    response: Response,
  ): Promise<GraphResponse> {
    const bytesData = await response.arrayBuffer();
    const uint8ArrayData = new Uint8Array(bytesData);
    const graphResponse: GraphResponse =
      wasm.graph_response_from_bytes(uint8ArrayData);
    return graphResponse;
  }

  /**
   * Copy a view in the given graph.
   */
  static async duplicateView(
    graphId: GraphId,
    viewId: ViewId,
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/views/${viewId}/duplicate`,
      { method: 'POST' },
    );

    if (!response) {
      this.ErrorStore.setError(
        `Failed to copy view ${viewId} on graph ${graphId}`,
      );
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }
    const [newViewId, diffJson] = await response.json();

    return [newViewId, deserializeGraphDiff(diffJson)];
  }

  static async deleteView(graphId: GraphId, viewId: ViewId): Promise<void> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/views/${viewId}`,
      { method: 'DELETE' },
    );

    if (!response) {
      this.ErrorStore.setError(`Failed to delete view ${viewId}`);
    }
  }

  static async runCodeFromNode(
    graphId: GraphId,
    operationNodeId: NodeId,
  ): Promise<GraphDiff | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/execute-operation`,
      {
        method: 'POST',
        body: JSON.stringify({
          operationNode: operationNodeId,
        }),
      },
    );

    if (!response) {
      this.ErrorStore.setError(
        `Failed to run code in node ${operationNodeId}.`,
      );
      return;
    }

    return deserializeGraphDiff(await response.json());
  }

  static async mergeNodes(
    graphId: GraphId,
    nodes: NodeId[],
    mergeType: MergeNodesTransformationType,
  ): Promise<undefined | GraphDiff> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/merge-nodes?mergeType=${mergeType}`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodes: nodes,
        }),
      },
    );

    if (!response) {
      return;
    }

    const diffJson = await response.json();
    return deserializeGraphDiff(diffJson);
  }

  static async exportGraphAsNetworkX(
    graphId: GraphId,
    authToken = '',
  ): Promise<Blob | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/export-node-link`,
      { method: 'GET', authToken },
    );
    if (!response) {
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }
    return await response.blob();
  }

  static async exportSelectionAsCSV(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/export-selection`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodes: selectedNodeIds,
        }),
      },
    );

    if (!response) {
      return;
    }

    return await response.text();
  }

  static async downloadSelectedNodesFiles(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/download-files`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodes: selectedNodeIds,
        }),
      },
    );

    if (!response) {
      return;
    }

    const taskIdJson: { task_id: Uuid } = await response.json();
    const taskId: Uuid = taskIdJson.task_id;

    return taskId;
  }

  static async transformNodesAndEdges(
    graphId: GraphId,
    currentViewId: ViewId,
    nodes: NodeId[],
    transformation: Transformation,
    inPlace: boolean,
  ): Promise<undefined | [ViewId, GraphDiff]> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/transform-nodes-and-edges`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodes: nodes,
          currentViewId: currentViewId,
          transformation: {
            [transformation.type]: {
              [transformation.name]: transformation.parameters,
            },
          },
          inPlace: inPlace,
        }),
      },
    );

    if (!response) {
      return;
    }

    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  /**
   * Get an AWS presigned URL if we can.
   */
  static async getPresignedURL(
    url: string,
    idToken: string | undefined = undefined,
  ): Promise<string | undefined> {
    // Return unchanged URL for public HTTP resources:
    if (url.substring(0, 4) === 'http') {
      return url;
    }

    // Get presigned URL from backend for S3 URIs:
    if (url.substring(0, 5) === 's3://') {
      const signedUrl = await APIService.getPresignedUrlFromURI(url, idToken);
      return String(signedUrl);
    }

    // Try getting pre-sigend URL from Amplify otherwise
    // Depreciation warning: This will be phased out once we've moved
    // everything to HTTP or S3 URIs.
    if (url.substring(0, 1) === '/') {
      url = url.slice(1);
    }

    const fileExists = await list({ prefix: url }).then((response) => {
      return response.items.length > 0;
    });

    return fileExists ? (await getUrl({ key: url })).url.toString() : '';
  }

  /**
   * Non API calls
   */

  static async getPresignedUrlFromURI(
    uri: string,
    idToken: string | undefined,
  ): Promise<string | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/signed-url`,
      {
        method: 'POST',
        body: JSON.stringify({
          uri,
        }),
        authToken: idToken,
      },
    );
    if (!response) {
      this.ErrorStore.setError(`Failed to get signed URL for ${uri}`);
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return '';
    }
    return (await response.json()).data;
  }

  static async getContentType(uri: string): Promise<string | undefined> {
    let response: Response | undefined;
    try {
      response = await fetch(uri, {
        method: 'HEAD',
      });
    } catch (error) {
      return;
    }

    if (!response || !response.ok) {
      return;
    }

    return response.headers.get('Content-Type') || '';
  }

  /**
   * Save a graph
   */
  static async saveGraph(graphId: GraphId, name: string): Promise<void> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/save?name=${name}`,
      {
        method: 'PUT',
      },
      false,
    );

    if (!response || !response.ok) {
      this.ErrorStore.setError(`Failed to save graph ${name}`);
    } else {
      this.ErrorStore.setSuccess(`Graph ${name} saved`);
    }

    record({
      name: 'graphSaved',
      attributes: { graphId: graphId, graphName: name },
    });
  }

  /**
   * Delete a graph
   */
  static async deleteGraph(graphId: GraphId): Promise<void> {
    await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/delete`,
      {
        method: 'DELETE',
      },
    );
  }

  /**
   * Post data files to the server
   */
  static async postDataFiles(
    files: FileList,
    graphId: GraphId,
    graphIsNew: boolean,
    openViews: ViewId[],
  ): Promise<GraphResponse | undefined> {
    const params: Record<string, string> = { newGraph: graphIsNew.toString() };
    if (openViews.length !== 0) {
      params['openViews'] = openViews.join(',');
    }
    const url = APIService.buildUrl(
      `${SERVER_URL}/v3/graphs/${graphId}/data`,
      params,
    );
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filename = file.name.replace(/^.*[\\/]/, '');
      const re = /(?:\.([^.]+))?$/;
      const extns = re.exec(filename);
      const extn = extns && extns[1];

      if (extn && extn !== 'csv') {
        formData.append(`${filename}?filetype=${extn}`, file);
      } else {
        formData.append(filename, file);
      }
    }

    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
      },
      retries: 1,
    });

    if (!response) {
      this.ErrorStore.setError('Failed to upload files');
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }
    return await APIService.receiveGraphResponse(response);
  }

  /**
   * Upload simian data
   */
  static async uploadSimian(
    graphId: GraphId,
    requestType: SimianRequestType,
    runIds: string,
  ): Promise<Uuid | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/simian`,
      {
        method: 'POST',
        body: JSON.stringify({
          requestType: requestType,
          requestIds: runIds,
        }),
      },
    );

    if (!response) {
      this.ErrorStore.setError(`Failed to upload siman run ${runIds}`);
      return;
    }

    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }

    const taskIdJson: { task_id: Uuid } = await response.json();
    this.ErrorStore.setSuccess(`Uploading from Simian started`);
    const taskId: Uuid = taskIdJson.task_id;

    return taskId;
  }

  /**
   * Upload query to SQL database
   */
  static async uploadQuery(
    graphId: GraphId,
    databaseURL: string,
    query: string,
    db?: SupportedSQLDbs,
    authToken: string = '',
  ): Promise<Uuid | undefined> {
    const url = `${SERVER_URL}/v3/graphs/${graphId}/data/sql`;
    db = db || dbTypeFromURL(databaseURL.toLowerCase());
    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: JSON.stringify({
        db,
        url: databaseURL,
        query: query,
      }),
      authToken,
    });

    const response_json = await response?.json();

    if (!response) {
      ErrorStore.setError(`Failed to upload SQL query.`);
      return;
    }
    if (!response.ok) {
      ErrorStore.setError(await response.text());
      return;
    }

    return response_json['task_id'];
  }

  /**
   * Upload schema to SQL database
   */
  static async uploadSchema(
    graphId: GraphId,
    databaseURL: string,
    db?: SupportedSQLDbs,
    authToken: string = '',
  ): Promise<[ViewId, GraphDiff, number] | undefined> {
    const url = `${SERVER_URL}/v3/graphs/${graphId}/data/sql/schema`;
    db = db || dbTypeFromURL(databaseURL.toLowerCase());

    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: JSON.stringify({
        db,
        url: databaseURL,
        query: '',
      }),
      authToken,
    });

    const response_json = await response?.json();

    if (!response) {
      ErrorStore.setError(`Failed to upload SQL schema.`);
      return;
    }
    if (!response.ok) {
      ErrorStore.setError(await response.text());
      return;
    }

    return response_json;
  }

  /**
   * Upload data from SQL database and reuse schema
   */
  static async uploadSQLReuseSchema(
    graphId: GraphId,
    databaseNodeId: NodeId,
    queryOrSchema: string | undefined,
  ): Promise<Uuid | undefined> {
    const url = `${SERVER_URL}/v3/graphs/${graphId}/data/sql/reuse-schema/${databaseNodeId}`;

    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: JSON.stringify(queryOrSchema),
    });

    if (!response) {
      ErrorStore.setError(`Failed to upload SQL data.`);
      return;
    }
    if (!response.ok) {
      ErrorStore.setError(await response.text());
      return;
    }

    const response_json = await response.json();

    if (queryOrSchema) {
      return response_json['task_id'];
    } else {
      return response_json;
    }
  }

  /**
   * Preview the SQL query.
   */
  static async previewSQL(
    graphId: GraphId,
    databaseURL: string,
    query: string,
  ): Promise<SQLPreviewResult | undefined> {
    const url = `${SERVER_URL}/v3/graphs/${graphId}/data/sql/preview`;

    const response = await APIService._fetchWithRetry(url, {
      method: 'POST',
      body: JSON.stringify({
        db: dbTypeFromURL(databaseURL.toLowerCase()),
        url: databaseURL,
        query: query,
      }),
    });

    if (!response) {
      ErrorStore.setError(`Could not preview SQL query.`);
      return;
    }

    const responseJson = await response.json();

    responseJson.isValid
      ? ErrorStore.setSuccess(responseJson.message)
      : ErrorStore.setError(responseJson.message);
    return responseJson;
  }

  /**
   * Upload data from SQL database
   */
  static async uploadSQLFromNode(
    graphId: GraphId,
    queryNodeId: NodeId,
  ): Promise<Uuid | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/data/sql-from-node/${queryNodeId}`,
      {
        method: 'POST',
      },
    );

    if (!response) {
      this.ErrorStore.setError(`Failed to upload SQL data.`);
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }
    const taskIdJson: { task_id: Uuid } = await response.json();
    const taskId: Uuid = taskIdJson.task_id;
    return taskId;
  }

  /**
   * Upload data from API endpoint
   */
  static async uploadFromAPI(
    graphId: GraphId,
    queryURL: string,
    method: APIUploadMethod,
    headers: APIUploadHeader[],
    body: string,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/data/api`,
      {
        method: 'POST',
        body: JSON.stringify({
          url: queryURL,
          method: method,
          headers: headers,
          body: body,
        }),
      },
    );

    if (!response) {
      this.ErrorStore.setError(`Failed to upload request data.`);
      return;
    }
    if (!response.ok) {
      this.ErrorStore.setError(await response.text());
      return;
    }
    const [view, diffJson] = await response.json();
    return [view, deserializeGraphDiff(diffJson)];
  }

  static async getDescribingView(
    graphId: GraphId,
    importanceData: [NodeId, number][],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/describe-nodes/view`,
      {
        method: 'POST',
        body: JSON.stringify({
          importanceData: importanceData,
        }),
      },
      true,
      false,
    );

    if (!response) {
      return;
    }

    const [view, diffJson] = await response.json();
    return [view, deserializeGraphDiff(diffJson)];
  }

  static async labelEmbedding(
    graphId: GraphId,
    nodes: NodeId[] | NodeId,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    const body: Record<string, unknown> = {};
    if (Array.isArray(nodes)) {
      body['nodes'] = nodes;
    } else {
      body['header'] = nodes;
    }
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/embed-labels`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
    );
    if (!response) {
      this.ErrorStore.setError(`Failed to extract string features`);
      return;
    }
    const [viewId, diffJson] = await response.json();
    return [viewId, deserializeGraphDiff(diffJson)];
  }

  static async duplicateNodes(
    graphId: GraphId,
    nodeIds: NodeId[],
    viewId: ViewId | undefined,
    offset: [number, number] | undefined,
  ): Promise<GraphDiff | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/duplicate-nodes`,
      {
        method: 'POST',
        body: JSON.stringify({
          nodeIds: nodeIds,
          viewId: viewId,
          offset: offset,
        }),
      },
    );
    if (!response) {
      this.ErrorStore.setError(`Failed to duplicate nodes.`);
      return;
    }
    return APIService.receiveBinaryGraphDiff(response);
  }

  /**
   * Onboarding status
   */
  static async getOnboardingStatus(): Promise<boolean | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/onboarding-status`,
      {
        method: 'GET',
      },
    );

    if (response === undefined) {
      return;
    }
    return await response.json();
  }

  static async updateOnboardingStatus(): Promise<Response | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/onboarding-status`,
      {
        method: 'PUT',
      },
    );

    if (!response) {
      return;
    }

    return response;
  }

  static async getGraphAccessList(
    graphId: GraphId,
  ): Promise<GraphAccess | undefined> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/share`,
      {
        method: 'GET',
      },
    );
    if (!response || !response.ok) {
      this.ErrorStore.setError(`Failed to get graph access list.`);
      return;
    }
    return response.json();
  }

  static async setGraphAccessList(
    graphId: GraphId,
    access: { emails: string[]; groups: string[]; ownerEmail: string },
  ): Promise<boolean> {
    const response = await APIService._fetchWithRetry(
      `${SERVER_URL}/v3/graphs/${graphId}/share`,
      {
        method: 'post',
        body: JSON.stringify(access),
      },
    );
    if (!response || !response.ok) {
      this.ErrorStore.setError(`Failed to get graph access list.`);
      return false;
    }
    return true;
  }
}
