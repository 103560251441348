import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import dashboardStore from '../stores/DashboardStore';
import { FieldState, NodeProperty } from '../stores/NodePropertiesStore';

import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import { runInAction } from 'mobx';

// Todo: makeStyles is deprecated, replace with sx prop
const useStyles = makeStyles({
  sectionHeader: {
    marginTop: '2em',
    marginBottom: '0em',
    textAlign: 'center',
  },
});

export const NodePropertiesDialog = observer((): JSX.Element => {
  const classes = useStyles();

  // todo: debounce this
  const handleChange = () => {
    let allow = true;
    let problem = '';
    dashboardStore.nodePropertiesStore.nodeProperties.forEach(
      (property: NodeProperty) => {
        if (!property.editable) return;
        if (property.state === FieldState.MultipleMixed) return;

        if (property.state === FieldState.None) {
          problem = property.key;
          allow = false;
          return;
        }

        if (property.value?.trim() === '') {
          if (!(property.key === 'url')) {
            problem = property.key;
            allow = false;
          }
        }
      },
    );
    runInAction(() => {
      dashboardStore.nodePropertiesStore.problemField = problem;
      dashboardStore.nodePropertiesStore.allowSubmit = allow;
    });
  };

  const handleSubmit = () => {
    runInAction(() => {
      handleChange();
    });
    if (dashboardStore.nodePropertiesStore.allowSubmit) {
      runInAction(() => {
        dashboardStore.nodePropertiesStore.updateNodeProperties();
        dashboardStore.nodePropertiesStore.open = false;
      });
    } else {
      // todo: error msgs?
    }
  };
  const handleCancel = () => {
    runInAction(() => {
      dashboardStore.nodePropertiesStore.problemField = '';
      dashboardStore.nodePropertiesStore.open = false;
    });
  };

  function isElementActive(key: string) {
    const element = document.getElementById(key);
    return element?.contains(document.activeElement);
  }

  const renderStringField = (
    propertyStore: NodeProperty,
    autoFocus: boolean,
  ): JSX.Element => {
    const disabled =
      !propertyStore.editable ||
      (propertyStore.state === FieldState.MultipleMixed &&
        !propertyStore.allowMultiEdit);
    return (
      <TextField
        multiline
        error={
          dashboardStore.nodePropertiesStore.problemField === propertyStore.key
        }
        margin="dense"
        size="small"
        id={propertyStore.key}
        label={propertyStore.key}
        key={propertyStore.key}
        fullWidth
        type="text"
        value={propertyStore.value}
        disabled={disabled}
        autoFocus={autoFocus}
        onFocus={(e) => autoFocus && e.target.select()}
        onChange={(e) => {
          runInAction(() => {
            propertyStore.value = e.target.value;
            handleChange();
          });
        }}
        onKeyDown={(e) => {
          const urlElementActive = isElementActive(
            dashboardStore.nodePropertiesStore.url.key,
          );
          if (urlElementActive === false && e.key === 'Enter' && !e.shiftKey) {
            runInAction(() => {
              handleSubmit();
            });
            e.stopPropagation();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() =>
                navigator.clipboard.writeText(
                  (propertyStore.value as string) ?? '',
                )
              }
              size="large"
            >
              <FileCopyIcon />
            </IconButton>
          ),
        }}
      />
    );
  };

  const renderBooleanField = (propertyStore: NodeProperty): JSX.Element => {
    // todo: consider acting on all selected nodes (not just from the current view)
    const showString = dashboardStore.currentGraphStore?.singleNodeSelected
      ? 'Show Label'
      : 'Show Labels';
    const hideString = dashboardStore.currentGraphStore?.singleNodeSelected
      ? 'Hide Label'
      : 'Hide Labels';
    const options =
      propertyStore.state === FieldState.MultipleMixed
        ? [
            { label: showString, value: 'true' },
            { label: 'Mixed', value: '[multiple values]' },
            { label: hideString, value: 'false' },
          ]
        : [
            { label: showString, value: 'true' },
            { label: hideString, value: 'false' },
          ];

    return (
      <SwitchButton
        options={options}
        value={propertyStore.value as 'true' | 'false' | 'mixed'}
        onChange={(option: SwitchOption) => {
          runInAction(() => {
            propertyStore.value = option.value as 'true' | 'false' | 'mixed';
            handleChange();
          });
        }}
      />
    );
  };

  const renderNumberField = (propertyStore: NodeProperty): JSX.Element => {
    // Note: using a text field so we can display '[multiple values]'
    return (
      <TextField
        error={
          dashboardStore.nodePropertiesStore.problemField === propertyStore.key
        }
        margin="dense"
        size="small"
        fullWidth
        id={propertyStore.key}
        label={propertyStore.key}
        key={propertyStore.key}
        type="text"
        value={propertyStore.value}
        onChange={(e) => {
          runInAction(() => {
            propertyStore.value = e.target.value;
            handleChange();
          });
        }}
      />
    );
  };

  return (
    <Dialog
      open={dashboardStore.nodePropertiesStore.open}
      onClose={handleCancel}
      onKeyDown={(e) => {
        const urlElementActive = isElementActive(
          dashboardStore.nodePropertiesStore.url.key,
        );
        if (urlElementActive === false && e.key === 'Enter' && !e.shiftKey)
          runInAction(() => {
            handleSubmit();
          });
        if (e.key === 'Escape') handleCancel();
        e.stopPropagation();
      }}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {dashboardStore.nodePropertiesStore.title}
      </DialogTitle>
      <DialogContent>
        {renderStringField(dashboardStore.nodePropertiesStore.label, true)}
        {renderBooleanField(dashboardStore.nodePropertiesStore.showLabel)}
        {/* todo: insert the carousel here */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
          }}
        >
          <Box
            sx={{
              paddingLeft: '0.5em',
            }}
          >
            <DialogContentText
              variant="subtitle1"
              className={classes.sectionHeader}
              style={{ textAlign: 'left' }}
            >
              Position & Size
            </DialogContentText>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '0.5em',
              }}
            >
              {renderNumberField(dashboardStore.nodePropertiesStore.x)}
              {renderNumberField(dashboardStore.nodePropertiesStore.y)}
              {renderNumberField(dashboardStore.nodePropertiesStore.size)}
            </Box>
          </Box>
          <Box
            sx={{
              paddingLeft: '0.5em',
            }}
          ></Box>
          <Box sx={{ paddingLeft: '0.5em' }}>
            <DialogContentText
              variant="subtitle1"
              className={classes.sectionHeader}
              style={{ textAlign: 'left' }}
            >
              Other Node Properties
            </DialogContentText>
            {renderStringField(dashboardStore.nodePropertiesStore.id, false)}
            {renderStringField(dashboardStore.nodePropertiesStore.url, false)}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Note: need the onMouseDown get around the onBlur event ordering issue */}
        <Button
          onMouseDown={(e) => e.preventDefault()}
          onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onMouseDown={(e) => e.preventDefault()}
          onClick={handleSubmit}
          disabled={!dashboardStore.nodePropertiesStore.allowSubmit}
          color="primary"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default NodePropertiesDialog;
