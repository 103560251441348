import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { observer } from 'mobx-react-lite';

import { Box, useTheme } from '@mui/material';

import actions from '../actions/actions';
import Views from './Views';
import ContextMenu from './ContextMenu';
import RightDrawer from './RightDrawer';
import NavigationDrawer from './NavigationDrawer';
import NodePropertiesDialog from './NodePropertiesDialog';
import SearchDialog from './SearchDialog';
import NodesByIdDialog from './NodesByIdDialog';
import Carousel from './Carousel';
import RotateNodesDialog from './RotateNodesDialog';
import AlertDialog from './AlertDialog';

const stopWheelEvent = (e: WheelEvent) => {
  if (e.ctrlKey) {
    e.preventDefault();
  }
};

const Dashboard = observer(() => {
  const theme = useTheme();

  const styles = {
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: 0,
      label: 'dashboard-root',
    },
    body: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      overflow: 'hidden',
      margin: '4px 0 4px 0',
    },
    views: {
      position: 'relative',
      flex: 'auto',
      flexGrow: '1',
      overflow: 'hidden',
      marginRight: '4px',
      marginLeft: '4px',
    },
    navigationDrawer: {
      width: '250px',
    },
  };

  useEffect(() => {
    window.addEventListener('keydown', actions.handleKeyDown);
    window.addEventListener('keyup', actions.handleKeyUp);
    window.addEventListener('wheel', stopWheelEvent, {
      passive: false,
    });
    return () => {
      window.removeEventListener('keydown', actions.handleKeyDown);
      window.removeEventListener('keyup', actions.handleKeyUp);
      window.removeEventListener('wheel', stopWheelEvent);
    };
  }, []);

  return (
    <Box sx={styles.root}>
      <DndProvider backend={HTML5Backend}>
        <Box sx={styles.body}>
          <NavigationDrawer />
          <Views />
          <RightDrawer />
        </Box>
      </DndProvider>

      <AlertDialog></AlertDialog>
      <NodePropertiesDialog></NodePropertiesDialog>
      <Carousel></Carousel>
      <SearchDialog></SearchDialog>
      <NodesByIdDialog></NodesByIdDialog>
      <RotateNodesDialog></RotateNodesDialog>
      <ContextMenu />
    </Box>
  );
});
export default Dashboard;
