/**
 * Constants for use in the various shaders.
 *
 * They should all be exported as const strings for insertion into the shader code.
 */

export const SELECTED_NODE_COLOR = 'vec3(0.0, 1.0, 1.0)';
export const HOVERED_NODE_COLOR_BOOST = 'vec3(0.1, 0.1, 0.1)';

export const DEFAULT_NODE_SIZE_SCALAR = '1.0';
export const HOVERED_NODE_SIZE_SCALAR = '1.5';
export const SELECTED_NODE_SIZE_SCALAR = '2.0';
export const SELECTED_AND_HOVERED_NODE_SIZE_SCALAR = '2.5';

// Frag Depth lower values are drawn on top of higher values

export const DEFAULT_NODE_FRAG_DEPTH = '0.2';
export const SELECTED_NODE_FRAG_DEPTH = '0.1';
export const HOVERED_NODE_FRAG_DEPTH = '0.1';
export const SELECTED_AND_HOVERED_NODE_FRAG_DEPTH = '0.0';

export const DEFAULT_EDGE_FRAG_DEPTH = '0.1';
export const SELECTED_EDGE_FRAG_DEPTH = '0.4';
export const HOVERED_EDGE_FRAG_DEPTH = '0.4';
export const SELECTED_AND_HOVERED_EDGE_FRAG_DEPTH = '0.3';
export const DRAGGING_EDGE_FRAG_DEPTH = '0.2';
