import React from 'react';
import { Box, Button } from '@mui/material';
import dashboardStore from '../stores/DashboardStore';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useTheme } from '@mui/material/styles';

export const DataFusion = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    groupByStatistics: {
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: '5px',
      marginBottom: `4px`,
    },
    fusionTitle: {
      label: 'RightDrawer-fusionTitle',
      fontSize: '14px',
      margin: `5px`,
    },
  };
  const dataFusionStore = dashboardStore.rightDrawerStore.dataFusionStore;

  const handleFuseData = () => {
    runInAction(() => {
      dataFusionStore.runFuseData();
    });
  };

  return (
    <>
      <Box sx={styles.groupByStatistics}>
        <Box sx={{ margin: '3px' }}>
          <Box sx={styles.fusionTitle}>Auto Data Fusion</Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              disabled={!dashboardStore.currentViewStore?.anyNodesSelected}
              onClick={handleFuseData}
              sx={{
                fontSize: '10px',
                marginTop: '10px',
                textTransform: 'none',
              }}
            >
              Fuse Nodes
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
});
