import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import DataService from '../services/DataService';
import { GroupByStatisticType } from '../types/app';
import { NodeStore } from './NodeStore';

export type SectionState = 'normal' | 'selected' | 'disabled';

export class GroupByStatisticDrawerStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Drawer state
   */
  groupByStatistic: GroupByStatisticType = 'mean'; // Default value

  groupByNode = new NodeStore();
  featureNode = new NodeStore();

  isGroupByNodeDrawerDisabled = (): boolean => {
    return !dashboardStore.singleNodeSelected;
  };

  isFeatureNodeDrawerDisabled = (): boolean => {
    return !dashboardStore.singleNodeSelected;
  };

  execute = async (): Promise<void> => {
    if (this.groupByStatistic != 'count' && this.featureNode.id === undefined)
      return;
    if (this.groupByNode.id === undefined) return;

    if (!dashboardStore.currentGraphStore) return;
    if (!dashboardStore.currentViewStore) return;
    const graphStore = dashboardStore.currentGraphStore;

    const result = await DataService.groupByStatistic(
      graphStore.id,
      this.groupByNode.id,
      this.groupByStatistic,
      this.featureNode.id,
    );
    if (!result) {
      return;
    }
    const [newHeaderId, newViewId, gDiff] = result;

    graphStore.applyDiff(gDiff, false);
    dashboardStore.currentViewStore?.addNodesById([newHeaderId]);
    await dashboardStore.openSingleView(newViewId);
  };
}
