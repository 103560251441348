import { Vector2 } from 'three';
import { MouseEvent } from 'react'; // This is unfortunate, todo: should not call onSelectStart directly from ThreeView.tsx

export class RectangularMarquee {
  enabled: boolean;
  private domElement: HTMLElement;
  private marquee: HTMLDivElement;
  private startPoint: Vector2;
  private pointTopLeft: Vector2;
  private pointBottomRight: Vector2;

  constructor(domElement: HTMLElement, cssClassName: string) {
    this.marquee = document.createElement('div');
    this.marquee.classList.add(cssClassName);
    this.marquee.style.pointerEvents = 'none';

    this.domElement = domElement;

    this.startPoint = new Vector2();
    this.pointTopLeft = new Vector2();
    this.pointBottomRight = new Vector2();

    this.enabled = true;
  }

  onSelectStart(event: MouseEvent): void {
    this.domElement.parentElement?.appendChild(this.marquee);

    this.marquee.style.left = event.clientX + 'px';
    this.marquee.style.top = event.clientY + 'px';
    this.marquee.style.width = '0px';
    this.marquee.style.height = '0px';

    this.startPoint.x = event.clientX;
    this.startPoint.y = event.clientY;
  }

  onSelectMove(event: MouseEvent): void {
    this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX);
    this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY);
    this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX);
    this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY);

    this.marquee.style.left = this.pointTopLeft.x + 'px';
    this.marquee.style.top = this.pointTopLeft.y + 'px';
    this.marquee.style.width =
      this.pointBottomRight.x - this.pointTopLeft.x + 'px';
    this.marquee.style.height =
      this.pointBottomRight.y - this.pointTopLeft.y + 'px';
  }

  onSelectOver(): void {
    if (this.marquee.parentElement)
      this.marquee.parentElement.removeChild(this.marquee);
  }
}
