import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { Node, NodeId } from '../types/app';
import { NodeStoreMessage } from '../types/wasm';

/*
 * Utility class to store a single node.
 * Provides getters and update methods.
 */
export class NodeStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _node: Node | undefined = undefined;
  get node(): Node | undefined {
    return this._node;
  }
  set node(node: Node | undefined) {
    this._node = node;
  }

  get id(): NodeId | undefined {
    return this.node?.id;
  }
  get label(): string {
    return this.node?.label ?? '';
  }

  reset = (): void => {
    this.node = undefined;
  };

  /*
   * Update the node from the selected nodes in the graph store.
   */
  update = async (): Promise<void> => {
    if (!dashboardStore.currentGraphStore) return;
    if (!dashboardStore.currentGraphStore.singleNodeSelected) return;

    await dashboardStore.currentGraphStore.updateSelectedNodes();

    this.node = dashboardStore.currentGraphStore.selectedNodes
      .values()
      .next().value;
  };
}

/*
 * Utility class to store a set of nodes.
 * Provides getters and update methods.
 */
export class MultiNodeStore {
  storeId: string;

  constructor() {
    this.storeId = crypto.randomUUID();
    makeAutoObservable(this);
  }

  private _display: string = '';
  get display(): string {
    return this._display;
  }
  set display(display: string) {
    this._display = display;
  }

  private _totalStoredNodes: number = 0;
  get totalStoredNodes(): number {
    return this._totalStoredNodes;
  }
  set totalStoredNodes(totalStoredNodes: number) {
    this._totalStoredNodes = totalStoredNodes;
  }

  reset = (): void => {
    dashboardStore.nodeStores.set(this.storeId, this);
    dashboardStore.currentGraphStore?.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        nodeStore: {
          storeId: this.storeId,
          action: 'reset',
        },
      },
    });
  };

  /*
   * Update the nodes from the selected nodes in the graph store.
   * Will add or replace the set based on `addNodes`.
   */
  update = async (addNodes: boolean = true): Promise<void> => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    if (!graphStore.anyNodesSelected) return;

    dashboardStore.nodeStores.set(this.storeId, this);
    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        nodeStore: {
          storeId: this.storeId,
          action: addNodes ? 'add' : 'update',
        },
      },
    });
  };

  updateByIds = async (
    ids: Set<NodeId>,
    addNodes: boolean = true,
  ): Promise<void> => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    if (!graphStore.anyNodesSelected) return;

    dashboardStore.nodeStores.set(this.storeId, this);
    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        nodeStore: {
          storeId: this.storeId,
          action: addNodes ? { addIds: [...ids] } : { updateWithIds: [...ids] },
        },
      },
    });
  };

  handleResponse = (response: NodeStoreMessage): void => {
    if ('display' in response) {
      this.totalStoredNodes = response.display.totalStoredNodes;
      this.display = response.display.display;
    }
  };
}
