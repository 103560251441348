import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { MultiNodeStore } from './NodeStore';
import ErrorStore from './ErrorStore';
import { Uuid } from '../types/app';
import { waitForToolMessage } from '../tools';

const NUM_TOP_CORRELATED_NODE_PAIRS = 5;

export class WhatButtonStore {
  selectedFeatureNodes = new MultiNodeStore();
  correlations: [[number, number], string[], number[]][] = [];

  get topCorrelatedNodePairs(): Array<[[string, string], number]> {
    // Return the top NUM_TOP_CORRELATED_NODE_PAIRS correlated node pairs.
    const map = new Map<[string, string], number>();

    // Numeric correlation values. Square matrix. Symmetric.
    let nodeOrder = this.correlations[1][1];
    let correlationValues = this.correlations[1][2];

    let n = nodeOrder.length;
    for (let i = 0; i < n; i++) {
      for (let j = i + 1; j < n; j++) {
        map.set(
          [nodeOrder[i][0], nodeOrder[j][0]],
          correlationValues[i * n + j],
        );
      }
    }

    // Categorical correlation values. Square matrix. Not symmetric.
    nodeOrder = this.correlations[2][1];
    correlationValues = this.correlations[2][2];

    n = nodeOrder.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        if (i === j) continue;
        map.set(
          [nodeOrder[i][0], nodeOrder[j][0]],
          correlationValues[i * n + j],
        );
      }
    }

    // Mixed correlation values. Rectangular matrix.
    nodeOrder = this.correlations[0][1];
    const [numCategorical, numNumerical] = this.correlations[0][0];
    correlationValues = this.correlations[0][2];

    for (let i = 0; i < numCategorical; i++) {
      for (let j = 0; j < numNumerical; j++) {
        map.set(
          [nodeOrder[i][0], nodeOrder[j + numCategorical][0]],
          correlationValues[i * numNumerical + j],
        );
      }
    }

    const sortedEntries = Array.from(map.entries()).sort((a, b) => b[1] - a[1]);
    return sortedEntries.slice(0, NUM_TOP_CORRELATED_NODE_PAIRS);
  }

  constructor() {
    makeAutoObservable(this);
  }

  get isDisabled(): boolean {
    return this.selectedFeatureNodes.totalStoredNodes === 0;
  }

  *execute() {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    ErrorStore.setSuccess('Analysis Started. Please wait for the results.');

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        patternFinding: this.selectedFeatureNodes.storeId,
      },
    });

    const taskId: Uuid | undefined = yield waitForToolMessage(
      graphStore,
      (msg) => {
        if (typeof msg === 'object' && 'patternFinding' in msg) {
          return msg.patternFinding;
        }
      },
    );
    if (!taskId) return;

    dashboardStore.addViewsTask(taskId, graphStore, "What's in my data?");
  }
}
