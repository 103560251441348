import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MonitoredTasks } from '../services/TaskService';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const BackgroundTasksButton = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const progress = (MonitoredTasks.instance.meanProgress() ?? 0) * 100;
  const labels: string[] = [];

  for (const task of MonitoredTasks.instance.tasks.values()) {
    const printMsg = `${task.label} @ ${(task.progress * 100).toFixed(1)}%`;
    labels.push(printMsg);
  }

  return (
    <div style={{ marginTop: '-10px' }}>
      <IconButton
        color="inherit"
        size="small"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CircularProgressWithLabel value={progress} color="inherit" />
      </IconButton>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          marginTop: '20px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* Render list of tasks*/}
        <div
          style={{
            margin: '5px',
            background: '#f0f0f0',
            padding: '10px',
            fontSize: '10px',
          }}
        >
          {labels.map((task, index) => (
            <div key={index}>{task}</div>
          ))}
        </div>
      </Popover>
    </div>
  );
});
