import MenuStore from './MenuStore';
import Actions from '../actions/actions';

const buildItems = (): MenuStore[] => {
  return [
    MenuStore.actionToMenuStore(Actions.uniqueActions.openHelpDocs),
    MenuStore.actionToMenuStore(Actions.uniqueActions.startOnboardingHelp),
  ];
};
export default class HelpMenuStore extends MenuStore {
  constructor() {
    super('item', null, 'Help', null, buildItems());
  }
}
