import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import { NodeSelectionBox } from './NodeSelectionBox';
import dashboardStore from '../stores/DashboardStore';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';
import { PropagateType } from 'wasm_service';

export const Propagate = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    propagate: {
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: '5px',
      marginBottom: `4px`,
    },
  };

  const propagateStore = dashboardStore.rightDrawerStore.propagateStore;
  const sourceSelectionBox = (
    <NodeSelectionBox
      label="Source Nodes"
      clear={propagateStore.sourceNodes.reset}
      select={propagateStore.sourceNodes.update}
      value={propagateStore.sourceNodes.display}
      isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
    />
  );

  const handleTypeChange = (option: SwitchOption) => {
    const newType = option.value as PropagateType;
    propagateStore.fromType = newType;

    // Automatically set toType to 'Color' if fromType is 'Color'
    if (newType === 'Color') {
      propagateStore.toType = 'Color';
    }
  };

  return (
    <Box sx={styles.propagate}>
      <Box sx={{ fontSize: '14px', margin: '4px' }}>{propagateStore.title}</Box>
      <Box>
        {/* Set Transformation Type */}
        <SwitchButton
          options={[
            { label: 'Label', value: 'Label' },
            { label: 'URL', value: 'URL' },
            { label: 'Color', value: 'Color' },
            { label: 'Edges', value: 'Edge' },
          ]}
          value={propagateStore.fromType}
          onChange={handleTypeChange}
        />
      </Box>
      {propagateStore.applyToNodes ? (
        <>
          {sourceSelectionBox}
          {propagateStore.fromType !== 'Color' && (
            <>
              <Divider sx={{ my: 3 }} />
              <Box>
                <SwitchButton
                  options={[
                    { label: 'Label', value: 'Label' },
                    { label: 'URL', value: 'URL' },
                  ]}
                  value={propagateStore.toType}
                  onChange={(option: SwitchOption) =>
                    (propagateStore.toType = option.value as PropagateType)
                  }
                />
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <NodeSelectionBox
            label="Header Nodes"
            clear={propagateStore.featureNodes.reset}
            select={propagateStore.featureNodes.update}
            value={propagateStore.featureNodes.display}
            isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
          />
          {sourceSelectionBox}
          <Divider sx={{ my: 3 }} />
        </>
      )}
      <NodeSelectionBox
        label="Target Nodes"
        clear={propagateStore.targetNodes.reset}
        select={propagateStore.targetNodes.update}
        value={propagateStore.targetNodes.display}
        isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ textTransform: 'none' }}
          variant="contained"
          size="small"
          color="secondary"
          onClick={propagateStore.execute}
          disabled={propagateStore.isDisabled}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
});
