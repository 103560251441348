/**
 * This contains all the actions that can be performed by a user
 * including any associated shortcut combinations and icons.
 *
 * Except, currently:
 * - Node sizing
 * - Node moving
 * - Adding Edges
 *
 * Note that one-off actions are held in the uniqueActions literal and
 * can be accessed explicitly. Classes of actions can be accessed by
 * the helpers in the class, such as:
 * - Layout
 * - Send to
 * - Color by
 * - ...
 *
 * Note that some Actions require additional params and so are wrapped in a function
 * that closes around the action. Eg, 'Send nodes to View'
 *
 * Note that even though this is a vanilla .ts file, we import React and use the
 * .tsx extension so we can easily represent the icons.
 *
 */
import React from 'react';
import { runInAction } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { TOO_MANY_VIEWS_MESSAGE } from '../Constants';
import clipboardStore from '../stores/ClipboardStore';
import ErrorStore from '../stores/ErrorStore';
import { UploadMenuState } from '../stores/UploadMenuStore';
import ViewStore from '../stores/ViewStore';
import { Node } from '../types/app';
import {
  Add,
  Cached,
  CenterFocusStrong,
  CenterFocusWeak,
  FileUpload,
  FileDownload,
  Close,
  ContentCopy,
  ContentPaste,
  LineAxis,
  Label,
  LabelOffOutlined,
  DeleteForever,
  CopyAll,
  OndemandVideo,
  Info,
  FileOpen,
  Undo,
  PivotTableChart,
  Redo,
  RotateRight,
  PlusOne,
  DeleteOutline,
  Save,
  HelpOutline,
} from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SearchIcon from '@mui/icons-material/Search';
import MergeIcon from '@mui/icons-material/Merge';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import CodeIcon from '@mui/icons-material/Code';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { withDashboardBackdrop, withCurrViewBackdrop } from '../utils/backdrop';

declare global {
  interface Window {
    actions: Actions;
  }
}

const isMac = navigator.userAgent.toUpperCase().includes('MAC');

/**
 * A representation of a keyboard command including the modifier keys
 * and the key itself.
 *
 * For handling non-alphanumeric keys, see: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
 *
 *       |            MAC               |          Windows             |
 * key   |  Name   | String | Event     |  Name   | String | Event     |
 * Shift |  Shift  |   ⇧    | .shiftKey |  Shift  |   ⇧    | .shiftKey |
 * Mod 1 | Command |   ⌘    | .metaKey  | Control |  ctrl  | .ctrlKey  |
 * Mod 2 | Control |  ctrl  | .ctrlKey  | Windows |   alt  | .altKey   |
 *
 */
export class KeyCommand {
  key: KeyboardEvent['key'] = '';
  shift: boolean;
  mod1: boolean;
  mod2: boolean;

  constructor(
    key: KeyboardEvent['key'],
    shift = false,
    mod1 = false,
    mod2 = false,
  ) {
    this.key = key;
    this.shift = shift;
    this.mod1 = mod1;
    this.mod2 = mod2;
  }

  /**
   * Human readable representation of the KeyCommand
   *
   * Also used as the key for the actionsByCommand Map.
   */
  get asString(): string {
    const shiftString = this.shift ? '⇧' : '';
    const mod1String = this.mod1 ? (isMac ? '⌘' : 'ctrl-') : '';
    const mod2String = this.mod2 ? (isMac ? 'ctrl-' : 'alt-') : '';

    let keyString = this.key;
    switch (keyString) {
      case 'ArrowUp':
        keyString = '↑';
        break;
      case 'ArrowDown':
        keyString = '↓';
        break;
      case 'ArrowRight':
        keyString = '→';
        break;
      case 'ArrowLeft':
        keyString = '←';
        break;
      case 'Delete':
        keyString = 'del';
        break;
    }
    return mod1String + mod2String + shiftString + keyString;
  }
}
/**
 * A single action including everything you need to display it as an icon
 * or menu item.
 */
export class Action {
  longLabel: string;
  shortLabel: string;
  description: string;
  action = (): void => {
    /* */
  };
  commands: KeyCommand[] = [];
  _disabled = (): boolean => false;
  _highlighted = (): boolean => false;
  icon?: JSX.Element;
  target?: string;

  constructor(
    longLabel = '',
    shortLabel = '',
    description = '',
    action: () => void,
    commands?: KeyCommand[],
    icon?: JSX.Element,
    disabled?: () => boolean,
    highlighted?: () => boolean,
  ) {
    this.longLabel = longLabel;
    this.shortLabel = shortLabel;
    this.description = description;
    this.action = action;
    this.commands = commands ?? [];
    this.icon = icon;

    this._disabled = disabled ?? (() => false);
    this._highlighted = highlighted ?? (() => false);
  }

  get disabledFunction(): () => boolean {
    return this._disabled;
  }
  get disabled(): boolean {
    return this._disabled();
  }

  get highlighted(): boolean {
    return this._highlighted();
  }

  /**
   * Return just the first command string
   */
  get commandString(): string {
    if (!this.commands.length) return '';
    const command = this.commands[0];
    return command.asString;
  }

  /**
   * Return all the command strings
   */
  get commandStrings(): string {
    return this.commands.map((command) => command.asString).join(', ');
  }
}

/**
 * Actions to open specific docs pages.
 */
const docsPaths = {
  featureExtractionDocs:
    './docs/content/How-To/extract_features/#feature-extraction-agent',
  geocodingDocs:
    './docs/content/How-To/extract_features/#geospatial-coordinate-extraction',
  dataRetrievalDocs:
    './docs/content/How-To/find_data/#using-the-data-retrieval-agent',
};

const docsActions = Object.fromEntries(
  Object.entries(docsPaths).map(([k, v]) => [
    k,
    new Action(
      'Docs',
      'Open Documentation',
      'Open the documentation.',
      () => window.open(v, '_blank'),
      [],
      <HelpOutline />,
    ),
  ]),
) as { [K in keyof typeof docsPaths]: Action };

/**
 * Unique actions by name
 */
const uniqueActions = {
  ...docsActions,

  /**
   * Undo / redo
   */
  undo: new Action(
    'Undo',
    'Undo',
    'Undo the previous action. Only affects the state of the Graph, Views, Nodes or Edges.',
    () => dashboardStore.currentGraphStore?.undo(),
    [new KeyCommand('z', false, true)],
    <Undo />,
    () => !dashboardStore.currentGraphStore?.hasUndos,
  ),

  redo: new Action(
    'Redo',
    'Redo',
    'Redo the previously undone action.',
    () => dashboardStore.currentGraphStore?.redo(),
    [new KeyCommand('z', true, true)],
    <Redo />,
    () => !dashboardStore.currentGraphStore?.hasRedos,
  ),

  /**
   * Zooming
   */

  zoomAll: new Action(
    'Zoom All',
    'All',
    'Zoom to fit all the nodes in the view.',
    () => dashboardStore.currentViewStore?.zoomAll(),
    [new KeyCommand('-')],
    <CenterFocusWeak />,
  ),
  zoomSelected: new Action(
    'Zoom Selected',
    'Selected',
    '',
    () => dashboardStore.currentViewStore?.zoomSelected(),
    [
      // Even though this one can never actually be called, we need it to show the user
      new KeyCommand('+', false, false),
      new KeyCommand('+', true, false),
      new KeyCommand('=', false, false),
    ],
    <CenterFocusStrong />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  /**
   * Upload
   */
  fileUpload: new Action(
    'Upload to New Graph',
    'New Graph',
    '',
    () => {
      dashboardStore.uploadMenuStore.uploadAdditionalData = false;
      dashboardStore.uploadMenuStore.state = UploadMenuState.Open;
    },
    [],
    <FileUpload />,
    () => false,
  ),
  fileUploadAdditionalData: new Action(
    'Add to Current Graph',
    'Add to Graph',
    '',
    () => {
      dashboardStore.uploadMenuStore.uploadAdditionalData = true;
      dashboardStore.uploadMenuStore.state = UploadMenuState.Open;
    },
    [],
    <FileUpload />,
    () => false,
  ),

  /**
   * Download
   */
  exportGraphAsEd: new Action(
    'Export Graph as .ed',
    'Graph as .ed',
    '',
    () => dashboardStore.exportCurrentGraphAsEd(),
    [],
    <FileDownload />,
  ),
  exportGraphAsNetworkX: new Action(
    'Export Graph to networkx',
    'Graph to networkx',
    '',
    () => dashboardStore.exportCurrentGraphAsNetworkX(),
    [],
    <FileDownload />,
  ),

  exportSelectedSubgraphToCsv: new Action(
    'Export selected subgraph as .csv',
    'Selected subgraph as .csv',
    'Export selected subgraph as .csv',
    withDashboardBackdrop(async () =>
      dashboardStore.exportSelectedSubgraphAsCsv(),
    ),
    [],
    <FileDownload />,
    () =>
      dashboardStore.currentGraphStore != undefined &&
      dashboardStore.currentGraphStore.anyNodesSelected === false,
  ),

  downloadFiles: new Action(
    'Attached files as .zip',
    'Attached files as .zip',
    'This will download a .zip file with all files attached to the selected nodes.',
    () => dashboardStore.downloadSelectedNodesFile(),
    [],
    <FileDownload />,
    () =>
      dashboardStore.currentGraphStore != undefined &&
      dashboardStore.currentGraphStore.anyNodesSelected === false,
  ),

  /**
   * Views
   */

  newView: new Action(
    'New View',
    'New View',
    '',
    () => {
      if (dashboardStore.openViews.length < 6) {
        if (dashboardStore.currentGraphStore?.anyNodesSelected) {
          dashboardStore.currentGraphStore?.newViewFromCurrentSelection();
        } else {
          dashboardStore.currentGraphStore?.newView();
        }
      } else {
        ErrorStore.setError(TOO_MANY_VIEWS_MESSAGE);
        return;
      }
    },
    [new KeyCommand('v', false, false, true)],
    <Add />,
    () => !dashboardStore.currentGraphStore,
  ),

  swapAxis: new Action(
    'Swap Horizontal and Vertical Axes',
    'Swap Axes',
    'Swap Horizontal and Vertical Axes',
    () => dashboardStore.currentViewStore?.swapAxes(),
    [],
    <PivotTableChart style={{ transform: 'rotate(270deg)' }} />,
  ),

  duplicateView: new Action(
    'Duplicate View',
    'Duplicate View',
    '',
    () =>
      dashboardStore.currentViewStore &&
      dashboardStore.duplicateView(dashboardStore.currentViewStore),
    [new KeyCommand('d', false, false, true)],
    <CopyAll />,
  ),
  deleteView: new Action(
    'Delete View',
    'Delete View',
    '',
    () =>
      dashboardStore.currentViewStore &&
      dashboardStore.deleteViewById(dashboardStore.currentViewStore.id),
    [],
    <DeleteForever />,
  ),
  closeView: new Action(
    'Close View',
    'Close',
    '',
    () =>
      dashboardStore.currentViewStore &&
      dashboardStore.closeView(dashboardStore.currentViewStore.id),
    [new KeyCommand('d', false, false, true)],
    <Close />,
  ),
  openViewFromNode: new Action(
    'Open View from Node',
    'Open View from Node',
    '',
    withDashboardBackdrop(async () => dashboardStore.openViewFromNode()),
    [new KeyCommand('o', false, false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  reloadCurrentView: new Action(
    'Reload View',
    'Reload',
    '',
    () => dashboardStore.currentViewStore?.reload(),
    [new KeyCommand('r', false, false, true)],
    <Cached />,
  ),

  /**
   * Graph Actions
   */
  submitGraphOptions: new Action(
    'Submit Graph Options',
    'Submit',
    '',
    () => dashboardStore.submitGraphOptions(),
    [],
    <Save />,
  ),
  deleteGraph: new Action(
    'Delete Graph',
    'Delete',
    '',
    () => dashboardStore.deleteCurrentGraph(),
    [],
    <DeleteForever />,
    () =>
      dashboardStore.currentGraphStore != undefined &&
      dashboardStore.currentGraphStore.ownerEmail !=
        dashboardStore.userEmailAddress,
  ),

  duplicateGraph: new Action(
    'Duplicate Graph',
    'Duplicate',
    '',
    () => dashboardStore.duplicateCurrentGraph(),
    [],
    <CopyAll />,
    () => dashboardStore.currentGraphStore == undefined,
  ),

  /**
   * Show
   */

  toggleShowEdges: new Action(
    'Show / Hide Edges',
    'Edges',
    'Show / Hide all edges in the current view.',
    () => dashboardStore.currentViewStore?.toggleShowEdges(),
    [],
    <LineAxis />,
    undefined,
    () => dashboardStore.currentViewStore?.showEdges ?? true,
  ),

  /**
   * Help
   */

  openHelpDocs: new Action(
    'Docs',
    'Open Documentation',
    'Open the documentation.',
    () => window.open('./docs/', '_blank'),
    [],
    <HelpOutline />,
  ),

  /**
   * Start onboarding help action
   */
  startOnboardingHelp: new Action(
    'Start Onboarding Help',
    'Start Onboarding',
    'Start the onboarding help.',
    () => {
      dashboardStore.onboardingHelpStore.start();
    },
    [],
    <TipsAndUpdatesOutlinedIcon />,
    () => dashboardStore.homePageStore.open,
  ),

  /**
   * Nodes
   */

  copyLabels: new Action(
    'Copy Node Labels',
    'Copy Labels',
    'Copy the selected nodes Labels to the clipboard.',
    () => {
      dashboardStore.currentViewStore?.copySelectedNodeLabels();
    },
    [],
    <FileCopyIcon />,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  copy: new Action(
    'Copy Nodes',
    'Copy',
    'Copy the selected nodes to the clipboard.',
    () => {
      dashboardStore.currentViewStore?.copySelectedNodes();
    },
    [new KeyCommand('c', false, true)],
    <ContentCopy />,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  paste: new Action(
    'Paste Nodes',
    'Paste',
    'Paste copied nodes into selected view.',
    () => {
      dashboardStore.currentViewStore?.pasteNodes();
    },
    [new KeyCommand('v', false, true)],
    <ContentPaste />,
    () => !clipboardStore.hasData,
  ),
  nodeProperties: new Action(
    'Node Properties',
    'Properties',
    'Open the selected node property menu.',
    () => (dashboardStore.nodePropertiesStore.open = true),
    [new KeyCommand('.')],
    <Info />,
    () => !dashboardStore.anyNodesSelected,
  ),
  showMedia: new Action(
    'View Node',
    'Watch Media',
    'If the selected nodes have an image or video, open the carousel.',
    () => (dashboardStore.carouselStore.open = true),
    [new KeyCommand('w')],
    <OndemandVideo />,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  openUrl: new Action(
    'Open URL / File',
    'Open URL / File',
    'In cases where a node has a url or file property, this will open the webpage or download the attached file.',
    () => dashboardStore.openSelectedNodeFile(),
    [new KeyCommand('u')],
    <FileOpen />,
    () => !dashboardStore.singleNodeSelected,
  ),
  getSuccessorsUnion: new Action(
    'Get Successors Union',
    'Successors Union',
    'Get the Union of all the Successors for the selected nodes from the entire Graph (not just this view).',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors(
          'successors',
          false,
        ),
    ),
    [new KeyCommand('g', false, true)],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  getSuccessorsIntersection: new Action(
    'Get Successors Intersection',
    'Successors Intersection',
    'Get the Intersection of all the Successors for the selected nodes from the entire Graph (not just this view).',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors(
          'successors',
          true,
        ),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  getPredecessorsUnion: new Action(
    'Get Predecessors Union',
    'Predecessors Union',
    'Get the Union of all the Predecessors for the selected nodes from the entire Graph (not just this view) excluding special nodes like x and y axes.',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors(
          'predecessors',
          false,
        ),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  getPredecessorsIntersection: new Action(
    'Get Predecessors Intersection',
    'Predecessors Intersection',
    'Get the Intersection of all the Predecessors for the selected nodes from the entire Graph (not just this view) excluding special nodes like x and y axes.',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors(
          'predecessors',
          true,
        ),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  getNeighborsUnion: new Action(
    'Get Neighbors Union',
    'Neighbors Union',
    'Get the Union of all the Neighbors for the selected nodes from the entire Graph (not just this view) excluding special nodes like x and y axes.',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors('both', false),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  getNeighborsIntersection: new Action(
    'Get Neighbors Intersection',
    'Neighbors Intersection',
    'Get the Intersection of all the Neighbors for the selected nodes from the entire Graph (not just this view) excluding special nodes like x and y axes.',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentViewStore?.addSelectedNeighbors('both', true),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  getConnections: new Action(
    'Get Connections',
    'Connections',
    'Find connections between the selected nodes from the entire Graph (not just this view).',

    withDashboardBackdrop(
      async () => dashboardStore.currentViewStore?.addConnections(),
    ),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  duplicateNode: new Action(
    'Duplicate Node',
    'Duplicate',
    'Duplicate the selected nodes.',
    () => dashboardStore.currentViewStore?.duplicateSelected(),
    [],
    <CopyAll />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  removeNode: new Action(
    'Remove Node from View',
    'Remove from View',
    '',
    () => dashboardStore.currentViewStore?.removeSelectedNodes(),
    [new KeyCommand('Delete', false, false)],
    <DeleteOutline />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  deleteNodes: new Action(
    'Delete Nodes from Graph',
    'Delete Nodes',
    'Delete Nodes from the Graph.',
    () => dashboardStore.currentViewStore?.deleteSelectedNodes(),
    [],
    <DeleteOutline />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  turnLabelsForSelectedNodesOn: new Action(
    'Show Label',
    'Show Label',
    '',
    () => dashboardStore.currentGraphStore?.updateSelectedNodesLabels(true),
    undefined,
    <Label />,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  turnLabelsForSelectedNodesOff: new Action(
    'Hide Label',
    'Hide Label',
    '',
    () => dashboardStore.currentGraphStore?.updateSelectedNodesLabels(false),
    undefined,
    <LabelOffOutlined />,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  toggleLabels: new Action(
    'Toggle Labels',
    'Toggle Labels',
    '',
    () => dashboardStore.currentGraphStore?.toggleLabels(),
    [new KeyCommand('l', false, false)],
    undefined,
  ),

  /**
   * Merge Nodes
   */
  mergeNodes: new Action(
    'Merge Nodes',
    'Merge',
    'Merge selected nodes into a new node.',
    withDashboardBackdrop(async () => dashboardStore.mergeNodes('merge')),
    [new KeyCommand('m', false, true)],
    <MergeIcon />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  mergeNodesByLabel: new Action(
    'Merge Nodes by Label',
    'Merge by Label',
    'Merge selected nodes into a new node by label.',
    withDashboardBackdrop(async () => dashboardStore.mergeNodes('deduplicate')),
    [new KeyCommand('m', true, true)],
    <MergeTypeIcon />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),
  mergeNodesIntoPredecessors: new Action(
    'Merge into Predecessors',
    'Merge into Predecessors',
    'Merge selected nodes into their predecessors.',
    withDashboardBackdrop(async () =>
      dashboardStore.mergeNodes('predecessors'),
    ),
    [],
    <MergeIcon />,
    () => !dashboardStore.currentViewStore?.anyNodesSelected,
  ),

  /**
   * Run code
   */

  runCode: new Action(
    'Run Code',
    'Run Code',
    'Run the code in the code editor.',
    withDashboardBackdrop(async () => dashboardStore.runCodeFromNode()),
    [],
    <CodeIcon />,
    () => dashboardStore.runCodeFromNodeDisabled(),
  ),

  /**
   * Edges
   */

  deleteEdges: new Action(
    'Delete Connecting Edges',
    'Delete Connecting Edges',
    '',
    () => dashboardStore.currentViewStore?.deleteConnectingEdges(),
    [],
    <DeleteOutline />,
    () => !dashboardStore.currentViewStore?.manyNodesSelected,
  ),

  /**
   * Selecting
   */

  selectAllNodes: new Action(
    'Select All',
    'All',
    'Select all the nodes in the view.',
    () => dashboardStore.currentViewStore?.selectAllNodes(),
    [new KeyCommand('a', false, true)],
    undefined,
    () => !dashboardStore.currentViewStore,
  ),
  selectNoneNodes: new Action(
    'Select None',
    'None',
    'Remove selection of any nodes in the view.',
    () => dashboardStore.currentViewStore?.selectNoneNodes(),
    [new KeyCommand('0', false, true)],
    undefined,
    () => !dashboardStore.currentViewStore,
  ),
  selectInverseNodes: new Action(
    'Invert Selection',
    'Invert',
    'Select all excluding the current selected nodes in the view.',
    () => dashboardStore.currentViewStore?.selectInverse(),
    [new KeyCommand('i', false, true)],
    undefined,
    () => !dashboardStore.currentViewStore,
  ),

  selectSuccessorsUnion: new Action(
    'Select Successors Union',
    'Successors Union',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'successors',
        false,
        false,
      ),
    [new KeyCommand('ArrowDown', false, false)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectSuccessorsUnionAdd: new Action(
    'Select Successors Union (Add to selection)',
    'Successors Union (Add)',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'successors',
        false,
        true,
      ),
    [new KeyCommand('ArrowDown', true, false)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectSuccessorsIntersection: new Action(
    'Select Successors Intersection',
    'Successors Intersection',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'successors',
        true,
        false,
      ),
    [new KeyCommand('ArrowDown', false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectSuccessorsIntersectionAdd: new Action(
    'Select Successors Intersection (Add to selection)',
    'Successors Intersection (Add)',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'successors',
        true,
        true,
      ),
    [new KeyCommand('ArrowDown', true, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),

  selectPredecessorsUnion: new Action(
    'Select Predecessors Union',
    'Predecessors Union',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'predecessors',
        false,
        false,
      ),
    [new KeyCommand('ArrowUp', false, false)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectPredecessorsUnionAdd: new Action(
    'Select Predecessors Union (Add to selection)',
    'Predecessors Union (Add)',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'predecessors',
        false,
        true,
      ),
    [new KeyCommand('ArrowUp', true, false)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectPredecessorsIntersection: new Action(
    'Select Predecessors Intersection',
    'Predecessors Intersection',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'predecessors',
        true,
        false,
      ),
    [new KeyCommand('ArrowUp', false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  selectPredecessorsIntersectionAdd: new Action(
    'Select Predecessors Intersection (Add to selection)',
    'Predecessors Intersection (Add)',
    '',
    () =>
      dashboardStore.currentGraphStore?.selectNeighbors(
        'predecessors',
        true,
        true,
      ),
    [new KeyCommand('ArrowUp', true, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showScenarioSpatial: new Action(
    'Show Spatial View',
    'Spatial View',
    'Geospatial view of scenarios and/or road networks.',
    withDashboardBackdrop(async () => dashboardStore.spatialView()),
    [new KeyCommand('g', false, false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showBarplot: new Action(
    'Show Barplot View',
    'Barplot',
    'Show a barplot view for the successors of the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.barplotView()),
    [new KeyCommand('b', false, false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showScatterPlot: new Action(
    'Show Scatter View',
    'Scatter Plot',
    'Show a scatter plot view for the successors of the selected header nodes.',
    withDashboardBackdrop(async () => dashboardStore.scatterPlot()),
    [new KeyCommand('s', false, false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showHistogram: new Action(
    'Show Histogram View',
    'Histogram',
    'Show a histogram view for the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.histogramPlot()),
    [new KeyCommand('h', false, false, true)],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showCorrelation: new Action(
    'Show correlations',
    'Correlations',
    'Plot correlation matrices between the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.correlationPlots()),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showPca: new Action(
    'Show PCA Embedding',
    'PCA Embedding',
    'Show a PCA embedding view for the successors of the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.pcaEmbedding()),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showLabelEmbedding: new Action(
    'Show Label Embedding',
    'Label Embedding',
    'Show a Label embedding view of the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.labelEmbedding()),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.anyNodesSelected,
  ),
  showTsne: new Action(
    'Show TSNE Embdding',
    'TSNE Embedding',
    'Show a TSNE embedding view for the successors of the selected nodes.',
    withDashboardBackdrop(async () => dashboardStore.tsneEmbedding()),
    [],
    undefined,
    () => !dashboardStore.currentViewStore?.manyNodesSelected,
  ),
  /**
   * Search
   */
  openSearch: new Action(
    'Search',
    'Search',
    'Search for a node in a view using the node label.',
    () => (dashboardStore.searchDialogOpen = true),
    [new KeyCommand('f', false, true)],
    <SearchIcon />,
    () => !dashboardStore.currentViewStore,
  ),

  openGetNodesById: new Action(
    'Get Node(s) by ID',
    'Node(s) by ID',
    'Get a Node or list of Nodes and add them to this View. Nodes should be in the form of: node-id, "node-id" or "["node-id1", "node-id2"]',
    () => (dashboardStore.nodesByIdDialogOpen = true),
    [],
    undefined,
    () => !dashboardStore.currentViewStore,
  ),

  /**
   * Axis nodes
   */
  copyAxisNode: new Action('Copy Axis Node', 'Copy ID', '', () => {
    if (!dashboardStore.currentViewStore) return;
    const node =
      dashboardStore.contextMenuStore.context === 'xNode'
        ? (dashboardStore.currentViewStore.xNode as Node)
        : (dashboardStore.currentViewStore.yNode as Node);
    if (node) clipboardStore.copyNodes(new Set([node]));
  }),
  setSelectedAsXAxisNode: new Action(
    'Set Selected as X Axis Node',
    'Set Selected as X Axis Node',
    '',
    () => {
      dashboardStore.currentViewStore?.setSelectedAsAxisNode('x');
    },
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  setSelectedAsYAxisNode: new Action(
    'Set Selected as Y Axis Node',
    'Set Selected as Y Axis Node',
    '',
    () => {
      dashboardStore.currentViewStore?.setSelectedAsAxisNode('y');
    },
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  setNodeAsFilter: new Action(
    'Set Selected Node as Filter',
    'Set Selected Node as Filter',
    'Set the selected node as a filter node for the current view. Only nodes which are successors of this filter node will remain in the view.',
    () => dashboardStore.currentViewStore?.setSelectedAsFilterNode(),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  colorSuccessorsByWeight: new Action(
    'Color Successors by Edge Weight',
    'Color Successors by Edge Weight',
    '',
    withDashboardBackdrop(
      async () =>
        dashboardStore.currentGraphStore?.colorSuccessorsByWeight(false),
    ),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  colorSuccessorsByWeightReversed: new Action(
    'Color Successors by Edge Weight (reversed)',
    'Color Successors by Edge Weight (reversed)',
    '',
    () => dashboardStore.currentGraphStore?.colorSuccessorsByWeight(true),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  sizeSuccessorsByWeight: new Action(
    'Size Successors by Edge Weight',
    'Size Successors by Edge Weight',
    '',
    withDashboardBackdrop(
      async () => dashboardStore.currentGraphStore?.sizeSelectedByWeight(),
    ),
    [],
    undefined,
    () => !dashboardStore.currentGraphStore?.singleNodeSelected,
  ),
  /**
   *  View
   */
  copyViewID: new Action('Copy ID', 'Copy View ID', '', () => {
    if (!dashboardStore.currentViewStore) return;
    const viewStore = dashboardStore.currentViewStore;
    const node = { id: viewStore.id, label: viewStore.label } as Node;
    if (node) clipboardStore.copyNodes(new Set([node]));
  }),

  copyViewURL: new Action('Copy URL', 'Copy View URL', '', () => {
    if (!dashboardStore.currentViewStore) return;
    clipboardStore.copyViewURL(dashboardStore.currentViewStore);
  }),
};

class Actions {
  /**
   * Map of KeyCommands to actions
   * Does not include Action Functions or the Send to Graph Actions
   *
   * Note multiple KeyCommands may map to the same Action.
   */
  actionsByCommand: Map<string, Action> = new Map();

  uniqueActions = uniqueActions;
  createNodeAction = (type: 'menubar' | 'contextmenu' | 'shortcut'): Action => {
    return new Action(
      'Create Node',
      'Create',
      'Create a new node.',
      () => dashboardStore.currentViewStore?.newNode(type),
      [new KeyCommand('n', false, false, true)],
      <PlusOne />,
      () => !dashboardStore.currentViewStore,
    );
  };
  /**
   * Layout
   */
  layoutActions: Action[] = [
    new Action(
      'Rotate',
      'Rotate',
      'Rotate selected nodes.',
      () => (dashboardStore.rotateNodesDialogOpen = true),
      [],
      <RotateRight />,
      () => !dashboardStore.currentViewStore?.anyNodesSelected,
    ),
    new Action(
      'Layout Vertical',
      'Vertical',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Vertical'),
      ),
      [new KeyCommand('V', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Layout Horizontal',
      'Horizontal',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Horizontal'),
      ),
      [new KeyCommand('H', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Layout Force-directed',
      'Force-directed',
      '',
      withCurrViewBackdrop(
        async () =>
          dashboardStore.currentViewStore?.autoLayout('ForceDirected'),
      ),
      [new KeyCommand('F', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Layout Spectral',
      'Spectral',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Spectral'),
      ),
      [new KeyCommand('S', true, false, true)],
      undefined,
      () =>
        !dashboardStore.currentViewStore ||
        (dashboardStore.currentViewStore.anyNodesSelected &&
          !dashboardStore.currentViewStore.anyEdgesSelected) ||
        (!dashboardStore.currentViewStore.anyNodesSelected &&
          !dashboardStore.currentViewStore.anyEdges),
    ),
    new Action(
      'Layout Bipartite',
      'Bipartite',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Bipartite'),
      ),
      [new KeyCommand('B', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Layout Radial',
      'Radial',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Radial'),
      ),
      [new KeyCommand('R', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Collapse Leaf Nodes',
      'Collapse Leaf Nodes',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('Collapse'),
      ),
      [new KeyCommand('C', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Align by Neighbors (x)',
      'Align by Neighbors (x)',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('AlignX'),
      ),
      [new KeyCommand('X', true, false, true)],
      undefined,
      () => {
        if (!dashboardStore.currentViewStore) return true;
        const viewStore = dashboardStore.currentViewStore;
        return !viewStore.anyNodesSelected || viewStore.allNodesSelected;
      },
    ),
    new Action(
      'Align by Neighbors (y)',
      'Align by Neighbors (y)',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.autoLayout('AlignY'),
      ),
      [new KeyCommand('Y', true, false, true)],
      undefined,
      () => {
        if (!dashboardStore.currentViewStore) return true;
        const viewStore = dashboardStore.currentViewStore;
        return !viewStore.anyNodesSelected || viewStore.allNodesSelected;
      },
    ),
    new Action(
      'Degree',
      'Degree',
      '',
      withCurrViewBackdrop(async () => {
        const viewStore = dashboardStore.currentViewStore!;
        viewStore.autoLayout('Degree');
        await viewStore.whenExtentsUpdates();
        if (viewStore.allNodesSelected || viewStore.noNodesSelected) {
          viewStore.zoomAll();
        }
      }),
      [new KeyCommand('D', true, false, true)],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
  ];

  /**
   * Color by...
   */
  colorByActions: Action[] = [
    new Action(
      'Color by Group',
      'Group',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentGraphStore?.colorByGroup(),
      ),
      [],
      undefined,
      () => !dashboardStore.currentGraphStore,
    ),
    new Action(
      'Color by Spatial Group',
      'Spatial Group',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.colorBySpatialGroup(),
      ),
      [],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Color by density',
      'Density',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.colorByDensity(),
      ),
      [],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Color by Spectrum',
      'Spectrum',
      '',
      withCurrViewBackdrop(
        async () => dashboardStore.currentViewStore?.colorBySpectral(),
      ),
      [],
      undefined,
      () =>
        !dashboardStore.currentViewStore ||
        (dashboardStore.currentViewStore.anyNodesSelected &&
          !dashboardStore.currentViewStore.anyEdgesSelected) ||
        (!dashboardStore.currentViewStore.anyNodesSelected &&
          !dashboardStore.currentViewStore.anyEdges),
    ),
    new Action(
      'Position (x axis)',
      'Position (x axis)',
      '',
      () =>
        dashboardStore.currentViewStore?.colorByWeight(
          dashboardStore.currentViewStore.xNode?.id,
          false,
        ),
      [],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
    new Action(
      'Reversed Position (x axis)',
      'Reversed Position (x axis)',
      '',
      () =>
        dashboardStore.currentViewStore?.colorByWeight(
          dashboardStore.currentViewStore.xNode?.id,
          true,
        ),
      [],
      undefined,
      () => !dashboardStore.currentViewStore,
    ),
  ];

  /**
   * Set color
   */
  setColorActions: Action[] = [
    new Action(
      'Red',
      'Red',
      '',
      () => dashboardStore.currentGraphStore?.setSelectedColor(1, 0, 0),
      [],
      undefined,
      () => !dashboardStore.currentGraphStore?.anyNodesSelected,
    ),
    new Action(
      'Green',
      'Green',
      '',
      () => dashboardStore.currentGraphStore?.setSelectedColor(0, 1, 0),
      [],
      undefined,
      () => !dashboardStore.currentGraphStore?.anyNodesSelected,
    ),
    new Action(
      'Blue',
      'Blue',
      '',
      () => dashboardStore.currentGraphStore?.setSelectedColor(0, 0, 1),
      [],
      undefined,
      () => !dashboardStore.currentGraphStore?.anyNodesSelected,
    ),
  ];

  /**
   * Sort Labels
   */
  sortByActions: Action[] = [
    new Action(
      'Sort Nodes by Color',
      'Sort by Color',
      'Sort the selected nodes based on their color .',
      () => {
        dashboardStore.currentViewStore?.sortBy('color');
      },
      [],
      undefined,
      () => !dashboardStore.currentViewStore?.anyNodesSelected,
    ),
    new Action(
      'Sort Nodes by Out Degree',
      'Sort by Degree (out)',
      'Sort the selected nodes based on the number of successors.',
      () => {
        dashboardStore.currentViewStore?.sortBy('degree');
      },
      [],
      undefined,
      () => !dashboardStore.currentViewStore?.anyNodesSelected,
    ),
    new Action(
      'Sort Nodes by Label',
      'Sort by Label',
      'Sort the selected nodes alphabetically by label.',
      () => {
        dashboardStore.currentViewStore?.sortBy('label');
      },
      [],
      undefined,
      () => !dashboardStore.currentViewStore?.anyNodesSelected,
    ),
    new Action(
      'Sort Nodes by Weight',
      'Sort by Weight',
      'Sort the selected nodes based on the weight of a header.',
      () => {
        dashboardStore.currentViewStore?.sortBy('weight');
      },
      [],
      undefined,
      () => !dashboardStore.currentViewStore?.anyNodesSelected,
    ),
  ];

  /**
   * All the actions except:
   * - Send to Graph
   * - ViewToView
   */
  allActions = [
    ...Object.values(uniqueActions),
    ...this.layoutActions,
    ...this.colorByActions,
    ...this.setColorActions,
    ...this.sortByActions,
    ...[
      this.createNodeAction('menubar'),
      this.createNodeAction('contextmenu'),
      this.createNodeAction('shortcut'),
    ],
  ];

  constructor() {
    this.allActions.forEach((action) => this.setActionByCommand(action));
    window.actions = this; // so we can invoke actions from the console
  }

  /**
   * Event handler for key events that handles modifiers
   */
  handleKeyDown = (event: KeyboardEvent): void => {
    // Close context menu on escape
    if (event.key === 'Escape') {
      dashboardStore.menuBarStore.open = false;
      dashboardStore.contextMenuStore.open = false;
      return;
    }

    if (event.key === ' ') {
      runInAction(() => (dashboardStore.isSpaceDown = true));
      return;
    }

    const command = new KeyCommand(
      event.key,
      event.shiftKey,
      isMac ? event.metaKey : event.ctrlKey,
      isMac ? event.ctrlKey : event.altKey,
    );

    runInAction(() => {
      const action = this.getActionByCommand(command);
      if (!action || action.disabled) return;
      event.preventDefault();
      action.action();
    });
  };

  handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === ' ') {
      runInAction(() => (dashboardStore.isSpaceDown = false));
      return;
    }
  };

  actionsWithKeyCommands = (): Action[] => {
    return this.allActions.filter((action) => action.commands.length > 0);
  };

  printOccupiedShortcuts = () => {
    this.actionsByCommand.forEach((action, keyCommand) => {
      console.log(`Shortcut: ${keyCommand}, Action: ${action.longLabel}`);
    });
  };

  private setActionByCommand(
    action: Action | ((sourceView: ViewStore, targetView: ViewStore) => Action),
  ) {
    // For now, only raw actions can have key commands, ignoring higher-order Actions
    if (action instanceof Action) {
      action.commands.forEach((command) => {
        this.actionsByCommand.set(command.asString, action);
      });
    }
  }
  private getActionByCommand(command: KeyCommand): Action | undefined {
    return this.actionsByCommand.get(command.asString);
  }
}
//const uniqueActions = {...docsActions, ...specificActions};

export default new Actions();
