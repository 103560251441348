import React from 'react';
import { Box, Button, Input, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface NodeSelectionBoxProps {
  label: string;
  clear: () => void;
  select: () => void;
  value: string;
  isDisabled: boolean; // todo: this should be a function
}

export const NodeSelectionBox: React.FC<NodeSelectionBoxProps> = ({
  label,
  clear,
  select,
  value,
  isDisabled,
}): JSX.Element => {
  return (
    <Box>
      {/* create box to enter comma separated list of values*/}
      <Box sx={{ marginTop: '10px', fontSize: '12px' }}>{label}</Box>
      <Input
        type="text"
        id={label}
        value={value}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '10px',
          width: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
          paddingLeft: '5px',
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      {value !== '' && (
        <IconButton
          size="small"
          onClick={clear}
          sx={{
            marginTop: '1px',
            position: 'absolute',
            right: '9px',
            backgroundColor: 'white',
            ':hover': {
              backgroundColor: 'white',
            },
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={select}
        disabled={isDisabled}
        sx={{
          fontSize: '10px',
          marginTop: '10px',
          textTransform: 'none',
        }}
      >
        Add selected
      </Button>
    </Box>
  );
};
