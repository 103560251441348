import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const SearchDialog = observer((): JSX.Element => {
  const [query, setQuery] = React.useState('');

  const handleSubmit = () => {
    dashboardStore.currentGraphStore?.selectNodesBySearchString(query);
    dashboardStore.searchDialogOpen = false;
    setQuery('');
  };

  return (
    <Dialog
      open={dashboardStore.searchDialogOpen}
      onClose={() => (dashboardStore.searchDialogOpen = false)}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
    >
      <DialogTitle id="form-dialog-title">Search</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="query"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              handleSubmit();
            }
            e.stopPropagation();
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (dashboardStore.searchDialogOpen = false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            handleSubmit();
            e.stopPropagation();
          }}
          color="primary"
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default SearchDialog;
