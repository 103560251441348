import React from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ClickAwayListener } from '@mui/base';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, useTheme, IconButton, Tooltip } from '@mui/material';
import dashboardStore from '../stores/DashboardStore';
import Menu from './Menu';

/**
 * Horizontal bar that acts like a Menu
 *
 * Todo: arrow navigation
 * l, r switch index or open submenu
 * up dn for each menu
 */

const HelpMenu = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    root: {
      display: 'flex',
      position: 'relative',
    },
    iconButton: {
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };

  const close = () => {
    dashboardStore.helpMenuStore.open = false;
  };

  runInAction(() => {
    dashboardStore.helpMenuStore.position = { top: 35, left: 0 };
  });

  return (
    <ClickAwayListener onClickAway={close} mouseEvent="onMouseDown">
      <Box sx={styles.root}>
        <Tooltip title={'Help'} arrow>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => {
              runInAction(() => {
                dashboardStore.helpMenuStore.open = true;
              });
            }}
            sx={styles.iconButton}
          >
            <QuestionMarkIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {dashboardStore.helpMenuStore.open ? (
          <Menu
            menuStore={dashboardStore.helpMenuStore}
            onClose={close}
            closeOthers={(localMenuStore) => {
              runInAction(() => {
                dashboardStore.closeOthers(
                  dashboardStore.helpMenuStore.items,
                  localMenuStore,
                );
              });
            }}
          />
        ) : null}
      </Box>
    </ClickAwayListener>
  );
});

export default HelpMenu;
