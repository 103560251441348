import React, { useState } from 'react';
import {
  TextField,
  MenuItem,
  Box,
  FormControl,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Select,
  Paper,
  IconButton,
  SelectChangeEvent,
  SvgIconTypeMap,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Delete } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import ErrorStore from '../stores/ErrorStore';
import { ListEntry, Permissions } from '../stores/ShareDialogStore';
import { observer } from 'mobx-react-lite';

//---------------------- Text Field with Auto Complete -----------------------//

interface AutocompleteProps {
  label: string;
  options: string[]; // autocomplete options
  sx: React.CSSProperties;
  addToList: (entry: string) => void;
  AddComponent: OverridableComponent<SvgIconTypeMap>;
  marginBottom: string;
  allowNewField: boolean;
}

const AutocompleteTextBox: React.FC<AutocompleteProps> = ({
  label,
  options,
  sx,
  addToList,
  AddComponent,
  marginBottom,
  allowNewField,
}) => {
  const [value, setValue] = useState('');

  const handleButtonClick = () => {
    if (value === '' || value === null) {
      return;
    }
    if (!allowNewField && !options.includes(value)) {
      setValue('');
      ErrorStore.setError(
        `"${value}" must exist beforehand. Please select from the dropdown.`,
      );
      return;
    }
    addToList(value);
    setValue('');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Autocomplete
        freeSolo={allowNewField}
        id="AutoCompleteDialog"
        options={options} // autocomplete options
        sx={{ ...sx, marginBottom: marginBottom }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onChange={(e) => {
              setValue(e.target.value);
            }} // handle typing in the text field
            onKeyDown={(e) => {
              if (e.key === 'Enter' && value !== null) {
                handleButtonClick();
              } else {
                // Do not allow keyboard shortcuts to be triggered
                e.stopPropagation();
              }
            }}
            variant={'standard'}
          />
        )}
        value={value}
        onChange={(event, newValue) => {
          // Handle clicking on the dropdown options
          if (newValue !== null) {
            addToList(newValue);
            setValue('');
          }
        }}
        PaperComponent={({ children }) => (
          <Paper elevation={8} variant="elevation">
            {children}
          </Paper>
        )}
      />
      <IconButton
        sx={{ marginBottom: marginBottom }}
        onClick={handleButtonClick}
        disabled={value === '' || value === null}
      >
        <AddComponent />
      </IconButton>
    </Box>
  );
};

//---------------------------- Sharing List Entry ----------------------------//

interface ListEntryProps {
  entry: ListEntry;
  validPermissions: Permissions[];
  onDelete: (id: number) => void;
  onUpdatePermission: (
    currPermission: Permissions,
    newPermission: Permissions,
    entryId: number,
  ) => void;
}
const SharingListEntry: React.FC<ListEntryProps> = observer(
  ({ entry, validPermissions, onDelete, onUpdatePermission }) => {
    const { id, primaryName, secondaryName, setPermission } = entry;

    const handlePermissionChange = (
      event: SelectChangeEvent<Permissions>,
    ): void => {
      const newPermission = event.target.value as Permissions;
      onUpdatePermission(setPermission, newPermission, id);
    };

    return (
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell align="left">
          <div>
            {/* primaryName, email address */}
            <div>{primaryName}</div>
            {/* secondaryName userName?*/}
            <div
              style={{
                fontSize: 'small',
                color: 'gray',
                alignContent: 'right',
              }}
            >
              {secondaryName ? `(${secondaryName})` : ''}
            </div>
          </div>
        </TableCell>
        <TableCell align="right" sx={{ width: 100 }}>
          {/* Dropdown menu for permissions */}
          {validPermissions.length > 0 && (
            <FormControl variant="standard">
              <Select
                value={setPermission}
                onChange={handlePermissionChange}
                autoWidth
                sx={{ fontSize: '12px' }}
              >
                {validPermissions.map((permission, index) => (
                  <MenuItem
                    value={permission}
                    sx={{ fontSize: '12px' }}
                    key={index}
                  >
                    {permission}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: 50 }}>
          <IconButton color="inherit" size="small" onClick={() => onDelete(id)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  },
);

//------------------------ Sharing List Preview Box --------------------------//

interface SharedListProps {
  entries: ListEntry[];
  validPermissions: Permissions[];
  onDelete: (id: number) => void;
  onUpdatePermission: (
    currPermission: Permissions,
    newPermission: Permissions,
    entryId: number,
  ) => void;
}
const SharingList: React.FC<SharedListProps> = observer(
  ({ entries, validPermissions, onDelete, onUpdatePermission }) => {
    if (entries.length === 0) {
      return null;
    }
    return (
      <Box
        sx={{
          marginTop: '20px',
          boxShadow: 3,
        }}
      >
        <TableContainer>
          <Table>
            <TableBody>
              {entries.map((entry, index) => (
                <SharingListEntry
                  entry={entry}
                  validPermissions={validPermissions}
                  onDelete={onDelete}
                  onUpdatePermission={onUpdatePermission}
                  key={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  },
);

interface ContainerProps {
  textBoxLabel: string;
  autoCompleteOptions: string[];
  listEntries: ListEntry[];
  validPermissions: Permissions[];
  allowNewField: boolean; // for the AutoComplete TextBox
  addToList: (primaryName: string, secondaryName?: string) => void;
  rmFromList: (id: number) => void;
  AddComponent: OverridableComponent<SvgIconTypeMap>;
  onUpdatePermission: (
    currPermission: Permissions,
    newPermission: Permissions,
    entryId: number,
  ) => void;
}

export const SharingListContainer: React.FC<ContainerProps> = observer(
  ({
    textBoxLabel,
    autoCompleteOptions,
    listEntries,
    validPermissions,
    allowNewField,
    addToList,
    rmFromList,
    AddComponent,
    onUpdatePermission,
  }) => {
    const margin = listEntries.length === 0 ? '40px' : '5px';
    return (
      <Box>
        <AutocompleteTextBox
          label={textBoxLabel}
          options={autoCompleteOptions}
          sx={{
            paddingTop: '5px',
            width: '475px',
            display: 'flex',
          }}
          addToList={addToList}
          AddComponent={AddComponent}
          marginBottom={margin}
          allowNewField={allowNewField}
        />
        <SharingList
          entries={listEntries}
          onDelete={rmFromList}
          onUpdatePermission={onUpdatePermission}
          validPermissions={validPermissions}
        />
      </Box>
    );
  },
);
