import ErrorStore from '../stores/ErrorStore';
import { IExtents, SupportedSQLDbs } from '../types/app';
import { ReadFileResultEvent } from '../types/worker';
import ReadFileWorker from '../workers/ReadFile.worker';

export function isPointInBox(
  x: number,
  y: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): boolean {
  return (
    ((x > x1 && x < x2) || (x > x2 && x < x1)) &&
    ((y > y1 && y < y2) || (y > y2 && y < y1))
  );
}

/**
 * Convert a hex string to an object with r, g and b values
 * @param hex
 * @returns
 */
export const hexTo8bitRGB = (
  hex: string,
): { r: number; g: number; b: number } | null => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToFloatRGB = (
  hex: string,
): { r: number; g: number; b: number } | null => {
  const result = hexTo8bitRGB(hex);
  if (!result) return null;

  return {
    r: result.r / 255,
    g: result.g / 255,
    b: result.b / 255,
  };
};

/**
 * Capitalize only the first letter of a string.
 *
 * @param input The string to capitalize
 * @returns     The capitalized string
 */
export const initialCap = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

const zeroOneToHex = (n: number): string => {
  n = Number(n);
  if (isNaN(n)) return '00';

  const hex = Math.floor(n * 255).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

/**
 * Convert rgb with components in the range of 0 to 1 to a hex string
 */
export const floatRGBToHex = (r: number, g: number, b: number): string => {
  return `#${zeroOneToHex(r)}${zeroOneToHex(g)}${zeroOneToHex(b)}`;
};

export const intRGBToHex = (r: number, g: number, b: number): string => {
  return floatRGBToHex(r / 255, g / 255, b / 255);
};

/**
 * Format numbers using:
 * - scientific notation below 1e-5.
 * - 6 significant digits below 1,000
 * - 3 significant digits above 1,000 with k, m, b, etc. suffixes.
 */
export const numberFormatter = (num: number): string => {
  const minDisplayValue = 1e-10;
  const maxExponent = 1e-5;

  const abs = Math.abs(num);

  if (abs < minDisplayValue) return '0';

  if (abs < maxExponent - minDisplayValue)
    return num.toExponential(2).toString();

  if (abs < 1000) {
    // Using Number() removes trailing zeros
    return Number(num.toFixed(6)).toString();
  }

  const suffixes = ['', 'k', 'm', 'b', 't', 'q', 's', 'o', 'n', 'd', 'u'];

  let magnitude = 0;
  while (Math.abs(num) >= 1000 && magnitude < suffixes.length - 1) {
    magnitude++;
    num /= 1000;
  }

  return `${Number(num.toFixed(3))}${suffixes[magnitude]}`;
};

/**
 * Formats dates to string:
 * - If the date is today, return the time as HH:MM.
 * - If the date is not today, return the date with the full month.
 */
export const dateFormatter = (date?: Date | undefined): string => {
  if (!date) return '';
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  return date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

/**
 * Sets the query string without reloading the page.
 *
 * Note: updateQueryParam, removeQueryParamByValue and appendQueryParam all flow through here.
 */
export const getBaseURL = (): string => {
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname
  );
};

/**
 * Add the query string to the URL without reloading the page.
 * If no query string is provided, the current query string will be removed.
 */
export const setQueryString = (queryParams?: URLSearchParams): void => {
  const queryString = queryParams?.toString()
    ? '?' + queryParams.toString()
    : '';
  const newURL = getBaseURL() + queryString;
  window.history.pushState({ path: newURL }, '', newURL);
};

/**
 * Replace the key with the passed in value(s) in the query string.
 */
export const updateQueryParam = (
  key: string,
  valueOrValues: string | string[],
): void => {
  const queryParams = new URLSearchParams(window.location.search);
  if (typeof valueOrValues === 'string') {
    queryParams.set(key, valueOrValues);
  } else {
    queryParams.delete(key);
    valueOrValues.forEach((value) => queryParams.append(key, value));
  }
  setQueryString(queryParams);
};

export const removeQueryParamByValue = (
  key: string,
  valueToRemove: string,
): void => {
  const queryParams = new URLSearchParams(window.location.search);
  const values = queryParams.getAll(key);
  values.splice(
    values.findIndex((vaule) => vaule === valueToRemove),
    1,
  );
  updateQueryParam(key, values);
};

export const appendQueryParam = (key: string, value: string): void => {
  const queryParams = new URLSearchParams(window.location.search);
  if (!queryParams.getAll(key).includes(value)) {
    queryParams.append(key, value);
  }
  setQueryString(queryParams);
};

/**
 * Return a function that will only execute once if called multiple times
 * within the given time period. `immediate` determines whether the function
 * is called at the beginning or end of the time period.
 */
export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  immediate = false,
  wait = 300,
) {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (...args: T): Promise<U> => {
    const executeNow = immediate && !timer;
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => {
        timer = undefined;
        if (!immediate) {
          resolve(callback(...args));
        }
      }, wait);
      if (executeNow) {
        resolve(callback(...args));
      }
    });
  };
}

/**
 * Given an array of sets, returns their intersection.
 */
export const intersectionOfSets = <T>(sets: Set<T>[]): Set<T> => {
  const intersection: Set<T> = new Set(sets[0]);
  sets.forEach((set) => {
    intersection.forEach((item) => {
      if (!set.has(item)) {
        intersection.delete(item);
      }
    });
  });
  return intersection;
};

/**
 * Given an array of sets, returns their union.
 */
export const unionOfSets = <T>(sets: Set<T>[]): Set<T> => {
  const union: Set<T> = new Set();
  sets.forEach((set) => {
    set.forEach((item) => {
      union.add(item);
    });
  });
  return union;
};

/**
 * Takes a UUID string and returns a pair of BigInts.
 *
 * Todo: Use a BigUint64Array array instead of a pair of BigInts in a non-typed
 * array because this will force them to be 64 bits.
 */
export const uuidToBigIntPair = (uuid: string): [bigint, bigint] => {
  const hex = uuid.replace(/-/g, '');

  return [
    BigInt('0x' + hex.substring(0, 16)),
    BigInt('0x' + hex.substring(16)),
  ];
};

/**
 * Takes a pair of bigints and returns a UUID string.
 *
 * Todo: Use a BigUint64Array array instead of a pair of BigInts so that they're
 * forced to be 64 bits.
 */
export const bigIntPairToUuid = (high: bigint, low: bigint): string => {
  const uuid =
    high.toString(16).padStart(16, '0') + low.toString(16).padStart(16, '0');

  // eslint-disable-next-line prettier/prettier
  return `${uuid.substring(0, 8)}-${uuid.substring(8, 12)}-${uuid.substring(12, 16)}-${uuid.substring(16, 20)}-${uuid.substring(20)}`;
};

/**
 * Checks if extents are equal.
 */
export const extentsEqual = (extents: IExtents, other: IExtents): boolean => {
  return (
    extents.xMin === other.xMin &&
    extents.xMax === other.xMax &&
    extents.yMin === other.yMin &&
    extents.yMax === other.yMax
  );
};

/*
 * File importing utils.
 */

export const MAX_CSV_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

export async function trimFile(
  csvFile: File,
  maxSizeBytes: number,
): Promise<File> {
  return new Promise((resolve, reject) => {
    const worker = new ReadFileWorker();

    // Set a file reading timeout
    const timeoutId = setTimeout(() => {
      worker.terminate();
      const err = 'Reading the file timed out';
      ErrorStore.setError(err);
      reject(err);
    }, 3_000); // 3 seconds max read time

    // On (worker) success
    worker.onmessage = (event: ReadFileResultEvent) => {
      clearTimeout(timeoutId);
      worker.terminate();
      const { file } = event.data;
      resolve(file);
    };

    worker.onerror = (err) => {
      ErrorStore.setError(err.error);
      reject(err);
    };

    // Start the worker
    worker.postMessage({ file: csvFile, maxSize: maxSizeBytes });
  });
}

/*
 * Database connection.
 */

export function dbTypeFromURL(url: string): SupportedSQLDbs | undefined {
  if (url.includes('postgres')) {
    return 'postgres';
  } else if (url.includes('mysql')) {
    return 'mySql';
  } else if (url.includes('sqlite')) {
    return 'sqlite';
  } else {
    return undefined;
  }
}
