import { DEFAULT_EDGE_FRAG_DEPTH } from './ShaderConstants';

export default `
precision highp float;

in vec3 vColor;

out vec4 fragColor;

void main() {
  gl_FragDepth = ${DEFAULT_EDGE_FRAG_DEPTH};
  fragColor = vec4(vColor, 1.0);
}
`;
