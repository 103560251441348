import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, useTheme } from '@mui/material';

import { actionToButton } from './View';
import Actions from '../actions/actions';

/**
 * Edit Bar for Undo, Redo, Copy, Paste
 */

const EditBar = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
      borderRadius: `${theme.shape.borderRadius}px`,
      justifyContent: 'space-between',
      height: '25px',
      padding: '0 4px 0 4px',
    },
    iconBox: {
      margin: '3px',
    },
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.iconBox}>
        {actionToButton(Actions.uniqueActions.undo)}
      </Box>
      <Box sx={styles.iconBox}>
        {actionToButton(Actions.uniqueActions.redo)}
      </Box>
    </Box>
  );
});
export default EditBar;
