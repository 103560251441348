import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { Vector3 } from 'three';

export class DRiskCSS2DObject extends CSS2DObject {
  vector?: Vector3;
  widthHalf?: number;
  heightHalf?: number;
  labelRotation?: number;
  constructor(element: HTMLElement) {
    super(element);
  }

  applyTransform() {
    if (!(this.vector && this.widthHalf && this.heightHalf)) return;
    this.element.style.transformOrigin = '-4px 50%';
    this.element.style.transform = `translate(4px,-50%) translate(${
      this.vector.x * this.widthHalf + this.widthHalf
    }px,${-this.vector.y * this.heightHalf + this.heightHalf}px) rotate(${
      this.labelRotation ?? 0
    }deg)`;
  }
}
