import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  const styles = {
    padding: '8px',
    flex: 0,
  };
  return (
    <Box sx={styles}>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://drisk.ai/">
          dRISK
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
};

export default Footer;
