import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { disable } from 'aws-amplify/analytics';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { Box, CssBaseline, useTheme } from '@mui/material';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import dashboardStore from '../stores/DashboardStore';
import Dashboard from './Dashboard';
import DRiskSnackbar from './DRiskSnackbar';
import DriskLogo from './DriskLogo';
import Footer from './Footer';
import Header from './Header';
import HomePage from './HomePage';
import { AppProps } from '../types/app';
import '../css/scrollbars.css';

// Set analytics enabled or not depending on environment variable
if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') {
  disable();
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// strict mobx settings:
configure({
  enforceActions: 'always', // 'observed' | 'always' | 'never'
  computedRequiresReaction: true,
  // This is causing hard-to-find warning: Derivation 'observer' is created/updated without reading any observable value.
  reactionRequiresObservable: true,
  // This causes warnings when using autocomplete, but should keep on to see other issues
  observableRequiresReaction: false,
  disableErrorBoundaries: false,
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#BAC9D1',
      dark: '#4f6c7a',
    },
    background: {
      default: '#BAC9D1',
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          userSelect: 'none',
        },
        'body:has(.app-controller)': {
          overflow: 'hidden',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
      },
    },
  },
});

/**
 * Amplify UI customization
 */
const components = {
  Header() {
    return (
      <Box
        onClick={() => window.location.reload()}
        sx={{
          textAlign: 'center',
          paddingTop: '2vh',
          paddingBottom: '1vh',
          margin: '8vh',
          cursor: 'pointer',
        }}
      >
        <DriskLogo />
      </Box>
    );
  },
  Footer() {
    return <Footer />;
  },
};

/**
 * Control flow of main components of the App
 **/
const AppController = observer(({ signOut, user }: AppProps) => {
  const theme = useTheme();

  const styles = {
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxHeight: '100vh',
      height: '100vh',
      overflow: 'hidden',
      padding: 0,
    },
    content: {
      flexGrow: 1,
      overflowY: 'hidden',
      padding: theme.spacing(1),
    },
  };

  return (
    <Box sx={styles.root} className="app-controller">
      <Header signOut={signOut} user={user} />

      <Box sx={styles.content}>
        {dashboardStore.homePageStore.open ? (
          <HomePage />
        ) : dashboardStore.currentGraphStore ? (
          <Dashboard />
        ) : (
          /* Empty box while no graph is open */
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
          />
        )}
        <DRiskSnackbar />
      </Box>

      <Footer />
    </Box>
  );
});

/**
 * Root React Component
 */
const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Authenticator
                components={components}
                signUpAttributes={['email']}
              >
                {({ signOut, user }) => (
                  <AppController signOut={signOut} user={user} />
                )}
              </Authenticator>
            </ThemeProvider>
          </StyledEngineProvider>
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
