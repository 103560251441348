import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import { actionToButton } from './View';
import Actions from '../actions/actions';

import { LabelOutlined } from '@mui/icons-material';
import dashboardStore from '../stores/DashboardStore';

const LabelButton = observer((): JSX.Element => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconButton: {
      padding: 0,
      backgroundColor: 'transparent',
      fontSize: 18,
    },
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box sx={styles.container}>
      <Tooltip open={showTooltip} title="Show / Hide Labels l" arrow>
        <IconButton
          color={'default'}
          sx={styles.iconButton}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            dashboardStore.currentGraphStore?.updateSelectedNodes();
            setPopoverOpen(true);
          }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => {
            setShowTooltip(false);
          }}
          size="large"
          disabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
        >
          <LabelOutlined />
        </IconButton>
      </Tooltip>

      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          width="100%"
          height={26}
          display="flex"
          justify-content="space-between"
          gap={1}
          padding={1}
        >
          <Tooltip title="">
            {actionToButton(
              Actions.uniqueActions.turnLabelsForSelectedNodesOff,
            )}
          </Tooltip>
          <Tooltip title="">
            {actionToButton(Actions.uniqueActions.turnLabelsForSelectedNodesOn)}
          </Tooltip>
        </Box>
      </Popover>
    </Box>
  );
});

export default LabelButton;
