import { makeAutoObservable, reaction, runInAction, when } from 'mobx';
import { getCurrentUser, AuthUser, fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import {
  APIView,
  AsyncFn,
  GeneratorFn,
  GraphId,
  GraphDiff,
  GraphMetaData,
  GraphResponse,
  MergeNodesTransformationType,
  Node,
  NodeId,
  ViewId,
  Uuid,
  SQLPreviewResult,
} from '../types/app';
import AlertDialogStore from './AlertDialogStore';
import APIService from '../services/APIService';
import CarouselStore from './CarouselStore';
import ContextMenuStore, {
  ContextMenuContext,
} from '../stores/ContextMenuStore';
import DataService from '../services/DataService';
import GraphStore from '../stores/GraphStore';
import InspectDrawerStore from './InspectDrawerStore';
import LoadingBackdropStore from './LoadingBackdropStore';
import MenuBarStore from '../stores/MenuBarStore';
import MenuStore from './MenuStore';
import InAppHelpStore from './InAppHelpStore';
import HelpMenuStore from './HelpMenuStore';
import { onboardingHelpItems } from '../in-app-help/InAppHelp';
import DataRetrievalAgentStore from './DataRetrievalAgentStore';
import NavigationStore from './NavigationStore';
import HomePageStore from '../stores/HomePageStore';
import NodePropertiesStore from './NodePropertiesStore';
import RightDrawerStore from './RightDrawerStore';
import SliderStore from '../stores/SliderStore';
import { Transformation } from './TransformationsDrawerStore';
import TransformationsDrawerStore from './TransformationsDrawerStore';
import NodeModel from '../models/NodeModel';
import ViewStore from '../stores/ViewStore';
import {
  appendQueryParam,
  removeQueryParamByValue,
  setQueryString,
  updateQueryParam,
  trimFile,
  MAX_CSV_FILE_SIZE,
} from '../utils/utils';
import ErrorStore from './ErrorStore';
import ShareDialogStore from './ShareDialogStore';
import { MonitoredTasks, openViewsCallback } from '../services/TaskService';
import { UploadMenuStore } from './UploadMenuStore';
import {
  MAX_OPEN_VIEWS,
  TOO_MANY_VIEWS_MESSAGE,
  MAX_URLS_TO_OPEN,
} from '../Constants';
import { MultiNodeStore } from './NodeStore';
import { TaskResult } from 'wasm_service';
import { withDashboardBackdrop } from '../utils/backdrop';

declare global {
  interface Window {
    dashboardStore: DashboardStore;
  }
}
/**
 * Singleton Mobx observable that describes the state of the dashboard:
 * - what graphs are available
 * - current graph
 * - what views are open
 * - how they're positioned
 * - other settings
 *
 * This can be accessed anywhere by importing it with:
 * import DashboardStore from '../stores/DashboardStore';
 *
 * and then get the fields you need with, eg:
 * DashboardStore.openViews
 */

const ALLOWED_FILE_IMPORT_EXTENSIONS =
  '.csv, .parquet, .json, .ed, .xyz, .asc, .ptx, .png, .jpg, .jpeg, .mov, .mp4, .zip, .pdf, .xosc, .xodr';

class DashboardStore {
  /**
   * All the graphs available to the user
   */
  public graphs: GraphMetaData[] = [];
  private graphsLoaded = false;

  get graphsMap(): Map<GraphId, GraphMetaData> {
    return new Map(this.graphs.map((graph) => [graph.id, graph]));
  }
  get currentGraphMetaData(): GraphMetaData | undefined {
    return this.graphs.find((graph) => graph.id === this.currentGraphStore?.id);
  }

  /**
   * If the file size restrictions should be ignored.
   */
  ignoreMaxFileSize = process.env.REACT_APP_IGNORE_MAX_FILE_SIZE ?? false;

  /**
   * The Views currently being viewed
   */
  public openViews: ViewStore[] = [];
  get openViewsMap(): Map<ViewId, ViewStore> {
    return new Map(this.openViews.map((view) => [view.id, view]));
  }
  public viewsSurfaceSize = { width: 100, height: 100 };

  // To allow for the small gutter which separates views
  VIEW_GUTTER = 4;

  closeAllViews(): void {
    if (!this.currentGraphStore) return;
    this.currentGraphStore.close();
    this.openViews = [];
  }

  allowGraphManipulationCustomization =
    process.env.REACT_APP_SHOW_GRAPH_MANIPULATION_CUSTOMIZATION != 'false';

  static instance: DashboardStore;
  public user: AuthUser | undefined = undefined;
  public userGroups: string[] = [];
  public userEmailAddress: string | undefined = undefined;

  nodeSizeSlider: SliderStore = new SliderStore(1, 0.01, 3.5, 0.01);

  constructor() {
    if (DashboardStore.instance) {
      return DashboardStore.instance;
    }
    DashboardStore.instance = this;

    window.dashboardStore = this; // for debugging
    makeAutoObservable(this);

    reaction(
      () => [
        this.openViews.length,
        this.viewsSurfaceSize.width,
        this.viewsSurfaceSize.height,
      ],
      this.layoutViews,
    );

    // Subscribe to the graph when the user is authenticated
    Hub.listen('auth', async (data) => {
      if (data?.payload?.event === 'signedIn') {
        this.setUser();
      }
    });

    Hub.listen('core', async (data) => {
      if (data?.payload?.event === 'configure') {
        this.setUser();
      }
    });

    when(() => this.graphsLoaded, this.initFromQueryParams);
  }
  /**
   * User and user groups
   */
  private setUser = async (): Promise<void> => {
    try {
      if (await getCurrentUser()) {
        const session = await fetchAuthSession();
        if (session) {
          const idToken =
            (await fetchAuthSession()).tokens?.idToken?.toString() ?? '';
          const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
          const groups: string[] = decodedToken['cognito:groups'];
          this.updateUserGroups(groups);
          runInAction(() => {
            this.userEmailAddress = decodedToken.email;
          });
        }

        const authUser = await getCurrentUser();
        this.updateUser(authUser);

        // Check if the user has completed the onboarding and start it if not
        const onboardingComplete =
          await this.onboardingHelpStore.userHasCompleted();

        if (!onboardingComplete) {
          this.onboardingHelpStore.start();
        }
      }
      await this.loadAndStoreGraphs();
      // eslint-disable-next-line
    } catch (error) { }
  };
  private updateUser(user: AuthUser | undefined) {
    runInAction(() => {
      this.user = user;
    });
  }

  private updateUserGroups(groups: string[]) {
    runInAction(() => {
      this.userGroups = groups;
    });
  }
  /**
   * QueryParams:
   * /?graphId=1234&viewId=1111&viewId=22222&copyGraph
   *
   * Note: copyGraph=true means copying all views from the graph
   */
  private initFromQueryParams = async (): Promise<void> => {
    const queryParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );

    const graphId = queryParams.get('graphId') as GraphId;
    if (!graphId) {
      setQueryString();
      this.homePageStore.open = true;
      return;
    }

    when(
      () => this.user != undefined,
      async () => {
        const queries: Map<string, (arg: URLSearchParams) => Promise<void>> =
          new Map([
            ['copyGraph', this.clonePublicGraph],
            ['makePublic', this.makeGraphPublic],
          ]);

        for (const [query, action] of queries) {
          if (queryParams.has(query)) {
            await action(queryParams);
          }
        }
      },
    );

    await this.setCurrentGraphStoreById(graphId);
  };

  private clonePublicGraph = async (
    queryParams: URLSearchParams,
  ): Promise<void> => {
    const graphId = queryParams.get('graphId') as GraphId;
    const newName = undefined; // use existing one
    const fromPublic = true;
    const graphStore = await this.cloneGraphById(graphId, newName, fromPublic);
    if (!graphStore) {
      setQueryString();
      return;
    }
    removeQueryParamByValue('copyGraph', 'true');
  };

  private makeGraphPublic = async (
    queryParams: URLSearchParams,
  ): Promise<void> => {
    const graphId = queryParams.get('graphId') as GraphId;
    await DataService.makeGraphPublic(graphId);
    removeQueryParamByValue('make-public', 'true');
    setQueryString();
    this.homePageStore.open = true;
    return;
  };

  /**
   * Update the query params based on the order of the currently open views.
   * Any unknown view ids will be removed.
   */
  updateQueryParamsFromOpenViews = (): void => {
    const validParams = new URLSearchParams();

    if (this.currentGraphStore) {
      validParams.set('graphId', this.currentGraphStore.id);
    }

    this.openViews.forEach((viewStore) => {
      validParams.append('viewId', viewStore.id);
    });
    setQueryString(validParams);
  };

  /**
   * The graphs passed in will also have the list of views if we've previously
   * loaded and saved them.
   */

  loadAndStoreGraphs = async () => {
    const graphsList = await DataService.getGraphs();
    runInAction(() => {
      this.graphs = graphsList;
      this.graphsLoaded = true;
    });
  };

  /**
   * The currently selected graph
   */
  _currentGraphStore: GraphStore | undefined = undefined;
  get currentGraphStore(): GraphStore | undefined {
    return this._currentGraphStore;
  }
  set currentGraphStore(graph: GraphStore | undefined) {
    this._currentGraphStore = graph;
  }

  // todo: update this so it doesn't load the data.
  setCurrentGraphStoreById = async (graphId: GraphId): Promise<void> => {
    const graphMetadata = this.graphs.find((graph) => graph.id === graphId);
    if (!graphMetadata || graphMetadata.id === this.currentGraphStore?.id)
      return;

    const { id, name, groups, ownerEmail } = graphMetadata;

    if (this.currentGraphStore) {
      // This might look like doing the work of the garbage collector,
      // but unfortunately it is not quite like that.
      // The main use case is upon cloning a graph. The only UUID that
      // is updated upon cloning is the Graph Id. Therefore, if we do not
      // close the views, we will continue to use the ViewStores that
      // belong to the graph before cloning.
      this.closeAllViews();
    }

    this.currentGraphStore = new GraphStore(id, name, groups, ownerEmail);

    updateQueryParam('graphId', this.currentGraphStore.id);
    await when(() => this.currentGraphStore?.initialized ?? false);
    this.homePageStore.open = false;
  };

  /*
   * Clone a graph and set it as the current graph.
   */
  cloneGraphById = withDashboardBackdrop(
    async (
      graphId: GraphId,
      name?: string,
      fromPublic?: boolean,
    ): Promise<GraphStore | undefined> => {
      const newGraphMetaData = await DataService.cloneGraph(
        graphId,
        name,
        fromPublic,
      );
      if (!newGraphMetaData) return;
      await this.loadAndStoreGraphs();
      await this.setCurrentGraphStoreById(newGraphMetaData.id);
      if (this.currentGraphStore?.id === newGraphMetaData.id) {
        ErrorStore.setSuccess(`Graph successfully cloned`);
        return this.currentGraphStore;
      } else {
        return;
      }
    },
  );

  closeGraphStore = (): void => {
    if (this.currentGraphStore) {
      this.closeAllViews();
      this.currentGraphStore = undefined;
      updateQueryParam('graphId', []);
      updateQueryParam('viewId', []);
      this.homePageStore.open = true;
    }
  };

  /**
   * The currently selected / worked on view.
   * Passed through from the GraphStore.
   */
  get currentViewStore(): ViewStore | undefined {
    return this.currentGraphStore?.currentViewStore;
  }
  set currentViewStore(view: ViewStore | undefined) {
    if (!this.currentGraphStore) return;
    this.currentGraphStore.currentViewStore = view;
  }
  setCurrentViewStoreById = (viewId: ViewId): ViewStore | undefined => {
    if (!this.currentGraphStore) return;
    const newView = this.currentGraphStore.viewStores.find(
      (view) => view.id === viewId,
    );
    if (!newView) return;
    if (newView.id === this.currentViewStore?.id) return;
    this.currentGraphStore.currentViewStore = newView;
    return newView;
  };

  /**
   * Dragging
   */

  /**
   * The ID of the view that is currently being dragged over. That is
   * the drop target.
   */
  private _dragHoverViewId: ViewId | undefined = undefined;
  get dragHoverViewId(): ViewId | undefined {
    return this._dragHoverViewId;
  }
  set dragHoverViewId(newState: ViewId | undefined) {
    this._dragHoverViewId = newState;
  }

  /**
   * Called when the dropped view is dropped either over the target
   * view or an empty space.
   *
   * Open the view if it isn't open it and shift the openViews
   * array accordingly.
   */
  dropView = async (dropId: ViewId, targetId?: ViewId): Promise<void> => {
    if (!this.currentGraphStore) return;

    let dropIndex = this.openViews.findIndex((view) => view.id === dropId);
    if (dropIndex < 0) {
      // This puts the view at the end of the list
      await this.openSingleView(dropId);
      dropIndex = this.openViews.length - 1;
    }

    const targetIndex = targetId
      ? this.openViews.findIndex((view) => view.id === targetId)
      : this.openViews.length;

    if (dropIndex === targetIndex) return;

    const dropView = this.openViews[dropIndex];
    this.openViews.splice(dropIndex, 1);
    this.openViews.splice(targetIndex, 0, dropView);
    this.updateQueryParamsFromOpenViews();
  };

  private layoutViews = () => {
    if (
      !this.viewsSurfaceSize.width ||
      !this.viewsSurfaceSize.height ||
      this.openViews.length === 0
    ) {
      return;
    }

    const aspectRatio =
      this.viewsSurfaceSize.width / this.viewsSurfaceSize.height;

    const numViews = this.openViews.length;

    if (numViews > MAX_OPEN_VIEWS) {
      ErrorStore.setError(TOO_MANY_VIEWS_MESSAGE);
      return;
    }

    let layout = '';
    switch (numViews) {
      case 1: {
        layout = 'single_row';
        break;
      }
      case 2: {
        if (aspectRatio > 1) {
          layout = 'single_row';
        } else {
          layout = 'single_column';
        }
        break;
      }
      case 3: {
        if (aspectRatio > 1.5) {
          layout = 'single_row';
        } else if (aspectRatio > 0.9) {
          layout = 'one_off';
        } else {
          layout = 'single_column';
        }
        break;
      }
      case 4: {
        if (aspectRatio > 2) {
          layout = 'single_row';
        } else if (aspectRatio < 0.75) {
          layout = 'single_column';
        } else {
          layout = 'grid';
        }
        break;
      }
      case 5: {
        layout = 'one_off';
        break;
      }
      case 6: {
        layout = 'grid';
        break;
      }
    }

    switch (layout) {
      case 'single_row': {
        const columnDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.width,
          numViews,
          this.VIEW_GUTTER,
        );

        this.openViews.forEach((viewStore, index) => {
          viewStore.top = viewStore.bottom = 0;
          viewStore.left = columnDims.sections[index].start;
          viewStore.right = columnDims.sections[index].end;
        });
        break;
      }
      case 'single_column': {
        const rowDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.height,
          numViews,
          this.VIEW_GUTTER,
        );

        this.openViews.forEach((viewStore, index) => {
          viewStore.left = viewStore.right = 0;
          viewStore.top = rowDims.sections[index].start;
          viewStore.bottom = rowDims.sections[index].end;
        });
        break;
      }
      case 'one_off': {
        /**
         * One-off layouts are only for 3 or 5 views:
         * - 3 views are laid out with the first view taking the first column and a 1x2 grid.
         * - 5 views are laid out with the first view taking the first column and a 2x2 grid.
         */

        const columnDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.width,
          numViews === 3 ? 2 : 3,
          this.VIEW_GUTTER,
        );

        const rowDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.height,
          2,
          this.VIEW_GUTTER,
        );

        this.openViews.forEach((viewStore, index) => {
          if (index === 0) {
            viewStore.top = rowDims.sections[0].start;
            viewStore.bottom = rowDims.sections[1].end;
            viewStore.left = columnDims.sections[0].start;
            viewStore.right = columnDims.sections[0].end;
            return;
          }
          const columnIndex = numViews === 3 ? 1 : ((index - 1) % 2) + 1;
          const rowIndex = Math.floor(index / (numViews === 3 ? 2 : 3));

          viewStore.top = rowDims.sections[rowIndex].start;
          viewStore.bottom = rowDims.sections[rowIndex].end;
          viewStore.left = columnDims.sections[columnIndex].start;
          viewStore.right = columnDims.sections[columnIndex].end;
        });
        break;
      }
      case 'grid': {
        /**
         * We only support 4 or 6 views in a grid:
         * - 4 views are laid out in a 2x2 grid.
         * - 6 views are laid out in a 3x2 grid.
         */
        const columnDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.width,
          numViews === 4 ? 2 : 3,
          this.VIEW_GUTTER,
        );

        const rowDims = this.calculateViewDimensions(
          this.viewsSurfaceSize.height,
          2,
          this.VIEW_GUTTER,
        );

        this.openViews.forEach((viewStore, index) => {
          const columnIndex = index % (numViews / 2);
          const rowIndex = Math.floor(index / (numViews / 2));

          viewStore.top = rowDims.sections[rowIndex].start;
          viewStore.bottom = rowDims.sections[rowIndex].end;
          viewStore.left = columnDims.sections[columnIndex].start;
          viewStore.right = columnDims.sections[columnIndex].end;
        });
        break;
      }
    }
  };

  /**
   * Given a length (width or height), gutter size, and number of sections (rows or columns),
   * calculate the length and start position of each section such that every section is the same size.
   *
   * @param totalLength The total length (width or height) of the view surface in px.
   * @param numberOfSections The number of sections (rows or columns) to divide the length into.
   * @param gutter The size of the gutter between sections in px.
   * @returns {
   *  sectionLength, - The length (width or height) of all the sections in px.
   *  sections,      - The array of .start and .end positions for each section.
   *                 - Note that .end is the distance from the right or bottom of the view.
   * }
   */
  private calculateViewDimensions = (
    totalLength: number,
    numberOfSections: number,
    gutter: number,
  ): { sectionLength: number; sections: { start: number; end: number }[] } => {
    if (numberOfSections < 1) return { sectionLength: 0, sections: [] };

    const sectionLength =
      (totalLength - gutter * (numberOfSections - 1)) / numberOfSections;

    const sections = Array.from({ length: numberOfSections }, (_, i) => ({
      start: i * (sectionLength + gutter),
      end: totalLength - i * (sectionLength + gutter) - sectionLength,
    }));

    return {
      sectionLength,
      sections,
    };
  };

  // todo: move this into GraphStore
  toggleOpenView = async (id: ViewId): Promise<void> => {
    if (!this.currentGraphStore) return;

    const isOpen = this.openViews.some((view) => view.id === id);
    if (isOpen) {
      this.closeView(id);
    } else {
      await this.openSingleView(id);
    }
  };

  openSingleView = async (id: ViewId): Promise<ViewStore | undefined> => {
    const viewStores = await this.openMultipleViews([id]);
    if (!viewStores) return;
    return viewStores[0];
  };

  // Open multiple views simultaneously without race conditions
  openMultipleViews = async (
    ids: ViewId[],
  ): Promise<ViewStore[] | undefined> => {
    ids = ids.filter((id) => !this.openViews.some((view) => view.id === id));
    if (ids.length == 0) return;
    if (this.openViews.length >= MAX_OPEN_VIEWS) {
      ErrorStore.setError(TOO_MANY_VIEWS_MESSAGE);
      return;
    }
    if (this.openViews.length + ids.length > MAX_OPEN_VIEWS) {
      ids = ids.slice(0, MAX_OPEN_VIEWS - this.openViews.length);
    }

    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    const viewStores: ViewStore[] = [];
    for (const id of ids) {
      const viewStore = await graphStore.getOrCreateViewStoreById(id);
      if (!viewStore) {
        removeQueryParamByValue('viewId', id);
        continue;
      }
      viewStores.push(viewStore);
    }

    if (!viewStores.length) return;
    this.currentGraphStore?.postMessageToGraphWorker({
      type: 'openViews',
      viewIds: viewStores.map((view) => view.id),
    });

    runInAction(() => {
      for (const viewStore of viewStores) {
        this.openViews.push(viewStore);
        appendQueryParam('viewId', viewStore.id);
      }

      graphStore.currentViewStore = viewStores[0];
    });

    await Promise.all(
      viewStores.map(async (viewStore) => {
        await viewStore.zoomWhenExtentsUpdate();
      }),
    );
    return viewStores;
  };

  closeView = async (id: ViewId) => {
    runInAction(async () => {
      if (!this.currentGraphStore) return;

      const index = this.openViews.findIndex((view) => view.id === id);
      if (index >= 0) {
        this.openViews.splice(index, 1);

        const totalViews = this.openViews.length;
        if (totalViews) {
          // Select the next view if there is one open
          const nextIndex = Math.min(index, totalViews - 1);
          this.currentGraphStore.currentViewStore = this.openViews[nextIndex];
        } else {
          // Otherwise select nothing
          this.currentGraphStore.currentViewStore = undefined;
        }
      }
      removeQueryParamByValue('viewId', id);
    });
  };

  // todo: move to graphStore
  deleteViewById = async (viewId: ViewId): Promise<void> => {
    if (!this.currentGraphStore) return;

    const closeAction = () => {
      // this.closeView(viewId);
      this.currentGraphStore?.deleteViewById(viewId);
    };

    AlertDialogStore.yesNoAlert(
      'Are you sure you want to delete this view?',
      closeAction,
    );
  };

  duplicateView = async (view: ViewStore) => {
    this.duplicateViewById(view.id);
  };

  duplicateViewById = async (viewId: ViewId) => {
    if (!this.currentGraphStore) return;

    const response = await APIService.duplicateView(
      this.currentGraphStore.id,
      viewId,
    );
    if (!response) return;

    const [newViewId, gDiff] = response;
    if (!this.currentGraphStore) return;

    this.currentGraphStore.applyDiff(gDiff);
    await this.openSingleView(newViewId);
  };

  *openViewFromNode() {
    const graph = this.currentGraphStore;
    if (!graph) return;
    if (!graph.anyNodesSelected) return;
    if (this.openViews.length >= MAX_OPEN_VIEWS) return;
    yield graph.updateSelectedNodes();

    let toOpen: NodeId[] = [];
    const toPlot: NodeId[] = [];
    graph.selectedNodeIds.forEach((nodeId) => {
      if (graph.views.some((view) => view.id === nodeId)) {
        if (!this.openViews.some((view) => view.id === nodeId)) {
          toOpen.push(nodeId);
        }
      } else {
        toPlot.push(nodeId);
      }
    });

    toOpen = toOpen.slice(0, MAX_OPEN_VIEWS - this.openViews.length);
    if (toOpen) {
      yield this.openMultipleViews(toOpen);
    }
    if (toPlot && this.openViews.length < MAX_OPEN_VIEWS) {
      yield this.createPlotView(toPlot);
    }
  }

  /**
   * New Graph
   */
  newGraph = async (
    name: string | undefined = undefined,
  ): Promise<GraphStore | undefined> => {
    let graphName: string;
    if (!name) {
      const n = this.graphs.filter(
        (graph) => graph.name?.startsWith('New Graph') ?? false,
      ).length;
      graphName = n > 0 ? `New Graph ${n}` : 'New Graph';
    } else {
      graphName = name;
    }
    const graphId = await DataService.newGraph(graphName);
    if (!graphId) return;
    await this.loadAndStoreGraphs();
    await this.setCurrentGraphStoreById(graphId);
    if (this.currentGraphStore?.id === graphId) {
      return this.currentGraphStore;
    } else {
      return;
    }
  };

  /**
   * Save, Delete Current Graph
   */
  submitGraphOptions = () => {
    if (!this.currentGraphStore) return;
    DataService.saveGraph(
      this.currentGraphStore.id,
      this.currentGraphStore.name,
    );
  };

  private deleteGraphById = async (graphId: GraphId) => {
    await DataService.deleteGraph(graphId);
  };

  deleteCurrentGraph = async () => {
    if (!this.currentGraphStore) return;
    await this.deleteGraphById(this.currentGraphStore?.id);
    this.closeGraphStore();
  };

  duplicateCurrentGraph = async () => {
    if (!this.currentGraphStore) return;
    const graphStore = await this.cloneGraphById(
      this.currentGraphStore.id,
      this.currentGraphStore.name + ` (${this.user?.username} copy)`,
    );
    if (!graphStore) {
      return;
    }
    this.currentGraphStore = graphStore;
  };

  /**
   * Export the graph or subgraphs
   */

  async exportCurrentGraphAsEd(): Promise<void> {
    if (!this.currentGraphStore) return;
    const uri = await DataService.getGraphPresignedUrl(
      this.currentGraphStore.id,
    );
    if (uri) {
      this.openFile(uri);
    } else {
      ErrorStore.setError('Failed to export the graph.');
    }
  }

  async exportCurrentGraphAsNetworkX(): Promise<void> {
    if (!this.currentGraphStore) return;
    const blob = await APIService.exportGraphAsNetworkX(
      this.currentGraphStore.id,
    );
    if (!blob) return;
    const fileName = this.currentGraphStore.id + '_node_link.json.zip';
    DataService.downloadData(new Blob([blob]), fileName);
  }

  *exportSelectedSubgraphAsCsv() {
    if (!this.currentGraphStore) return;
    if (!this.currentGraphStore.anyNodesSelected) return;

    yield this.currentGraphStore.updateSelectedNodes();
    const json: string | undefined = yield DataService.exportSelectionAsCsv(
      this.currentGraphStore.id,
      this.currentGraphStore.selectedNodeIds,
    );
    if (!json) {
      ErrorStore.setError('Failed to export the subgraph.');
      return;
    }
    if (json.startsWith('{')) {
      const data: {
        nodes: string;
        edges: string;
      } = JSON.parse(json);
      const nodeFileName = this.currentGraphStore.name + '_selected_nodes.csv';
      DataService.downloadData(new Blob([data.nodes]), nodeFileName);
      const edgeFileName = this.currentGraphStore.name + '_selected_edges.csv';
      DataService.downloadData(new Blob([data.edges]), edgeFileName);
    } else {
      const fileName = this.currentGraphStore.name + '_selection.csv';
      DataService.downloadData(new Blob([json]), fileName);
    }

    ErrorStore.setSuccess('Subgraph exported successfully as .csv.');
  }

  /**
   * Menus
   */

  menuBarStore = new MenuBarStore();
  contextMenuStore = new ContextMenuStore();
  helpMenuStore = new HelpMenuStore();

  closeOthers = (
    store: MenuBarStore | ContextMenuStore | MenuStore[],
    localMenuStore: MenuStore,
  ): void => {
    let myList;

    if (store instanceof MenuBarStore || store instanceof ContextMenuStore) {
      myList = store.items;
    } else if (store) {
      myList = store;
    }

    myList?.forEach((menuStore) => {
      if (menuStore !== localMenuStore && menuStore.type === 'item') {
        menuStore.open = false;
      }
    });
  };

  openContextMenu = (
    position: { left: number; top: number },
    context: ContextMenuContext,
  ) => {
    this.contextMenuStore.openMenu(position, context);
  };

  /**
   * Node Stores
   */
  nodeStores: Map<string, MultiNodeStore> = new Map();

  /**
   * Home page
   */
  homePageStore = new HomePageStore();

  /**
   * RightDrawer
   */
  rightDrawerStore = new RightDrawerStore();

  /**
   * TransformationsDrawer
   */
  transformationsDrawerStore = new TransformationsDrawerStore();

  /**
   * InspectDrawer
   */
  inspectDrawerStore = new InspectDrawerStore();

  /**
   * DataRetrievalAgent
   */
  dataRetrievalAgentStore = new DataRetrievalAgentStore();

  /**
   * Navigation Drawer (left)
   */
  navigationStore = new NavigationStore();

  /**
   * Loading backdrop
   */
  loadingBackdropStore = new LoadingBackdropStore();

  /**
   * Backdrop helper method.
   */
  withDashboardBackdrop<T extends unknown[], U>(
    func: AsyncFn<T, U> | GeneratorFn<T, U>,
  ) {
    return async (...args: T) => {
      this.loadingBackdropStore.openBackdrop();
      let output;
      try {
        const result = func(...args);
        if (result instanceof Promise) {
          output = await result;
        } else {
          let curr = result.next();
          while (!curr.done) {
            curr = result.next();
          }
          output = curr.value;
        }
      } finally {
        this.loadingBackdropStore.closeBackdrop();
      }
      return output;
    };
  }

  /**
   * Share Dialog Store
   */
  shareDialogStore = new ShareDialogStore();

  /**
   * Node Properties Store
   */
  nodePropertiesStore = new NodePropertiesStore();

  /**
   * Onboarding In App Help Store
   */
  onboardingHelpStore = new InAppHelpStore('onboarding', onboardingHelpItems);

  get singleNodeSelected(): boolean {
    return this.currentGraphStore?.singleNodeSelected ?? false;
  }

  get anyNodesSelected(): boolean {
    return this.currentGraphStore?.anyNodesSelected ?? false;
  }

  // Note: You have to await updateSelectedNodes before using this
  get singleSelectedNode(): Node | undefined {
    return this.currentGraphStore?.singleSelectedNode;
  }

  /**
   * Carousel
   */
  carouselStore = new CarouselStore();

  openFile = async (url: string): Promise<void> => {
    const signedURL = await APIService.getPresignedURL(url);
    window.open(signedURL);
  };

  *openSelectedNodeFile() {
    const graph = this.currentGraphStore;
    if (!graph) return;
    if (!graph.singleNodeSelected) return;

    yield graph.updateSelectedNodes();
    const node = graph.singleSelectedNode;
    if (!node) return;
    yield Promise.all(
      NodeModel.deserialize(node)
        .urls()
        .slice(0, MAX_URLS_TO_OPEN)
        .map((url) => this.openFile(url)),
    );
  }

  *downloadSelectedNodesFile() {
    if (!this.currentGraphStore) return;
    if (!this.currentGraphStore.anyNodesSelected) return;

    yield this.currentGraphStore.updateSelectedNodes();
    const taskId: string | undefined =
      yield DataService.downloadSelectedNodesFiles(
        this.currentGraphStore.id,
        this.currentGraphStore.selectedNodeIds,
      );
    if (!taskId) {
      return;
    }

    const callback = async (result: TaskResult) => {
      switch (result.type) {
        case 'presignedURL': {
          const url = result.payload;
          this.openFile(url);
          break;
        }
      }
    };
    MonitoredTasks.instance.addTask(taskId, 'Downloading Files', callback);
  }

  /**
   * Edge Types
   */

  private _edgeTypesDialogOpen = false;
  get edgeTypesDialogOpen(): boolean {
    return this._edgeTypesDialogOpen;
  }
  set edgeTypesDialogOpen(newState: boolean) {
    this._edgeTypesDialogOpen = newState;
  }

  /**
   * Search
   */

  private _searchDialogOpen = false;
  get searchDialogOpen(): boolean {
    return this._searchDialogOpen;
  }
  set searchDialogOpen(newState: boolean) {
    this._searchDialogOpen = newState;
  }

  /**
   * Nodes by ID
   */
  private _nodesByIdDialogOpen = false;
  get nodesByIdDialogOpen(): boolean {
    return this._nodesByIdDialogOpen;
  }
  set nodesByIdDialogOpen(newState: boolean) {
    this._nodesByIdDialogOpen = newState;
  }

  /**
   * Rotate nodes
   */

  private _rotateNodesDialogOpen = false;
  get rotateNodesDialogOpen(): boolean {
    return this._rotateNodesDialogOpen;
  }
  set rotateNodesDialogOpen(newState: boolean) {
    this._rotateNodesDialogOpen = newState;
  }

  /**
   * Upload Data
   */

  uploadMenuStore: UploadMenuStore = new UploadMenuStore();

  private async _handleUploadFiles(
    handler: (files: FileList) => Promise<void>,
  ): Promise<void> {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = ALLOWED_FILE_IMPORT_EXTENSIONS;
    input.multiple = true; // Allow selecting multiple files
    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;
      if (!target.files) {
        return;
      }

      const numFiles = target.files.length;
      const totalSize = Array.from(target.files).reduce(
        (acc, file) => acc + file.size,
        0,
      );

      const transfer = new DataTransfer();

      for (const file of target.files) {
        const extn = (file.name.split('.').pop() ?? '').toLowerCase();

        if (extn === 'ed' && numFiles > 1) {
          ErrorStore.setError('Cannot upload multiple .ed files at once.');
          return;
        }

        if (totalSize > MAX_CSV_FILE_SIZE && !this.ignoreMaxFileSize) {
          if (extn === 'csv') {
            const trimmed = await trimFile(file, MAX_CSV_FILE_SIZE / numFiles);
            transfer.items.add(trimmed);
            ErrorStore.setWarning(
              `The uploaded data file(s) have been truncated because uploading via the browser is limited to ${
                MAX_CSV_FILE_SIZE / 1024 / 1024
              } MB in total. Please use database connectors or the API to upload larger amounts of data.`,
            );
          } else if (extn === 'ed') {
            transfer.items.add(file);
          } else {
            ErrorStore.setError(
              `The uploaded data file(s) have been truncated because uploading via the browser is limited to ${
                MAX_CSV_FILE_SIZE / 1024 / 1024
              } MB in total. Please use database connectors or the API to upload larger amounts of data.`,
            );
          }
        } else {
          transfer.items.add(file);
        }
      }
      await handler(transfer.files);
    };
    input.click();
  }

  async handleUploadFiles(): Promise<void> {
    if (
      this.uploadMenuStore.uploadAdditionalData &&
      this.currentGraphStore === undefined
    ) {
      ErrorStore.setError(
        'Please select a graph first before adding data to it',
      );
      return;
    }
    const newGraph = !this.uploadMenuStore.uploadAdditionalData;
    return this._handleUploadFiles((files: FileList) => {
      return this.uploadData(files, newGraph);
    });
  }

  uploadData = this.withDashboardBackdrop(
    async (files: FileList, newGraph: boolean) => {
      const maxChars = 80;
      const ellipsis = '...';

      if (!files.length) return;
      let graphStore: GraphStore;
      if (newGraph) {
        const fNamesNoExt: string[] = [...files].map(
          (f) => f.name.split('.')[0],
        );
        const name = fNamesNoExt.join(' | ');
        const finalName =
          name.length > maxChars
            ? name.slice(0, maxChars - ellipsis.length) + ellipsis
            : name;
        const response = await this.newGraph(finalName);
        if (!response) {
          return;
        }
        graphStore = response;
      } else {
        if (!this.currentGraphStore) {
          return;
        }
        graphStore = this.currentGraphStore;
      }
      const response = await DataService.uploadData(
        files,
        graphStore.id,
        newGraph,
        this.openViews.map((view) => view.id),
      );
      if (!response) {
        return;
      }
      const newViews = this.handleGraphResponse(response);
      await this.openDataUploadViews(newViews);

      ErrorStore.setSuccess(`File imported successfully`);
      this.submitGraphOptions();
    },
  );

  /**
   * space-key cmds
   */
  _isSpaceDown = false;
  _spaceReleased = false;

  get isSpaceDown() {
    return this._isSpaceDown;
  }

  set isSpaceDown(val) {
    this._isSpaceDown = val;
    this.spaceReleased = !val;
  }

  get spaceReleased() {
    return this._spaceReleased;
  }

  set spaceReleased(val) {
    this._spaceReleased = val;
  }

  /**
   * File Upload
   */

  private _fileUploadOpen = false;
  get fileUploadOpen(): boolean {
    return this._fileUploadOpen;
  }
  set fileUploadOpen(newState: boolean) {
    this._fileUploadOpen = newState;
  }

  get canCreateViewFromNodes(): boolean {
    return !!this.currentViewStore?.anyNodesSelected;
  }

  *barplotView() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const response: [ViewId, GraphDiff] | undefined = yield DataService.barplot(
      graphStore.id,
      graphStore.selectedNodeIds,
    );
    if (!response) {
      return;
    }
    const [viewId, gDiff] = response;
    graphStore.applyDiff(gDiff, false);
    yield this.openSingleView(viewId);
  }

  *spatialView() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const response: [ViewId, GraphDiff] | undefined = yield DataService.spatial(
      graphStore.id,
      graphStore.selectedNodeIds,
    );
    if (!response) {
      return;
    }

    const [viewId, gDiff] = response;
    graphStore.applyDiff(gDiff, false);
    yield this.openSingleView(viewId);
  }

  *scatterPlot() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const response: [ViewId, GraphDiff] | undefined =
      yield DataService.scatterPlot(graphStore.id, graphStore.selectedNodeIds);
    if (!response) {
      return;
    }
    const [viewId, gDiff] = response;
    graphStore.applyDiff(gDiff, false);
    yield this.openSingleView(viewId);
  }

  *plot() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;
    yield graphStore.updateSelectedNodes();
    yield this.createPlotView(graphStore.selectedNodeIds);
  }

  async createPlotView(nodeIds: NodeId[]): Promise<ViewStore | undefined> {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;
    const response = await DataService.plot(graphStore.id, nodeIds);
    if (!response) {
      return;
    }

    const [viewId, gDiff] = response;
    graphStore.applyDiff(gDiff, false);
    return this.openSingleView(viewId);
  }

  *histogramPlot() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const response: [ViewId, GraphDiff] = yield APIService.histogram(
      graphStore.id,
      graphStore.selectedNodeIds,
    );
    if (!response) {
      return;
    }
    const [viewId, gDiff] = response;
    graphStore.applyDiff(gDiff, false); // Update the view list
    yield this.openSingleView(viewId);
  }

  *correlationPlots() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const selectedNodes = graphStore.selectedNodeIds;
    if (!selectedNodes) return;

    const result: [ViewId[], GraphDiff] | undefined =
      yield DataService.calculateCorrelation(graphStore.id, selectedNodes);
    if (!result) {
      return;
    }
    const [newViewIds, gDiff] = result;

    graphStore.applyDiff(gDiff);
    yield this.openMultipleViews(newViewIds);
  }

  *pcaEmbedding() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    const currentView = this.currentViewStore;
    if (!currentView) return;

    yield graphStore.updateSelectedNodes();
    const response: [ViewId, GraphDiff] | undefined =
      yield APIService.pcaEmbedding(graphStore.id, graphStore.selectedNodeIds);
    if (!response) {
      return;
    }
    const [[projView, featureView], gDiff] = response;
    graphStore.applyDiff(gDiff, false);

    yield this.openMultipleViews([projView, featureView]);
  }

  *labelEmbedding() {
    const graph = this.currentGraphStore;
    if (!graph) return;

    yield graph.updateSelectedNodes();
    const selection = graph.selectedNodes;
    if (selection.size === 0) {
      return;
    }
    let nodes: string | string[];
    if (selection.size === 1) {
      nodes = selection.values().next().value.id;
    } else {
      nodes = Array.from(selection).map((n) => n.id);
    }
    const output: [ViewId, GraphDiff] | undefined =
      yield DataService.labelEmbedding(graph.id, nodes);
    if (!output) return;
    const [viewId, graphDiff] = output;
    graph.applyDiff(graphDiff);
    yield this.openSingleView(viewId);
  }

  *tsneEmbedding() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    yield graphStore.updateSelectedNodes();
    const taskId: string = yield DataService.tsneEmbedding(
      graphStore.id,
      graphStore.selectedNodeIds,
    );

    if (!taskId) return;

    this.addViewsTask(taskId, graphStore, 'Tsne Embedding');
  }

  runCodeFromNodeDisabled = (): boolean => {
    // Taking the url from the single selected node and checking its beginning
    // is tricky because selectedNodes is lazy. Given that this UI is temporary,
    // we are resorting to allowing any single node to be run as code, and if not
    // valid it will fail.
    return !this.currentGraphStore?.singleNodeSelected;
  };

  *runCodeFromNode() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    const currentView = this.currentViewStore;
    if (!currentView) return;

    if (!currentView.singleNodeSelected) {
      return;
    }
    yield graphStore.updateSelectedNodes();
    const operationNode = graphStore.selectedNodes.values().next().value;
    const operationNodeId = operationNode.id;
    const type = operationNode.url.includes('%%python') ? 'python' : 'sql';

    if (type === 'python') {
      const graphDiff: GraphDiff | undefined = yield APIService.runCodeFromNode(
        graphStore.id,
        operationNodeId,
      );
      if (!graphDiff) {
        return;
      }
      graphStore.applyDiff(graphDiff);
      const selectedNodeIds = new Set(graphDiff.nodes.newOrUpdated.keys());
      currentView.addNodesById([...selectedNodeIds]);
      currentView.setSelectedNodesByIds(selectedNodeIds);
    } else if (type === 'sql') {
      const taskId: Uuid | undefined = yield APIService.uploadSQLFromNode(
        graphStore.id,
        operationNodeId,
      );
      if (!taskId) {
        return;
      }

      const callback = async (result: TaskResult) => {
        await openViewsCallback(graphStore.id, result);
        this.currentViewStore?.selectAllNodes();
      };
      MonitoredTasks.instance.addTask(taskId, 'SQL Query', callback);
    }
  }

  *runSQLQuery() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;

    this.dataRetrievalAgentStore.chatHistory.push({
      message: { role: 'user', content: 'Run SQL Query.' },
      show: true,
    });
    const taskId: Uuid | undefined = yield APIService.uploadSQLReuseSchema(
      graphStore.id,
      this.dataRetrievalAgentStore.databaseConnectionNode.node?.id ?? '',
      this.dataRetrievalAgentStore.currentQuery,
    );
    if (!taskId) {
      return;
    }

    const callback = async (result: TaskResult) => {
      await openViewsCallback(graphStore.id, result);
      this.currentViewStore?.selectAllNodes();
      this.dataRetrievalAgentStore.chatHistory.push({
        message: {
          role: 'assistant',
          content: 'SQL query executed successfully.',
        },
        show: true,
      });
    };
    MonitoredTasks.instance.addTask(taskId, 'SQL Query', callback);
  }

  *runSQLPreview() {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;
    this.dataRetrievalAgentStore.chatHistory.push({
      message: { role: 'user', content: 'Preview SQL query.' },
      show: true,
    });

    const previewResult: SQLPreviewResult | undefined =
      yield APIService.previewSQL(
        graphStore.id,
        this.dataRetrievalAgentStore.databaseConnectionNode.node?.url ?? '',
        this.dataRetrievalAgentStore.currentQuery,
      );

    if (!previewResult) {
      return;
    }

    this.dataRetrievalAgentStore.queryRunOK = previewResult.isValid;
    this.dataRetrievalAgentStore.chatHistory.push({
      message: { role: 'assistant', content: previewResult.message },
      show: true,
    });
  }

  *mergeNodes(mergeType: MergeNodesTransformationType) {
    const graphStore = this.currentGraphStore;
    if (!graphStore) return;
    const currentView = this.currentViewStore;
    if (!currentView) return;
    yield graphStore.updateSelectedNodes();

    const response: GraphDiff | undefined = yield APIService.mergeNodes(
      graphStore.id,
      graphStore.selectedNodeIds,
      mergeType,
    );

    if (!response) {
      return;
    }

    const graphDiff = response;
    graphStore.applyDiff(graphDiff);

    const selectedNodeIds = new Set(graphDiff.nodes.newOrUpdated.keys());
    currentView.setSelectedNodesByIds(selectedNodeIds);
  }

  transformNodeAndOpenView = this.withDashboardBackdrop(
    async (transformation: Transformation, inPlace: boolean): Promise<void> => {
      const graphStore = this.currentGraphStore;
      if (!graphStore) return;
      const currentView = this.currentViewStore;
      if (!currentView) return;

      // Send transformation job to server
      await graphStore.updateSelectedNodes();
      const response = await DataService.transformNodesAndEdges(
        graphStore.id,
        currentView.id,
        graphStore.selectedNodeIds,
        transformation,
        inPlace,
      );

      if (!response) {
        return;
      }

      // Apply graphDiff from transformation
      const graphDiff = response[1];
      graphStore.applyDiff(graphDiff);

      // Open newly returned view
      const newViewId = response[0];
      if (!newViewId) {
        return;
      }
      await this.openSingleView(newViewId);
    },
  );

  transformEdges = this.withDashboardBackdrop(
    async (transformation: Transformation, inPlace: boolean): Promise<void> => {
      const graphStore = this.currentGraphStore;
      if (!graphStore) return;
      const edgeValueStore = this.transformationsDrawerStore.edgeValueStore;

      const view = this.currentViewStore;
      if (!view) return;

      let headerNodes: string[] = [];
      if (
        edgeValueStore.firstHeaderNode.id !== undefined &&
        edgeValueStore.secondHeaderNode.id !== undefined
      ) {
        headerNodes = [
          edgeValueStore.firstHeaderNode.id,
          edgeValueStore.secondHeaderNode.id,
        ];
      }

      // Send transformation job to server
      await graphStore.updateSelectedNodes();
      const response = await DataService.transformNodesAndEdges(
        graphStore.id,
        view.id,
        edgeValueStore.isDivisionOrSubtractionHeader
          ? headerNodes
          : graphStore.selectedNodeIds,
        transformation,
        inPlace,
      );

      if (!response) {
        return;
      }

      // Apply graphDiff from transformation
      const graphDiff = response[1];
      graphStore.applyDiff(graphDiff);
    },
  );

  /*
   * Handle Graph response from the server.
   * Applies a graph diff and reloads any updated, open views.
   * Returns newly created view ids.
   */
  handleGraphResponse = (response: GraphResponse): APIView[] => {
    if (!this.currentGraphStore) return [];
    this.currentGraphStore.applyDiff(response.diff);
    response.updatedViews.forEach((viewId) => {
      const view = this.openViews.find((v) => v.id === viewId);
      if (view) {
        // todo: reload in parallel
        view.reload();
      }
    });
    return response.newViews.map(([viewId, name]) => {
      return { id: viewId, name: name };
    });
  };

  /*
   * Add a task to the MonitoredTasks that receives and opens views.
   */
  addViewsTask(taskId: NodeId, graphStore: GraphStore, label: string) {
    MonitoredTasks.instance.addTask(
      taskId,
      label,
      async (result: TaskResult) => {
        await openViewsCallback(graphStore.id, result);
      },
    );
  }

  notToOpen = ['PCA Feature Weights'];
  async openDataUploadViews(views: APIView[]) {
    const toOpen = views
      .filter((view) => !this.notToOpen.includes(view.name))
      .map((view) => view.id);
    await this.openMultipleViews(toOpen);
  }
}

export default new DashboardStore();
