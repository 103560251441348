import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import SimianUploadMenu from './SimianUploadMenu';
import APIUploadMenu from './APIUploadMenu';
import SQLUploadMenu from './SQLUploadMenu';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UploadMenuState } from '../stores/UploadMenuStore';

const UploadMenu = observer((): JSX.Element => {
  const store = dashboardStore.uploadMenuStore;

  const dialogStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  };

  const menuTitle =
    store.state === UploadMenuState.Open
      ? 'Upload Menu'
      : store.state === UploadMenuState.Database
        ? 'Upload from Database'
        : null;

  return (
    <Dialog
      open={store.open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        store.state = UploadMenuState.Closed;
      }}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      style={dialogStyle}
    >
      <DialogTitle id="form-dialog-title">{menuTitle}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* Render initial buttons if additional buttons are not shown */}
          {store.state === UploadMenuState.Open && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  await dashboardStore.handleUploadFiles();
                  store.state = UploadMenuState.Closed;
                }}
              >
                Upload File
              </Button>
              <Box sx={{ marginTop: 1, marginLeft: 4 }} />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  store.state = UploadMenuState.Database;
                }}
              >
                Upload from Database
              </Button>
            </>
          )}
          {/* Additional buttons to appear after "Upload from Database" is clicked */}
          {store.state === UploadMenuState.Database && (
            <Box sx={{ alignItems: 'center' }}>
              {/* Back to Upload Menu Button*/}
              <Tooltip title="Back to Upload Menu" placement="bottom" arrow>
                <IconButton
                  sx={{ position: 'absolute', left: 15 }}
                  onClick={() => {
                    store.state = UploadMenuState.Open;
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  store.state = UploadMenuState.Simian;
                }}
                disabled={!store.simianStore.isSimianUser}
              >
                Simian
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  store.state = UploadMenuState.SQL;
                }}
              >
                SQL
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  store.state = UploadMenuState.API;
                }}
              >
                API
              </Button>
            </Box>
          )}
        </Box>
        <SimianUploadMenu />
        <SQLUploadMenu />
        <APIUploadMenu />
      </DialogContent>
    </Dialog>
  );
});

export default UploadMenu;
