// Vertex shader for nodes (dots)
import {
  DEFAULT_NODE_SIZE_SCALAR,
  HOVERED_NODE_SIZE_SCALAR,
  SELECTED_NODE_SIZE_SCALAR,
  SELECTED_AND_HOVERED_NODE_SIZE_SCALAR,
} from './ShaderConstants';

export default `
precision highp float;

uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

uniform float sizeScalar; // Scalar applied to all the nodes
uniform vec3 axisScale; // Independent axis scaling which is applied to the translation
uniform vec3 dragDelta; // Offset applied to the translation for selected nodes

in vec3 position;
in vec3 translate;
in vec3 color;
in float size;      // Size of individual nodes
/**
 * State of individual Nodes:
 * - 1u: selected : Make bigger and respect offset (frag shader changes color)
 * - 2u: hovered  : Make bigger
 */
in uint stateFlags;

out vec3 vColor;
flat out uint vStateFlags;

void main() {
  bool selected = (stateFlags & 1u) != 0u;
  bool hovered = (stateFlags & 2u) != 0u;

  float flagScalar = ${DEFAULT_NODE_SIZE_SCALAR};

  if (selected && hovered) {
    flagScalar = ${SELECTED_AND_HOVERED_NODE_SIZE_SCALAR};
  } else if (selected) {
    flagScalar = ${SELECTED_NODE_SIZE_SCALAR};
  } else if (hovered) {
    flagScalar = ${HOVERED_NODE_SIZE_SCALAR};
  }

  vec3 offsetTranslation = selected ? dragDelta : vec3(0.0);
  vec3 scaledTranslation = (translate + offsetTranslation) * axisScale;

  vec4 mvPosition = modelViewMatrix * vec4(scaledTranslation, 1.0);
  mvPosition.xyz += position * size * sizeScalar * flagScalar;

  vStateFlags = stateFlags;
  vColor = color;
  gl_Position = projectionMatrix * mvPosition;
}
`;
