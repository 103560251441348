import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AlertsDialogStore, {
  UserActionRequest,
} from '../stores/AlertDialogStore';

const DRiskDialog = observer((): JSX.Element => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    AlertsDialogStore.showDialog = false;
  };

  if (AlertsDialogStore.latestEvent === undefined) return <></>;
  if (AlertsDialogStore.latestEvent?.message === undefined) return <></>;

  const renderDialog = (msg: string, request: UserActionRequest) => (
    <Dialog
      open={
        AlertsDialogStore.showDialog &&
        AlertsDialogStore.latestEvent?.userActionRequest !== undefined
      }
      onClose={handleClose}
    >
      <DialogTitle>{request.dialogTitle}</DialogTitle>
      <DialogContent>{msg}</DialogContent>
      <DialogActions>
        {/* More than 2 options for the user to choose from */}
        {request.dialogActions.length > 2 &&
          request.dialogActions.map((option, index) => (
            <Button
              key={index}
              onClick={() => {
                option.callback();
                AlertsDialogStore.showDialog = false;
              }}
              color="inherit"
            >
              {option.optionName}
            </Button>
          ))}
        {request.dialogActions.length === 2 && (
          <>
            {/* Confirm case */}
            <Button
              key={0}
              onClick={() => {
                request.dialogActions[0].callback();
                AlertsDialogStore.showDialog = false;
              }}
              color="inherit"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  request.dialogActions[0].callback();
                  AlertsDialogStore.showDialog = false;
                }
              }}
              autoFocus
            >
              {request.dialogActions[0].optionName}
            </Button>

            {/* Reject case */}
            <Button
              key={1}
              onClick={() => {
                request.dialogActions[1].callback();
                AlertsDialogStore.showDialog = false;
              }}
              color="inherit"
            >
              {request.dialogActions[1].optionName}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );

  return renderDialog(
    AlertsDialogStore.latestEvent?.message,
    AlertsDialogStore.latestEvent?.userActionRequest,
  );
});
export default DRiskDialog;
