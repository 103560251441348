import { runInAction, makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { debounce } from '../utils/utils';
import {
  InspectOutput,
  NeighborSummary,
  NeighborType,
  NodeSummary,
  Uuid,
} from '../types/wasm';

const MAX_LABEL_LENGTH = 20;

class InspectDrawerStore {
  public data: [string, number][] = [];
  public stats?: Omit<NeighborSummary, 'nodes'>;
  public selection: boolean = false;
  private root: string = '';
  public fullSummary?: NodeSummary<Uuid>;

  constructor() {
    makeAutoObservable(this);
  }

  private _page: number = 0;
  get page(): number {
    return this._page;
  }
  set page(p: number) {
    this._page = p;
  }

  get display(): boolean {
    return this.stats !== undefined;
  }

  private _neighborType: NeighborType = 'successors'; // Default value
  get neighborType(): NeighborType {
    return this._neighborType;
  }
  set neighborType(newType: NeighborType) {
    this._neighborType = newType;
    this.setStats();
  }

  setStats = () => {
    if (this.fullSummary) {
      const data = this.fullSummary[this._neighborType];
      this.data = data.nodes;
      this.stats = {
        count: data.count,
        sum: data.sum,
        min: data.min,
        max: data.max,
        mean: data.mean,
        std: data.std,
      };
    }
  };

  get truncatedLabel(): string {
    if (this.selection) {
      return `${this.root.substring(0, MAX_LABEL_LENGTH - 7)} - selection`;
    } else {
      if (this.root.length > MAX_LABEL_LENGTH) {
        return `${this.root.substring(0, MAX_LABEL_LENGTH)}`;
      } else {
        return this.root;
      }
    }
  }

  update = debounce(
    async () => {
      const graphStore = dashboardStore.currentGraphStore;
      if (!graphStore) return;
      runInAction(() => {
        graphStore.postMessageToGraphWorker({
          type: 'tool',
          toolInput: { inspect: null },
        });
      });
    },
    undefined,
    100,
  );

  updateWithData = (output: InspectOutput) => {
    this.page = 0;
    if (output === 'reset') {
      this.fullSummary = undefined;
      this.stats = undefined;
      this.root = '';
    } else if ('singleNode' in output) {
      this.fullSummary = output.singleNode.summary;
      this.root = output.singleNode.root;
      this.selection = false;
      this.neighborType =
        this.fullSummary.successors.count >= this.fullSummary.predecessors.count
          ? 'successors'
          : 'predecessors';
      this.setStats();
    } else if ('selection' in output) {
      this.fullSummary = undefined;
      this.stats = output.selection.stats;
      this.root = output.selection.root;
      this.data = [];
      this.selection = true;
    }
  };
}

export default InspectDrawerStore;
