// Fragment shader for nodes (dots)
import {
  SELECTED_NODE_COLOR,
  HOVERED_NODE_COLOR_BOOST,
  DEFAULT_NODE_FRAG_DEPTH,
  SELECTED_NODE_FRAG_DEPTH,
  HOVERED_NODE_FRAG_DEPTH,
  SELECTED_AND_HOVERED_NODE_FRAG_DEPTH,
} from './ShaderConstants';

export default `
precision highp float;

in vec3 vColor;
flat in uint vStateFlags;

out vec4 fragColor;

void main() {
  bool selected = (vStateFlags & 1u) != 0u;
  bool hovered = (vStateFlags & 2u) != 0u;

  float fragDepth = ${DEFAULT_NODE_FRAG_DEPTH};

  if (selected && hovered) {
    fragDepth = ${SELECTED_AND_HOVERED_NODE_FRAG_DEPTH};
  } else if (hovered) {
    fragDepth = ${HOVERED_NODE_FRAG_DEPTH};
  } else if (selected) {
    fragDepth = ${SELECTED_NODE_FRAG_DEPTH};
  }

  gl_FragDepth = fragDepth;

  fragColor = selected ? vec4(${SELECTED_NODE_COLOR}, 1.0) : vec4(vColor, 1.0);
  fragColor += hovered ? vec4(${HOVERED_NODE_COLOR_BOOST}, 0.0) : vec4(0.0);
}
`;
