/**
 * App-wide constants
 */
import * as wasm from 'wasm_service';
await wasm.default();

/**
 * Hard constants
 */
export const MAX_OPEN_VIEWS = 6;
export const TOO_MANY_VIEWS_MESSAGE =
  'You have reached the maximum number of open views. Please close a view before opening another.';

// default view axis labels
export const VIEW_X_AXIS = 'x ';
export const VIEW_Y_AXIS = 'y ';

// common node labels
export const CATEGORICAL_HEADER: string = 'string';
export const NUMERICAL_HEADER: string = 'numeric';
export const DATETIME_HEADER: string = 'datetime';
export const COLUMN_HEADER: string = 'columns';
export const TRANSFORMED_COLUMNS_HEADER: string = 'transformed_columns';
export const FUSED_COLUMNS_HEADER: string = 'fused_columns';

// maximum urls that can be opened from a single node
export const MAX_URLS_TO_OPEN = 5;

/**
 * Constant for now, should allow users to configure.
 */
export const TOTAL_NODE_LABELS = 250;

// max length of node label when on rollover
export const ROLLOVER_MAX_LABEL_LENGTH = 42;

export const DEFAULT_NODE_SIZE = 1;
export const DEFAULT_NODE_COLOR = { red: 0, green: 0, blue: 0 };

export const AUTH_TOKEN_REFRESH_INTERVAL = 1000 * 60 * 20; // 20 minutes

// specific groups that support custom actions
export const SIMIAN_ALLOWED_GROUP = 'maymobility';

// Remove when using `HashGraph`.
export const VIEW_PROPERTY_IDS = {
  VIEW_TYPE_ID: wasm.VIEW_TYPE_ID(),
  CREATION_TIMESTAMP_ID: wasm.CREATION_TIMESTAMP_ID(),
  HIDE_EDGES_ID: wasm.HIDE_EDGES_ID(),
  LABEL_SIZE_ID: wasm.LABEL_SIZE_ID(),
  LABEL_ANGLE_ID: wasm.LABEL_ANGLE_ID(),
  SHOW_GRID_ID: wasm.SHOW_GRID_ID(),
  SHOW_AXES_ID: wasm.SHOW_AXES_ID(),
  AXES_DISCONNECTED_ID: wasm.AXES_DISCONNECTED_ID(),
  DATETIME_FORMAT_ID: wasm.DATETIME_FORMAT_ID(),
  COORDINATE_TRANSFORM_ID: wasm.COORDINATE_TRANSFORM_ID(),
};
