import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import { NodeSelectionBox } from './NodeSelectionBox';
import dashboardStore from '../stores/DashboardStore';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';

export const LinearFit = observer((): JSX.Element => {
  const fitStore = dashboardStore.rightDrawerStore.fitStore;
  return (
    <>
      <NodeSelectionBox
        label="Feature Nodes"
        clear={fitStore.featureNodes.reset}
        select={fitStore.featureNodes.update}
        value={fitStore.featureNodes.display}
        isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
      />
      <Divider sx={{ padding: '5px' }} />
      <NodeSelectionBox
        label="Target Variables"
        clear={fitStore.targetNode.reset}
        select={fitStore.targetNode.update}
        value={fitStore.targetNode.label}
        isDisabled={!dashboardStore.currentGraphStore?.singleNodeSelected}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            fitStore.fit().then(() => {
              runInAction(() => {
                fitStore.targetNode.reset();
                fitStore.featureNodes.reset();
              });
            });
          }}
          disabled={
            fitStore.featureNodes.totalStoredNodes === 0 ||
            fitStore.targetNode === undefined
          }
          sx={{ fontSize: '10px', textTransform: 'none' }}
        >
          Fit & Predict
        </Button>
      </Box>
    </>
  );
});
