import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AxesDisconnected() {
  const strokeWidth = 1.5;

  const width = 24;
  const height = 24;
  const gutter = 3;

  const rectLength = 8;
  const rectWidth = 6;

  const slashGutter = 4;

  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={`0 0 ${width} ${height}`}
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d={`
            M ${gutter} ${gutter}
            L ${gutter} ${gutter + rectLength}
            L ${gutter + rectWidth} ${gutter + rectLength}
            L ${gutter + rectWidth} ${gutter}
          `}
        />

        <path
          d={`
            M ${width - gutter} ${height - gutter}
            L ${width - gutter - rectLength} ${height - gutter}
            L ${width - gutter - rectLength} ${height - gutter - rectWidth}
            L ${width - gutter} ${height - gutter - rectWidth}
          `}
        />

        <path
          d={`
            M ${gutter + rectWidth / 2} ${gutter + rectLength}
            L ${gutter + rectWidth / 2} ${
              height - gutter - rectWidth / 2 - slashGutter
            }
          `}
        />

        <path
          d={`
            M ${gutter + rectWidth / 2 + slashGutter} ${
              height - gutter - rectWidth / 2
            }
            L ${width - gutter - rectLength} ${height - gutter - rectWidth / 2}
          `}
        />

        <path
          d={`M ${gutter} ${height - gutter} L ${width - gutter} ${gutter}`}
        />
      </svg>
    </SvgIcon>
  );
}
