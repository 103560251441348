import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { APIUploadMethod, APIUploadHeader } from '../types/app';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import { UploadMenuState } from '../stores/UploadMenuStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withDashboardBackdrop } from '../utils/backdrop';

const APIUploadMenu = observer((): JSX.Element => {
  const uploadStore = dashboardStore.uploadMenuStore;
  const store = uploadStore.apiStore;

  const switchOptions = [
    { label: 'GET', value: 'GET' },
    { label: 'POST', value: 'POST' },
  ];

  const handleUpload = withDashboardBackdrop(async () => {
    uploadStore.state = UploadMenuState.Closed;
    await dashboardStore.uploadMenuStore.apiStore.upload();
  });

  const dialogStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  };

  return (
    <Dialog
      open={store.open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        uploadStore.state = UploadMenuState.Closed;
      }}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      style={dialogStyle}
    >
      <DialogTitle id="form-dialog-title">Upload Menu</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Import data from an HTTP request
        </Typography>
        <SwitchButton
          options={switchOptions}
          value={store.method}
          onChange={(option: SwitchOption) => {
            store.method = option.value as APIUploadMethod;
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            marginTop: '10px',
          }}
        >
          <Box sx={{ width: '100%', height: '100%', marginBottom: '16px' }}>
            <TextField
              sx={{ width: '100%' }}
              multiline
              minRows={1}
              label="URL"
              variant="outlined"
              value={store.queryURL}
              onChange={(e) => {
                store.queryURL = e.target.value;
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              fullWidth
            />
            <TextField
              sx={{ width: '100%', marginTop: '10px', marginBottom: '16px' }}
              multiline
              minRows={1}
              label="Body (JSON)"
              variant="outlined"
              value={store.body}
              onChange={(e) => {
                store.body = e.target.value;
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              fullWidth
            />
            <HeaderInput
              headers={store.headers}
              setHeaders={(headers) => {
                store.headers = headers;
              }}
            />
          </Box>
          <Box
            sx={{
              width: '20%',
              height: '40%',
              marginBottom: '16px',
              marginLeft: 'auto',
            }}
          >
            {/* Back to Upload from Database Menu*/}
            <Tooltip
              title="Back to Upload from Database Menu"
              placement="bottom"
              arrow
            >
              <IconButton
                sx={{ position: 'absolute', left: 15 }}
                onClick={() => {
                  uploadStore.state = UploadMenuState.Database;
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleUpload}
              sx={{ marginLeft: '8px', width: '30%', textTransform: 'none' }}
              disabled={!store.queryURL}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

interface HeaderInputProps {
  headers: APIUploadHeader[];
  setHeaders: (headers: APIUploadHeader[]) => void;
}

const HeaderInput: React.FC<HeaderInputProps> = ({ headers, setHeaders }) => {
  const handleAddHeader = () => {
    setHeaders([...headers, { name: '', value: '' }]);
  };

  const handleRemoveHeader = (index: number) => {
    runInAction(() => {
      const updatedHeaders = [...headers];
      updatedHeaders.splice(index, 1);
      setHeaders(updatedHeaders);
    });
  };

  const handleChangeHeader = (
    index: number,
    field: keyof APIUploadHeader,
    value: string,
  ) => {
    runInAction(() => {
      const updatedHeaders = [...headers];
      updatedHeaders[index][field] = value;
      setHeaders(updatedHeaders);
    });
  };

  return (
    <div>
      {headers.map((header, index) => (
        <Grid container spacing={2} alignItems="center" key={`header-${index}`}>
          <Grid item xs={4}>
            <TextField
              label="Header Name"
              value={header.name}
              onChange={(e) =>
                handleChangeHeader(index, 'name', e.target.value)
              }
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Header Value"
              value={header.value}
              onChange={(e) =>
                handleChangeHeader(index, 'value', e.target.value)
              }
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleRemoveHeader(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        onClick={handleAddHeader}
        sx={{ textTransform: 'none' }}
      >
        Add Header
      </Button>
    </div>
  );
};

export default APIUploadMenu;
