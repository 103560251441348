import { Node, NodeId, NodeUpdate } from '../types/app';
import { DEFAULT_NODE_SIZE, DEFAULT_NODE_COLOR } from '../Constants';
import Edge from './Edge';

/**
 * Node states are represented as a bitfield. This allows for the selected and
 * hover states to be set simulataneously. The states are:
 * - None: 00
 * - Selected: 01
 * - Hovered: 10
 * - Selected and Hovered: 11
 * Updates to node state should use bitwise operations to correctly set the state.
 */
export enum NodeStates {
  None = 0,
  Selected = 1,
  Hovered = 2,
}

export const DEFAULT_NODE: Omit<Node, 'id'> = {
  label: '',
  url: '',
  red: DEFAULT_NODE_COLOR.red,
  green: DEFAULT_NODE_COLOR.green,
  blue: DEFAULT_NODE_COLOR.blue,
  size: DEFAULT_NODE_SIZE,
  showLabel: false,
};

export default class NodeModel implements Node {
  readonly id: NodeId;
  label: string;
  showLabel = false;
  url: string; // Should use URL type
  size = DEFAULT_NODE_SIZE;

  // RGB colors are defined as integers in the range [0, 256)
  red = DEFAULT_NODE_COLOR.red;
  green = DEFAULT_NODE_COLOR.green;
  blue = DEFAULT_NODE_COLOR.blue;

  edgesIn: Set<Edge>; // All Edges where .to is this Node
  edgesOut: Set<Edge>; // All Edges where .from is this Node

  constructor(id: NodeId, label = '', url = '') {
    this.id = id;
    this.label = label;
    this.url = url;

    this.edgesIn = new Set();
    this.edgesOut = new Set();
  }

  // todo: memoize
  get edgesInIds(): Set<NodeId> {
    return new Set([...this.edgesIn].map((edge) => edge.from));
  }
  get edgesOutIds(): Set<NodeId> {
    return new Set([...this.edgesOut].map((edge) => edge.to));
  }

  get degree(): number {
    return this.edgesIn.size + this.edgesOut.size;
  }

  updateFromDiff(nodeDiff: NodeUpdate) {
    this.label = nodeDiff.label ?? this.label;
    this.showLabel = nodeDiff.showLabel ?? this.showLabel;
    this.url = nodeDiff.url ?? this.url;
    this.size = nodeDiff.size ?? this.size;
    this.red = nodeDiff.red ?? this.red;
    this.blue = nodeDiff.blue ?? this.blue;
    this.green = nodeDiff.green ?? this.green;
  }

  static createFromDiff(nodeDiff: {
    id: NodeId;
    nodeUpdate: NodeUpdate;
  }): NodeModel {
    const node = new NodeModel(nodeDiff.id);
    node.updateFromDiff(nodeDiff.nodeUpdate);
    return node;
  }

  updateFromSerialized(node: Node | NodeUpdate) {
    this.label = node.label ?? this.label;
    this.showLabel = node.showLabel ?? this.showLabel;
    this.url = node.url ?? this.url;
    this.size = node.size ?? this.size;
    this.red = node.red ?? this.red;
    this.blue = node.blue ?? this.blue;
    this.green = node.green ?? this.green;
  }

  static serialize(node: Node): Node {
    return {
      id: node.id,
      label: node.label,
      showLabel: node.showLabel,
      url: node.url,
      size: node.size,
      red: node.red,
      green: node.green,
      blue: node.blue,
    };
  }

  static deserialize(serializedNode: Node | [NodeId, NodeUpdate]): NodeModel {
    let nodeId: NodeId;
    let nodeUpdate: NodeUpdate;

    nodeId = (serializedNode as Node).id;
    nodeUpdate = serializedNode as NodeUpdate;

    if (nodeId === undefined) {
      [nodeId, nodeUpdate] = serializedNode as [NodeId, NodeUpdate];
    }

    const node = new NodeModel(nodeId);
    node.updateFromSerialized(nodeUpdate);
    return node;
  }

  /*
   * Returns individual urls if the property is formatted as `[url1, url2, url3]`.
   * Otherwise, returns the url as a single element array.
   */
  urls(): string[] {
    if (!this.url) {
      return [];
    }
    const url = this.url.trim();
    if (!url.startsWith('[')) {
      return [url];
    }
    return url
      .slice(1, -1)
      .split(', ')
      .filter((url) => url.trim().length > 0)
      .map((url) => url.trim());
  }
}
