import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { ViewSortType } from '../types/app';
import dashboardStore from '../stores/DashboardStore';
import { runInAction } from 'mobx';

const DropdownMenu = () => {
  const options = ['Name', 'Created Time'];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateAndClose = (viewSortType: ViewSortType) => {
    runInAction(() => {
      dashboardStore.navigationStore.sortBy = viewSortType;
    });
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Sort Views" arrow>
        <IconButton
          aria-label="more"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <SwapVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleUpdateAndClose(option as ViewSortType)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
