import { IExtents } from '../types/app';

export class Extents {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  private assigned: boolean = false;

  constructor(extents?: IExtents) {
    if (extents) {
      this.xMin = extents.xMin;
      this.xMax = extents.xMax;
      this.yMin = extents.yMin;
      this.yMax = extents.yMax;
      this.assigned = true;
    } else {
      this.xMin = 0;
      this.xMax = 1;
      this.yMin = 0;
      this.yMax = 1;
    }
  }

  addPoint(x: number, y: number): void {
    if (!this.assigned) {
      this.xMin = x;
      this.xMax = x;
      this.yMin = y;
      this.yMax = y;
    } else {
      this.xMin = Math.min(this.xMin, x);
      this.xMax = Math.max(this.xMax, x);
      this.yMin = Math.min(this.yMin, y);
      this.yMax = Math.max(this.yMax, y);
    }
    this.assigned = true;
  }

  get width(): number {
    return this.xMax - this.xMin;
  }

  get height(): number {
    return this.yMax - this.yMin;
  }

  get aspectRatio(): number {
    return this.height > 0 ? this.width / this.height : 1.0;
  }

  /**
   * If the extents have no size, we need to fix them so that there's width and height.
   * If both width and height are zero, add a padding of 1 both.
   * If only one of them is zero then pad to the same scales (accounting for aspect ratio).
   */
  fix(aspectRatio: number = 1.0): void {
    if (this.width > 0 && this.height > 0) return;

    let padding = 1;
    if (this.width > 0 && this.height <= 0) {
      padding = this.width / 2 / aspectRatio;
    } else if (this.width <= 0 && this.height > 0) {
      padding = (this.height / 2) * aspectRatio;
    }

    if (this.width === 0) {
      this.xMin -= padding;
      this.xMax += padding;
    } else if (this.width < 0) {
      this.xMin = -padding;
      this.xMax = padding;
    }
    if (this.height === 0) {
      this.yMin -= padding;
      this.yMax += padding;
    } else if (this.height < 0) {
      this.yMin = -padding;
      this.yMax = padding;
    }
  }

  print(): void {
    console.log(
      `Extents:
  xMin: ${this.xMin}, xMax: ${this.xMax}, yMin: ${this.yMin}, yMax: ${this.yMax}
  width: ${this.width}, height: ${this.height}`,
    );
  }
}
