import { makeAutoObservable } from 'mobx';

class LoadingBackdropStore {
  private _open: boolean;

  constructor() {
    this._open = false;
    makeAutoObservable(this);
  }

  get backdropOpen(): boolean {
    return this._open;
  }

  openBackdrop = () => {
    this._open = true;
  };

  closeBackdrop = () => {
    this._open = false;
  };
}

export default LoadingBackdropStore;
