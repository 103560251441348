import { makeAutoObservable } from 'mobx';
import { Node } from '../types/app';
import ViewNode from '../models/ViewNode';
import { getBaseURL } from '../utils/utils';
import ViewStore from './ViewStore';

const LOG_TO_CONSOLE = false;

declare global {
  interface Window {
    clipboardStore: ClipboardStore;
  }
}

/**
 * Singleton holding the current state of any data
 * copied to the clipboard by the user.
 */
class ClipboardStore {
  viewNodes: Set<ViewNode> = new Set();
  nodes: Set<Node> = new Set();

  constructor() {
    window.clipboardStore = this; // for debugging
    makeAutoObservable(this);
  }

  clear(): void {
    this.viewNodes = new Set();
    this.nodes = new Set();
  }

  get hasData(): boolean {
    return this.hasNodes || this.hasViewNodes;
  }
  get hasNodes(): boolean {
    return this.nodes.size > 0;
  }
  get hasViewNodes(): boolean {
    return this.viewNodes.size > 0;
  }

  copyViewNodes(viewNodes: Set<ViewNode>): void {
    if (viewNodes.size === 0) return;

    this.clear();

    this.viewNodes = viewNodes;

    const clipboardNodes = this.viewNodesToClipboard(this.viewNodes);
    LOG_TO_CONSOLE && console.log(clipboardNodes);
    navigator.clipboard.writeText(clipboardNodes);
  }

  copyNodeLabels(nodes: Set<ViewNode>): void {
    if (nodes.size === 0) return;

    // Note: We don't save the nodes here as we can't paste them back in.

    const clipboardNodes = this.nodeLabelsToClipboard(nodes);
    LOG_TO_CONSOLE && console.log(clipboardNodes);
    navigator.clipboard.writeText(clipboardNodes);
  }

  copyNodes(nodes: Set<Node>): void {
    if (nodes.size === 0) return;

    this.clear();

    this.nodes = nodes;

    const clipboardNodes = this.nodesToClipboard(this.nodes);
    LOG_TO_CONSOLE && console.log(clipboardNodes);
    navigator.clipboard.writeText(clipboardNodes);
  }

  copyViewURL(viewStore: ViewStore): void {
    this.clear();
    const viewURL = `${getBaseURL()}?graphId=${
      viewStore.graphStore.id
    }&viewId=${viewStore.id}`;
    navigator.clipboard.writeText(viewURL);
  }

  /**
   * These are for serializing to the clipboard for pasting into
   * other apps and so are not necessarily JSON.
   */

  private nodeLabelsToClipboard(viewNodesSet: Set<ViewNode>): string {
    const viewNodes = Array.from(viewNodesSet);
    if (viewNodes.length === 0) return '';
    if (viewNodes.length === 1) return `"${viewNodes[0].node.label}"`;
    return JSON.stringify(viewNodes.map((viewNode) => viewNode.node.label));
  }

  private nodesToClipboard(nodesSet: Set<Node>): string {
    const nodes = Array.from(nodesSet);
    if (nodes.length === 0) return '';
    if (nodes.length === 1) return `"${nodes[0].id}"`;
    return JSON.stringify(nodes.map((node) => node.id));
  }

  private viewNodesToClipboard(viewNodesSet: Set<ViewNode>): string {
    const viewNodes = Array.from(viewNodesSet);
    if (viewNodes.length === 0) return '';
    if (viewNodes.length === 1) return `"${viewNodes[0].node.id}"`;
    return JSON.stringify(viewNodes.map((viewNode) => viewNode.node.id));
  }
}
export default new ClipboardStore();
