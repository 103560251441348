import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import {
  NeighborQueryType,
  NodeLabelTransformationType,
  TransformOperation,
  EdgeValueTransformation,
  ConvertNodesTransformationType,
} from '../types/app';
import { NodeSelectionBox } from './NodeSelectionBox';

import {
  NodeLabelStore,
  EdgeValueStore,
  NodeConversionStore,
} from '../stores/TransformationsDrawerStore';

import {
  Box,
  Button,
  Collapse,
  Input,
  Slider,
  IconButton,
  styled,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { KeyboardArrowRight, ScatterPlot } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Resizable } from 're-resizable';

import dashboardStore from '../stores/DashboardStore';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import DataRetrievalAgent from './DataRetrievalAgent';
import { LinearFit } from './LinearFit';
import { GroupByStatistics } from './GroupByStatistics';
import { DataFusion } from './DataFusion';
import { Propagate } from './PropagateBox';
import InspectBox from './InspectBox';
import { Clustering } from './Clustering';
import { FeatureExtraction } from './FeatureExtraction';
import HelperToolTip from './HelpToolTip';
import { Describer } from './Describer';
import { WhatButton } from './WhatButton';
import { AgentMetaData } from '../types/app';
import { PredictModelType } from 'wasm_service';

export const MAX_LABEL_SIZE = 25;
const HEADER_HEIGHT = 24;
const PADDING_SIZE = 4;
const MIN_WIDTH = 240;
const MAX_WIDTH = 600;

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  marginBottom: `${PADDING_SIZE}px`,
}));

export const AccordionSummary = styled((props: AgentMetaData) => (
  <Tooltip
    title={
      <Box sx={{ padding: '5px' }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          {props.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '14px',
          }}
        >
          {props.description}
        </Typography>
        {props.docs_url && (
          <Box display="flex" justifyContent="flex-end" sx={{ padding: '5px' }}>
            <Button
              variant="contained"
              onClick={() => window.open(props.docs_url, '_blank')}
              size="small"
              color="secondary"
            >
              Learn more
            </Button>
          </Box>
        )}
      </Box>
    }
    placement="left-start"
    enterDelay={700}
    enterNextDelay={700}
    arrow
  >
    <MuiAccordionSummary
      expandIcon={<KeyboardArrowRight sx={{ fontSize: 16 }} />}
      {...props}
    >
      <Typography sx={{ flexGrow: 1 }}>{props.name}</Typography>
    </MuiAccordionSummary>
  </Tooltip>
))(({ theme }) => ({
  minHeight: '24px',
  backgroundColor: theme.palette.grey[100],
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: `0 0 0 ${theme.spacing(1)}`,
    fontSize: '14px',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  fontSize: '14px',
  marginLeft: 0,
  bgcolor: 'blue',
}));

const RightDrawer = observer((): JSX.Element => {
  const theme = useTheme();

  const rightDrawerStore = dashboardStore.rightDrawerStore;
  const transformationStore = dashboardStore.transformationsDrawerStore;
  const edgeValueStore = transformationStore.edgeValueStore;
  const collapsedSize = 25;

  const handleLimitSliderChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    rightDrawerStore.neighborQueryLimitValue = newValue as number;
  };

  const handleLimitInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    rightDrawerStore.neighborQueryLimitValue = Number(event.target.value);
  };

  const styles = {
    root: {
      label: 'RightDrawer-root',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      bgcolor: 'white',
      margin: 0,
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    header: {
      label: 'RightDrawer-header',
      display: 'flex',
      justifyContent: 'space-between',
      height: `${HEADER_HEIGHT}px`,
      paddingTop: '2px',
      borderRadius: rightDrawerStore.open
        ? `${theme.shape.borderRadius}px 0 0 0`
        : `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    headerText: {
      color: theme.palette.grey[600],
      alignSelf: 'center',
      fontWeight: 'normal',
      fontSize: '1em',
    },
    body: {
      label: 'RightDrawer-body',
      bgcolor: 'white',
      padding: `${PADDING_SIZE}px ${PADDING_SIZE}px 0px ${PADDING_SIZE}px`,
      overflow: 'auto',
      height: `${dashboardStore.viewsSurfaceSize.height - HEADER_HEIGHT}px`,
    },
    transformation: {
      label: 'RightDrawer-transformation',
      border: `1px solid ${theme.palette.divider}`,
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      marginBottom: `${PADDING_SIZE}px`,
    },
    transformationTitle: {
      label: 'RightDrawer-transformationTitle',
      fontSize: '14px',
      margin: `${PADDING_SIZE}px`,
    },
    transformationButton: {
      label: 'RightDrawer-transformationButton',
      width: '100%',
      fontSize: '12px',
      textTransform: 'capitalize',
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    switchBox: {
      label: 'RightDrawer-switchBox',
      justifyContent: 'center',
      width: '100%',
      padding: '5px',
    },
    inputBox: {
      label: 'RightDrawer-inputBox',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      paddingLeft: '6px',
      height: '70%',
    },
    input: {
      label: 'RightDrawer-input',
      paddingLeft: '10px',
      width: '100%',
    },
    applyBox: {
      label: 'RightDrawer-applyBox',
      padding: '12px 6px 12px 6px',
      display: 'flex',
      justifyContent: 'right',
    },
    infoBox: {
      label: 'RightDrawer-infoBox',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '8px',
      border: '1px solid #ddd',
      fontSize: '12px',
    },
    rootNodeStyle: {
      label: 'RightDrawer-rootNodeStyle',
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '14px',
      overflowX: 'hidden',
      overflowY: 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap' as const,
    },
    groupByStatistics: {
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: '5px',
    },
  };

  const ApplyButtonGrid = (
    transformationStore: EdgeValueStore | NodeLabelStore | NodeConversionStore,
  ): JSX.Element => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {
            // In Place is hidden for NodeConversionStore as it causes user confusion.
            // NodeConversionStore is always not in place.
            !(transformationStore instanceof NodeConversionStore) && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    sx={{ marginLeft: '5px' }}
                    checked={transformationStore.inPlace}
                    onChange={(e) => {
                      transformationStore.inPlace = e.target.checked;
                    }}
                  />
                }
                label="In Place"
              />
            )
          }
        </Grid>
        <Grid item>
          <Box sx={styles.applyBox}>
            <Button
              sx={{ textTransform: 'none' }}
              variant="contained"
              size="small"
              color="secondary"
              onClick={async () => {
                await transformationStore.execute();
              }}
              disabled={transformationStore.disabled}
            >
              Apply
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const onboardingStore = dashboardStore.onboardingHelpStore;

  return (
    <Collapse
      in={rightDrawerStore.open}
      orientation="horizontal"
      collapsedSize={collapsedSize}
    >
      <Resizable
        defaultSize={{
          width: MIN_WIDTH,
          height: '100%',
        }}
        minWidth={MIN_WIDTH}
        maxWidth={MAX_WIDTH}
        enable={{
          left: true,
        }}
      >
        <Box sx={styles.root}>
          {/* Header */}
          <Box
            sx={styles.header}
            onClick={() => (rightDrawerStore.open = !rightDrawerStore.open)}
          >
            <IconButton
              size="small"
              sx={{ padding: '1px', width: `${collapsedSize}px` }}
            >
              {rightDrawerStore.open ? (
                <KeyboardArrowRight />
              ) : (
                <Tooltip
                  title="Open agents drawer"
                  enterDelay={700}
                  enterNextDelay={700}
                  arrow
                >
                  <ScatterPlot />
                </Tooltip>
              )}
            </IconButton>
            {rightDrawerStore.open && (
              <Typography variant="subtitle2" sx={styles.headerText}>
                Agents
              </Typography>
            )}
            <IconButton size="small" sx={{ padding: '1px' }}>
              <Tooltip
                title="Close agents drawer"
                enterDelay={700}
                enterNextDelay={700}
                arrow
              >
                <ScatterPlot />
              </Tooltip>
            </IconButton>
          </Box>

          <Collapse in={rightDrawerStore.open}>
            <Box sx={styles.body}>
              {/* Inspect Box */}
              <HelperToolTip
                inAppHelpItem={onboardingStore.getItemByIndex(4)}
                inAppHelpStore={onboardingStore}
                store={rightDrawerStore}
              >
                <InspectBox />
              </HelperToolTip>
              <HelperToolTip
                inAppHelpItem={onboardingStore.getItemByIndex(5)}
                inAppHelpStore={onboardingStore}
                store={rightDrawerStore}
              >
                <Box>
                  {/* Describe */}
                  <Accordion
                    onChange={(_, open) => {
                      rightDrawerStore.describerStore.open = open;
                    }}
                  >
                    <AccordionSummary
                      name={'Describe'}
                      description={
                        'Find out which predecessor nodes best describe your selection of nodes.'
                      }
                      docs_url={
                        '/docs/content/How-To/extract_features/#describer'
                      }
                    />
                    <AccordionDetails>
                      <Describer />
                    </AccordionDetails>
                  </Accordion>
                  {/* Get Neighbors */}
                  <Accordion>
                    <AccordionSummary
                      name="Get Neighbors"
                      description="Find the nodes which connect to your selection, and add them into the current view."
                      docs_url="/docs/content/How-To/find_data/#adding-node-neighbours-to-view"
                    />
                    <AccordionDetails>
                      <Box>
                        <Box
                          sx={{
                            marginBottom: '5px',
                            borderRadius: `${theme.shape.borderRadius}px`,
                          }}
                        >
                          <SwitchButton
                            options={[
                              { label: 'Successors', value: 'successors' },
                              { label: 'Predecessors', value: 'predecessors' },
                              { label: 'Both', value: 'both' },
                            ]}
                            value={rightDrawerStore.neighborQueryType}
                            onChange={(option: SwitchOption) =>
                              (rightDrawerStore.neighborQueryType =
                                option.value as NeighborQueryType)
                            }
                            disabled={rightDrawerStore.neighborQueryDisabled}
                          />
                        </Box>

                        <Box sx={{ marginBottom: '5px' }}>
                          <SwitchButton
                            options={[
                              { label: 'Union', value: false },
                              { label: 'Intersection', value: true },
                            ]}
                            value={rightDrawerStore.neighborQueryIntersection}
                            onChange={(option: SwitchOption) =>
                              (rightDrawerStore.neighborQueryIntersection =
                                option.value as boolean)
                            }
                            disabled={rightDrawerStore.neighborQueryDisabled}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: 'white',
                            borderStyle: `solid`,
                            borderColor: `${
                              rightDrawerStore.neighborQueryDisabled
                                ? theme.palette.divider
                                : theme.palette.divider
                            }`,
                            borderWidth: rightDrawerStore.neighborQueryLimited
                              ? '1px'
                              : '0px',
                            fontSize: '14px',
                            marginBottom: `${PADDING_SIZE}px`,
                            borderRadius: `${theme.shape.borderRadius}px`,
                          }}
                        >
                          <SwitchButton
                            options={[
                              { label: 'No limit', value: false },
                              { label: 'Limit', value: true },
                            ]}
                            value={
                              !rightDrawerStore.neighborQueryDisabled &&
                              rightDrawerStore.neighborQueryLimited
                            }
                            onChange={(option: SwitchOption) => {
                              if (!rightDrawerStore.neighborQueryDisabled) {
                                rightDrawerStore.neighborQueryLimited =
                                  option.value as boolean;
                                rightDrawerStore.neighborQueryLimitValue = 1000;
                              }
                            }}
                            border={!rightDrawerStore.neighborQueryLimited}
                            disabled={rightDrawerStore.neighborQueryDisabled}
                          />
                          <Box
                            sx={{
                              padding: '8px 8px 8px',
                              color: !rightDrawerStore.neighborQueryLimited
                                ? theme.palette.grey[500]
                                : 'black',
                              transition: 'opacity 0.15s ease-in', // Add the CSS transition properties
                              opacity: rightDrawerStore.neighborQueryLimited
                                ? 1
                                : 0,
                              overflow: 'hidden',
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Slider
                                size="small"
                                value={rightDrawerStore.neighborQueryLimitValue}
                                valueLabelDisplay="off"
                                onChange={handleLimitSliderChange}
                                step={1}
                                min={rightDrawerStore.neighborQueryLimitMin}
                                max={rightDrawerStore.neighborQueryLimitMax}
                                disabled={
                                  rightDrawerStore.neighborQueryDisabled ||
                                  !rightDrawerStore.neighborQueryLimited
                                }
                              />
                              <Input
                                size="small"
                                value={rightDrawerStore.neighborQueryLimitValue}
                                onChange={handleLimitInputChange}
                                disabled={
                                  rightDrawerStore.neighborQueryDisabled ||
                                  !rightDrawerStore.neighborQueryLimited
                                }
                                inputProps={{
                                  step: 1,
                                  min: rightDrawerStore.neighborQueryLimitMin,
                                  max: rightDrawerStore.neighborQueryLimitMax,
                                  type: 'number',
                                  maxLength: 4,
                                }}
                                sx={{
                                  paddingLeft: '8px',
                                  textAlign: 'center',
                                  fontSize: 12,
                                  width: '64px',
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'right',
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() =>
                              !rightDrawerStore.neighborQueryDisabled &&
                              rightDrawerStore.executeGetNeighbors()
                            }
                            disabled={
                              !dashboardStore.currentGraphStore
                                ?.anyNodesSelected
                            }
                          >
                            Get
                          </Button>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  {/* Fits */}
                  <Accordion>
                    <AccordionSummary
                      name="Fits"
                      description="Make a predictor using linear or logistic regression."
                      docs_url="/docs/content/How-To/making_predictions/"
                    />
                    <AccordionDetails>
                      <Box>
                        {/* Linear Vs Non-Linear */}
                        <SwitchButton
                          options={[
                            { label: 'Linear', value: 'linear' },
                            { label: 'Non-Linear', value: 'nonLinear' },
                          ]}
                          value={rightDrawerStore.fitStore.model}
                          onChange={(option: SwitchOption) => {
                            runInAction(
                              () =>
                                (rightDrawerStore.fitStore.model =
                                  option.value as PredictModelType),
                            );
                          }}
                        />
                      </Box>
                      {rightDrawerStore.fitStore.model === 'linear' && (
                        <LinearFit />
                      )}
                      {rightDrawerStore.fitStore.model !== 'linear' && (
                        <>
                          <Box
                            sx={{
                              backgroundColor: theme.palette.grey[100],
                              border: '1px solid #ccc',
                              borderRadius: `${PADDING_SIZE}px`,
                              padding: '10px',
                              marginTop: '10px',
                              textAlign: 'center',
                            }}
                          >
                            Coming soon...
                          </Box>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  {/* Transformations */}
                  <Accordion>
                    <AccordionSummary
                      name="Transformations"
                      description="Update node labels, edge weights, and data types by applying transformations."
                      docs_url="/docs/content/How-To/clean_data/"
                    />
                    <AccordionDetails>
                      {/* Edge Value Transformation */}
                      <Box sx={styles.transformation}>
                        <Box sx={styles.transformationTitle}>
                          {edgeValueStore.title}
                        </Box>
                        <Box sx={styles.switchBox}>
                          <SwitchButton
                            options={[
                              { label: 'Add', value: 'add' },
                              { label: 'Multiply', value: 'multiply' },
                              { label: 'Subtract', value: 'subtract' },
                              { label: 'Divide', value: 'divide' },
                            ]}
                            value={edgeValueStore.operationType}
                            onChange={(option: SwitchOption) =>
                              (edgeValueStore.operationType =
                                option.value as TransformOperation)
                            }
                          />
                        </Box>
                        <Box sx={styles.switchBox}>
                          <SwitchButton
                            options={[
                              { label: 'Numeric', value: 'numeric' },
                              { label: 'Headers', value: 'headers' },
                            ]}
                            value={edgeValueStore.type}
                            onChange={(option: SwitchOption) => (
                              (edgeValueStore.type =
                                option.value as EdgeValueTransformation),
                              (edgeValueStore.inPlace = false)
                            )}
                          />
                          {/* Set Transformation Value */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingRight: '5px',
                              height: '70%',
                            }}
                          >
                            {edgeValueStore.type === 'numeric' && (
                              <>
                                Value:
                                <Input
                                  sx={styles.input}
                                  type="text"
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '-' || value === '') {
                                      edgeValueStore.value = 0;
                                    } else {
                                      edgeValueStore.value = parseFloat(value);
                                    }
                                  }}
                                />
                              </>
                            )}
                          </Box>
                          {edgeValueStore.isDivisionOrSubtractionHeader && (
                            <Box sx={styles.groupByStatistics}>
                              {/* Numeric or Datetime Node Selection */}
                              <NodeSelectionBox
                                label="First Header Node"
                                clear={edgeValueStore.firstHeaderNode.reset}
                                select={edgeValueStore.firstHeaderNode.update}
                                value={edgeValueStore.firstHeaderNode.label}
                                isDisabled={false}
                              />
                              <Divider sx={{ padding: '5px' }} />
                              {/* Secondary Dataset Feature Node */}
                              <NodeSelectionBox
                                label="Second Header Node"
                                clear={edgeValueStore.secondHeaderNode.reset}
                                select={edgeValueStore.secondHeaderNode.update}
                                value={edgeValueStore.secondHeaderNode.label}
                                isDisabled={false}
                              />
                            </Box>
                          )}

                          {ApplyButtonGrid(edgeValueStore)}
                        </Box>
                      </Box>
                      {/* Node Label Transformation */}
                      <Box sx={styles.transformation}>
                        <Box sx={styles.transformationTitle}>
                          {transformationStore.nodeLabelStore.title}
                        </Box>
                        <Box sx={styles.switchBox}>
                          {/* Set Transformation Type */}
                          <SwitchButton
                            options={[
                              {
                                label: 'Replace',
                                value: 'Replace' as NodeLabelTransformationType,
                              },
                              {
                                label: 'Split',
                                value: 'Split' as NodeLabelTransformationType,
                              },
                            ]}
                            value={transformationStore.nodeLabelStore.type}
                            onChange={(option: SwitchOption) =>
                              (transformationStore.nodeLabelStore.type =
                                option.value as NodeLabelTransformationType)
                            }
                          />
                          {/* Set Transformation Value */}

                          {transformationStore.nodeLabelStore.type ===
                          'Replace' ? (
                            <>
                              <Box sx={styles.inputBox}>
                                From:
                                <Input
                                  sx={styles.input}
                                  type="string"
                                  value={
                                    transformationStore.nodeLabelStore.from
                                  }
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) =>
                                    (transformationStore.nodeLabelStore.from =
                                      e.target.value)
                                  }
                                />
                              </Box>
                              <Box sx={styles.inputBox}>
                                To:
                                <Input
                                  sx={styles.input}
                                  type="string"
                                  value={transformationStore.nodeLabelStore.to}
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) =>
                                    (transformationStore.nodeLabelStore.to =
                                      e.target.value)
                                  }
                                />
                              </Box>
                            </>
                          ) : (
                            <Box sx={styles.inputBox}>
                              Value:
                              <Input
                                sx={styles.input}
                                type="string"
                                value={transformationStore.nodeLabelStore.value}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) =>
                                  (transformationStore.nodeLabelStore.value =
                                    e.target.value)
                                }
                              />
                            </Box>
                          )}

                          {ApplyButtonGrid(transformationStore.nodeLabelStore)}
                        </Box>
                      </Box>
                      {/* Convert Nodes Transformation */}
                      <Box sx={styles.transformation}>
                        <Box sx={styles.transformationTitle}>
                          {transformationStore.nodeConversionStore.title}
                        </Box>

                        <Box sx={styles.switchBox}>
                          {/* Set Transformation Type */}
                          <SwitchButton
                            options={[
                              { label: 'string', value: 'toCategorical' },
                              { label: 'numerical', value: 'toContinuous' },
                            ]}
                            value={transformationStore.nodeConversionStore.type}
                            onChange={(option: SwitchOption) =>
                              (transformationStore.nodeConversionStore.type =
                                option.value as ConvertNodesTransformationType)
                            }
                          />
                          {ApplyButtonGrid(
                            transformationStore.nodeConversionStore,
                          )}
                        </Box>
                      </Box>
                      <Propagate />
                    </AccordionDetails>
                  </Accordion>

                  {/* Statistics Box */}
                  <Accordion>
                    <AccordionSummary
                      name="Statistics"
                      description="Calculate statistics like count, sum or mean."
                      docs_url={'/docs/content/How-To/calculate_statistics/'}
                    />
                    <AccordionDetails>
                      <GroupByStatistics />
                    </AccordionDetails>
                  </Accordion>
                  {/* Data Fusion Box */}
                  <Accordion>
                    <AccordionSummary
                      name="Data Fusion"
                      description="Automaticall fuse together different datasets."
                      docs_url="/docs/content/How-To/fuse_data/"
                    />
                    <AccordionDetails>
                      <DataFusion />
                    </AccordionDetails>
                  </Accordion>
                  {/* Clustering Box */}
                  <Accordion>
                    <AccordionSummary
                      name="Clustering"
                      description="Automatically create clusters from your data."
                      docs_url="/docs/content/How-To/extract_features/#clustering"
                    />
                    <AccordionDetails>
                      <Clustering />
                    </AccordionDetails>
                  </Accordion>
                  {/* Feature Extraction Box */}
                  <Accordion>
                    <AccordionSummary
                      name="Feature Extraction"
                      description="Extract features from descriptive node labels."
                      docs_url="/docs/content/How-To/extract_features/#feature-extraction-agent"
                    />
                    <AccordionDetails>
                      <FeatureExtraction />
                    </AccordionDetails>
                  </Accordion>
                  {/* Data Retrieval Agent Box */}
                  {<DataRetrievalAgent />}
                  {/* What Button */}
                  <Accordion>
                    <AccordionSummary
                      name="Pattern Finding"
                      description="Uncover interesting patterns, distributions, and anomalies in your data."
                      docs_url="/docs/content/How-To/pattern_finding/"
                    />
                    <AccordionDetails>
                      <WhatButton />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </HelperToolTip>
            </Box>
          </Collapse>
        </Box>
      </Resizable>
    </Collapse>
  );
});
export default RightDrawer;
