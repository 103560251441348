import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { SupportedSQLDbs } from '../types/app';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UploadMenuState } from '../stores/UploadMenuStore';

const SQLUploadMenu = observer((): JSX.Element => {
  const uploadStore = dashboardStore.uploadMenuStore;
  const store = uploadStore.sqlStore;

  const switchOptions = [
    { label: 'Sqlite', value: 'sqlite' },
    { label: 'MySQL', value: 'mySql' },
    { label: 'PostgresSQL', value: 'postgres' },
  ];

  const handleUpload = async (schema: boolean) => {
    uploadStore.state = UploadMenuState.Closed;
    if (schema) {
      store.uploadSchema();
    } else {
      store.uploadQuery();
    }
  };

  const dialogStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  };

  return (
    <Dialog
      open={store.open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        uploadStore.state = UploadMenuState.Closed;
      }}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      style={dialogStyle}
    >
      <DialogTitle id="form-dialog-title">Upload Menu</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Import data from a SQL query.
        </Typography>
        <SwitchButton
          options={switchOptions}
          value={store.db}
          onChange={(option: SwitchOption) => {
            store.db = option.value as SupportedSQLDbs;
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Box sx={{ width: '100%', height: '100%', marginBottom: '16px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <Typography
                sx={{
                  flex: '0 0 auto',
                  fontWeight: 'bold',
                  marginRight: '4px',
                }}
              >
                Database URL:
              </Typography>
              <Input
                sx={{ marginRight: 'auto', width: '60%' }}
                type="text"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  store.databaseURL = e.target.value;
                }}
              />
            </Box>
            <TextField
              sx={{ width: '100%' }}
              multiline
              minRows={3}
              label="Query"
              variant="outlined"
              value={store.query}
              onChange={(e) => {
                store.query = e.target.value;
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '50%',
              height: '40%',
              marginBottom: '16px',
              marginLeft: 'auto',
            }}
          >
            {/* Back to Upload from Database Menu*/}
            <Tooltip
              title="Back to Upload from Database Menu"
              placement="bottom"
              arrow
            >
              <IconButton
                sx={{ position: 'absolute', left: 15 }}
                onClick={() => {
                  uploadStore.state = UploadMenuState.Database;
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleUpload(true)}
              sx={{ width: '30%', textTransform: 'none' }}
              disabled={!store.databaseURL}
            >
              Import schema
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => handleUpload(false)}
              sx={{ width: '30%', textTransform: 'none' }}
              disabled={!store.databaseURL || !store.query}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default SQLUploadMenu;
