import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Input,
  Tooltip,
  CircularProgress,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import ToggleButton from '@mui/material/ToggleButton';
import { useTheme } from '@mui/material/styles';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import dashboardStore from '../stores/DashboardStore';

const MAX_CHARACTERS = 100; // max characters to display node labels  in the table

interface NodeSelectionBoxPlusAddFromViewProps {
  label: string;
  clear: () => void;
  select: () => void;
  addFromView: () => void;
  value: string;
  isDisabled: boolean;
  noView: boolean;
}

export const NodeSelectionBoxPlusAddFromView: React.FC<
  NodeSelectionBoxPlusAddFromViewProps
> = ({
  label,
  clear,
  select,
  addFromView,
  value,
  isDisabled,
  noView,
}): JSX.Element => {
  return (
    <Box>
      {/* create box to enter comma separated list of values*/}
      <Box sx={{ marginTop: '10px', fontSize: '12px' }}>{label}</Box>
      <Input
        type="text"
        id={label}
        value={value}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '10px',
          width: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
          paddingLeft: '5px',
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      {value !== '' && (
        <IconButton
          size="small"
          onClick={clear}
          sx={{
            position: 'absolute',
            right: '10px',
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={select}
          disabled={isDisabled}
          sx={{
            fontSize: '10px',
            marginTop: '10px',
            textTransform: 'none',
          }}
        >
          Add selected
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={addFromView}
          disabled={noView}
          sx={{
            fontSize: '10px',
            marginTop: '10px',
            textTransform: 'none',
          }}
        >
          Add View Axes
        </Button>
      </Box>
    </Box>
  );
};

interface NodeSelectionBoxPlaceholderProps {
  label: string;
  clear: () => void;
  select: () => void;
  value: string;
  placeholder: string | undefined;
  isDisabled: boolean;
}

export const NodeSelectionBoxPlaceholder: React.FC<
  NodeSelectionBoxPlaceholderProps
> = ({ label, clear, select, value, placeholder, isDisabled }): JSX.Element => {
  return (
    <Box>
      {/* create box to enter comma separated list of values*/}
      <Box sx={{ marginTop: '10px', fontSize: '12px' }}>{label}</Box>
      <Input
        type="text"
        id={label}
        value={value}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '10px',
          width: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
          paddingLeft: '5px',
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        placeholder={placeholder}
      />
      {value !== '' && (
        <IconButton
          size="small"
          onClick={clear}
          sx={{
            position: 'absolute',
            right: '10px',
          }}
        >
          <ClearIcon />
        </IconButton>
      )}
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={select}
        disabled={isDisabled}
        sx={{
          fontSize: '10px',
          marginTop: '10px',
          textTransform: 'none',
        }}
      >
        Add selected
      </Button>
    </Box>
  );
};

export const Describer = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    describer: {
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: '5px',
      marginBottom: `4px`,
    },
  };

  const describerStore = dashboardStore.rightDrawerStore.describerStore;

  useEffect(
    () =>
      autorun(() => {
        dashboardStore.currentGraphStore?.selectionUpdate;
        describerStore.update();
      }),
    [],
  );

  return (
    <Box sx={styles.describer}>
      <Box>
        <Box sx={{ fontSize: '14px', margin: '4px' }}>Describing Features</Box>
        <Box>
          <FeaturesTable key={'inspectFeaturesTable'} />
        </Box>
      </Box>
      {describerStore.showSettings ? (
        <Box>
          <Box sx={{ fontSize: '14px', margin: '4px' }}>Describer Settings</Box>
          <Box
            sx={{
              padding: '0 5px', // Add 10px horizontal padding
            }}
          >
            <SwitchButton
              options={[
                { label: 'Linear', value: 'linear' },
                { label: 'Non-Linear', value: 'nonLinear' },
              ]}
              value={describerStore.linear ? 'linear' : 'nonLinear'}
              onChange={(option: SwitchOption) => {
                describerStore.linear = option.value === 'linear';
              }}
            />
            <NodeSelectionBoxPlaceholder
              label="With comparison to:"
              clear={describerStore.backgroundNodes.reset}
              select={describerStore.backgroundNodes.update}
              value={describerStore.backgroundNodes.display}
              isDisabled={!dashboardStore.anyNodesSelected}
              placeholder="Leave blank for nodes in active view"
            />
            <NodeSelectionBoxPlaceholder
              label="Using:"
              clear={describerStore.predecessorNodes.reset}
              select={describerStore.predecessorNodes.update}
              value={describerStore.predecessorNodes.display}
              isDisabled={!dashboardStore.anyNodesSelected}
              placeholder="Leave blank for all predecessors"
            />
            <NodeSelectionBoxPlusAddFromView
              label="Ignoring:"
              clear={describerStore.ignoredPredecessorNodes.reset}
              select={describerStore.ignoredPredecessorNodes.update}
              addFromView={describerStore.addIgnoredPredecessorsFromView}
              value={describerStore.ignoredPredecessorNodes.display}
              isDisabled={!dashboardStore.anyNodesSelected}
              noView={!dashboardStore.currentViewStore}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
});

const FeaturesTable = observer((): JSX.Element => {
  const describerStore = dashboardStore.rightDrawerStore.describerStore;

  const columns = [
    {
      id: 'label',
      label: 'Feature Label',
      minWidth: 100,
      align: 'left' as const,
    },
    {
      id: 'importance',
      label: 'Importance',
      minWidth: 100,
      align: 'center' as const,
    },
  ];

  const styles = {
    cell: {
      fontSize: '12px',
      padding: '4px',
      paddingLeft: '8px',
    },
    row: {
      height: '30px',
    },
  };

  const data: [string | undefined, number][] = dashboardStore.anyNodesSelected
    ? describerStore.paddedDescribingData
    : Array.from({ length: 7 }, () => [undefined, 0.0]);

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={styles.row}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    padding: '4px',
                    paddingLeft: '8px',
                    textAlign: column.align,
                    minWidth: column.minWidth,
                    fontSize: '12px',
                  }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              if (
                index === 3 &&
                (describerStore.linear || describerStore.isLoading)
              ) {
                // If it's the middle row
                return (
                  <TableRow key={index} style={{ ...styles.row }}>
                    <TableCell
                      colSpan={2}
                      align="center"
                      style={{ ...styles.cell }}
                    >
                      {describerStore.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ textAlign: 'center' }}
                        >
                          &#8942; {/* Vertical ellipsis */}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={index} style={styles.row}>
                  {row[0] === undefined ? (
                    <TableCell colSpan={2} align="center" style={styles.cell}>
                      <Box
                        sx={{
                          backgroundColor: 'grey',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </TableCell>
                  ) : (
                    <>
                      <TableCell style={styles.cell}>
                        {row[0].substring(0, MAX_CHARACTERS)}
                      </TableCell>
                      <TableCell
                        style={{ ...styles.cell, textAlign: 'center' }}
                      >
                        {Number(row[1]).toFixed(2)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={describerStore.createViewFromData}
          disabled={describerStore.viewIsDisabled}
          sx={{
            fontSize: '10px',
            marginTop: '10px',
            textTransform: 'none',
          }}
        >
          Create View
        </Button>
        <Tooltip title="Describer settings" arrow>
          <ToggleButton
            value="check"
            selected={describerStore.showSettings}
            onChange={() => {
              describerStore.showSettings = !describerStore.showSettings;
            }}
            sx={{
              fontSize: '15px',
              marginTop: '2px',
              marginLeft: '2px', // Adjust margin as needed
              borderRadius: '4px', // Make the button square
            }}
          >
            <TuneIcon />
          </ToggleButton>
        </Tooltip>
      </Box>
    </Paper>
  );
});
