import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material';

const NodesByIdDialog = observer((): JSX.Element => {
  const [nodeInput, setNodeInput] = React.useState('');

  return (
    <Dialog
      open={dashboardStore.nodesByIdDialogOpen}
      onClose={() => (dashboardStore.nodesByIdDialogOpen = false)}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
    >
      <DialogTitle id="form-dialog-title">Get Node(s) by ID</DialogTitle>
      <DialogContent>
        <Box>
          Paste in Node IDs to add to this View in one of the following formats:
        </Box>
        <Box>
          <ul>
            <li>node-id</li>
            <li>node-id-a, node-id-b, ...</li>
            <li>&quot;node-id&quot;</li>
            <li>&quot;node-id-a&quot;,&quot;node-id-b&quot;, ...</li>
            <li>[&quot;node-id-a&quot;, &quot;node-id-b&quot;, ...]</li>
          </ul>
        </Box>

        <TextField
          autoFocus
          margin="dense"
          id="nodes"
          type="text"
          value={nodeInput}
          onChange={(e) => setNodeInput(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (dashboardStore.nodesByIdDialogOpen = false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            dashboardStore.currentViewStore?.addNodesToViewByString(nodeInput);
            dashboardStore.nodesByIdDialogOpen = false;
            setNodeInput('');
          }}
          autoFocus
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default NodesByIdDialog;
