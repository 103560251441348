import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import dashboardStore from '../stores/DashboardStore';

export const Clustering = observer((): JSX.Element => {
  const store = dashboardStore.rightDrawerStore.clusteringStore;

  const handleNumClustersChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    runInAction(() => {
      store.textBox = event.target.value;
    });
  };

  const handleExecute = dashboardStore.withDashboardBackdrop(async () => {
    store.run();
  });

  return (
    <>
      <Box>
        <TextField
          label="Number (Optional)"
          type="number"
          variant="outlined"
          value={store.textBox}
          onChange={handleNumClustersChange}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleExecute}
          sx={{
            fontSize: '10px',
            marginTop: '10px',
            textTransform: 'none',
          }}
        >
          Calculate
        </Button>
      </Box>
    </>
  );
});
