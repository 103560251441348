import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

import dashboardStore from '../stores/DashboardStore';
import ViewWithBackdrop from './View';
import DashboardLoadingBackdrop from './LoadingBackdrop';

const Views = observer((): JSX.Element => {
  const ref = React.useRef<HTMLElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      runInAction(() => {
        if (
          !ref.current ||
          ref.current?.clientHeight < 0 ||
          ref.current?.clientHeight < 0
        )
          return;
        dashboardStore.viewsSurfaceSize.width = ref.current.clientWidth;
        dashboardStore.viewsSurfaceSize.height = ref.current.clientHeight;
      });
    });
    ref.current && resizeObserver.observe(ref.current);
  }, []);

  const styles = {
    outer: {
      position: 'relative',
      flex: 'auto',
      flexGrow: '1',
      overflow: 'hidden',
      marginRight: '4px',
      marginLeft: '4px',
    },
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      transition: 'none',
    },
    card: {
      position: 'absolute',
      backgroundColor: 'white',
      overflow: 'none',
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    relativePosition: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
  };

  return (
    <Box sx={styles.outer} onContextMenu={(e) => e.preventDefault()}>
      <Box ref={ref} sx={styles.container}>
        <Box sx={styles.relativePosition}>
          {dashboardStore.openViews.map((viewStore, index) => {
            return (
              <Box
                key={viewStore.id}
                sx={{
                  ...styles.card,
                  gridArea: `area-${index}`,
                  border: 'none',
                  top: viewStore.top,
                  bottom: viewStore.bottom,
                  left: viewStore.left,
                  right: viewStore.right,
                }}
              >
                <ViewWithBackdrop viewStore={viewStore} />
              </Box>
            );
          })}
        </Box>
        <DashboardLoadingBackdrop />
      </Box>
    </Box>
  );
});
export default Views;
