import { DRAGGING_EDGE_FRAG_DEPTH } from './ShaderConstants';

export default `
precision highp float;
out vec4 fragColor;

void main() {
  gl_FragDepth = ${DRAGGING_EDGE_FRAG_DEPTH};
  fragColor = vec4(0.0, 0.0, 0.0, 1.0); // Black for now
}
`;
