import React, { useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { fetchAuthSession } from 'aws-amplify/auth';
import ErrorStore from '../stores/ErrorStore';

// Show the VpnKey icon. When the user clicks on the icon,
// the API key is copied to the clipboard and
// show CheckCircleOutlineIcon for 1 second

const ApiKey = () => {
  const theme = useTheme();
  const styles = {
    iconButton: {
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };

  const [showTick, setShowTick] = useState(false);

  const handleClick = () => {
    fetchAuthSession().then((session) => {
      const token = session.tokens?.idToken?.toString() ?? '';
      navigator.clipboard.writeText(`"${token}"`);
      setShowTick(true);
      ErrorStore.setSuccess('API Authorization Token copied to clipboard');
      setTimeout(() => {
        setShowTick(false);
      }, 1000);
    });
  };

  return (
    <Tooltip title="Copy authentication token for API access" arrow>
      <IconButton
        color="inherit"
        onClick={handleClick}
        size="small"
        sx={styles.iconButton}
      >
        {showTick ? <CheckCircleOutlineIcon /> : <VpnKeyIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default ApiKey;
