import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { GraphDiff, NeighborQueryType, ViewId } from '../types/app';
export type SectionState = 'normal' | 'selected' | 'disabled';
import { FeatureExtractionStore } from './FeatureExtractionStore';
import { GroupByStatisticDrawerStore } from './StatisticsDrawerStore';
import { DescriberStore } from './DescriberStore';
import { DataFusionDrawerStore, PropagateDrawerStore } from './DataFusionStore';
import APIService from '../services/APIService';
import { WhatButtonStore } from './WhatButtonStore';
import { FitsDrawerStore } from './FitsDrawerStore';

export class ClusteringStore {
  private _textBox: string = '';
  get textBox(): string {
    return this._textBox;
  }
  set textBox(nClusters: string) {
    this._textBox = nClusters;
  }
  get numClusters(): number | undefined {
    if (this._textBox === '') {
      return undefined;
    }
    return Number(this._textBox);
  }
  get disableButton(): boolean {
    if (this.numClusters === undefined) {
      return false;
    }
    return this.numClusters < 1 || this.numClusters > this.maxClusters;
  }
  get maxClusters(): number {
    const view = dashboardStore.currentViewStore;
    if (!view) return 0;
    return view.totalNodes;
  }
  *run() {
    const graph = dashboardStore.currentGraphStore;
    const view = dashboardStore.currentViewStore;
    if (!view || !graph) return;

    let selectedNodeIds = undefined;
    if (graph.anyNodesSelected) {
      yield graph.updateSelectedNodes();
      selectedNodeIds = graph.selectedNodeIds;
    }

    const result: [ViewId, GraphDiff] | undefined =
      yield APIService.viewClustering(
        graph.id,
        view.id,
        this.numClusters,
        selectedNodeIds,
      );
    if (!result) return;
    const [viewId, diff] = result;
    graph.applyDiff(diff);
    yield dashboardStore.openSingleView(viewId);
  }

  constructor() {
    makeAutoObservable(this);
  }
}

class RightDrawerStore {
  dataFusionStore = new DataFusionDrawerStore();
  propagateStore = new PropagateDrawerStore();
  clusteringStore = new ClusteringStore();
  fitStore = new FitsDrawerStore();
  featureExtractionStore = new FeatureExtractionStore();
  groupByStatisticStore = new GroupByStatisticDrawerStore();
  describerStore = new DescriberStore();
  whatButtonStore = new WhatButtonStore();

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Drawer state
   */

  private _open = true;
  get open(): boolean {
    return this._open;
  }
  set open(newState: boolean) {
    this._open = newState;
  }

  /**
   * Get Neighbors
   */

  get neighborQueryDisabled(): boolean {
    return !dashboardStore.currentViewStore;
  }

  private _neighborQueryType: NeighborQueryType = 'successors';
  get neighborQueryType(): NeighborQueryType {
    return this._neighborQueryType;
  }
  set neighborQueryType(newState: NeighborQueryType) {
    this._neighborQueryType = newState;
  }

  private _neighborQueryIntersection = false;
  get neighborQueryIntersection(): boolean {
    return this._neighborQueryIntersection;
  }
  set neighborQueryIntersection(newState: boolean) {
    this._neighborQueryIntersection = newState;
  }

  private _neighborQueryLimited = false;
  get neighborQueryLimited(): boolean {
    return this._neighborQueryLimited;
  }
  set neighborQueryLimited(newState: boolean) {
    this._neighborQueryLimited = newState;
  }

  neighborQueryLimitMin = 1;
  neighborQueryLimitMax = 1000;

  private _neighborQueryLimitValue = 1000;
  get neighborQueryLimitValue(): number {
    return this._neighborQueryLimitValue;
  }
  set neighborQueryLimitValue(newValue: number) {
    this._neighborQueryLimitValue = newValue;
  }

  async executeGetNeighbors() {
    if (!dashboardStore.currentGraphStore?.anyNodesSelected) return;
    const view = dashboardStore.currentViewStore;
    if (!view) return;

    dashboardStore.loadingBackdropStore.openBackdrop();
    await view.addSelectedNeighbors(
      this.neighborQueryType,
      this.neighborQueryIntersection,
      this.neighborQueryLimited ? this.neighborQueryLimitValue : undefined,
    );
    dashboardStore.loadingBackdropStore.closeBackdrop();
  }

  /**
   * Get Node(s) by ID
   */

  get idQueryDisabled(): boolean {
    return !dashboardStore.currentViewStore;
  }

  private _idQueryInputText = '';
  set idQueryInputText(newText: string) {
    this._idQueryInputText = newText;
  }
  get idQueryInputText(): string {
    return this._idQueryInputText;
  }

  executeGetNodesById = async (): Promise<void> => {
    dashboardStore.currentViewStore?.addNodesToViewByString(
      this.idQueryInputText,
    );
  };
}
export default RightDrawerStore;
