import { makeAutoObservable } from 'mobx';
import { TooltipProps } from '@mui/material';
/**
 * A single In App Help item. It contains a name, title, body, media,
 * and a enabled function.
 * The body is a string of text, and the media is a string of the path
 * to the media content.
 */
export class InAppHelpItem {
  name: string;
  title: string;
  body: string;
  media: string;
  link?: string | undefined;
  placement: TooltipProps['placement'];
  _enabled = (): boolean => false;

  constructor(
    name: string,
    title: string,
    body: string,
    media: string,
    link: string | undefined,
    placement: TooltipProps['placement'],
    enabled?: () => boolean,
  ) {
    this.name = name;
    this.title = title;
    this.body = body;
    this.media = media;
    this.link = link;
    this.placement = placement;
    this._enabled = enabled ?? (() => false);
    makeAutoObservable(this);
  }

  get enableFunction(): () => boolean {
    return this._enabled;
  }

  set enabled(enabled: boolean) {
    this._enabled = () => enabled;
  }

  get enabled(): boolean {
    return this._enabled();
  }
}

export const onboardingHelpItems: InAppHelpItem[] = [
  new InAppHelpItem(
    'graph',
    'Navigate your graph',
    'Here are the views that let you interact with your data from all angles to get a comprehensive understanding of your entire database.\nOpen a view to proceed with onboarding if none are currently open.',
    '/in-app-help-media/1.gif',
    '/docs/content/How-To/upload_data/#navigation-drawer',
    'right-start',
    () => false,
  ),
  new InAppHelpItem(
    'view',
    'Customize your view',
    'Here you can customize the appearance of your view, like increasing font size, toggling gridlines and changing the angle of labels.',
    '/in-app-help-media/2.gif',
    '/docs/content/How-To/customise_views/',
    'left-end',
  ),
  new InAppHelpItem(
    'view',
    'Navigate your view',
    'Use "Zoom All" to re-center your view, and "Zoom Selected" to quickly zoom-in to any highlighted nodes.\nTo pan, hold spacebar and move the mouse, or right click and drag in the view.',
    '/in-app-help-media/3.gif',
    '/docs/content/How-To/interact_with_data/',
    'right',
  ),
  new InAppHelpItem(
    'topbar',
    'Act on your data',
    'Generate new views, such as bar plots and histograms, then investigate the patterns within them.\nUse "Get" to gather all data points connected to your selected nodes. Edit node properties like label visibility, and color.',
    '/in-app-help-media/4.gif',
    '/docs/content/How-To/create_views/',
    'left-start',
  ),
  new InAppHelpItem(
    'inspect',
    'Get context on all your data',
    'Use the inspect panel to see which data points are connected to your current selection, and to review related statistics.',
    '/in-app-help-media/5.gif',
    '/docs/content/How-To/interact_with_data/#inspecting-nodes',
    'left',
  ),
  new InAppHelpItem(
    'agents',
    'Transform your data and perform predictions',
    'Here you can find AI agents for extracting features, cleaning, fusing and making predictions on your data.',
    '/in-app-help-media/6.gif',
    '/docs/content/How-To/extract_features/',
    'left',
  ),
];
