import { makeAutoObservable } from 'mobx';
import dashboardStore from './DashboardStore';
import { NodeStore, MultiNodeStore } from './NodeStore';
import {
  SQLAgentOutput,
  PythonLLMResponse,
  PythonCodeResponse,
  MessageHistory,
} from 'wasm_service';
import { Uuid } from '../types/app';

export class DataRetrievalAgentStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _isSQL = true;
  open = false;
  get canSendPrompt() {
    return (
      this.userPrompt.length > 0 &&
      dashboardStore.currentViewStore !== undefined
    );
  }

  databaseConnectionNode = new NodeStore();
  featureNodes = new MultiNodeStore();
  dataNodes = new MultiNodeStore();

  get isSQL() {
    return this._isSQL;
  }

  set isSQL(value: boolean) {
    this._isSQL = value;
  }

  /**
   * Prompt field
   */
  userPrompt = '';
  agentResponse = '';
  userPromptToShow = '';
  chatHistory: { message: MessageHistory; show: boolean }[] = [];

  currentQuery = '';
  currentPythonCode = '';
  queryRunOK = false;
  pythonRunning = false;

  get agentResponseToShow() {
    if (this.agentResponse.length === 0) return '';
    return 'Agent: ' + this.agentResponse;
  }

  get chatHistoryToShow() {
    return this.chatHistory
      .map((msg) =>
        msg.show ? msg.message.role + ': ' + msg.message.content : '',
      )
      .join('\n');
  }

  executeQueryToPython = async () => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    this.chatHistory.push({
      message: { role: 'user', content: this.userPrompt },
      show: true,
    });

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        pythonAgent: {
          pythonLLMPayload: {
            featureStoreId: this.featureNodes.storeId,
            userPrompt: this.userPrompt,
            messageHistory: this.chatHistory.map((entry) => ({
              ...entry.message,
            })),
          },
        },
      },
    });
  };

  executePythonQuery = async (): Promise<void> => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    this.pythonRunning = true;
    this.chatHistory.push({
      message: { role: 'user', content: 'Run Python Query.' },
      show: true,
    });

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        pythonAgent: {
          pythonCodePayload: {
            featureStoreId: this.featureNodes.storeId,
            dataNodes: this.dataNodes.storeId,
            pythonCode: this.currentPythonCode,
            messageHistory: this.chatHistory.map((entry) => ({
              ...entry.message,
            })),
          },
        },
      },
    });
  };

  executeSqlQuery = async (): Promise<void> => {
    const view = dashboardStore.currentViewStore;
    if (!view) return;
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    this.chatHistory.push({
      message: { role: 'user', content: this.userPrompt },
      show: true,
    });

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        sqlAgent: {
          databaseConnectionNode: this.databaseConnectionNode.id ?? '',
          messageHistory: this.chatHistory.map((entry) => ({
            ...entry.message,
          })),
        },
      },
    });
  };

  handleResponse = async (response: SQLAgentOutput) => {
    this.userPrompt = '';
    this.agentResponse = response.generatedResponse ?? '';
    const responseIsSQL = response.isSql ?? false;
    if (responseIsSQL) {
      this.currentQuery = this.agentResponse;
      this.queryRunOK = false; // This is to disable the run button.
    }

    if (!responseIsSQL) {
      this.chatHistory.push({
        message: { role: 'assistant', content: this.agentResponse },
        show: true,
      });
    } else {
      this.chatHistory.push({
        message: {
          role: 'assistant',
          content:
            (response.noteToUser ? response.noteToUser : '') +
            '\n' +
            this.agentResponse,
        },
        show: true,
      });
    }
    this.chatHistory = this.chatHistory.slice(-30); //Keep the last 30 messages
  };

  handlePythonLLMResponse = async (response: PythonLLMResponse) => {
    if (!response) return;
    this.userPrompt = '';
    this.agentResponse = response.generatedResponse ?? '';
    if (response.isPython) {
      this.currentPythonCode = this.agentResponse;
      this.chatHistory.push({
        message: {
          role: 'assistant',
          content: this.agentResponse,
        },
        show: true,
      });
      // At the moment the SQL and the Python agent are sharing the history.
    } else {
      this.chatHistory.push({
        message: {
          role: 'assistant',
          content:
            (response.noteToUser ? response.noteToUser : '') +
            '\n' +
            this.agentResponse,
        },
        show: true,
      });
    }
    this.chatHistory = this.chatHistory.slice(-30); //Keep the last 30 messages
  };

  handlePythonCodeResponse = async (
    response: PythonCodeResponse<Uuid>,
  ): Promise<void> => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    if (!response) return;
    this.chatHistory.push({
      message: {
        role: 'assistant',
        content: response.operationResultString.substring(0, 300),
      },
      show: true,
    });

    this.chatHistory.push({
      message: {
        role: 'assistant',
        content: response.outputString.substring(0, 300),
      },
      show: false,
    });

    await dashboardStore.openSingleView(response.viewId);
    this.pythonRunning = false;
  };
}
export default DataRetrievalAgentStore;
