import { makeAutoObservable, runInAction } from 'mobx';
import { record } from 'aws-amplify/analytics';
import { AlertColor } from '@mui/material';

const MAX_SNACKS = 3;

export interface UserAction {
  label: string;
  callback: () => void;
}

export interface Snack {
  id: number;
  severity: AlertColor;
  message: string;
  repeats: number;
  userAction?: UserAction;
}

export interface ErrorStorer {
  setError(customMessage: string, availableAction?: UserAction): void;

  setWarning(customMessage: string, availableAction?: UserAction): void;

  setSuccess(customMessage: string, availableAction?: UserAction): void;
}

/**
 * Singleton Mobx observable that holds the current error and last
 * 100 to 200 previous errors.
 */
class ErrorStore {
  previousEvents: Snack[] = [];

  private constructor() {
    makeAutoObservable(this);
  }
  static instance = new ErrorStore();

  private insertOrUpdate(snack: Snack) {
    const idx: number = this.previousEvents.findIndex(
      (old_snack: Snack) => old_snack.message == snack.message,
    );
    if (idx === -1) {
      // same message not repeated
      this.previousEvents.push(snack);

      if (this.previousEvents.length > MAX_SNACKS) {
        // Remove oldest event in memory
        this.previousEvents = this.previousEvents.slice(1, undefined);
      }
    } else {
      // mesage repeated, updating its position
      const snack = this.previousEvents.splice(idx, 1)[0];
      snack.repeats += 1;
      snack.id = this.previousEvents.length;
      this.previousEvents.push(snack);
    }
  }

  private doAlert(
    severity: AlertColor,
    customMessage: string,
    userAction?: UserAction,
  ) {
    runInAction(() => {
      const alert: Snack = {
        id: this.previousEvents.length,
        severity: severity,
        message: customMessage,
        repeats: 1,
        userAction: userAction,
      };

      this.insertOrUpdate(alert);

      record({
        name: severity,
        attributes: { errorMessage: customMessage },
      });
    });
  }

  formattedMessage(snack: Snack, maxChars: number) {
    let counter = '';
    if (snack.repeats >= 2) {
      counter = ` (${snack.repeats})`;
    }
    if (snack.message.length + counter.length > maxChars) {
      const msgSlice = snack.message.slice(0, maxChars - 3 - counter.length);
      return msgSlice + counter;
    }
    return snack.message + counter;
  }

  /**
   * Add an error to the model. Can be called with just a message or with an
   * error or response object.
   */
  setError(customMessage: string, availableAction?: UserAction) {
    this.doAlert('error', customMessage, availableAction);
  }

  setWarning(customMessage: string, availableAction?: UserAction) {
    this.doAlert('warning', customMessage, availableAction);
  }

  setSuccess(customMessage: string, availableAction?: UserAction) {
    this.doAlert('success', customMessage, availableAction);
  }
}

export default ErrorStore.instance;
