import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import { UploadMenuState } from '../stores/UploadMenuStore';
import { SimianRequestType } from '../types/app';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';

const SimianUploadMenu = observer((): JSX.Element => {
  const uploadStore = dashboardStore.uploadMenuStore;
  const store = uploadStore.simianStore;

  const handleUpload = async () => {
    uploadStore.state = UploadMenuState.Closed;
    await store.upload();
  };

  const dialogStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  };

  return (
    <Dialog
      open={store.open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => {
        uploadStore.state = UploadMenuState.Closed;
      }}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
      style={dialogStyle}
    >
      <DialogTitle id="form-dialog-title">Upload Menu</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Box sx={{ width: '100%', height: '40%', marginBottom: '16px' }}>
            {/* request type */}
            <SwitchButton
              options={[
                { label: 'Customer CI Request', value: 'customerCIRequest' },
                { label: 'Batch Request', value: 'batchRequest' },
              ]}
              value={store.requestType}
              onChange={(option: SwitchOption) =>
                (store.requestType = option.value as SimianRequestType)
              }
            />
          </Box>
          <Box sx={{ width: '100%', height: '40%', marginBottom: '16px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '50%',
              }}
            >
              <Typography
                sx={{
                  flex: '0 0 auto',
                  width: 'auto',
                  fontWeight: 'bold',
                  marginRight: '4px',
                }}
              >
                Simian Id:
              </Typography>
              <Input
                sx={{ marginRight: 'auto', width: '60%' }}
                type="text"
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => (store.requestId = e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '20%',
              height: '40%',
              marginBottom: '16px',
              marginLeft: 'auto',
            }}
          >
            {/* Back to Upload from Database Menu*/}
            <Tooltip
              title="Back to Upload from Database Menu"
              placement="bottom"
              arrow
            >
              <IconButton
                sx={{ position: 'absolute', left: 15 }}
                onClick={() => {
                  uploadStore.state = UploadMenuState.Database;
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleUpload}
              sx={{ marginLeft: '8px', width: '30%', textTransform: 'none' }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default SimianUploadMenu;
