import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
} from '@mui/material';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import dashboardStore from '../stores/DashboardStore';
import { Accordion, AccordionDetails, AccordionSummary } from './RightDrawer';
import { textEllipsis } from '../styles/common';
import { NeighborType } from '../types/wasm';

const ROWS_PER_PAGE = 4;
const MAX_CHARACTERS = 100; // max characters to display node labels  in the table

const InspectBox = observer((): JSX.Element => {
  const inspectStore = dashboardStore.inspectDrawerStore;

  useEffect(
    () =>
      autorun(() => {
        dashboardStore.currentGraphStore?.selectionUpdate;
        inspectStore.update();
      }),
    [],
  );

  const styles = {
    rootNodeStyle: {
      fontWeight: 'bold',
      fontSize: '14px',
      ...textEllipsis({ lines: 1 }),
    },
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        name={'Inspect'}
        description={'Details on the currently selected subgraph.'}
        docs_url={'/docs/content/How-To/interact_with_data/#inspecting-nodes'}
      />
      <AccordionDetails>
        {inspectStore.display ? (
          <Box>
            <Box sx={styles.rootNodeStyle}>{inspectStore.truncatedLabel}</Box>
            <Box>
              <Box sx={{ marginTop: '8px', marginBottom: '8px' }}>
                <SwitchButton
                  options={[
                    { label: 'Successors', value: 'successors' },
                    { label: 'Predecessors', value: 'predecessors' },
                  ]}
                  value={inspectStore.neighborType}
                  onChange={(option: SwitchOption) => {
                    inspectStore.neighborType = option.value as NeighborType;
                    inspectStore.page = 0;
                  }}
                />
              </Box>
              <NeighborsTable key={'inspectNeighborsTable'} />
            </Box>
            {inspectStore.stats ? (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginTop: '8px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  <StatBlock
                    label="count"
                    value={inspectStore.stats['count']}
                  />
                  <StatBlock label="sum" value={inspectStore.stats['sum']} />
                  <StatBlock label="mean" value={inspectStore.stats['mean']} />
                  <StatBlock label="std" value={inspectStore.stats['std']} />
                  <StatBlock label="min" value={inspectStore.stats['min']} />
                  <StatBlock label="max" value={inspectStore.stats['max']} />
                </Box>
              </Box>
            ) : (
              <Box />
            )}
          </Box>
        ) : (
          <Box sx={{ minHeight: '7em', fontSize: '12px' }}>
            Select a single node to inspect.
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
});

// todo: the Number Formatter into utils

interface NumberFormatProps {
  number: number | null;
}

const formatNumber = (number: number | null): string => {
  if (number === null) {
    return '';
  }

  if (number === 0) {
    return '0';
  }

  const abs = Math.abs(number);
  if (abs >= 1e6 || abs <= 1e-3) {
    return number.toExponential(2);
  }

  if (Number.isInteger(number)) {
    return number.toLocaleString();
  }

  const dp = Math.max(
    0,
    Math.min(4, Math.floor(4 - Math.log10(Math.abs(number)))),
  );
  return number.toFixed(dp);
};

const FormattedNumber: React.FC<NumberFormatProps> = ({ number }) => {
  const formatted = formatNumber(number);
  return <span>{formatted}</span>;
};

interface StatBlockProps {
  label: string;
  value: number | string;
}

const StatBlock: React.FC<StatBlockProps> = ({ label, value }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      minWidth: '50%',
    }}
  >
    <Box sx={{ width: '50%' }}>{label}</Box>
    <Box sx={{ width: '50%', fontWeight: 'bold' }}>
      <FormattedNumber number={Number(value)} />
    </Box>
  </Box>
);

const NeighborsTable = observer((): JSX.Element => {
  const inspectStore = dashboardStore.inspectDrawerStore;

  const columns = [
    { id: 'label', label: 'Label', minWidth: 100, align: 'left' as const },
    { id: 'value', label: 'Value', minWidth: 100, align: 'center' as const },
  ];

  const styles = {
    cell: {
      fontSize: '12px',
      padding: '4px',
      paddingLeft: '8px',
    },
    row: {
      height: '12px',
    },
  };

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={styles.row}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    padding: '4px',
                    paddingLeft: '8px',
                    textAlign: column.align,
                    minWidth: column.minWidth,
                    fontSize: '12px',
                  }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(ROWS_PER_PAGE > 0
              ? inspectStore.data.slice(
                  inspectStore.page * ROWS_PER_PAGE,
                  inspectStore.page * ROWS_PER_PAGE + ROWS_PER_PAGE,
                )
              : inspectStore.data
            ).map((row, index) => (
              <TableRow key={index} style={styles.row}>
                <TableCell style={styles.cell}>
                  {row[0].substring(0, MAX_CHARACTERS)}
                </TableCell>
                <TableCell style={{ ...styles.cell, textAlign: 'center' }}>
                  {row[1]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={inspectStore.data.length}
        rowsPerPage={ROWS_PER_PAGE}
        page={inspectStore.page}
        onPageChange={(event, newPage) => {
          inspectStore.page = newPage;
        }}
      />
    </Paper>
  );
});

export default InspectBox;
