import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Actions from '../actions/actions';
import { GraphId, GraphMetaData } from '../types/app';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  DataGrid,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import DatasetIcon from '@mui/icons-material/Dataset';
import { useTheme } from '@mui/material/styles';

import dashboardStore from '../stores/DashboardStore';
import UploadMenu from './UploadMenu';
import { textEllipsis } from '../styles/common';
import { withDashboardBackdrop } from '../utils/backdrop';
import DashboardLoadingBackdrop from './LoadingBackdrop';
import { dateFormatter } from '../utils/utils';

type GraphTableData = {
  id: GraphId;
  name: string | undefined;
  description: string | undefined;
  tags: string[] | undefined;
  thumbnail: string | undefined;
  owner: string | undefined;
  groups: string[] | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
};

type ExampleGraphCardProps = {
  graph: GraphTableData;
  handleClick: ({ id, name }: Pick<GraphTableData, 'id' | 'name'>) => void;
};

function convertToGraphMeta(graphs: GraphMetaData[]): GraphTableData[] {
  return graphs.map((graph) => ({
    id: graph.id,
    name: graph.name,
    description: graph.description,
    tags: graph.tags,
    thumbnail: graph.thumbnail,
    owner: graph.ownerEmail,
    groups: graph.groups,
    createdAt: graph.createdAt ? new Date(graph.createdAt * 1000) : undefined,
    updatedAt: graph.updatedAt ? new Date(graph.updatedAt * 1000) : undefined,
  }));
}

const GraphsTable = observer(
  ({ graphs }: { graphs: GraphTableData[] }): JSX.Element => {
    const theme = useTheme();
    const columns = [
      {
        field: 'name',
        headerName: 'Graph name',
        flex: 2,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title="Double click to open graph"
            enterDelay={700}
            enterNextDelay={700}
            arrow
          >
            <Typography variant="body2">{params.value}</Typography>
          </Tooltip>
        ),
      },
      {
        field: 'owner',
        headerName: 'Owner',
        flex: 1,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title="Double click to open graph"
            enterDelay={700}
            enterNextDelay={700}
            arrow
          >
            <Typography variant="body2">{params.value}</Typography>
          </Tooltip>
        ),
      },
      {
        field: 'groups',
        headerName: 'Shared with',
        flex: 1,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title="Double click to open graph"
            enterDelay={700}
            enterNextDelay={700}
            arrow
          >
            <Typography variant="body2">{params.value}</Typography>
          </Tooltip>
        ),
      },
      {
        field: 'createdAt',
        type: 'dateTime',
        headerName: 'Created',
        flex: 1,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.value ? params.value.toLocaleString() : ''}
            enterDelay={700}
            enterNextDelay={700}
            arrow
          >
            <Typography variant="body2">
              {dateFormatter(params.value)}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: 'updatedAt',
        type: 'dateTime',
        headerName: 'Last modified',
        flex: 1,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.value ? params.value.toLocaleString() : ''}
            enterDelay={700}
            enterNextDelay={700}
            arrow
          >
            <Typography variant="body2">
              {dateFormatter(params.value)}
            </Typography>
          </Tooltip>
        ),
      },
    ];

    const handleRowDoubleClick: GridEventListener<'rowDoubleClick'> = (
      params,
    ) => {
      dashboardStore.setCurrentGraphStoreById(params.row.id);
    };

    return (
      <Box>
        <Box paddingBottom="2em">
          <Typography variant="h5">My Graphs</Typography>
        </Box>
        <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
          <DataGrid
            rows={graphs}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
              sorting: {
                sortModel: [{ field: 'updatedAt', sort: 'desc' }],
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            onRowDoubleClick={handleRowDoubleClick}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaders': {
                background: theme.palette.secondary.main,
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-row': {
                borderBottom: `1px solid ${theme.palette.divider}`, // Keep border between rows
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              borderLeft: 'none',
              borderRight: 'none',
            }}
            localeText={{
              footerRowSelected: () => '',
            }}
          />
        </Box>
      </Box>
    );
  },
);

const ExampleGraphCard = ({ graph, handleClick }: ExampleGraphCardProps) => {
  const theme = useTheme();
  const styles = {
    cardContainer: {
      position: 'relative',
      cursor: 'pointer',
      width: '100%',
      minWidth: 200,
      maxWidth: 320,
      height: 300,
      transition: '.2s',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.dark}`,
      },
      '&:hover .cart-hint-text': {
        bottom: '10px',
        opacity: 1,
      },
    },
    cardMedia: {
      height: 140,
      borderRadius: '5px 5px 0 0',
      boxSizing: 'border-box',
      padding: '16px',
      paddingBottom: 0,
    },
    cardTitle: {
      ...textEllipsis({ lines: 1 }),
      fontWeight: 'bold',
    },
    cardSubTitle: {
      fontSize: '10px',
      fontWeight: '600',
      paddingBottom: '10px',
      ...textEllipsis({ lines: 1 }),
    },
    cardDescription: {
      ...textEllipsis({ lines: 3 }),
    },
    cardHintText: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '-30px',
      opacity: 0,
      color: theme.palette.secondary.dark,
      fontSize: '10px',
      transition: '.2s',
    },
  };

  return (
    <Card
      onClick={() => handleClick({ id: graph.id, name: graph.name })}
      key={graph.id}
      sx={styles.cardContainer}
    >
      <CardMedia
        sx={styles.cardMedia}
        image={graph.thumbnail}
        crossOrigin="anonymous"
        title={graph.name}
        component="img"
      />
      <CardContent>
        <Typography variant="subtitle1" sx={styles.cardTitle}>
          {graph.name}
        </Typography>
        <Typography variant="body2" sx={styles.cardSubTitle}>
          {graph.tags?.join(' · ')}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={styles.cardDescription}
        >
          {graph.description}
        </Typography>
        <Typography className="cart-hint-text" sx={styles.cardHintText}>
          Open example graph
        </Typography>
      </CardContent>
    </Card>
  );
};

const ExampleGraphTable = observer(
  ({ graphs }: { graphs: GraphTableData[] }): JSX.Element => {
    const theme = useTheme();

    const handleClick = withDashboardBackdrop(
      async ({ id }: Pick<GraphTableData, 'id'>) => {
        const name = undefined; // keep the existing name + ' (${user} copy)'
        await dashboardStore.cloneGraphById(id, name, true);
      },
    );

    const styles = {
      root: {
        height: `calc(100vh - 200px)`,
        width: '100%',
        paddingRight: '1em',
        overflow: 'auto',
        [theme.breakpoints.down('xl')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [theme.breakpoints.down('lg')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      },
    };

    return (
      <Box>
        <Box paddingBottom="2em">
          <Typography variant="h5">Example Graphs</Typography>
        </Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(5, 1fr)"
          gap={3}
          sx={styles.root}
        >
          {graphs.map((graph) => (
            <ExampleGraphCard
              key={graph.id}
              graph={graph}
              handleClick={handleClick}
            />
          ))}
        </Box>
      </Box>
    );
  },
);

const HomePage = observer((): JSX.Element => {
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState(0);

  const styles = {
    root: {
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      margin: '1em',
      padding: '1em',
    },
    menu: {
      height: '100%',
      width: '15em',
      backgroundColor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      borderTop: 0,
    },
    content: {
      height: '100%',
      width: '100%',
      overflow: 'auto',
      flexGrow: 1,
      backgroundColor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      marginLeft: '1em',
      borderTop: 0,
    },
    boxContent: {
      height: '100%',
      overflow: 'hidden',
      marginTop: '8px',
    },
  };

  const tabs = [
    {
      id: 0,
      title: 'My Graphs',
      tooltipText: 'Browse your graphs and graphs shared with you',
      icon: <BubbleChartIcon />,
    },
    {
      id: 1,
      title: 'Examples',
      tooltipText: 'Browse example graphs',
      icon: <DatasetIcon />,
    },
  ];

  const handleTabClick = (tabId: number) => {
    setSelectedTab(tabId);
    dashboardStore.homePageStore.currentPage = tabId;
  };

  return (
    <Box sx={styles.root}>
      {/* Navigation menu */}
      <Box sx={styles.menu}>
        <Box>
          <List>
            <ListItem sx={{ width: '100%', borderTop: 0 }}>
              <Box sx={{ width: '100%' }}>
                <Tooltip
                  title="Upload new file or connect to a database"
                  enterDelay={700}
                  enterNextDelay={700}
                  arrow
                >
                  <Button
                    startIcon={<AddIcon />}
                    fullWidth
                    onClick={Actions.uniqueActions.fileUpload.action}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                      },
                      background: 'rgba(211, 211, 211, 0.5)',
                      boxShadow: '2px rgba(0, 0, 0, 0.1)',
                      fontSize: '36px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      color: 'black',
                      borderRadius: '24px',
                      height: '48px',
                      lineHeight: '48px',
                      maginTop: 0,
                    }}
                  >
                    <Typography>Upload</Typography>
                  </Button>
                </Tooltip>
              </Box>
            </ListItem>
            {tabs.map((tab) => (
              <Tooltip
                key={tab.id}
                title={tab.tooltipText}
                enterDelay={700}
                enterNextDelay={700}
                arrow
              >
                <ListItem
                  key={tab.id}
                  disablePadding
                  sx={{
                    color:
                      selectedTab === tab.id
                        ? 'black'
                        : theme.palette.secondary.main,
                    fontWeight: selectedTab === tab.id ? 'bold' : 'light',
                    '&:hover': {
                      background: theme.palette.action.hover,
                      borderRadius: `${theme.shape.borderRadius}px`,
                    },
                    borderRadius: `${theme.shape.borderRadius}px`,
                  }}
                >
                  <ListItemButton onClick={() => handleTabClick(tab.id)}>
                    <ListItemIcon>{tab.icon}</ListItemIcon>
                    <ListItemText primary={tab.title} />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Box>
      </Box>
      {/* Content */}
      <Box sx={styles.content}>
        {/* My graphs */}
        {dashboardStore.homePageStore.currentPage == 0 && (
          <Box>
            <Box sx={styles.boxContent}>
              <GraphsTable
                graphs={convertToGraphMeta(
                  dashboardStore.graphs.filter((g) => !g.isBlueprint),
                )}
              />
            </Box>
          </Box>
        )}
        {/* Example Graphs*/}
        {dashboardStore.homePageStore.currentPage == 1 && (
          <Box>
            <Box sx={styles.boxContent}>
              <ExampleGraphTable
                graphs={convertToGraphMeta(
                  dashboardStore.graphs.filter((g) => g.isBlueprint),
                )}
              />
            </Box>
          </Box>
        )}
      </Box>
      <UploadMenu />
      <DashboardLoadingBackdrop />
    </Box>
  );
});
export default HomePage;
