import React from 'react';
import { observer } from 'mobx-react-lite';
import { ClickAwayListener } from '@mui/base';
import { Box } from '@mui/material';

import Menu from './Menu';
import dashboardStore from '../stores/DashboardStore';

export const ContextMenu = observer((): JSX.Element => {
  if (!dashboardStore.contextMenuStore.open) return <></>;

  const close = () => {
    dashboardStore.contextMenuStore.open = false;
  };

  const handleClickAway = () => {
    close();
  };

  const handleClose = () => {
    close();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <Box onContextMenu={(e) => e.preventDefault()}>
        <Menu
          menuStore={dashboardStore.contextMenuStore}
          onClose={handleClose}
          closeOthers={(localMenuStore) => {
            dashboardStore.closeOthers(
              dashboardStore.contextMenuStore,
              localMenuStore,
            );
          }}
          context={dashboardStore.contextMenuStore.context}
        />
      </Box>
    </ClickAwayListener>
  );
});

export default ContextMenu;
