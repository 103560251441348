import { runInAction, makeAutoObservable } from 'mobx';

import APIService from '../services/APIService';
import dashboardStore from './DashboardStore';
import GraphStore from './GraphStore';
import NodeModel from '../models/NodeModel';

export type CarouselItem = {
  signedURL?: string;
  label: string;
  type: 'image' | 'video' | 'other' | 'unknown';
};

const IMAGE_EXTENSIONS = ['gif', 'jpeg', 'jpg', 'png', 'svg'];
const VIDEO_EXTENSIONS = ['avi', 'flv', 'mov', 'mp4', 'webm', 'wmv'];

export default class CarouselStore {
  constructor() {
    makeAutoObservable(this);
  }

  public isLoading = false;

  private _open = false;

  get open(): boolean {
    return this._open;
  }
  set open(open: boolean) {
    if (!this.open && open) this.init();
    this._open = open;
  }

  private _index = 0;
  get index(): number {
    return this._index;
  }
  set index(index: number) {
    this._index = index;
  }

  private _items: CarouselItem[] = [];
  get items(): CarouselItem[] {
    return this._items;
  }
  set items(items: CarouselItem[]) {
    this._items = items;
  }

  private async init(): Promise<void> {
    if (!dashboardStore.currentGraphStore) return;
    this.isLoading = true;
    const graphStore: GraphStore = dashboardStore.currentGraphStore;
    await graphStore.updateSelectedNodes();

    runInAction(() => {
      this.index = 0;
      this.items = [];
    });

    for (const node of graphStore.selectedNodes) {
      for (const url of NodeModel.deserialize(node).urls()) {
        await this.addItem(node.label, url);
      }
    }
    this.isLoading = false;
  }

  private async addItem(label: string, url: string): Promise<void> {
    const type = await this.getType(url);
    const signedURL = await APIService.getPresignedURL(url);
    if (type === 'image' || type === 'video') {
      runInAction(() => {
        this.items.push({
          signedURL,
          label,
          type,
        });
      });
      this.isLoading = false;
    }
  }

  private async getType(
    uri: string,
  ): Promise<'image' | 'video' | 'other' | 'unknown'> {
    const extension = uri.split('.').pop();
    if (extension) {
      // Try to guess the content type from the URL extension
      if (IMAGE_EXTENSIONS.includes(extension.toLocaleLowerCase())) {
        return 'image';
      } else if (VIDEO_EXTENSIONS.includes(extension.toLocaleLowerCase())) {
        return 'video';
      }

      // If there is no match, try to get the content type from a HEAD request
      const contentType = await APIService.getContentType(uri);
      if (contentType) {
        if (contentType.startsWith('image')) {
          return 'image';
        } else if (contentType.startsWith('video')) {
          return 'video';
        }
      }
      return 'other';
    }
    return 'unknown';
  }
}
