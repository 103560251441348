import * as THREE from 'three';
import { Vector3 } from 'three';
import { DRiskCSS2DObject } from './DRiskCSS2DObject';

/**
 * Copy of the THREE CSS2DRenderer for use in the LabelEngine
 *
 * Because THREE implements everything in the constructor, just copying the entre class.
 *
 * Changes:
 * - Convert to ts
 * - Positioning of label
 * - Always creates its element.
 */

export class DRiskCSS2DRenderer {
  public domElement: HTMLElement;
  private width = 0;
  private height = 0;
  private widthHalf = 0;
  private heightHalf = 0;

  private cache: {
    objects: WeakMap<DRiskCSS2DObject, { distanceToCameraSquared: number }>;
  };

  private viewMatrix = new THREE.Matrix4();
  private viewProjectionMatrix = new THREE.Matrix4();

  constructor() {
    this.cache = {
      objects: new WeakMap(),
    };

    this.domElement = document.createElement('div') as HTMLElement;
    this.domElement.style.overflow = 'hidden';
  }

  getSize(): { width: number; height: number } {
    return {
      width: this.width,
      height: this.height,
    };
  }

  setSize(width: number, height: number): void {
    this.width = width;
    this.height = height;

    this.widthHalf = this.width / 2;
    this.heightHalf = this.height / 2;

    this.domElement.style.width = width + 'px';
    this.domElement.style.height = height + 'px';
  }

  render(scene: THREE.Scene, camera: THREE.Camera): void {
    scene.updateMatrixWorld();
    this.viewMatrix.copy(camera.matrixWorldInverse);
    this.viewProjectionMatrix.multiplyMatrices(
      camera.projectionMatrix,
      this.viewMatrix,
    );
    this.renderObject(scene, scene, camera);
    this.zOrder(scene);
  }

  private renderObject(
    object: THREE.Object3D,
    scene: THREE.Scene,
    camera: THREE.Camera,
  ): void {
    // So we don't have to allocate all the time
    const vector = new Vector3();

    if (object instanceof DRiskCSS2DObject) {
      vector.setFromMatrixPosition(object.matrixWorld);
      vector.applyMatrix4(this.viewProjectionMatrix);

      const visible =
        object.visible === true &&
        vector.z >= -1 &&
        vector.z <= 1 &&
        object.layers.test(camera.layers) === true;
      object.element.style.display = visible === true ? 'block' : 'none';

      if (visible === true) {
        object.onBeforeRender(this, scene, camera);

        const element = object.element;
        object.vector = vector;
        object.widthHalf = this.widthHalf;
        object.heightHalf = this.heightHalf;

        object.applyTransform();

        if (element.parentNode !== this.domElement) {
          this.domElement.appendChild(element);
        }

        object.onAfterRender(this, scene, camera);
      }

      const objectData = {
        distanceToCameraSquared: this.getDistanceToSquared(camera, object),
      };
      this.cache.objects.set(object, objectData);
    }

    for (let i = 0, l = object.children.length; i < l; i++) {
      this.renderObject(object.children[i], scene, camera);
    }
  }

  private getDistanceToSquared(
    object1: THREE.Object3D,
    object2: THREE.Object3D,
  ): number {
    const a = new Vector3().setFromMatrixPosition(object1.matrixWorld);
    const b = new Vector3().setFromMatrixPosition(object2.matrixWorld);
    return a.distanceToSquared(b);
  }

  private filterAndFlatten(scene: THREE.Scene): DRiskCSS2DObject[] {
    const result: DRiskCSS2DObject[] = [];

    scene.traverse(function (object) {
      if (object instanceof DRiskCSS2DObject) result.push(object);
    });
    return result;
  }

  private zOrder(scene: THREE.Scene): void {
    const sorted = this.filterAndFlatten(scene).sort((a, b) => {
      if (a.renderOrder !== b.renderOrder) {
        return b.renderOrder - a.renderOrder;
      }
      const distanceA = this.cache.objects.get(a)?.distanceToCameraSquared ?? 0;
      const distanceB = this.cache.objects.get(b)?.distanceToCameraSquared ?? 0;
      return distanceA - distanceB;
    });

    const zMax = sorted.length;

    for (let i = 0, l = sorted.length; i < l; i++) {
      sorted[i].element.style.zIndex = zMax - i + '';
    }
  }
}
