import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Collapse,
  IconButton,
  styled,
  Typography,
  TextField,
  Tooltip,
  Icon,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DropdownMenu from './DropdownMenu';
import {
  KeyboardArrowLeft,
  AutoAwesomeMotion,
  DeleteForever,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Resizable } from 're-resizable';

import dashboardStore from '../stores/DashboardStore';
import UploadMenu from './UploadMenu';
import ViewStore from '../stores/ViewStore';
import HelperToolTip from './HelpToolTip';
import GraphPropertiesMenu from './GraphPropertiesMenu';
import NavigationStore from '../stores/NavigationStore';
import { textEllipsis } from '../styles/common';
import { dateFormatter } from '../utils/utils';

// todo: Get these from global style
const TEXT_COLORS = {
  inactive: '#83A2B2',
  active: '#000000',
  not_open: '#414242',
};

const BACKGROUND_COLORS = {
  inactive: '#FFFFFF',
  inactiveRollover: 'grey.100',
  active: '#E9F2F7',
  activeRollover: '#BAC9D1',
};

const MAX_WIDTH = 600;
const MIN_WIDTH = 240;

const GraphTitleTextField = styled(TextField)(() => ({
  width: '100%',
  padding: '8px',
  '& .MuiOutlinedInput-input': {
    fontSize: 14,
    fontWeight: 'bold',
    padding: 0,
  },
}));

const FilterTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  margin: '1px 8px 0 4px',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '& .MuiOutlinedInput-input': {
    fontSize: 14,
    padding: 0,
  },
  '& fieldset': {
    border: 'none',
    padding: '0px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 14,
  },
  '& .MuiInput-root': {
    fontSize: '14px',
  },
}));

function useDebouncedCallback<T extends unknown[]>(
  callback: (...args: T) => void,
  delay: number,
) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number>();

  callbackRef.current = callback;

  return (...args: T) => {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(
      () => callbackRef.current(...args),
      delay,
    );
  };
}

type NavigationDrawerViewProps = {
  view: ViewStore;
};

const NavigationDrawerView = observer(
  ({ view }: NavigationDrawerViewProps): JSX.Element => {
    const theme = useTheme();
    // Todo: all of these hard-coded numbers should come from the theme
    const styles = {
      root: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '14px',
        fontWeight: view.open ? 'bold' : 'normal',
        color: view.open ? TEXT_COLORS.active : TEXT_COLORS.not_open,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: BACKGROUND_COLORS.inactiveRollover,
        },
        '& .hover-element': {
          visibility: 'hidden',
        },
        '&:hover .hover-element': {
          visibility: 'visible',
        },
        borderRadius: `${theme.shape.borderRadius}px`,
        paddingTop: '2px',
      },
      currentDotContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        paddingLeft: '12px',
        paddingRight: '4px',
      },
      currentDot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '0.6em',
        height: '0.6em',
        bgcolor: view.current ? theme.palette.success.light : 'transparent',
        border: view.current
          ? `1px solid ${theme.palette.success.light}`
          : `1px solid ${theme.palette.grey[300]}`,
        borderRadius: '50%',
      },
      rightSide: {
        width: '70%',
        ...textEllipsis({ lines: 1 }),
      },
      viewButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        color: theme.palette.secondary.main,
        marginLeft: 'auto',
        marginRight: '2px',
      },
      viewButton: {
        padding: '0',
        fontSize: '18px',
        margin: '1px',
      },
    };

    const [isHovered, setIsHovered] = React.useState(false);

    const handleLineClick = () => {
      dashboardStore.toggleOpenView(view.id);
    };

    const handleDotClick = () => {
      if (view.open) {
        dashboardStore.setCurrentViewStoreById(view.id);
      } else {
        dashboardStore.openSingleView(view.id);
      }
    };

    const timestampDisplay = (timestamp: number) => {
      if (!timestamp) return '';
      const createdDate = new Date(timestamp * 1000);
      return dateFormatter(createdDate);
    };

    return (
      <Box
        sx={styles.root}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <Box sx={styles.currentDotContainer} onClick={handleDotClick}>
          <Box sx={styles.currentDot}></Box>
        </Box>
        <Box sx={styles.rightSide} onClick={handleLineClick}>
          <Typography
            paragraph
            sx={{
              marginBottom: '1px',
              ...textEllipsis({ lines: 1 }),
            }}
          >
            {view.label}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              paragraph
              sx={{
                color: 'grey',
                fontSize: '12px',
                marginBottom: '2px',
                ...textEllipsis({ lines: 1 }),
              }}
            >
              {NavigationStore.viewTypeDisplay(view)}
            </Typography>
            <Typography
              paragraph
              sx={{
                color: 'grey',
                fontSize: '12px',
                marginBottom: '2px',
                ...textEllipsis({ lines: 1 }),
              }}
            >
              {timestampDisplay(view.createdTimestamp)}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.viewButtons}>
          {isHovered && (
            <Tooltip title={'Delete View'} arrow>
              <IconButton
                size="small"
                sx={styles.viewButton}
                onClick={() => dashboardStore.deleteViewById(view.id)}
              >
                <DeleteForever />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  },
);

const NavigationDrawer = observer((): JSX.Element => {
  const theme = useTheme();
  const store = dashboardStore.navigationStore;
  const inputRef = useRef<string>('');
  const [resizeFlag, setResizeFlag] = useState(false);
  const [editedGraphName, setEditedGraphName] = useState(
    dashboardStore.currentGraphStore?.name || '',
  );

  useEffect(() => {
    setEditedGraphName(dashboardStore.currentGraphStore?.name || '');
  }, [dashboardStore.currentGraphStore]);

  const handleBlur = () => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    if (editedGraphName === graphStore.name) {
      return;
    }

    graphStore.updateName(editedGraphName);
    dashboardStore.submitGraphOptions();
  };

  const handleTextFieldChange = useDebouncedCallback(
    (value: string) => (store.query = value),
    250,
  );

  // this is required or else the icon is clipped on Chrome (fine on Edge!)
  const collapsedSize = 25;
  const styles = {
    root: {
      height: '100%',
      backgroundColor: store.open ? 'white' : 'inherit',
      overflowX: 'hidden',
      overflowY: 'auto',
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
    },
    tooltipContainer: {
      backgroundColor: 'transparent',
      overflowX: 'hidden',
      overflowY: 'auto',
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      width: store.open ? '100%' : `${collapsedSize}px`,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      bgcolor: 'white',
      height: 24,
      paddingTop: '2px',
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      width: store.open ? '100%' : `${collapsedSize}px`,
    },
    body: {
      bgcolor: 'white',
      fontSize: 14,
      cursor: 'pointer',
    },
    addView: {
      backgroundColor: 'grey.100',
      padding: '4px 10px 2px 16px',
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      fontSize: '15px',
      width: '15px',
    },
    graphRoot: {
      display: 'flex',
      flexDirection: 'column',
      padding: '4px',
      cursor: 'pointer',
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    graphBody: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '16px',
      fontSize: '12px',
    },
  };

  const onboardingStore = dashboardStore.onboardingHelpStore;
  return (
    <Collapse
      in={store.open}
      orientation="horizontal"
      collapsedSize={collapsedSize}
    >
      <Resizable
        defaultSize={{
          width: MIN_WIDTH,
          height: '100%',
        }}
        minWidth={MIN_WIDTH}
        maxWidth={MAX_WIDTH}
        enable={{
          right: true,
        }}
        onResize={() => setResizeFlag(!resizeFlag)}
      >
        <Box sx={styles.root}>
          <HelperToolTip
            inAppHelpItem={onboardingStore.getItemByIndex(0)}
            inAppHelpStore={onboardingStore}
            childrenSx={{
              width: store.open ? '100%' : `${collapsedSize + 2}px`,
              height: store.open ? '100%' : `${collapsedSize + 2}px`,
              zIndex: 0,
            }}
            store={store}
          >
            <Box sx={styles.tooltipContainer}>
              {/* Header */}
              <Box
                sx={styles.header}
                onClick={() => (store.open = !store.open)}
              >
                <Box style={{ display: 'flex' }}>
                  <Tooltip
                    title="View navigation drawer"
                    enterDelay={700}
                    enterNextDelay={700}
                    arrow
                  >
                    <IconButton
                      size="small"
                      sx={{ padding: '1px', width: `${collapsedSize}px` }}
                    >
                      <AutoAwesomeMotion />
                    </IconButton>
                  </Tooltip>
                  {store.open && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: theme.palette.grey[600],
                        alignSelf: 'center',
                        fontWeight: 'normal',
                        fontSize: '1em',
                      }}
                    >
                      Current Graph
                    </Typography>
                  )}
                </Box>

                <IconButton size="small" sx={{ padding: '1px' }}>
                  {store.open ? <KeyboardArrowLeft /> : ''}
                </IconButton>
              </Box>

              <Collapse in={store.open}>
                <Box sx={styles.body}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* Graph title */}
                    <Box sx={{ flexGrow: 1, width: '90%' }}>
                      <GraphTitleTextField
                        multiline
                        margin="dense"
                        size="small"
                        type="text"
                        value={editedGraphName}
                        onChange={(e) => {
                          setEditedGraphName(e.target.value);
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            (e.target as HTMLInputElement).blur();
                          }
                          e.stopPropagation();
                        }}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box sx={{ width: '10%' }}>
                      <GraphPropertiesMenu />
                    </Box>
                  </Box>

                  {/* View search */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingY: '1em',
                      width: '100%',
                    }}
                  >
                    <Icon
                      sx={{ fontSize: '18px', color: theme.palette.grey[400] }}
                    >
                      <SearchOutlinedIcon />
                    </Icon>
                    <FilterTextField
                      placeholder="Search views..."
                      defaultValue={store.query}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        inputRef.current = e.target.value;
                        handleTextFieldChange(inputRef.current);
                      }}
                    />
                    {/* View Sorting */}
                    <DropdownMenu />
                  </Box>

                  {/* View list */}
                  <Box>
                    <Box sx={styles.graphRoot}>
                      {store.dataLoaded ? (
                        store.filteredViews.map((view) => (
                          <NavigationDrawerView key={view.id} view={view} />
                        ))
                      ) : (
                        <Box sx={styles.graphBody}>Loading Data...</Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </HelperToolTip>
        </Box>
        <UploadMenu></UploadMenu>
      </Resizable>
    </Collapse>
  );
});
export default NavigationDrawer;
