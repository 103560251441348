import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
export type SectionState = 'normal' | 'selected' | 'disabled';
import GraphStore from './GraphStore';
import { NodeStore, MultiNodeStore } from './NodeStore';
import { waitForToolMessage } from '../tools';
import { PredictModelType } from 'wasm_service';
import { Uuid } from '../types/app';

export class FitsDrawerStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Drawer state
   */
  model: PredictModelType = 'linear'; // Default value

  featureNodes = new MultiNodeStore();
  targetNode = new NodeStore();

  // Predict
  fit = async (): Promise<void> => {
    if (this.targetNode.id === undefined) return;

    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    const taskId = await this.launchPredictor(graphStore);
    if (!taskId) return;
    this.handleTaskResponse(taskId);
  };

  private launchPredictor = async (
    graphStore: GraphStore,
  ): Promise<Uuid | undefined> => {
    if (!this.targetNode.id) return;
    if (!this.featureNodes.totalStoredNodes) return;
    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        predict: {
          model: this.model,
          featureNodes: this.featureNodes.storeId,
          targetNode: this.targetNode.id,
        },
      },
    });

    const taskId: Uuid | undefined = await waitForToolMessage(
      graphStore,
      (msg) => {
        if (typeof msg === 'object' && 'predict' in msg) {
          return msg.predict;
        }
      },
    );
    return taskId;
  };

  handleTaskResponse = (taskId: Uuid) => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    dashboardStore.addViewsTask(taskId, graphStore, 'Fitting model');
  };
}
