import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

export type SwitchOption<T = unknown> = {
  label: string;
  value: T;
};

type SwitchProps = {
  options: SwitchOption[];
  value: string | number | boolean;
  disabled?: boolean;
  onChange?: (option: SwitchOption) => void;
  border?: boolean;
  fontsize?: string;
};

/**
 * A multi-option switch that looks like a button.
 */
const SwitchButton = ({
  options,
  value,
  disabled,
  onChange,
  border = true,
  fontsize = '12px',
}: SwitchProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        borderWidth: border ? '1px' : '0px 0px 1px 0px',
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
        borderRadius: `${theme.shape.borderRadius}px`,
        cursor: 'pointer',
      }}
    >
      {options.map((option, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: disabled
              ? theme.palette.grey[100]
              : option.value === value
                ? theme.palette.secondary.main
                : 'white',
            color: disabled ? theme.palette.grey[500] : 'black',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-around',
            transition: 'all 0.3s ease-in 0s',
            fontSize: fontsize,
            '&:not(:last-child)': {
              borderRight: `1px solid ${theme.palette.grey[100]}`,
            },
            borderRadius: `${theme.shape.borderRadius}px`,
          }}
          onClick={() => !disabled && onChange && onChange(option)}
        >
          {option.label}
        </Box>
      ))}
    </Box>
  );
};
export default SwitchButton;
