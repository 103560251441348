import { makeAutoObservable } from 'mobx';
import {
  APIUploadHeader,
  APIUploadMethod,
  GraphDiff,
  SupportedSQLDbs,
  SimianRequestType,
  ViewId,
} from '../types/app';
import dashboardStore from './DashboardStore';
import DataService from '../services/DataService';
import ErrorStore from '../stores/ErrorStore';
import { deserializeGraphDiff } from '../utils/graphDiffHelper';
import { MonitoredTasks, openViewsCallback } from '../services/TaskService';
import { SIMIAN_ALLOWED_GROUP } from '../Constants';
import GraphStore from './GraphStore';
import { dbTypeFromURL } from '../utils/utils';
import { TaskResult } from 'wasm_service';

export enum UploadMenuState {
  Closed,
  Open,
  API,
  Database,
  Simian,
  SQL,
}

export class UploadMenuStore {
  constructor() {
    makeAutoObservable(this);
  }

  /*
   * General parameters.
   */

  get open(): boolean {
    return this.state !== UploadMenuState.Closed;
  }

  private _uploadAdditionalData: boolean = false;
  get uploadAdditionalData(): boolean {
    return this._uploadAdditionalData;
  }

  set uploadAdditionalData(value: boolean) {
    this._uploadAdditionalData = value;
  }

  private _state: UploadMenuState = UploadMenuState.Closed;
  get state(): UploadMenuState {
    return this._state;
  }

  set state(value: UploadMenuState) {
    this._state = value;
  }

  apiStore: APIUploadMenuStore = new APIUploadMenuStore(this);
  simianStore: SimianUploadMenuStore = new SimianUploadMenuStore(this);
  sqlStore: SQLUploadMenuStore = new SQLUploadMenuStore(this);
}

/*
 * SQL Uploader
 */
export class SQLUploadMenuStore {
  private parent: UploadMenuStore;

  constructor(parent: UploadMenuStore) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  get open(): boolean {
    return this.parent.state === UploadMenuState.SQL;
  }

  private _databaseURL: string = '';
  get databaseURL(): string {
    return this._databaseURL;
  }

  set databaseURL(value: string) {
    this._databaseURL = value;
    const db = dbTypeFromURL(value);
    if (db) this.db = db;
  }

  private _query: string = '';
  get query(): string {
    return this._query;
  }

  set query(value: string) {
    this._query = value;
  }

  private _db: SupportedSQLDbs = 'sqlite';
  get db(): SupportedSQLDbs {
    return this._db;
  }

  set db(value: SupportedSQLDbs) {
    this._db = value;
  }

  *uploadSchema() {
    const newGraph = !this.parent.uploadAdditionalData;
    let graphStore: GraphStore | undefined = undefined;
    if (newGraph) {
      const split = this.databaseURL.split('/').pop();
      const newGraphName = split ? split : 'SQL Database';
      const response: GraphStore | undefined =
        yield dashboardStore.newGraph(newGraphName);
      if (!response) {
        return;
      }
      graphStore = response;
    } else {
      if (!dashboardStore.currentGraphStore) {
        return;
      }
      graphStore = dashboardStore.currentGraphStore;
    }

    if (newGraph) dashboardStore.loadingBackdropStore.openBackdrop();

    const response: [string, GraphDiff, number] =
      yield DataService.uploadSchema(
        graphStore.id,
        this.databaseURL,
        this.db,
        undefined,
      );
    if (!response) {
      if (newGraph) dashboardStore.loadingBackdropStore.closeBackdrop();
      return;
    }
    const [viewId, diff, numRows] = response;
    graphStore.applyDiff(deserializeGraphDiff(diff));
    if (newGraph) dashboardStore.loadingBackdropStore.closeBackdrop();
    yield dashboardStore.openSingleView(viewId);
    ErrorStore.setSuccess(
      `Successfully imported database schema and a sample of ${numRows} rows.`,
    );
  }

  *uploadQuery() {
    const newGraph = !this.parent.uploadAdditionalData;
    let graphStore: GraphStore | undefined = undefined;
    if (newGraph) {
      const newGraphName = this.query;
      const response: GraphStore | undefined =
        yield dashboardStore.newGraph(newGraphName);
      if (!response) {
        return;
      }
      graphStore = response;
    } else {
      if (!dashboardStore.currentGraphStore) {
        return;
      }
      graphStore = dashboardStore.currentGraphStore;
    }

    const taskId: string | undefined = yield DataService.uploadQuery(
      graphStore.id,
      this.databaseURL,
      this.query,
      this.db,
    );

    if (!taskId) return;

    if (newGraph) {
      dashboardStore.loadingBackdropStore.openBackdrop();
    }

    const callback = async (result: TaskResult) => {
      if (!graphStore) return;
      await openViewsCallback(graphStore.id, result);
      if (newGraph) {
        dashboardStore.loadingBackdropStore.closeBackdrop();
      }
    };

    MonitoredTasks.instance.addTask(
      taskId,
      'Importing from SQL database',
      callback,
    );
  }
}

/*
 * API Uploader
 */
export class APIUploadMenuStore {
  private parent: UploadMenuStore;

  constructor(parent: UploadMenuStore) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  get open(): boolean {
    return this.parent.state === UploadMenuState.API;
  }

  private _queryURL: string = '';
  get queryURL(): string {
    return this._queryURL;
  }

  set queryURL(value: string) {
    this._queryURL = value;
  }

  private _body: string = '';
  get body(): string {
    return this._body;
  }

  set body(value: string) {
    this._body = value;
  }

  private _method: APIUploadMethod = 'GET' as APIUploadMethod;
  get method(): APIUploadMethod {
    return this._method;
  }

  set method(value: APIUploadMethod) {
    this._method = value;
  }

  private _headers: APIUploadHeader[] = [];
  get headers(): APIUploadHeader[] {
    return this._headers;
  }

  set headers(value: APIUploadHeader[]) {
    this._headers = value;
  }

  upload = async () => {
    const newGraph = !this.parent.uploadAdditionalData;

    let graphStore;
    if (newGraph) {
      const response = await dashboardStore.newGraph('API Upload');
      if (!response) {
        return;
      }
      graphStore = response;
    } else {
      if (!dashboardStore.currentGraphStore) {
        return;
      }
      graphStore = dashboardStore.currentGraphStore;
    }
    const response = await DataService.uploadFromAPI(
      graphStore.id,
      this.queryURL,
      this.method,
      this.headers,
      this.body,
    );
    if (!response) {
      return;
    }
    const [viewId, diff] = response;
    graphStore.applyDiff(diff);
    await dashboardStore.openSingleView(viewId);
  };
}

/*
 * Simian Uploader
 */
export class SimianUploadMenuStore {
  private parent: UploadMenuStore;

  constructor(parent: UploadMenuStore) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  get open(): boolean {
    return this.parent.state === UploadMenuState.Simian;
  }

  get isSimianUser(): boolean {
    return dashboardStore.userGroups.some((g) => g === SIMIAN_ALLOWED_GROUP);
  }

  private _requestId: string = '';
  get requestId(): string {
    return this._requestId;
  }

  set requestId(value: string) {
    this._requestId = value;
  }

  private _requestType: SimianRequestType = 'customerCIRequest';
  get requestType(): SimianRequestType {
    return this._requestType;
  }

  set requestType(value: SimianRequestType) {
    this._requestType = value;
  }

  VIEWS_TO_OPEN = ['Batch Request', 'spatial'];

  upload = async () => {
    const requestId = this.requestId;
    const requestType = this.requestType;
    if (!requestId.trim()) {
      ErrorStore.setError('Not a valid request Id.');
      return;
    }
    const newGraph = !this.parent.uploadAdditionalData;
    if (newGraph) {
      const name = `simian batch request ${requestId}`;
      const response = await dashboardStore.newGraph(name);
      if (!response) {
        return;
      }
    }

    if (!dashboardStore.currentGraphStore) {
      return;
    }
    const graphId = dashboardStore.currentGraphStore.id;
    const name = `simian batch request ${requestId}`;

    dashboardStore.loadingBackdropStore.openBackdrop();
    const taskId = await DataService.uploadSimian(
      graphId,
      requestType,
      requestId,
    );
    dashboardStore.loadingBackdropStore.closeBackdrop();
    if (!taskId) {
      return;
    }

    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) {
      return;
    }

    const callback = async (result: TaskResult) => {
      if (dashboardStore.currentGraphStore?.id != graphStore.id) {
        return;
      }

      switch (result.type) {
        case 'viewIds': {
          // Now filter the view ids where the name is in the VIEWS_TO_OPEN
          const viewIds = result.payload.filter(async (viewId: ViewId) => {
            const viewStore = await graphStore.getOrCreateViewStoreById(viewId);
            return this.VIEWS_TO_OPEN.some((allowedName) =>
              viewStore.label.includes(allowedName),
            );
          });
          await dashboardStore.openMultipleViews(viewIds);
        }
      }
    };

    MonitoredTasks.instance.addTask(taskId, name, callback);
  };
}
