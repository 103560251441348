import * as THREE from 'three';

import { TOTAL_NODE_LABELS } from '../Constants';
import { DRiskCSS2DRenderer } from './DRiskCSS2DRenderer';
import { DRiskCSS2DObject } from './DRiskCSS2DObject';

import { reaction, IReactionDisposer } from 'mobx';
import { floatRGBToHex } from '../utils/utils';
import ViewStore, { DragState } from '../stores/ViewStore';

import '../css/index.css';

const ROLLOVER_FONT_MULTIPLE = 1.15;
const FONT_SIZE_DEFAULT = 11;
const MAX_LABEL_LENGTH = 24;

export default class LabelEngine {
  private viewStore: ViewStore;
  private renderer: DRiskCSS2DRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private labelParent: THREE.Object3D;
  private hoverParent: THREE.Object3D;

  /**
   * Cache of CSS2DObjects indexed by NodeId
   */
  private labelCSSObjects: DRiskCSS2DObject[] = [];
  private hoverObject: DRiskCSS2DObject;

  private reactions: IReactionDisposer[] = [];

  private nodeTranslateArray = new Float32Array();
  private nodeColorArray = new Float32Array();
  private nodeStateArray = new Uint32Array();

  constructor(
    scene: THREE.Scene,
    camera: THREE.PerspectiveCamera,
    renderer = new DRiskCSS2DRenderer(),
    view: ViewStore,
    container: HTMLDivElement,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.viewStore = view;
    this.renderer = renderer;

    this.labelParent = new THREE.Group();

    const width = container?.clientWidth ?? 100;
    const height = container?.clientHeight ?? 100;

    this.renderer.setSize(width, height);
    this.renderer.domElement.style.position = 'absolute';
    this.renderer.domElement.style.top = '0';
    container.appendChild(this.renderer.domElement);

    this.hoverObject = new DRiskCSS2DObject(document.createElement('div'));
    this.hoverParent = new THREE.Object3D();
    this.createCSSObjects();

    this.reactions.push(
      reaction(
        () => this.viewStore.labelAngleSlider.value,
        this.observeRotation,
      ),
    );

    this.reactions.push(
      reaction(() => this.viewStore.labelSizeSlider.value, this.observeSize),
    );

    this.reactions.push(
      reaction(
        () => [this.viewStore.totalHoveredNodes, this.viewStore.dragState],
        this.observeHoveredNodes,
      ),
    );
  }

  bindSharedArrays = (
    nodeTranslateArray: Float32Array,
    nodeColorArray: Float32Array,
    nodeStateArray: Uint32Array,
  ): void => {
    this.nodeTranslateArray = nodeTranslateArray;
    this.nodeColorArray = nodeColorArray;
    this.nodeStateArray = nodeStateArray;
  };

  /**
   * Create all the css objects at once so we can reuse them.
   */
  private createCSSObjects = (): void => {
    /**
     * Hover
     */
    const hoverDiv = document.createElement('div');
    hoverDiv.className = 'rollover-tooltip';
    hoverDiv.textContent = '';
    this.hoverObject = new DRiskCSS2DObject(hoverDiv);
    this.hoverObject.renderOrder = 2147483647;
    this.hoverObject.visible = false;
    this.hoverObject.element.style.fontSize = `${
      FONT_SIZE_DEFAULT * ROLLOVER_FONT_MULTIPLE
    }px`;

    this.hoverParent = new THREE.Object3D();
    this.hoverParent.add(this.hoverObject);
    this.scene.add(this.hoverParent);

    /**
     * Labels
     */
    this.labelParent = new THREE.Group();

    for (let i = 0; i < TOTAL_NODE_LABELS; i++) {
      const labelId = `label-${i}`;
      const div = document.createElement('div');
      div.className = 'node-label';
      div.id = labelId;
      div.addEventListener('click', this.handleLabelClick);
      div.addEventListener('mousedown', this.handleMouseDown);
      div.addEventListener('mousemove', this.handleLabelMouseMove);
      const labelCSSObject = new DRiskCSS2DObject(div);
      labelCSSObject.labelRotation = this.viewStore.labelAngleSlider.value;
      labelCSSObject.element.style.fontSize = `${
        FONT_SIZE_DEFAULT * this.viewStore.labelSizeSlider.value
      }px`;
      this.labelCSSObjects.push(labelCSSObject);
      this.labelParent.add(labelCSSObject);
    }
    this.scene.add(this.labelParent);
  };

  observeLabels = (): void => {
    for (let labelIndex = 0; labelIndex < TOTAL_NODE_LABELS; labelIndex++) {
      const labelCSSObject = this.labelCSSObjects[labelIndex];

      if (labelIndex >= this.viewStore.labels.length) {
        labelCSSObject.element.textContent = '';
        labelCSSObject.visible = false;
        continue;
      }
      labelCSSObject.visible = true;

      const labelObj = this.viewStore.labels[labelIndex];
      const nodeIndex = labelObj.index;

      // Content
      labelCSSObject.element.textContent =
        labelObj.label.length > MAX_LABEL_LENGTH
          ? labelObj.label.substring(0, MAX_LABEL_LENGTH - 1) + '...'
          : labelObj.label;

      // Position
      this.positionLabel(labelIndex, nodeIndex);

      // Color
      labelCSSObject.element.style.borderColor = floatRGBToHex(
        this.nodeColorArray[nodeIndex * 3],
        this.nodeColorArray[nodeIndex * 3 + 1],
        this.nodeColorArray[nodeIndex * 3 + 2],
      );
    }
  };

  observeAxisScale = (): void => {
    this.positionAllLabels();
    this.updateHoverLabel();
  };

  observeDragDelta = (): void => {
    this.positionAllLabels();
    this.updateHoverLabel();
  };

  private observeHoveredNodes = (): void => {
    this.updateHoverLabel();
  };

  private positionAllLabels = (): void => {
    for (const [
      labelIndex,
      { index: nodeIndex },
    ] of this.viewStore.labels.entries()) {
      if (labelIndex >= TOTAL_NODE_LABELS) break;
      this.positionLabel(labelIndex, nodeIndex);
    }
  };

  private positionLabel = (labelIndex: number, nodeIndex: number): void => {
    const labelCSSObject = this.labelCSSObjects[labelIndex];
    const selected = this.nodeStateArray[nodeIndex] & 0b1;

    let x = this.nodeTranslateArray[nodeIndex * 3];
    let y = this.nodeTranslateArray[nodeIndex * 3 + 1];

    if (selected) {
      x += this.viewStore.dragDelta.x;
      y += this.viewStore.dragDelta.y;
    }
    labelCSSObject.position.set(
      x * this.viewStore.xAxisScale,
      y * this.viewStore.yAxisScale,
      0,
    );
  };

  private updateHoverLabel = (): void => {
    if (
      this.viewStore.totalHoveredNodes === 0 ||
      (this.viewStore.totalSelectedNodes === 1 &&
        this.viewStore.dragState === DragState.MOVING_NODES)
    ) {
      this.hoverObject.visible = false;
      return;
    }

    this.hoverObject.visible = true;
    this.hoverObject.element.textContent = this.viewStore.hoveredLabel;

    this.hoverObject.position.set(
      (this.viewStore.hoveredPosition.x + this.viewStore.dragDelta.x) *
        this.viewStore.xAxisScale,
      (this.viewStore.hoveredPosition.y + this.viewStore.dragDelta.y) *
        this.viewStore.yAxisScale,
      0,
    );
    this.hoverObject.labelRotation = this.viewStore.labelAngleSlider.value;
    this.hoverObject.applyTransform();
  };

  private observeRotation = (): void => {
    this.labelCSSObjects.forEach((obj) => {
      obj.labelRotation = this.viewStore.labelAngleSlider.value;
      obj.applyTransform();
    });
  };

  private observeSize = (): void => {
    this.labelCSSObjects.forEach((obj) => {
      obj.element.style.fontSize = `${
        FONT_SIZE_DEFAULT * this.viewStore.labelSizeSlider.value
      }px`;
    });
  };

  translateAttributeNeedsUpdate = (): void => {
    this.updateHoverLabel();
    this.positionAllLabels();
  };

  handleResize = (width: number, height: number): void => {
    this.renderer.setSize(width, height);
  };

  /**
   * Need this to prevent the View from getting the event (which
   * clears the selection).
   */
  private handleMouseDown = (event: MouseEvent): void => {
    event.stopPropagation();
  };

  private handleLabelClick = (event: MouseEvent): void => {
    const { target } = event;
    if (!target || !(target instanceof HTMLElement) || !target.id) return;
    const labelIndex = parseInt(target.id.split('-')[1], 10);
    const { id: nodeId } = this.viewStore.labels[labelIndex];

    if (event.shiftKey) {
      this.viewStore.addToSelectionByIds(new Set([nodeId]));
    } else if (event.ctrlKey || event.metaKey) {
      this.viewStore.toggleSelectionByIds(new Set([nodeId]));
    } else {
      this.viewStore.setSelectedNodesByIds(new Set([nodeId]));
    }
    event.stopPropagation();
  };

  private handleLabelMouseMove = (event: MouseEvent): void => {
    // Do not respond to mousemove events if we are dragging the selection box or moving Nodes
    if (
      this.viewStore.dragState === DragState.DRAGGING_SELECTION_BOX ||
      this.viewStore.dragState === DragState.MOVING_NODES
    ) {
      return;
    }
    event.stopPropagation();
  };

  render(): void {
    this.renderer.render(this.scene, this.camera);
  }

  destroy(): void {
    this.reactions.forEach((disposer) => disposer());
    this.reactions = [];

    this.labelCSSObjects.forEach((obj) => this.labelParent.remove(obj));
    this.scene.remove(this.labelParent);
  }
}
