import React from 'react';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Slider,
} from '@mui/material';

const RotateNodesDialog = observer((): JSX.Element => {
  const [degrees, setDegrees] = React.useState<number | string>(0);

  const marks = [
    {
      value: -360,
      label: '-360°',
    },
    {
      value: 0,
      label: '0°',
    },
    {
      value: 360,
      label: '360°',
    },
  ];

  return (
    <Dialog
      open={dashboardStore.rotateNodesDialogOpen}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => (dashboardStore.rotateNodesDialogOpen = false)}
      aria-labelledby="form-dialog-title"
      disableEnforceFocus
    >
      <DialogTitle id="form-dialog-title">Rotate Nodes</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container alignItems="center">
            <Grid item xs={9} style={{ padding: '3em' }}>
              <Slider
                value={typeof degrees === 'number' ? degrees : 0}
                onChange={(e, newValue) => setDegrees(+newValue)}
                min={-360}
                max={360}
                step={5}
                marks={marks}
                aria-labelledby="input-slider"
              />
            </Grid>
            <Grid item xs={3} style={{ paddingBottom: '3em' }}>
              <Input
                value={degrees}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setDegrees(
                    e.target.value === '' ? '' : Number(e.target.value),
                  );
                }}
                onBlur={() => {
                  if (degrees > 360) {
                    setDegrees(360);
                  } else if (degrees < -360) {
                    setDegrees(-360);
                  }
                }}
                inputProps={{
                  step: 10,
                  min: -360,
                  max: 360,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => (dashboardStore.rotateNodesDialogOpen = false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            dashboardStore.currentViewStore?.rotateNodes(Number(degrees));
            dashboardStore.rotateNodesDialogOpen = false;
          }}
          autoFocus
          color="primary"
        >
          Rotate
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default RotateNodesDialog;
