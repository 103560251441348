import dashboardStore from '../stores/DashboardStore';
import { AsyncFn, GeneratorFn } from '../types/app';

async function executeWithBackdrop<T extends unknown[], U>(
  func: AsyncFn<T, U> | GeneratorFn<T, U>,
  openBackdrop: () => void,
  closeBackdrop: () => void,
  ...args: T
): Promise<U> {
  openBackdrop();
  let output;
  try {
    const result = func(...args);
    if (result instanceof Promise) {
      output = await result;
    } else {
      let curr = result.next();
      while (!curr.done) {
        curr = result.next();
      }
      output = curr.value;
    }
  } finally {
    closeBackdrop();
  }
  return output;
}

export function withDashboardBackdrop<T extends unknown[], U>(
  func: AsyncFn<T, U> | GeneratorFn<T, U>,
) {
  return (...args: T) =>
    executeWithBackdrop(
      func,
      dashboardStore.loadingBackdropStore.openBackdrop,
      dashboardStore.loadingBackdropStore.closeBackdrop,
      ...args,
    );
}

export function withCurrViewBackdrop<T extends unknown[], U>(
  func: AsyncFn<T, U> | GeneratorFn<T, U>,
) {
  return (...args: T) => {
    const backdropStore = dashboardStore.currentViewStore?.loadingBackdropStore;
    const openFn = backdropStore ? backdropStore.openBackdrop : () => {};
    const closeFn = backdropStore ? backdropStore.closeBackdrop : () => {};
    executeWithBackdrop(func, openFn, closeFn, ...args);
  };
}
