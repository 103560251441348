import { flowResult, makeAutoObservable, runInAction, when } from 'mobx';
import {
  VIEW_X_AXIS,
  VIEW_Y_AXIS,
  AUTH_TOKEN_REFRESH_INTERVAL,
} from '../Constants';
import {
  APIGraph,
  GraphDiff,
  GraphId,
  GraphMetaData,
  NeighborQueryType,
  Node,
  NodeId,
  ViewData,
  ViewId,
  ViewProperties,
  ViewMetaData,
} from '../types/app';
import {
  GW_OutEvent,
  GW_InData,
  GW_LabelPayload,
  GW_SharedBuffers,
  GW_ErrorPayload,
} from '../types/graphWorker';
import { fetchAuthSession } from 'aws-amplify/auth';
import dashboardStore from './DashboardStore';
import ViewNode from '../models/ViewNode';
import DataService from '../services/DataService';
import ViewStore from '../stores/ViewStore';
import * as GDiff from '../utils/graphDiffHelper';
import GraphWorker from '../workers/Graph.worker';
import ErrorStore from './ErrorStore';
import { ToolOutput } from 'wasm_service';
import { MonitoredTasks } from '../services/TaskService';
import { debounce } from '../utils/utils';

/**
 * Observable that defines everything we know about the
 * entire graph (all views). It contains the list of views
 * it knows about, but lazily loads them from the service only
 * when asked for a specific view.
 *
 * Note: This class shouldn't be imported directly. Observers should
 * import the DashboardStore and access this via DashboardStore.currentGraph.
 */
export default class GraphStore implements APIGraph {
  id: GraphId;
  name: string;
  groups?: string[] | undefined = undefined;
  ownerEmail?: string | undefined = undefined;
  dataLoaded = false;

  views: BasicView[] = [];
  selectedNodes: Set<Node> = new Set();
  hoveredNodes: Set<NodeId> = new Set();

  totalNodes = 0;
  totalEdges = 0;
  totalSelectedNodes = 0;
  totalSelectedEdges = 0;

  selectionUpdate = 0; // changes can be reacted to
  selectionUpdated() {
    this.selectionUpdate++;
  }

  public graphWorker: Worker;
  initialized = false;
  private authTokenInterval: NodeJS.Timeout | undefined;

  constructor(
    id: GraphId,
    name = '',
    groups: string[] = [],
    ownerEmail: string = '',
  ) {
    this.id = id;
    this.name = name;
    this.groups = groups;
    this.ownerEmail = ownerEmail;

    this.graphWorker = new GraphWorker();
    this.graphWorker.onmessage = this.handleGraphWorkerMessage;
    this.graphWorker.onerror = this.handleGraphWorkerErrorEvent;

    if (!crossOriginIsolated) {
      console.error(
        `The crossOriginIsolated flag is false!
The server needs to set these headers so we can use shared memory with the graph worker:
  'Cross-Origin-Embedder-Policy': 'require-corp',
  'Cross-Origin-Opener-Policy': 'same-origin',
`,
      );
      return;
    }

    makeAutoObservable(this, {
      id: false,
    });
  }

  /**
   * Always use this method to send messages to the GraphWorker so
   * we get proper type checking.
   */
  public postMessageToGraphWorker(message: GW_InData) {
    if (!this.initialized && message.type !== 'init') {
      // wait for initialization before sending messages
      when(
        () => this.initialized,
        () => {
          this.graphWorker.postMessage(message);
        },
        { timeout: 10000 },
      );
    } else {
      this.graphWorker.postMessage(message);
    }
  }

  private handleGraphWorkerMessage = async (event: GW_OutEvent) => {
    const { data } = event;
    switch (data.type) {
      case 'loaded':
        this.initGraphWorker();
        break;
      case 'views':
        await flowResult(this.updateViewsList(data.views));
        runInAction(() => {
          this.initialized = true;
        });
        break;
      case 'graphMetaData':
        this.applyGraphMetaData(data.graphMetaData);
        break;
      case 'viewNewBuffers':
        this.viewNewBuffers(data.sharedBuffers);
        break;
      case 'viewBuffersUpdated':
        this.viewBuffersUpdated(data.viewMetaData);
        break;
      case 'viewMetaData':
        this.applyViewMetaData(data.viewMetaData);
        break;
      case 'viewLabels':
        /**
         * Labels have to be handled separately from the other Node data because
         * because shared array buffers cannot handle variable-length data.
         */
        this.applyLabels(data.viewLabels);
        break;
      case 'undoState':
        runInAction(() => {
          this.hasUndos = data.undoState?.hasUndos ?? false;
          this.hasRedos = data.undoState?.hasRedos ?? false;
        });
        break;
      case 'error':
        this.handleGraphWorkerErrorMessage(data.error);
        break;
      case 'tool':
        this.handleToolOutput(data.toolOutput);
        break;
      case 'longRunningTask': {
        const { taskId, msg } = data.longRunningTask!;
        MonitoredTasks.instance.getTask(taskId)?.handleMessage(msg);
        break;
      }
    }
  };

  private handleGraphWorkerErrorEvent = (event: ErrorEvent) => {
    console.error('GraphWorker error:', event.message);
  };

  private handleGraphWorkerErrorMessage = (error?: GW_ErrorPayload) => {
    switch (error?.type) {
      case 'error':
        ErrorStore.setError(error.customMessage, error.availableAction);
        break;
      case 'warning':
        ErrorStore.setWarning(error.customMessage, error.availableAction);
        break;
      case 'success':
        ErrorStore.setSuccess(error.customMessage, error.availableAction);
        break;
    }
  };

  private initGraphWorker = async () => {
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    this.postMessageToGraphWorker({
      type: 'init',
      id: this.id,
      authToken: authToken ?? '',
    });

    // Refresh the auth token every 20 minutes
    runInAction(() => {
      this.authTokenInterval = setInterval(async () => {
        this.postMessageToGraphWorker({
          type: 'refreshAuthToken',
          authToken:
            (await fetchAuthSession()).tokens?.idToken?.toString() ?? '',
        });
      }, AUTH_TOKEN_REFRESH_INTERVAL);
    });
  };

  private applyGraphMetaData = (graphMetaData?: GraphMetaData) => {
    if (!graphMetaData) return;
    runInAction(() => {
      this.totalNodes = graphMetaData.totalNodes ?? 0;
      this.totalEdges = graphMetaData.totalEdges ?? 0;
      this.totalSelectedNodes = graphMetaData.totalSelectedNodes ?? 0;
      this.totalSelectedEdges = graphMetaData.totalSelectedEdges ?? 0;
      this.selectionUpdated();
    });
  };

  /**
   * Updates the meta data for a View that has come from the worker.
   */
  private applyViewMetaData = (metaData?: ViewMetaData) => {
    if (!metaData) return;
    const viewStore = this.viewStores.find(
      (viewStore) => viewStore.id === metaData.id,
    );
    if (!viewStore) return;

    viewStore.applyMetaData(metaData);

    if (
      metaData.totalSelectedNodes !== undefined ||
      metaData.totalHoveredNodes !== undefined
    ) {
      // Tell all the views to update their state attributes
      dashboardStore.openViews.forEach((viewStore) => {
        viewStore.stateAttributeNeedsUpdate();
      });

      if (metaData.totalSelectedNodes !== undefined) {
        this.chooseActiveViewAfterSelectionUpdate();
      }
    }
  };

  /**
   * Debounced version of _chooseActiveViewAfterSelectionUpdate to allow
   * multiple selection updates to be batched together.
   */
  private chooseActiveViewAfterSelectionUpdate = debounce(
    () => this._chooseActiveViewAfterSelectionUpdate(),
    undefined,
    500,
  );

  /**
   * After a selection update, if the currently active view has no selection
   * but there is a view that does, then make it the active view.
   */
  private _chooseActiveViewAfterSelectionUpdate = () => {
    if (this.currentViewStore?.anyNodesSelected) return;
    const viewStore = this.openViews.find(
      (viewStore) => viewStore.anyNodesSelected,
    );
    if (viewStore && viewStore !== this.currentViewStore) {
      dashboardStore.setCurrentViewStoreById(viewStore.id);
    }
  };

  /**
   * The worker has created new shared buffers for a specific view that
   * we need to bind to the GPU.
   */
  private viewNewBuffers = (newBuffers?: GW_SharedBuffers) => {
    if (!newBuffers) return;
    const viewStore = this.viewStores.find(
      (viewStore) => viewStore.id === newBuffers.viewId,
    );
    if (!viewStore) return;

    viewStore.bindBuffers(newBuffers);
  };

  /**
   * Called when worker has updated the shared buffers for a specific view.
   * The only info in the metaData we need is the viewId.
   *
   * todo: create a payload to specify which specific attributes (transform,
   * color, ...) have changed.
   */
  private viewBuffersUpdated = (metaData?: ViewMetaData) => {
    if (metaData) {
      const viewStore = this.viewStores.find(
        (viewStore) => viewStore.id === metaData.id,
      );
      if (!viewStore) return;
      viewStore.applyMetaData(metaData);
      viewStore.allAttributesNeedUpdate();
    }
  };

  /**
   * Updates the list of all the views in the Graph.
   *
   * viewDiffs will have the name and id of all of the views
   * in the graph, but none of the other meta data.
   */
  private *updateViewsList(viewsData?: ViewData[]) {
    if (viewsData === undefined) return;
    this.views = viewsData.map((viewData) => {
      return new BasicView(viewData.id, viewData.label, viewData.properties);
    });

    for (const viewData of viewsData) {
      const viewStore: ViewStore | undefined =
        yield this.getOrCreateViewStoreById(viewData.id);
      if (!viewStore) continue;
      viewStore.applyPropertiesData(viewData.properties);
      viewStore.label = viewData.label;
    }

    // Set creating viewstores so they display at once in the nav bar.
    runInAction(() => {
      this.dataLoaded = true;
    });

    const queryParams = new URLSearchParams(window.location.search);
    const queryViewIds = [...new Set(queryParams.getAll('viewId'))];

    const openViewIds = new Set(
      dashboardStore.openViews.map((view) => view.id),
    );
    const allViewIds = new Set(viewsData.map((view) => view.id));

    const viewsToOpen: ViewId[] = [];

    // If there are no views open, try to open the home view or the first spatial view.
    if (!this.initialized && queryViewIds.length === 0) {
      const homeView = this.views.find((view) => view.label === 'Home');
      const spatialView = this.views.find(
        (view) => view.label?.includes('Spatial'),
      );

      if (homeView) {
        viewsToOpen.push(homeView.id);
      } else if (spatialView) {
        viewsToOpen.push(spatialView.id);
      }
    }

    queryViewIds.forEach((viewId) => {
      // Open the view if it's in the query params and not already open
      if (!openViewIds.has(viewId) && allViewIds.has(viewId)) {
        viewsToOpen.push(viewId);
      }

      // Close any views that are not in the list of views.
      if (!allViewIds.has(viewId)) {
        dashboardStore.closeView(viewId);
      }
    });
    if (viewsToOpen.length) {
      dashboardStore.openMultipleViews(viewsToOpen);
    }
  }

  /**
   * Selecting Nodes
   *
   * Anything that needs to act on the all the currently selected nodes in the graph
   * should call await updateSelectedNodes() which will get the selected nodes from the worker
   * and update the selectedNodes observable.
   */

  public updateSelectedNodes = async (): Promise<void> => {
    const selectedNodes = await this.getSelectedNodesFromWorker();
    runInAction(() => {
      this.selectedNodes = selectedNodes;
    });
  };

  private getSelectedNodesFromWorker = (): Promise<Set<Node>> => {
    this.postMessageToGraphWorker({
      type: 'getSelectedNodes',
    });
    return new Promise<Set<Node>>((resolve, reject) => {
      const handler = (event: GW_OutEvent) => {
        const { data } = event;
        if (data.type === 'selectedNodes') {
          this.graphWorker.removeEventListener('message', handler);
          resolve(data.selectedNodes ?? new Set());
        }
      };

      // Give the worker 1 second to respond
      setTimeout(() => {
        this.graphWorker.removeEventListener('message', handler);
        reject('No response from Worker getting Selected Nodes.');
      }, 1000);

      this.graphWorker.addEventListener('message', handler);
    });
  };

  public getNodesForViewFromWorker = async (
    viewId: ViewId,
  ): Promise<Set<ViewNode>> => {
    this.postMessageToGraphWorker({
      type: 'getViewNodes',
      viewIds: [viewId],
    });

    return new Promise<Set<ViewNode>>((resolve, reject) => {
      const handler = (event: GW_OutEvent) => {
        const { data } = event;
        if (data.type === 'viewNodes') {
          this.graphWorker.removeEventListener('message', handler);
          resolve(data.viewSelectedNodes ?? new Set());
        }
      };

      // Give the worker 1 second to respond
      setTimeout(() => {
        this.graphWorker.removeEventListener('message', handler);
        reject('No response from Worker');
      }, 1000);

      this.graphWorker.addEventListener('message', handler);
    });
  };

  // Note: public so it can be called from the ViewStore
  public getSelectedNodesForViewFromWorker = async (
    viewId: ViewId,
  ): Promise<Set<ViewNode>> => {
    this.postMessageToGraphWorker({
      type: 'getSelectedNodes',
      viewIds: [viewId],
    });

    return new Promise<Set<ViewNode>>((resolve, reject) => {
      const handler = (event: GW_OutEvent) => {
        const { data } = event;
        if (data.type === 'viewSelectedNodes') {
          this.graphWorker.removeEventListener('message', handler);
          resolve(data.viewSelectedNodes ?? new Set());
        }
      };

      // Give the worker 1 second to respond
      setTimeout(() => {
        this.graphWorker.removeEventListener('message', handler);
        reject('No response from Worker');
      }, 1000);

      this.graphWorker.addEventListener('message', handler);
    });
  };

  setSelectedNodes = (
    nodes: Set<NodeId>,
    addToSelection?: boolean,
    toggleSelection?: boolean,
  ): void => {
    this.postMessageToGraphWorker({
      type: 'selectionInput',
      selectionInput: {
        action: 'byIds',
        nodeIds: nodes,
        toggleSelection,
        addToSelection,
      },
    });
  };

  get anyNodesSelected(): boolean {
    return this.totalSelectedNodes > 0;
  }

  get singleNodeSelected(): boolean {
    return this.totalSelectedNodes === 1;
  }

  get singleSelectedNode(): Node | undefined {
    if (!this.singleNodeSelected) return undefined;
    return this.selectedNodes.values().next().value;
  }

  get selectedNodeIds(): NodeId[] {
    return [...this.selectedNodes].map((node) => node.id);
  }

  /**
   * Hovered Nodes
   *
   * Anything that needs to act on the all the currently hovered nodes in the graph
   * should call await updateHoveredNodes() which will get the hovered nodes from the worker
   * and update the hoveredNodes observable.
   */

  public updateHoveredNodes = async (): Promise<void> => {
    const hoveredNodes = await this.getHoveredNodesFromWorker();
    runInAction(() => {
      this.hoveredNodes = hoveredNodes;
    });
  };

  private getHoveredNodesFromWorker = (): Promise<Set<NodeId>> => {
    this.postMessageToGraphWorker({
      type: 'getHoveredNodes',
    });
    return new Promise<Set<NodeId>>((resolve, reject) => {
      const handler = (event: GW_OutEvent) => {
        const { data } = event;
        if (data.type === 'hoveredNodes') {
          this.graphWorker.removeEventListener('message', handler);
          resolve(data.hoveredNodes ?? new Set());
        }
      };

      // Give the worker 1 second to respond
      setTimeout(() => {
        this.graphWorker.removeEventListener('message', handler);
        reject('No response from Worker getting Hovered Nodes.');
      }, 1000);

      this.graphWorker.addEventListener('message', handler);
    });
  };

  public getHoveredNodesForViewFromWorker = async (
    viewId: ViewId,
  ): Promise<Set<NodeId>> => {
    this.postMessageToGraphWorker({
      type: 'getHoveredNodes',
      viewIds: [viewId],
    });

    return new Promise<Set<NodeId>>((resolve, reject) => {
      const handler = (event: GW_OutEvent) => {
        const { data } = event;
        if (data.type === 'hoveredNodes') {
          this.graphWorker.removeEventListener('message', handler);
          resolve(data.hoveredNodes ?? new Set());
        }
      };

      // Give the worker 1 second to respond
      setTimeout(() => {
        this.graphWorker.removeEventListener('message', handler);
        reject('No response from Worker');
      }, 1000);

      this.graphWorker.addEventListener('message', handler);
    });
  };

  private applyLabels = (labelPayload?: GW_LabelPayload) => {
    if (!labelPayload) return;
    const viewStore = this.viewStores.find(
      (viewStore) => viewStore.id === labelPayload.id,
    );
    if (!viewStore) return;

    viewStore.applyLabels(labelPayload.labels);
  };

  public updateName(name: string): void {
    runInAction(() => {
      this.name = name;
    });
    const diff = GDiff.emptyGraphDiff();
    GDiff.addNode(diff, this.id, { label: name });
    this.applyDiff(diff);
  }

  public close(): void {
    this.authTokenInterval && clearInterval(this.authTokenInterval);
    this.currentViewStore = undefined;
    this.viewStores.forEach((viewStore) => viewStore.close());
    this.graphWorker.terminate();
  }

  /**
   * The list of ViewStores that have been opened by the user.
   */
  viewStores: ViewStore[] = [];

  get openViews(): ViewStore[] {
    return this.viewStores.filter((view) => view.open);
  }

  /**
   * The currently selected View
   */
  currentViewStore: ViewStore | undefined;

  /**
   * Create a new view with the given name and id if they're provided.
   */
  newView = async (
    label?: string,
    xLabel: string = VIEW_X_AXIS,
    yLabel: string = VIEW_Y_AXIS,
  ): Promise<ViewStore | undefined> => {
    if (!label) {
      const nthViewName = (n: number) => `${labelPrefix}${n}`;

      const labelPrefix = 'Untitled View '; // note the space
      const untitledViews =
        this.views?.filter((view) => view.label?.startsWith(labelPrefix)) ?? [];

      let n = 0;
      for (; n < untitledViews.length; n++) {
        if (!untitledViews.find((view) => view.label === nthViewName(n))) {
          break;
        }
      }
      label = nthViewName(n);
    }

    const response = await DataService.createView(
      this.id,
      label,
      xLabel,
      yLabel,
    );
    if (!response) return;
    const [viewId, gDiff] = response;
    this.applyDiff(gDiff);

    // Wait for the worker to add the view.
    await when(() => this.views.some((v) => v.id === viewId), {
      timeout: 1000,
    });

    /**
     * We need to send this because the ViewStore didn't exist when
     * the diff was sent to the worker.
     */
    this.postMessageToGraphWorker({
      type: 'getViewMetaData',
      viewIds: [viewId],
    });

    const viewStore = await dashboardStore.openSingleView(viewId);

    return viewStore;
  };

  /**
   * Create a new view with the current selection.
   */
  newViewFromCurrentSelection = async (): Promise<void> => {
    const nodes = new Set<ViewNode>();
    for (const view of this.openViews) {
      if (view.anyNodesSelected) {
        await view.updateSelectedNodes();
        view.selectedNodes.forEach((node) => {
          nodes.add(node);
        });
      }
    }

    const viewStore = await this.newView();
    if (!viewStore) return;
    if (!nodes.size) return;

    await viewStore.addNodes(nodes);
    viewStore.zoomWhenExtentsUpdate();
  };

  /**
   * Create a ViewStore for a view that already exists in the graph.
   *
   * - If the View is not in the Graph, return undefined.
   * - If the view is in the list of ViewStores we already have, return it.
   * - Otherwise, create a new ViewStore and return it.
   */
  async getOrCreateViewStoreById(viewId: ViewId) {
    // In case the worker is still loading the views
    await when(() => this.views.some((v) => v.id === viewId), {
      timeout: 1000,
    });

    const existingViewStore = this.viewStores.find(
      (view) => view.id === viewId,
    );
    if (existingViewStore) {
      return existingViewStore;
    }

    const newViewStore = new ViewStore(this, viewId);

    // check again after await point
    const existingViewStoreAgain = this.viewStores.find(
      (view) => view.id === viewId,
    );
    if (existingViewStoreAgain) {
      return existingViewStoreAgain;
    }
    if (newViewStore) {
      runInAction(() => {
        this.viewStores.push(newViewStore);
      });
    }
    return newViewStore;
  }

  async deleteViewById(viewId: ViewId): Promise<void> {
    await dashboardStore.closeView(viewId);
    runInAction(() => {
      this.viewStores = this.viewStores.filter((view) => view.id !== viewId);
    });

    const diff: GraphDiff = GDiff.emptyGraphDiff();
    GDiff.deleteEdge(diff, this.id, viewId);

    this.applyDiff(diff);
  }

  /**
   * Apply a diff to the graph.
   */

  applyDiff(diff: GraphDiff, notifyAPI: boolean = true): void {
    this.postMessageToGraphWorker({
      type: 'graphDiff',
      graphDiff: diff,
      options: { notifyAPI },
    });
  }

  /**
   * Selection
   */

  selectNoneNodes(): void {
    this.postMessageToGraphWorker({
      type: 'selectionInput',
      selectionInput: { action: 'none' },
    });
  }

  selectNodesByIds(nodeIds: Set<NodeId>): void {
    this.postMessageToGraphWorker({
      type: 'selectionInput',
      selectionInput: {
        action: 'byIds',
        nodeIds,
      },
    });
  }

  selectNeighbors(
    neighborQueryType: NeighborQueryType,
    intersection = true,
    addToSelection = false,
  ): void {
    this.postMessageToGraphWorker({
      type: 'selectionInput',
      selectionInput: {
        action: 'neighbors',
        intersection,
        addToSelection,
        query: neighborQueryType,
      },
    });
  }

  // Todo: add regex
  selectNodesBySearchString(searchString: string): void {
    const searchStringLower = searchString.trim().toLowerCase();
    this.postMessageToGraphWorker({
      type: 'selectionInput',
      selectionInput: {
        action: 'searchByString',
        query: searchStringLower,
      },
    });
  }

  /**
   * Edges
   */

  /**
   * Add Edges from all the nodes in set A to set B
   */
  addEdgesBetweenNodes(fromNodes: Set<NodeId>, toNodes: Set<NodeId>): void {
    if (!fromNodes.size || !toNodes.size) return;
    const diff = GDiff.emptyGraphDiff();
    fromNodes.forEach((from) => {
      toNodes.forEach((to) => {
        GDiff.addEdge(diff, from, to, 1);
      });
    });
    this.applyDiff(diff);
  }

  /**
   * Node properties
   */
  updateNodeLabels = async (
    nodes: Set<Node>,
    newState: boolean,
  ): Promise<void> => {
    const diff = GDiff.emptyGraphDiff();
    nodes.forEach((node) => {
      GDiff.addNode(diff, node.id, { showLabel: newState });
    });
    this.applyDiff(diff);
  };

  updateSelectedNodesLabels = async (newState: boolean): Promise<void> => {
    if (this.anyNodesSelected) {
      await this.updateSelectedNodes();
      this.updateNodeLabels(this.selectedNodes, newState);
    }
  };

  /*
   * Toggle labels for all selected nodes if any are selected or all nodes
   * in the active view if none are selected. If all nodes have the same
   * showLabel they are toggled otherwise they are all turned on.
   */
  toggleLabels = async (): Promise<void> => {
    const diff = GDiff.emptyGraphDiff();
    let updateSet: Set<Node>;
    if (!this.anyNodesSelected) {
      if (!this.currentViewStore) return;
      await this.currentViewStore.updateNodes();
      updateSet = new Set();
      this.currentViewStore.nodes.forEach((viewNode) => {
        updateSet.add(viewNode.node);
      });
    } else {
      await this.updateSelectedNodes();
      updateSet = this.selectedNodes;
    }
    if (!updateSet.size) return;

    const first = updateSet.values().next().value.showLabel;
    let toggle = true;
    for (const node of updateSet) {
      if (node.showLabel !== first) {
        toggle = false;
        break;
      }
    }
    updateSet.forEach((node) => {
      GDiff.addNode(diff, node.id, {
        showLabel: toggle ? !node.showLabel : true,
      });
    });
    this.applyDiff(diff);
  };

  /**
   * Set the color of the selected Nodes.
   */
  setSelectedColor = async (r: number, g: number, b: number): Promise<void> => {
    if (!this.anyNodesSelected) return;
    await this.updateSelectedNodes();
    runInAction(() => {
      this.setNodesColor(this.selectedNodeIds, r, g, b);
    });
  };

  setNodesColor = async (
    nodes: NodeId[] | Set<NodeId>,
    red: number,
    green: number,
    blue: number,
  ): Promise<void> => {
    const diff = GDiff.emptyGraphDiff();
    nodes.forEach((node) => {
      GDiff.addNode(diff, node, { red, green, blue });
    });
    this.applyDiff(diff);
  };

  /**
   * Color by
   */
  *colorSuccessorsByWeight(reversed: boolean) {
    if (!this.singleNodeSelected) {
      return;
    }
    yield this.updateSelectedNodes();
    const graphDiff: GraphDiff | undefined = yield DataService.colorBy(
      this.id,
      'node',
      [],
      undefined,
      this.singleSelectedNode?.id,
      reversed,
    );
    if (graphDiff) {
      this.applyDiff(graphDiff, false);
    }
  }

  *colorByWeight(
    axisNodeId: NodeId | undefined,
    viewId: ViewId | undefined,
    reversed = false,
  ) {
    if (!axisNodeId) return;
    let selectedNodeIds = undefined;
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      selectedNodeIds = this.selectedNodeIds;
    }
    const graphDiff: GraphDiff | undefined = yield DataService.colorBy(
      this.id,
      'node',
      selectedNodeIds,
      viewId,
      axisNodeId,
      reversed,
    );
    if (graphDiff) {
      this.applyDiff(graphDiff, false);
    }
  }

  *colorByGroup() {
    let selectedNodeIds = undefined;
    let viewId = undefined;
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      selectedNodeIds = this.selectedNodeIds;
    } else {
      viewId = this.currentViewStore?.id;
    }
    const graphDiff: GraphDiff = yield DataService.colorBy(
      this.id,
      'group',
      selectedNodeIds,
      viewId,
    );
    if (!graphDiff) return;
    this.applyDiff(graphDiff, false);
  }

  /**
   * Set the size of the selected Nodes.
   */
  setSelectedSize = async (size: number): Promise<void> => {
    if (!this.anyNodesSelected) return;
    await this.updateSelectedNodes();
    const diff = GDiff.emptyGraphDiff();
    this.selectedNodes.forEach((node) => {
      GDiff.addNode(diff, node.id, { size });
    });
    this.applyDiff(diff);
  };

  /**
   * Size by
   */
  *sizeSelectedByWeight() {
    if (!this.singleNodeSelected) {
      return;
    }
    yield this.updateSelectedNodes();
    if (!this.singleSelectedNode) return;
    yield this.sizeByWeight(this.singleSelectedNode.id);
  }

  sizeByWeight = async (
    nodeId: NodeId,
    reverse: boolean = false,
  ): Promise<void> => {
    const gDiff = await DataService.sizeBy(this.id, nodeId, reverse);
    if (gDiff) {
      this.applyDiff(gDiff, false);
    }
  };

  /**
   * Undo/Redo
   */

  hasUndos = false;
  hasRedos = false;

  undo() {
    this.postMessageToGraphWorker({
      type: 'undo',
    });
  }

  redo() {
    this.postMessageToGraphWorker({
      type: 'redo',
    });
  }

  /**
   * Tools
   */
  handleToolOutput = (toolOutput?: ToolOutput) => {
    if (!toolOutput) return;
    if (toolOutput === 'dataFusion') return;
    if (toolOutput === 'layout') return;
    if (toolOutput === 'propagate') return;
    if ('patternFinding' in toolOutput) return;
    if ('predict' in toolOutput) return; // see "FitsDrawerStore::fit"
    if ('inspect' in toolOutput) {
      dashboardStore.inspectDrawerStore.updateWithData(toolOutput.inspect);
    } else if ('describe' in toolOutput) {
      dashboardStore.rightDrawerStore.describerStore.updateWithData(
        toolOutput.describe,
      );
    } else if ('nodeStore' in toolOutput) {
      if ('display' in toolOutput.nodeStore) {
        dashboardStore.nodeStores
          .get(toolOutput.nodeStore.display.storeId)
          ?.handleResponse(toolOutput.nodeStore);
      }
    } else if ('pythonAgent' in toolOutput) {
      if ('pythonLLMResponse' in toolOutput.pythonAgent) {
        dashboardStore.dataRetrievalAgentStore.handlePythonLLMResponse(
          toolOutput.pythonAgent.pythonLLMResponse,
        );
      }
      if ('pythonCodeResponse' in toolOutput.pythonAgent) {
        dashboardStore.dataRetrievalAgentStore.handlePythonCodeResponse(
          toolOutput.pythonAgent.pythonCodeResponse,
        );
      }
    } else if ('sqlAgent' in toolOutput) {
      dashboardStore.dataRetrievalAgentStore.handleResponse(
        toolOutput.sqlAgent,
      );
    }
  };
}

/**
 * Basic data for a view in the graph that may be open or closed.
 *
 * See `ViewStore` for a view that is open.
 */
export class BasicView {
  readonly id: ViewId;
  readonly label: string;
  readonly properties: ViewProperties;

  constructor(id: ViewId, label: string, properties: ViewProperties) {
    this.id = id;
    this.label = label;
    this.properties = properties;
    makeAutoObservable(this);
  }
}
