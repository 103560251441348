import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import { GridOn, GridOff, BorderStyle } from '@mui/icons-material';
import ViewStore from '../stores/ViewStore';

const icon = (showAxes: boolean, showGrid: boolean) => {
  if (showAxes && showGrid) {
    return <GridOn />;
  } else if (showAxes) {
    return <BorderStyle sx={{ transform: 'rotate(-90deg)' }} />;
  } else {
    return <GridOff />;
  }
};

const GridOptionsButton = observer(
  ({ viewStore }: { viewStore: ViewStore }): JSX.Element => {
    const styles = {
      container: {
        display: 'flex',
        justifyContent: 'center',
      },
      iconButton: {
        padding: 0,
        backgroundColor: 'transparent',
        fontSize: 18,
      },
    };

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    return (
      <Box sx={styles.container}>
        <Tooltip title="Show / Hide Grid" arrow>
          <IconButton
            color={
              viewStore.showGrid || viewStore.showRulers ? 'primary' : 'default'
            }
            sx={styles.iconButton}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
              setPopoverOpen(true);
            }}
            size="large"
          >
            {icon(viewStore.showRulers, viewStore.showGrid)}
          </IconButton>
        </Tooltip>

        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={() => setPopoverOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title="Hide Grid">
              <IconButton
                color="default"
                sx={styles.iconButton}
                onClick={() => {
                  viewStore.updateViewProperties({
                    showAxes: false,
                    showGrid: false,
                  });
                  setPopoverOpen(false);
                }}
                size="large"
              >
                <GridOff />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rulers Only">
              <IconButton
                color="primary"
                sx={styles.iconButton}
                onClick={() => {
                  viewStore.updateViewProperties({
                    showAxes: true,
                    showGrid: false,
                  });
                  setPopoverOpen(false);
                }}
                size="large"
              >
                <BorderStyle sx={{ transform: 'rotate(-90deg)' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Grid & Axes">
              <IconButton
                color="primary"
                sx={styles.iconButton}
                onClick={() => {
                  viewStore.updateViewProperties({
                    showGrid: true,
                    showAxes: true,
                  });
                  setPopoverOpen(false);
                }}
                size="large"
              >
                <GridOn />
              </IconButton>
            </Tooltip>
          </Box>
        </Popover>
      </Box>
    );
  },
);
export default GridOptionsButton;
