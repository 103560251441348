import * as THREE from 'three';
import { MapView, BingMapsProvider } from 'geo-three';

import ViewStore from '../stores/ViewStore';
import { CoordinateTransform } from '../utils/coordinateTransforms';
// TODO: Override GoogleMapsProvider URL forming to use Google Maps API.

export default class MapEngine {
  private viewStore: ViewStore;
  private scene: THREE.Scene;
  private camera: THREE.Camera;
  private renderer: THREE.Renderer;

  constructor(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    viewStore: ViewStore,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.viewStore = viewStore;

    // Provider handles API calls to Bing Maps.
    const provider = new BingMapsProvider(
      process.env.REACT_APP_BING_MAPS_API_KEY,
      BingMapsProvider.ROAD,
    );
    provider.mapSize = 512;
    provider.format = 'png';
    provider.minZoom = 1;
    provider.maxZoom = 19;

    // Map object uses the provider to display the tiles.
    const map = new MapView(MapView.PLANAR, provider);
    map.scale.set(360, 1, 360);
    map.rotation.x = Math.PI / 2;
    map.rotation.y = 0;
    map.rotation.z = 0;
    map.position.set(0, 0, 0);

    this.scene.add(map);
  }

  render = (): void => {
    if (
      this.viewStore.coordinateTransform === CoordinateTransform.WebMercator
    ) {
      this.renderer.render(this.scene, this.camera);
    }
  };

  destroy(): void {
    // todo: clean up reactions
  }
}
