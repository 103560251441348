import { makeAutoObservable } from 'mobx';
import { InAppHelpItem } from '../in-app-help/InAppHelp';
import ErrorStore from './ErrorStore';
import dashboardStore from './DashboardStore';
import DataService from '../services/DataService';

export default class InAppHelpStore {
  name: string;
  items: InAppHelpItem[];
  public enabled: boolean = false;

  constructor(name: string, items: InAppHelpItem[]) {
    this.name = name;
    this.items = items;
    makeAutoObservable(this);
  }

  hasJustStarted(): boolean {
    return this.getActiveItemIndex() === 0;
  }

  hasJustFinished(): boolean {
    return this.getActiveItemIndex() + 1 === this.items.length;
  }

  getItems(): InAppHelpItem[] {
    return this.items;
  }

  getItemsCount(): number {
    return this.items.length;
  }

  getItemByName(name: string): InAppHelpItem | undefined {
    return this.items.find((item) => item.name === name);
  }

  getItemByIndex(index: number): InAppHelpItem {
    return this.items[index];
  }

  getActiveItemIndex(): number {
    return this.items.findIndex((item) => item.enabled);
  }

  start(): void {
    this.enabled = true;
    this.items.forEach((item, index) => {
      if (index === 0) {
        item.enabled = true;
      } else {
        item.enabled = false;
      }
    });
  }

  stop(): void {
    this.enabled = false;
    this.items.forEach((item) => {
      item.enabled = false;
    });
  }

  next(): void {
    const currentIndex = this.getActiveItemIndex();
    if (currentIndex === undefined) {
      return;
    }
    if (currentIndex === this.items.length - 1) {
      return;
    }
    this.items[currentIndex].enabled = false;
    this.items[currentIndex + 1].enabled = true;
  }

  back(): void {
    const currentIndex = this.getActiveItemIndex();
    if (currentIndex === undefined) {
      return;
    }

    if (currentIndex === 0) {
      return;
    }
    this.items[currentIndex].enabled = false;
    this.items[currentIndex - 1].enabled = true;
  }

  async complete(): Promise<void> {
    const currentGraphStore = dashboardStore.currentGraphStore;
    if (!currentGraphStore) {
      return;
    }
    // Stop the in app help
    this.stop();
    dashboardStore.loadingBackdropStore.openBackdrop();

    // Update the onboarding status for the current user
    const response = await DataService.updateOnboardingHelpStatus();

    if (!response) {
      dashboardStore.loadingBackdropStore.closeBackdrop();
      ErrorStore.setError(`Failed to update onboarding status.`);
      return;
    }

    // Close the loading backdrop and show a success message
    dashboardStore.loadingBackdropStore.closeBackdrop();
    ErrorStore.setSuccess(
      'Onboarding tour complete! You can restart at any point from the Help ? menu.',
    );
  }

  async userHasCompleted(): Promise<boolean | undefined> {
    const completed = DataService.getOnboardingStatus();

    if (!completed) {
      ErrorStore.setError('Failed to get onboarding status.');
      return;
    }

    return completed;
  }
}
