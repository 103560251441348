import { makeAutoObservable } from 'mobx';
import { viewTypeDisplay } from 'wasm_service';
import { ViewSortType } from '../types/app';
import dashboardStore from './DashboardStore';
import ViewStore from './ViewStore';

class NavigationStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _query = '';
  get query(): string {
    return this._query;
  }
  set query(newState: string) {
    this._query = newState;
  }

  private _open = true;
  get open(): boolean {
    return this._open;
  }
  set open(newState: boolean) {
    this._open = newState;
  }

  private _sortBy: ViewSortType = 'Name';
  get sortBy(): ViewSortType {
    return this._sortBy;
  }
  set sortBy(newState: ViewSortType) {
    this._sortBy = newState;
  }

  get dataLoaded(): boolean {
    return dashboardStore.currentGraphStore?.dataLoaded ?? false;
  }

  get filteredViews(): ViewStore[] {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return [];
    const query = dashboardStore.navigationStore.query;
    let filteredViews =
      query === ''
        ? [...graphStore.viewStores]
        : graphStore.viewStores.filter((view) =>
            view.label.toLowerCase().includes(query.toLowerCase()),
          );

    if (this.sortBy === ('Name' as ViewSortType)) {
      filteredViews = filteredViews.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
    } else if (this.sortBy === ('Created Time' as ViewSortType)) {
      filteredViews = filteredViews.sort(
        (a, b) => b.createdTimestamp - a.createdTimestamp, // newest first
      );
    }

    return filteredViews;
  }

  static viewTypeDisplay(view: ViewStore): string | undefined {
    return viewTypeDisplay(view.viewType);
  }
}

export default NavigationStore;
