import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function AxesConnected() {
  const strokeWidth = 1.5;

  const width = 24;
  const height = 24;
  const gutter = 3;

  const rectLength = 8;
  const rectWidth = 6;

  const fill = 'rgb(216, 216, 216)';

  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={`0 0 ${width} ${height}`}
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect
          x={gutter}
          y={gutter}
          width={rectWidth}
          height={rectLength}
          fill={fill}
          stroke="none"
          paintOrder="stroke"
        />
        <path
          d={`
            M ${gutter} ${gutter}
            L ${gutter} ${gutter + rectLength}
            L ${gutter + rectWidth} ${gutter + rectLength}
            L ${gutter + rectWidth} ${gutter}
          `}
        />

        <rect
          x={width - gutter - rectLength}
          y={height - gutter - rectWidth}
          width={rectLength}
          height={rectWidth}
          fill={fill}
          stroke="none"
          paintOrder="stroke"
        />
        <path
          d={`
            M ${width - gutter} ${height - gutter}
            L ${width - gutter - rectLength} ${height - gutter}
            L ${width - gutter - rectLength} ${height - gutter - rectWidth}
            L ${width - gutter} ${height - gutter - rectWidth}
          `}
        />

        <path
          d={`
            M ${gutter + rectWidth / 2} ${gutter + rectLength}
            L ${gutter + rectWidth / 2} ${height - gutter - rectWidth / 2}
            L ${width - gutter - rectLength} ${height - gutter - rectWidth / 2}
          `}
        />
      </svg>
    </SvgIcon>
  );
}
