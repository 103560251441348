import { makeAutoObservable, runInAction } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { NodeId } from '../types/app';
import { DescribeOutput } from '../types/wasm';
import ErrorStore from './ErrorStore';
import { debounce } from '../utils/utils';
import { MultiNodeStore } from './NodeStore';
import { withDashboardBackdrop } from '../utils/backdrop';
import DataService from '../services/DataService';

const STD_COLUMN_WEIGHT_ABS_THRESHOLD = 1.0;

export class DescriberStore {
  title = 'Describe Nodes';

  public isLoading = false;
  public data: DescribeOutput;

  constructor() {
    this.data = [];
    makeAutoObservable(this);
  }

  private _open = false;
  get open(): boolean {
    return this._open;
  }
  set open(newState: boolean) {
    this._open = newState;
  }

  private _showSettings = false;
  get showSettings(): boolean {
    return this._showSettings;
  }
  set showSettings(show: boolean) {
    this._showSettings = show;
  }

  private _page: number = 0;
  get page(): number {
    return this._page;
  }
  set page(p: number) {
    this._page = p;
  }

  private _linear = true;
  set linear(linear: boolean) {
    this._linear = linear;
    this.clear();
    this.update();
  }

  get linear(): boolean {
    return this._linear;
  }

  backgroundNodes = new MultiNodeStore();
  predecessorNodes = new MultiNodeStore();
  ignoredPredecessorNodes = new MultiNodeStore();

  addIgnoredPredecessorsFromView = (): void => {
    if (
      dashboardStore.currentViewStore === undefined ||
      dashboardStore.currentViewStore.xNode === undefined ||
      dashboardStore.currentViewStore.yNode === undefined
    ) {
      return;
    }
    const nodes: Set<NodeId> = new Set();
    nodes.add(dashboardStore.currentViewStore.xNode.id);
    nodes.add(dashboardStore.currentViewStore.yNode.id);
    this.ignoredPredecessorNodes.updateByIds(nodes);
  };

  get viewIsDisabled(): boolean {
    return this.data.length === 0;
  }

  clear = (): void => {
    this.data = [];
  };

  updateWithData = (data: DescribeOutput): void => {
    this.data = data;
    this.isLoading = false;
  };

  update = debounce(() => this.runUpdateStats(), undefined, 100);
  private runUpdateStats() {
    if (!this.open) return;
    if (!dashboardStore.rightDrawerStore.open) return;
    if (!dashboardStore.anyNodesSelected) return;
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    const currentViewStore = graphStore.currentViewStore;
    if (!currentViewStore) return;

    this.isLoading = true;
    runInAction(() => {
      graphStore.postMessageToGraphWorker({
        type: 'tool',
        toolInput: {
          describe: {
            viewId: currentViewStore.id,
            backgroundStoreId: this.backgroundNodes.storeId,
            predecessorStoreId: this.predecessorNodes.storeId,
            ignoredPredecessorStoreId: this.ignoredPredecessorNodes.storeId,
            linear: this.linear,
          },
        },
      });
    });
  }

  get describingData(): [string, number][] {
    // Iterate through this.data and return an array of [label, importance]
    // filtering out each element where the absolute value of importance is less
    // than STD_COLUMN_WEIGHT_ABS_THRESHOLD
    return this.data
      .filter(
        (d) =>
          Math.abs(d.importance) >
          (this.linear ? STD_COLUMN_WEIGHT_ABS_THRESHOLD : 0.0),
      )
      .map((d) => [d.featureLabel, d.importance]);
  }

  get linearPaddedDescribingData(): [string | undefined, number][] {
    // Get an array of the first three elements of describingData and the
    // last three elements of describingData, padding with an empty string and 0.0 where necessary
    const describingData = this.describingData;
    const len = describingData.length;
    if (len === 0) {
      // Return an array of 7 empty strings and 0.0
      const result: [string | undefined, number][] = [];
      for (let i = 0; i < 7; i++) {
        result.push([undefined, 0.0] as [string | undefined, number]);
      }
      return result;
    } else if (len == 1) {
      // Return an array of the first element followed by 7 empty strings
      const result: [string | undefined, number][] = [];
      result.push(describingData[0]);
      for (let i = 0; i < 6; i++) {
        result.push([undefined, 0.0] as [string | undefined, number]);
      }
      return result;
    } else if (len <= 6) {
      // Insert ['', 0.0] in the middle of this array repeatedly until it is of length 7
      const halfLen = Math.floor(len / 2);
      const firstHalf = describingData.slice(0, halfLen);
      const secondHalf = describingData.slice(halfLen, len);
      const result: [string | undefined, number][] = [];
      for (let i = 0; i < 7 - len; i++) {
        result.push([undefined, 0.0] as [string | undefined, number]);
      }
      return [...firstHalf, ...result, ...secondHalf];
    }
    return [
      ...describingData.slice(0, 3),
      [undefined, 0.0],
      ...describingData.slice(len - 3, len),
    ];
  }

  get paddedDescribingData(): [string | undefined, number][] {
    if (this.linear) {
      return this.linearPaddedDescribingData;
    } else {
      return this.describingData
        .slice(0, 7)
        .concat(Array(7).fill([undefined, 0.0]))
        .slice(0, 7);
    }
  }

  createViewFromData = withDashboardBackdrop(async (): Promise<void> => {
    if (!dashboardStore.currentGraphStore) return;
    const graphStore = dashboardStore.currentGraphStore;
    if (this.data.length == 0) {
      ErrorStore.setError('Cannot create view from empty data array.');
      return;
    }

    const response = await DataService.getDescribingView(
      graphStore.id,
      this.data.map((d) => [d.featureId, d.importance] as [string, number]),
    );
    if (!response) {
      return;
    }

    const [viewId, diff] = response;
    graphStore.applyDiff(diff);
    await dashboardStore.openSingleView(viewId);
  });
}
