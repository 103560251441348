export default `
precision highp float;

uniform mat4 modelViewMatrix; // Model-View matrix
uniform mat4 projectionMatrix; // Projection matrix

uniform vec3 axisScale; // Independent axis scaling which is applied to the translation
uniform vec3 mousePoint;

in vec3 position;

/**
 * THREE.LineSegments expects 2 vertices per call. We fill the buffer with
 * every other vertex being a dummy. This flag indicates whether this vertex
 * should use the position attribute or the mousePoint uniform.
 */
in float useMousePoint;

void main() {
  vec3 endPoint = position;

  if (useMousePoint > 0.5) {
    endPoint = mousePoint;
  }

  endPoint *= axisScale;

  gl_Position = projectionMatrix * modelViewMatrix * vec4(endPoint, 1.0);
}
`;
