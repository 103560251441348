import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  useTheme,
  Box,
  Button,
  Dialog,
  DialogTitle,
  MobileStepper,
  Typography,
  CircularProgress,
} from '@mui/material';
import dashboardStore from '../stores/DashboardStore';
import { CarouselItem } from '../stores/CarouselStore';
import NoMediaImage from '../images/no-media.png';

const MediaContent = ({ item }: { item: CarouselItem | undefined }) => {
  const theme = useTheme();
  const styles = {
    noMediaContent: {
      display: 'flex',
      paddingBottom: '40px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noMediaTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: theme.palette.grey[700],
    },
    noMediaSubtitle: {
      fontSize: '12px',
      color: theme.palette.grey[500],
    },
  };

  if (!item) {
    return (
      <Box sx={styles.noMediaContent}>
        <img src={NoMediaImage} alt="no-media" height="120"></img>
        <Typography variant="body2" sx={styles.noMediaTitle}>
          No available media
        </Typography>
        <Typography variant="body2" sx={styles.noMediaSubtitle}>
          Please select different nodes to watch media
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {item.type === 'video' ? (
        <video
          autoPlay
          muted
          controls
          src={item.signedURL}
          style={{
            maxWidth: '75vw',
            maxHeight: '75vh',
            objectFit: 'contain',
          }}
          crossOrigin="anonymous"
        >
          Your browser does not support this HTML5 video element.
        </video>
      ) : (
        <img
          src={item.signedURL}
          style={{
            maxWidth: '75vw',
            maxHeight: '75vh',
            objectFit: 'contain',
          }}
          alt="carousel item"
          crossOrigin="anonymous"
        />
      )}
    </>
  );
};

const Carousel = observer((): JSX.Element => {
  const theme = useTheme();
  const store = dashboardStore.carouselStore;
  const index = store.index;
  const item = index < store.items.length ? store.items[index] : undefined;
  const total = store.items.length;

  const styles = {
    stepperContainer: {
      maxWidth: '100%',
      flexGrow: 10,
    },
    stepperButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    mediaContainer: {
      maxWidth: '100%',
      maxHeight: '100%',
      minWidth: '350px',
      minHeight: '200px',
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const handleNext = () => {
    store.index = index >= total - 1 ? 0 : index + 1;
  };

  const handleBack = () => {
    store.index = index <= 0 ? total - 1 : index - 1;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowRight') {
      handleNext();
    } else if (e.key === 'ArrowLeft') {
      handleBack();
    }
    e.stopPropagation();
  };

  return (
    <Dialog
      open={store.open}
      onClose={() => (store.open = false)}
      onKeyDown={handleKeyDown}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      disableEnforceFocus
    >
      {!store.isLoading ? (
        <>
          <DialogTitle id="form-dialog-title">{item?.label}</DialogTitle>
          {total > 1 && (
            <MobileStepper
              variant={total < 10 ? 'dots' : 'text'}
              steps={total}
              position="static"
              activeStep={index}
              sx={styles.stepperContainer}
              nextButton={
                <Box sx={styles.stepperButton}>
                  <Button size="small" onClick={handleNext}>
                    Next
                  </Button>
                </Box>
              }
              backButton={
                <Box sx={styles.stepperButton}>
                  <Button size="small" onClick={handleBack}>
                    Back
                  </Button>
                </Box>
              }
            />
          )}
          <Box
            sx={{
              ...styles.mediaContainer,
              backgroundColor: item ? theme.palette.grey[400] : '',
            }}
          >
            <MediaContent item={item} />
          </Box>
        </>
      ) : (
        <Box sx={{ ...styles.mediaContainer, alignItems: 'center' }}>
          <CircularProgress sx={{ color: theme.palette.secondary.main }} />
        </Box>
      )}
    </Dialog>
  );
});
export default Carousel;
