import GraphStore from '../stores/GraphStore';
import { GW_OutEvent } from '../types/graphWorker';
import { ToolOutput } from '../types/wasm';

/**
 * Returns a promise that resolves when the extractor function returns an output
 * for a tool message from the graph worker.
 * In case of a timeout, undefined is always returned
 */
export function waitForToolMessage<T>(
  graphStore: GraphStore,
  extractor: (data: ToolOutput) => T | undefined,
  timeout: number = 5000,
): Promise<T | undefined> {
  return new Promise<T | undefined>((resolve) => {
    const handler = (event: GW_OutEvent) => {
      const { data } = event;
      if (data.type === 'tool' && data.toolOutput) {
        const out = extractor(data.toolOutput);
        if (out !== undefined) {
          resolve(out);
        }
      }
    };

    setTimeout(() => {
      graphStore.graphWorker.removeEventListener('message', handler);
      resolve(undefined);
    }, timeout);

    graphStore.graphWorker.addEventListener('message', handler);
  });
}
