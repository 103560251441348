import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Tooltip, IconButton, useTheme } from '@mui/material';
import {
  TextFieldsTwoTone,
  TextRotationAngleupOutlined,
  Public,
  CalendarMonth,
} from '@mui/icons-material';

import AxesConnected from '../icons/AxesConnected';
import AxesDisconnected from '../icons/AxesDisconnected';
import dashboardStore from '../stores/DashboardStore';
import ViewStore from '../stores/ViewStore';
import { actionToButton } from './View';
import Actions from '../actions/actions';
import HelperToolTip from './HelpToolTip';
import { debounce } from '../utils/utils';

import GridOptionsButton from './GridOptionsButton';
import Slider from './Slider';
import { CoordinateTransform } from '../utils/coordinateTransforms';

interface Props {
  viewStore: ViewStore;
}

const ViewToolbar = observer(({ viewStore }: Props) => {
  const theme = useTheme();

  const styles = {
    iconButton: {
      padding: '0',
      backgroundColor: 'transparent',
      fontSize: '24px',
    },
  };

  const handleChangeLabelSize = debounce(
    (labelSize: number) => {
      dashboardStore.currentViewStore?.updateViewProperties({ labelSize });
    },
    false,
    100,
  );
  const handleChangeLabelAngle = debounce(
    (labelAngle: number) => {
      dashboardStore.currentViewStore?.updateViewProperties({ labelAngle });
    },
    false,
    100,
  );

  return (
    <HelperToolTip
      inAppHelpItem={dashboardStore.onboardingHelpStore.getItemByIndex(1)}
      inAppHelpStore={dashboardStore.onboardingHelpStore}
      allowOpen={viewStore.current}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0',
          backgroundColor: 'white',
          border: `1px solid ${theme.palette.grey[400]}`,
          borderLeft: 'none',
          borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
          boxShadow: 1,
        }}
      >
        {/* Main Tools */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '2px',
            fontSize: '24px',
          }}
        >
          {actionToButton(Actions.uniqueActions.toggleShowEdges)}
          <Slider
            name={'Label Size'}
            Icon={TextFieldsTwoTone}
            sliderStore={viewStore.labelSizeSlider}
            onChange={() =>
              handleChangeLabelSize(viewStore.labelSizeSlider.value)
            }
          />
          <Slider
            name={'Label Angle'}
            Icon={TextRotationAngleupOutlined}
            sliderStore={viewStore.labelAngleSlider}
            onChange={() =>
              handleChangeLabelAngle(viewStore.labelAngleSlider.value)
            }
          />
          <GridOptionsButton viewStore={viewStore} />

          <Tooltip
            title="Toggle whether the X and Y axes can scale independently"
            arrow
          >
            <IconButton
              sx={styles.iconButton}
              color={
                viewStore.showGrid || viewStore.showRulers
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                if (
                  viewStore.coordinateTransform ===
                  CoordinateTransform.WebMercator
                ) {
                  return;
                }
                if (!viewStore.showGrid && !viewStore.showRulers) {
                  viewStore.updateViewProperties({
                    showGrid: true,
                    showAxes: true,
                  });
                }
                if (viewStore.axesConnected) {
                  viewStore.updateViewProperties({ axesDisconnected: true });
                } else {
                  viewStore.xAxisScale = 1;
                  viewStore.yAxisScale = 1;
                  viewStore.updateViewProperties({ axesDisconnected: false });
                }
              }}
            >
              {viewStore.axesConnected ? (
                <AxesConnected font-size="inherit" />
              ) : (
                <AxesDisconnected font-size="inherit" />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title="Format temporal axis" arrow>
            <IconButton
              sx={styles.iconButton}
              color={!viewStore.datetimeFormat ? 'default' : 'primary'}
              onClick={() => {
                viewStore.toggleFormatTemporalAxis();
              }}
            >
              <CalendarMonth />
            </IconButton>
          </Tooltip>

          <Tooltip title="Show geospatial map" arrow>
            <IconButton
              sx={styles.iconButton}
              color={
                viewStore.coordinateTransform == CoordinateTransform.WebMercator
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                viewStore.toggleMapDisplay();
              }}
            >
              <Public />
            </IconButton>
          </Tooltip>

          {actionToButton(Actions.uniqueActions.zoomAll)}
          {actionToButton(Actions.uniqueActions.zoomSelected)}
          {actionToButton(Actions.uniqueActions.swapAxis)}
        </Box>
      </Box>
    </HelperToolTip>
  );
});
export default ViewToolbar;
