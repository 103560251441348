import { makeAutoObservable } from 'mobx';

class SliderStore {
  default: number;
  min: number;
  max: number;
  step: number;

  constructor(def = 0, min = 0.01, max = 2, step = 0.01) {
    this._value = def;
    this.default = def;
    this.min = min;
    this.max = max;
    this.step = step;

    makeAutoObservable(this);
  }

  private _value = 1;
  get value(): number {
    return this.step != 0
      ? Math.round(this._value / this.step) * this.step
      : this._value;
  }
  set value(newValue: number) {
    this._value = newValue;
  }

  private _sliderOpen = false;
  get sliderOpen(): boolean {
    return this._sliderOpen;
  }
  set sliderOpen(newState: boolean) {
    this._sliderOpen = newState;
  }

  private _sliderElement: Element | undefined = undefined;
  get sliderElement(): Element | undefined {
    return this._sliderElement;
  }
  set sliderElement(element: Element | undefined) {
    this._sliderElement = element;
  }
}
export default SliderStore;
