import React from 'react';
import { observer } from 'mobx-react-lite';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, Button, TextField, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import dashboardStore from '../stores/DashboardStore';
import Actions from '../actions/actions';

export const FeatureExtraction = observer((): JSX.Element => {
  const featureExtractionStore =
    dashboardStore.rightDrawerStore.featureExtractionStore;
  const theme = useTheme();
  const styles = {
    border: {
      label: 'featureExtractionBorder',
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      marginBottom: `5px`,
    },
    transformationTitle: {
      label: 'RightDrawer-transformationTitle',
      fontSize: '14px',
      margin: `5px`,
    },
    iconButton: {
      borderRadius: '50%',
      color: theme.palette.grey[600],
      overflow: 'hidden',
      '&:hover': {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };
  const switchOptions = [
    { label: 'On selected', value: false },
    { label: 'As features', value: true },
  ];

  return (
    <>
      <Box sx={{ marginBottom: '5px' }}>
        <SwitchButton
          options={switchOptions}
          value={featureExtractionStore.fromHeaders}
          onChange={(option: SwitchOption) => {
            featureExtractionStore.fromHeaders = option.value as boolean;
          }}
        />
      </Box>
      <Box sx={styles.border}>
        <Box sx={{ margin: '3px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={styles.transformationTitle}>Extract String Features</Box>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                Actions.uniqueActions.featureExtractionDocs.action();
              }}
              sx={styles.iconButton}
            >
              <QuestionMarkIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Box>
            <TextField
              multiline
              fullWidth
              label="Prompt (optional)"
              variant="outlined"
              value={featureExtractionStore.textBox}
              onChange={(e) => {
                featureExtractionStore.textBox = e.target.value;
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  featureExtractionStore.runExtractStringFeatures();
                }}
                disabled={featureExtractionStore.disableButton}
                sx={{
                  fontSize: '10px',
                  marginTop: '10px',
                  textTransform: 'none',
                }}
              >
                Extract Features
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.border}>
        <Box sx={{ margin: '3px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={styles.transformationTitle}>Extract Coordinates</Box>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                Actions.uniqueActions.geocodingDocs.action();
              }}
              sx={styles.iconButton}
            >
              <QuestionMarkIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => featureExtractionStore.runGeocoding()}
              disabled={featureExtractionStore.disableGeocoding}
              sx={{
                fontSize: '10px',
                marginTop: '10px',
                textTransform: 'none',
              }}
            >
              Extract Coordinates
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
});
