import {
  autorun,
  makeAutoObservable,
  reaction,
  runInAction,
  when,
  IReactionDisposer,
} from 'mobx';
import {
  GraphDiff,
  IExtents,
  MetaNode,
  MouseCoordinate,
  NeighborQueryType,
  NodeId,
  Point,
  SortType,
  ViewProperties,
  ViewId,
  ViewMetaData,
  ViewType,
  ViewPropertiesUpdate,
} from '../types/app';
import { LayoutEnum } from 'wasm_service';
import ErrorStore from '../stores/ErrorStore';
import clipboardStore from '../stores/ClipboardStore';

import { GW_Label, GW_SharedBuffers } from '../types/graphWorker';
import { ViewAction } from '../types/wasm';
import { Extents } from '../utils/UtilityClasses';
import {
  toIntrinsicCoordinates,
  toExtrinsicCoordinates,
} from '../utils/coordinateTransforms';

import ViewNode from '../models/ViewNode';
import { CoordinateTransform } from '../utils/coordinateTransforms';
import DataService from '../services/DataService';
import GraphStore from '../stores/GraphStore';
import SliderStore from './SliderStore';
import dashboardStore from '../stores/DashboardStore';
import ThreeView from '../three/ThreeView';
import * as GDiff from '../utils/graphDiffHelper';
import LoadingBackdropStore from './LoadingBackdropStore';
import { waitForToolMessage } from '../tools';
import { NodeStates } from '../models/NodeModel';
import AlertDialogStore from './AlertDialogStore';

// todo: move to UtilityClasses
export class Vector3 {
  x: number;
  y: number;
  z: number;
  constructor(x = 0, y = 0, z = 0) {
    this.x = x;
    this.y = y;
    this.z = z;
    makeAutoObservable(this);
  }
}

// TODO: Convert to a traditional State Machine
export enum DragState {
  NONE,
  MOVING_CAMERA,
  MOVING_NODES,
  DRAGGING_SELECTION_BOX,
  DRAWING_EDGE,
}

/**
 * Observable that holds all the user state for a single View.
 */
export default class ViewStore {
  nodeSizeSlider: SliderStore;
  labelSizeSlider: SliderStore;
  labelAngleSlider: SliderStore;
  gridOptionsSlider: SliderStore;

  /**
   * Locks the view to wait for a response from the worker.
   */
  loadingBackdropStore: LoadingBackdropStore;

  /**
   * Max multiplier you can zoom in or out relative to the extents
   * of all the nodes.
   */
  MAX_ZOOM_IN = 55000;
  MAX_ZOOM_OUT = 10;

  CAMERA_FOV = 100;
  EXTRA_ZOOM = 1.3; // Scaler for extra padding for zooming

  /**
   * Compensate for the header and footer heights to get the correct height for the canvas.
   */
  HEIGHT_CORRECTION = 46;

  graphStore: GraphStore;

  threeView?: ThreeView;

  deleted: boolean;

  top = 0; // in pixels
  bottom = 0;
  left = 0;
  right = 0;

  constructor(graph: GraphStore, viewId: ViewId) {
    this.graphStore = graph;
    this.id = viewId;
    this.deleted = false;

    // Todo: these numbers shouldn't be hardcoded
    this.nodeSizeSlider = new SliderStore(1, 0.01, 2, 0.01);
    this.labelSizeSlider = new SliderStore(1, 0.01, 2, 0.01);
    this.labelAngleSlider = new SliderStore(0, -90, 90, 15);
    this.gridOptionsSlider = new SliderStore(1, 0.01, 2, 0.01);

    this.loadingBackdropStore = new LoadingBackdropStore();

    makeAutoObservable(this, {
      id: false,
      graphStore: false,
      threeView: false,
    });
    this.createReactions();
  }

  private reactions: IReactionDisposer[] = [];
  private createReactions() {
    this.reactions.push(
      reaction(
        () => this.axesConnected,
        () => this.zoomAll(),
      ),
    );

    autorun(() => {
      this.open; // required to send the scalar when view opened
      this.graphStore.postMessageToGraphWorker({
        type: 'viewInput',
        viewInput: {
          id: this.id,
          scalePayload: {
            viewId: this.id,
            nodeSizeScalar: this.nodeSizeScalar,
            xAxisScale: this.xAxisScale,
            yAxisScale: this.yAxisScale,
          },
        },
      });
    });
  }

  /**
   * Meta Data
   */
  id: ViewId = '';
  label: string = '';
  xNode?: MetaNode;
  yNode?: MetaNode;
  filterNodes: MetaNode[] = [];
  totalNodes = 0;
  totalEdges = 0;
  totalSelectedNodes = 0;
  totalSelectedEdges = 0;

  totalHoveredNodes = 0;
  hoveredPosition = { x: 0, y: 0 }; // extrinsic
  hoveredLabel = '';
  hoveredSelected = false;

  extents: IExtents = new Extents(); // extrinsic
  extentsSelected: IExtents = new Extents(); // extrinsic

  nodes: Set<ViewNode> = new Set();
  selectedNodes: Set<ViewNode> = new Set();
  hoveredNodes: Set<NodeId> = new Set();

  metaDataUpdate = 0; // Changes can be reacted to. Does not update for hover updates.
  metaDataUpdated() {
    this.metaDataUpdate++;
  }
  whenMetaDataUpdates(): Promise<void> {
    const cur = this.metaDataUpdate;
    return when(() => this.metaDataUpdate !== cur);
  }
  extentsUpdate = 0; // Changes can be reacted to. Specific to extents.
  extentsUpdated() {
    this.extentsUpdate++;
  }
  whenExtentsUpdates(): Promise<void> {
    const cur = this.extentsUpdate;
    return when(() => this.extentsUpdate !== cur);
  }

  /**
   * Additional View Properties
   * Deliberately kept separate from the Meta Data
   */
  viewType: ViewType = 'Custom' as ViewType;
  createdTimestamp: number = 0;

  /**
   * Interaction
   */

  dragState: DragState = DragState.NONE;

  /**
   * Node labels have to be stored here because variable-length strings can't be
   * stored in the SharedArrayBuffer.
   */
  labels: GW_Label[] = [];

  applyLabels = (labels: GW_Label[]): void => {
    this.labels = labels;
  };

  /**
   * Called when the Graph Data has changed and we need to update the view.
   *
   * Note: the explicit checks are necessary so that we do not assign the same
   * objects / values to the properties which can trigger mobx to recompute.
   */
  applyMetaData = (metaData: ViewMetaData): void => {
    if (
      (metaData.label || metaData.label === '') &&
      metaData.label !== this.label
    )
      this.label = metaData.label;
    if (metaData.xNode) this.xNode = metaData.xNode;
    if (metaData.yNode) this.yNode = metaData.yNode;
    if (metaData.filterNodes) this.filterNodes = metaData.filterNodes;

    if (
      (metaData.totalNodes || metaData.totalNodes === 0) &&
      metaData.totalNodes !== this.totalNodes
    )
      this.totalNodes = metaData.totalNodes;
    if (
      (metaData.totalEdges || metaData.totalEdges === 0) &&
      metaData.totalEdges !== this.totalEdges
    )
      this.totalEdges = metaData.totalEdges;
    if (
      (metaData.totalSelectedNodes || metaData.totalSelectedNodes === 0) &&
      metaData.totalSelectedNodes !== this.totalSelectedNodes
    )
      this.totalSelectedNodes = metaData.totalSelectedNodes;
    if (
      (metaData.totalSelectedEdges || metaData.totalSelectedEdges === 0) &&
      metaData.totalSelectedEdges !== this.totalSelectedEdges
    )
      this.totalSelectedEdges = metaData.totalSelectedEdges;
    if (
      (metaData.totalHoveredNodes || metaData.totalHoveredNodes === 0) &&
      metaData.totalHoveredNodes !== this.totalHoveredNodes
    )
      this.totalHoveredNodes = metaData.totalHoveredNodes;
    if (
      (metaData.hoveredLabel || metaData.hoveredLabel === '') &&
      metaData.hoveredLabel !== this.hoveredLabel
    )
      this.hoveredLabel = metaData.hoveredLabel;
    if (metaData.hoveredPosition)
      this.hoveredPosition = metaData.hoveredPosition;
    if (
      metaData.hoveredSelected !== undefined &&
      metaData.hoveredSelected !== null
    )
      this.hoveredSelected = metaData.hoveredSelected;
    metaData.extents && (this.extents = metaData.extents);
    metaData.extentsSelected &&
      (this.extentsSelected = metaData.extentsSelected);

    if (
      metaData.label !== undefined ||
      metaData.xNode !== undefined ||
      metaData.yNode !== undefined ||
      metaData.filterNodes !== undefined ||
      metaData.totalNodes !== undefined ||
      metaData.totalEdges !== undefined ||
      metaData.totalSelectedNodes !== undefined ||
      metaData.totalSelectedEdges !== undefined ||
      metaData.extents !== undefined ||
      metaData.extentsSelected !== undefined
    ) {
      // Does not update for hover updates.
      this.metaDataUpdated();
      if (
        metaData.extents !== undefined ||
        metaData.extentsSelected !== undefined
      )
        this.extentsUpdated();
    }
  };

  applyPropertiesData = (
    properties: ViewProperties | ViewPropertiesUpdate,
  ): void => {
    if (
      properties.createdTimestamp !== undefined &&
      properties.createdTimestamp !== this.createdTimestamp
    ) {
      this.createdTimestamp = properties.createdTimestamp;
    }
    if (
      properties.viewType !== undefined &&
      properties.viewType !== this.viewType
    ) {
      this.viewType = properties.viewType;
    }
    if (
      properties.hideEdges !== undefined &&
      properties.hideEdges !== this.hideEdges
    ) {
      this._hideEdges = properties.hideEdges;
    }
    if (
      properties.labelSize !== undefined &&
      properties.labelSize !== this.labelSizeSlider.value
    ) {
      this.labelSizeSlider.value = properties.labelSize;
    }
    if (
      properties.labelAngle !== undefined &&
      properties.labelAngle !== this.labelAngleSlider.value
    ) {
      this.labelAngleSlider.value = properties.labelAngle;
    }
    if (
      properties.showGrid !== undefined &&
      properties.showGrid !== this.showGrid
    ) {
      this._showGrid = properties.showGrid;
    }
    if (
      properties.showAxes !== undefined &&
      properties.showAxes !== this.showRulers
    ) {
      this._showRulers = properties.showAxes;
    }
    if (
      properties.axesDisconnected !== undefined &&
      properties.axesDisconnected !== this.axesDisconnected
    ) {
      this._axesDisconnected = properties.axesDisconnected;
    }
    if (
      properties.datetimeFormat !== undefined &&
      properties.datetimeFormat !== this.datetimeFormat
    ) {
      this._datetimeFormat = properties.datetimeFormat;
    }
    if (
      properties.coordinateTransform !== undefined &&
      properties.coordinateTransform !== this.coordinateTransform
    ) {
      this.coordinateTransform = properties.coordinateTransform;
    }
  };

  get open(): boolean {
    return dashboardStore.openViewsMap.has(this.id);
  }

  close = (): void => {
    this.reactions.forEach((disposer) => disposer());
    this.reactions = [];
    this.threeView?.destroy();
    this.graphStore.postMessageToGraphWorker({
      type: 'closeViews',
      viewIds: [this.id],
    });
  };

  bindBuffers = (newBuffers: GW_SharedBuffers): void => {
    this.threeView?.bindBuffers(newBuffers);
  };

  allAttributesNeedUpdate(): void {
    this.translateAttributeNeedsUpdate();
    this.sizeAttributeNeedsUpdate();
    this.colorAttributeNeedsUpdate();
    this.stateAttributeNeedsUpdate();
    this.edgeIndexNeedsUpdate();
    this.labelAttributeNeedsUpdate();
  }
  translateAttributeNeedsUpdate(): void {
    this.threeView?.translateAttributeNeedsUpdate();
  }
  sizeAttributeNeedsUpdate(): void {
    this.threeView?.sizeAttributeNeedsUpdate();
  }
  colorAttributeNeedsUpdate(): void {
    this.threeView?.colorAttributeNeedsUpdate();
  }
  stateAttributeNeedsUpdate(): void {
    this.threeView?.stateAttributeNeedsUpdate();
  }
  edgeIndexNeedsUpdate(): void {
    this.threeView?.edgeIndexNeedsUpdate();
  }
  labelAttributeNeedsUpdate(): void {
    this.threeView?.labelAttributeNeedsUpdate();
  }

  get current(): boolean {
    return this === this.graphStore.currentViewStore;
  }

  /**
   * Reload the view by requesting data from the server.
   */
  reload = () => {
    this.graphStore.postMessageToGraphWorker({
      type: 'openViews',
      viewIds: [this.id],
    });
  };

  /**
   *  Nodes in the view must be manually updated.
   */
  updateNodes = async (): Promise<void> => {
    const nodes = await this.graphStore.getNodesForViewFromWorker(this.id);
    runInAction(() => {
      this.nodes = nodes;
    });
  };

  /**
   * Selecting Nodes
   *
   * Anything that needs to act on the Nodes selected in a View
   * should await updateSelectedNodes() which will get the selected
   * nodes from the worker and update the selectedNodes set.
   */
  updateSelectedNodes = async (): Promise<void> => {
    const selectedNodes =
      await this.graphStore.getSelectedNodesForViewFromWorker(this.id);
    runInAction(() => {
      this.selectedNodes = selectedNodes;
    });
  };

  /**
   * Note: These do not automatically update the selectedNodes set from
   * the worker. Await updateSelectedNodes() first.
   */

  get selectedNodesArray(): ViewNode[] {
    return Array.from(this.selectedNodes.values());
  }

  get selectedNodeIdsArray(): NodeId[] {
    return this.selectedNodesArray.map((viewNode) => viewNode.node.id);
  }

  get selectedNodeIdsSet(): Set<NodeId> {
    return new Set(this.selectedNodeIdsArray);
  }

  get singleSelectedNode(): ViewNode | undefined {
    return this.singleNodeSelected ? this.selectedNodesArray[0] : undefined;
  }

  get noNodesSelected(): boolean {
    return this.totalSelectedNodes === 0;
  }
  get anyNodesSelected(): boolean {
    return this.totalSelectedNodes > 0;
  }
  get singleNodeSelected(): boolean {
    return this.totalSelectedNodes === 1;
  }
  get manyNodesSelected(): boolean {
    return this.totalSelectedNodes > 1;
  }
  get allNodesSelected(): boolean {
    return this.totalSelectedNodes === this.totalNodes;
  }
  get anyEdgesSelected(): boolean {
    return this.totalSelectedEdges > 0;
  }
  get anyEdges(): boolean {
    return this.totalEdges > 0;
  }

  /**
   * Hovered Nodes
   *
   * Anything that needs to act on the Nodes hovered in a View
   * should await updateHoveredNodes() which will get the hovered
   * nodes from the worker and update the hoveredNodes set.
   */

  updateHoveredNodes = async (): Promise<void> => {
    const hoveredNodes = await this.graphStore.getHoveredNodesForViewFromWorker(
      this.id,
    );
    runInAction(async () => {
      this.hoveredNodes = hoveredNodes;
    });
  };

  /**
   * Note: These do not automatically update the hoveredNodes set from
   * the worker. Await updateHoveredNodes() first.
   */

  /**
   * Call this after the user has stopped dragging the selected nodes.
   *
   * Temporarily updates the state array with the new positions. These will
   * be overwritten by the worker's response which should be identical anyway.
   */
  selectedNodesHaveMoved(): void {
    /**
     */
    if (this.dragState !== DragState.MOVING_NODES) return;
    if (!this.threeView || !this.threeView) return;

    let nodeIndex = 0;
    for (const selected of this.threeView.nodeStateArray!) {
      if (selected & NodeStates.Selected) {
        this.threeView.nodeTranslateArray![nodeIndex * 3] += this.dragDelta.x;
        this.threeView.nodeTranslateArray![nodeIndex * 3 + 1] +=
          this.dragDelta.y;
      }
      nodeIndex++;
    }

    this.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.id,
        positionDelta: {
          x: this.dragDelta.x,
          y: this.dragDelta.y,
        },
        hoverPayload: {
          mousePosition: {
            x: this.currentMouseCoordinates.x,
            y: this.currentMouseCoordinates.y,
          },
        },
      },
    });
    runInAction(() => {
      // Hover is not updated while dragging so update it here.
      this.hoveredPosition = this.currentMouseCoordinates;
      this.dragState = DragState.NONE;
      this.translateAttributeNeedsUpdate();
    });
  }

  selectAllNodes(): void {
    this.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.id,
        selectionPayload: {
          viewId: this.id,
          action: 'selectAll',
        },
      },
    });
  }

  selectNoneNodes(): void {
    this.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.id,
        selectionPayload: {
          viewId: this.id,
          action: 'selectNone',
        },
      },
    });
  }

  selectInverse(): void {
    this.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.id,
        selectionPayload: {
          viewId: this.id,
          action: 'selectInverse',
        },
      },
    });
  }

  /**
   * View Properties
   */

  /**
   * Need these instead of a standard setter so we can distinguish between the
   * user changing the labels, which has to be sent to the graph worker, and the
   * labels changing because of data coming from the worker.
   */

  userChangeLabel = (label: string): void => {
    const diff: GraphDiff = GDiff.emptyGraphDiff();
    GDiff.addNode(diff, this.id, { label });
    this.graphStore.applyDiff(diff);
  };

  userChangeXYNodeLabel = (label: string, nodeType: 'x' | 'y'): void => {
    const diff: GraphDiff = GDiff.emptyGraphDiff();
    const id = nodeType === 'x' ? this.xNode?.id : this.yNode?.id;
    if (!id) return;

    GDiff.addNode(diff, id, { label });
    this.graphStore.applyDiff(diff);
  };

  /**
   * Mouse coordinates projected into the ThreeView's frame. (Which is
   * the same as the Data coordinate space.)
   */

  /**
   * Mouse coordinates updated as the mouse moves
   */
  private _currentMouseCoordinates: MouseCoordinate = { x: 0, y: 0 };
  get currentMouseCoordinates(): MouseCoordinate {
    return this._currentMouseCoordinates;
  }
  set currentMouseCoordinates(coords: MouseCoordinate) {
    this._currentMouseCoordinates = coords;
  }

  /*
   * Mouse coordinates projected into the intrinsic coordinate space.
   */
  get currentMouseCoordinatesIntrinsic(): MouseCoordinate {
    return this.toIntrinsicCoordinates(this.currentMouseCoordinates);
  }

  /**
   * Mouse coordinates updated on mousedown
   */
  private _startMouseCoordinates: MouseCoordinate = { x: 0, y: 0 };
  get startMouseCoordinates(): MouseCoordinate {
    return this._startMouseCoordinates;
  }
  set startMouseCoordinates(coords: MouseCoordinate) {
    this._startMouseCoordinates = coords;
  }

  get dragDelta(): MouseCoordinate {
    return this.dragState === DragState.MOVING_NODES
      ? {
          x: this.currentMouseCoordinates.x - this.startMouseCoordinates.x,
          y: this.currentMouseCoordinates.y - this.startMouseCoordinates.y,
        }
      : { x: 0, y: 0 };
  }

  get dragHovered(): boolean {
    return dashboardStore.dragHoverViewId === this.id;
  }

  /**
   * Options
   */

  private _hideEdges = true;
  get hideEdges(): boolean {
    return this._hideEdges;
  }
  set hideEdges(show: boolean) {
    this._hideEdges = show;
  }
  get showEdges(): boolean {
    return !this.hideEdges;
  }

  toggleShowEdges(): void {
    this.updateViewProperties({ hideEdges: !this.hideEdges });
    this.hideEdges = !this.hideEdges;
  }

  /**
   * Nodes
   */

  get nodesArray(): ViewNode[] {
    return Array.from(this.nodes.values());
  }

  get nodeIdsArray(): NodeId[] {
    return this.nodesArray.map((viewNode) => viewNode.node.id);
  }

  get nodeIdsSet(): Set<NodeId> {
    return new Set(this.nodeIdsArray);
  }

  /**
   * Map between intrinsic (graph) and extrinsic (view) coordinates.
   */
  toExtrinsicCoordinates = (point: Point): Point => {
    return toExtrinsicCoordinates(this.coordinateTransform, point);
  };

  toIntrinsicCoordinates = (point: Point): Point => {
    return toIntrinsicCoordinates(this.coordinateTransform, point);
  };

  /**
   * Creates a new node at (extrinsic) position x, y.
   */
  async createNode(x: number, y: number): Promise<NodeId | undefined> {
    if (!this.xNode || !this.yNode) return;

    const newNodeId = crypto.randomUUID();
    const { x: xInt, y: yInt } = this.toIntrinsicCoordinates({ x, y });
    const diff = GDiff.emptyGraphDiff();
    GDiff.addNode(diff, newNodeId, { showLabel: true, label: 'node' });
    GDiff.addEdge(diff, this.xNode.id, newNodeId, xInt);
    GDiff.addEdge(diff, this.yNode.id, newNodeId, yInt);
    this.filterNodes.forEach((filterNode) => {
      GDiff.addEdge(diff, filterNode.id, newNodeId, 1);
    });
    this.graphStore.applyDiff(diff);
    await this.whenMetaDataUpdates();

    return newNodeId;
  }

  addNodes = async (viewNodes: Set<ViewNode>): Promise<void> => {
    if (viewNodes.size === 0) return;
    if (!this.xNode || !this.yNode) return;
    const diff = GDiff.emptyGraphDiff();
    for (const viewNode of viewNodes) {
      GDiff.addEdge(diff, this.xNode.id, viewNode.node.id, viewNode.x);
      GDiff.addEdge(diff, this.yNode.id, viewNode.node.id, viewNode.y);
      this.filterNodes.forEach((filterNode) => {
        GDiff.addEdge(diff, filterNode.id, viewNode.node.id, 1);
      });
    }
    this.graphStore.applyDiff(diff);
    await this.whenMetaDataUpdates();
  };

  /**
   * Create a new Node positioned based on the context.
   *
   * top menu -> create at camera position
   * context menu -> create at mouse position
   * shortcut -> create at mouse position if inside bounds
   *             otherwise camera position
   *
   * Adds edges to all selected nodes if any. Sets the new node as selected.
   */
  *newNode(context: 'menubar' | 'contextmenu' | 'shortcut') {
    const pos = { x: 0, y: 0 };
    switch (context) {
      case 'menubar':
        pos.x = this.currentCameraPosition.x;
        pos.y = this.currentCameraPosition.y;
        break;
      case 'contextmenu':
        pos.x = this.currentMouseCoordinates.x;
        pos.y = this.currentMouseCoordinates.y;
        break;
      case 'shortcut':
        if (this.mouseInsideBounds) {
          pos.x = this.currentMouseCoordinates.x;
          pos.y = this.currentMouseCoordinates.y;
        } else {
          pos.x = this.currentCameraPosition.x;
          pos.y = this.currentCameraPosition.y;
        }
        break;
    }

    const node_id: NodeId | undefined = yield this.createNode(pos.x, pos.y);
    if (!node_id) return;

    // Add edges to selected nodes
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      const diff = GDiff.emptyGraphDiff();
      this.selectedNodeIdsArray.forEach((nodeId) => {
        GDiff.addEdge(diff, node_id, nodeId, 1);
      });
      this.graphStore.applyDiff(diff);
    }

    this.graphStore.setSelectedNodes(new Set([node_id]));
  }

  removeNodes = (viewNodes: Set<ViewNode>): void => {
    if (viewNodes.size === 0) return;
    if (!this.xNode || !this.yNode) return;

    const diff: GraphDiff = GDiff.emptyGraphDiff();

    for (const viewNode of viewNodes) {
      if (this.filterNodes.length > 0) {
        // If there are filter Nodes, only remove the Edges to the filter Nodes.
        this.filterNodes.forEach((filterNode) => {
          GDiff.deleteEdge(diff, filterNode.id, viewNode.node.id);
        });
      } else {
        // Otherwise remove the x and y Edges.
        GDiff.deleteEdge(diff, this.xNode.id, viewNode.node.id);
        GDiff.deleteEdge(diff, this.yNode.id, viewNode.node.id);
      }
    }
    this.graphStore.applyDiff(diff);
  };

  removeSelectedNodes = async (): Promise<void> => {
    this.postViewActionToWorker('removeSelectedNodes');
    this.graphStore.selectNoneNodes();
  };

  deleteSelectedNodes = async (): Promise<void> => {
    const action = async () => {
      if (!this.anyNodesSelected) return;
      this.postViewActionToWorker('deleteSelectedNodes');
      this.graphStore.selectNoneNodes();
    };

    AlertDialogStore.yesNoAlert(
      'Are you sure you want to delete these nodes?',
      action,
    );
  };

  /**
   * Edges
   */

  /**
   * Deletes all the edges between the selected nodes.
   */
  deleteConnectingEdges = () => {
    if (!this.anyNodesSelected) return;
    this.postViewActionToWorker('deleteConnectingEdges');
  };

  /**
   * Dragging Edges
   */

  private _draggingFromSelected = false;
  get draggingFromSelected(): boolean {
    return this._draggingFromSelected;
  }
  set draggingFromSelected(newState: boolean) {
    this._draggingFromSelected = newState;
  }

  private _draggingEdgesFrom: Set<NodeId> = new Set();
  get draggingEdgesFrom(): Set<NodeId> {
    return this._draggingEdgesFrom;
  }
  set draggingEdgesFrom(nodes: Set<NodeId>) {
    this._draggingEdgesFrom = nodes;
  }

  /**
   * Copy and Paste
   */

  copySelectedNodeLabels = async (): Promise<void> => {
    await this.updateSelectedNodes();
    if (this.selectedNodes.size === 0) return;
    clipboardStore.copyNodeLabels(this.selectedNodes);
  };

  copySelectedNodes = async (): Promise<void> => {
    await this.updateSelectedNodes();
    if (this.selectedNodes.size === 0) return;
    clipboardStore.copyViewNodes(this.selectedNodes);
  };

  pasteNodes = async (): Promise<void> => {
    const viewNodes = clipboardStore.viewNodes;
    if (viewNodes.size > 0) {
      if (!this.xNode || !this.yNode) return;

      const diff: GraphDiff = GDiff.emptyGraphDiff();
      for (const viewNode of viewNodes) {
        // Assume these nodes are already in the graph
        GDiff.addEdge(diff, this.xNode.id, viewNode.node.id, viewNode.x);
        GDiff.addEdge(diff, this.yNode.id, viewNode.node.id, viewNode.y);
        this.filterNodes.forEach((filterNode) => {
          GDiff.addEdge(diff, filterNode.id, viewNode.node.id, 1);
        });
      }
      this.graphStore.applyDiff(diff);
    }

    // If there are Nodes, we paste them.
    const nodes = clipboardStore.nodes;
    if (nodes.size > 0) {
      if (!this.xNode || !this.yNode) return;

      const diff: GraphDiff = GDiff.emptyGraphDiff();
      for (const node of nodes) {
        GDiff.addEdge(
          diff,
          this.xNode.id,
          node.id,
          this.currentCameraPosition.x,
        );
        GDiff.addEdge(
          diff,
          this.yNode.id,
          node.id,
          this.currentCameraPosition.y,
        );
      }
      this.graphStore.applyDiff(diff);
    }
    this.zoomWhenExtentsUpdate();
  };

  private _hasDragged = true;
  get hasDragged(): boolean {
    return this._hasDragged;
  }
  set hasDragged(newState: boolean) {
    this._hasDragged = newState;
  }

  /**
   * Meta Nodes (x, y, filter)
   */

  swapAxes = (): void => {
    if (!this.xNode || !this.yNode || !this.threeView) return;

    const oldPos = this.threeView.getCameraPosition();
    const newPos = { x: oldPos.y, y: oldPos.x, z: oldPos.z };
    this.threeView.setCameraPosition(newPos);

    const oldXScale = this.xAxisScale;
    this.xAxisScale = this.yAxisScale;
    this.yAxisScale = oldXScale;

    const diff = GDiff.emptyGraphDiff();
    GDiff.addEdge(diff, this.id, this.xNode.id, 1);
    GDiff.addEdge(diff, this.id, this.yNode.id, 0);
    this.graphStore.applyDiff(diff);
  };

  setSelectedAsAxisNode = async (axis: 'x' | 'y'): Promise<void> => {
    await this.graphStore.updateSelectedNodes();

    runInAction(() => {
      const node = this.graphStore.singleSelectedNode;
      if (!node) {
        ErrorStore.setError('Expecting one and only one node to be selected');
        return;
      }

      // Ensure the incoming Node is not on the other axis.
      const otherAxisNode = axis === 'x' ? this.yNode : this.xNode;
      if (otherAxisNode?.id === node.id) {
        ErrorStore.setError('Can not assign the same node to both axes.');
        return;
      }

      const oldAxisNode = axis === 'x' ? this.xNode : this.yNode;
      if (oldAxisNode?.id === node.id) {
        return;
      }

      const diff = GDiff.emptyGraphDiff();
      GDiff.addEdge(diff, this.id, node.id, axis === 'x' ? 0 : 1);

      // Make the old axis Node a filter Node.
      oldAxisNode && GDiff.addEdge(diff, this.id, oldAxisNode.id, 2);
      this.graphStore.applyDiff(diff);
    });
  };

  setSelectedAsFilterNode = async (): Promise<void> => {
    await this.graphStore.updateSelectedNodes();

    const node = this.graphStore.singleSelectedNode;
    if (!node) {
      ErrorStore.setError('Expecting one and only one node to be selected');
      return;
    }

    if (node.id === this.xNode?.id || node.id === this.yNode?.id) {
      ErrorStore.setError('Can not assign an axis node as a filter node.');
      return;
    }

    const diff = GDiff.emptyGraphDiff();
    GDiff.addEdge(diff, this.id, node.id, 2);
    this.graphStore.applyDiff(diff);
  };

  deleteFilterNode = (node: MetaNode): void => {
    const diff = GDiff.emptyGraphDiff();
    GDiff.deleteEdge(diff, this.id, node.id);
    this.graphStore.applyDiff(diff);
  };

  setSelectedNodesByIds = (nodeIds: Set<NodeId>): void => {
    this.graphStore.setSelectedNodes(nodeIds);
  };

  addToSelectionByIds = (nodeIds: Set<NodeId>): void => {
    this.graphStore.setSelectedNodes(nodeIds, true);
  };

  toggleSelectionByIds = async (nodeIds: Set<NodeId>): Promise<void> => {
    this.graphStore.setSelectedNodes(nodeIds, false, true);
  };

  duplicateSelected = async (): Promise<void> => {
    if (!this.anyNodesSelected) return;
    const offsetX = this.extentsSelected.xMax - this.extentsSelected.xMin;
    const offsetY = this.extentsSelected.yMax - this.extentsSelected.yMin;
    this.postViewActionToWorker({
      duplicateSelected: { offsetX, offsetY },
    });
  };

  /**
   * Actions
   */

  mouseInsideBounds = false;

  postViewActionToWorker(action: ViewAction) {
    this.graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        viewAction: {
          viewId: this.id,
          action,
        },
      },
    });
  }

  /**
   * Color by
   */

  *colorByWeight(axisNodeId: NodeId | undefined, reversed: boolean) {
    yield this.graphStore.colorByWeight(axisNodeId, this.id, reversed);
  }

  *colorBySpatialGroup() {
    let selectedNodeIds = undefined;
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      selectedNodeIds = this.selectedNodeIdsArray;
    }
    const graphDiff: GraphDiff | undefined = yield DataService.colorBy(
      this.graphStore.id,
      'spatialGroup',
      selectedNodeIds,
      this.id,
    );
    if (!graphDiff) return;
    this.graphStore.applyDiff(graphDiff, false);
  }

  *colorBySpectral() {
    let selectedNodeIds = undefined;
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      selectedNodeIds = this.selectedNodeIdsArray;
    }
    const graphDiff: GraphDiff | undefined = yield DataService.colorBy(
      this.graphStore.id,
      'spectral',
      selectedNodeIds,
      this.id,
    );
    if (!graphDiff) return;
    this.graphStore.applyDiff(graphDiff, false);
  }

  *colorByDensity() {
    let selectedNodeIds = undefined;
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      selectedNodeIds = this.selectedNodeIdsArray;
    }
    const graphDiff: GraphDiff | undefined = yield DataService.colorBy(
      this.graphStore.id,
      'density',
      selectedNodeIds,
      this.id,
    );
    if (graphDiff) {
      this.graphStore.applyDiff(graphDiff, false);
    }
  }

  *resetColor() {
    let nodes: NodeId[] = [];
    if (this.anyNodesSelected) {
      yield this.updateSelectedNodes();
      nodes = this.selectedNodeIdsArray;
    } else {
      yield this.updateNodes();
      nodes = this.nodeIdsArray;
    }
    if (nodes.length === 0) return;
    this.graphStore.setNodesColor(nodes, 0, 0, 0);
  }

  /**
   * Set the size of the selected Nodes.
   */
  *setSizeForSelectedNodes(size: number) {
    if (!this.anyNodesSelected) return;
    yield this.updateSelectedNodes();
    const diff = GDiff.emptyGraphDiff();
    this.selectedNodes.forEach((viewNode) => {
      GDiff.addNode(diff, viewNode.node.id, { size });
    });
    this.graphStore.applyDiff(diff);
  }

  *sortBy(sortType: SortType) {
    yield this.updateSelectedNodes();
    const diff: GraphDiff | undefined = yield DataService.sortBy(
      this.graphStore.id,
      this.id,
      this.selectedNodeIdsArray,
      sortType,
    );
    if (!diff) return;
    this.graphStore.applyDiff(diff, false);
  }

  /**
   * Rotate selected nodes by the given number of degrees around the average position (pivot point).
   */
  *rotateNodes(degrees: number) {
    if (!this.anyNodesSelected) return;
    if (!this.xNode || !this.yNode) return;
    yield this.updateSelectedNodes();

    // Rotate around on screen position
    const toRotate: [ViewNode, number, number][] = this.selectedNodesArray.map(
      (n) => {
        const extrinsic = this.toExtrinsicCoordinates({ x: n.x, y: n.y });
        return [n, extrinsic.x, extrinsic.y];
      },
    );
    const [pivotX, pivotY] = toRotate
      .reduce(([px, py], [, x, y]) => [px + x, py + y], [0, 0])
      .map((v) => v / toRotate.length);

    const rad = degrees * (Math.PI / 180);
    const sin = Math.sin(rad);
    const cos = Math.cos(rad);

    const diff = GDiff.emptyGraphDiff();
    for (const [node, x, y] of toRotate) {
      const xNew = cos * (x - pivotX) + sin * (y - pivotY) + pivotX;
      const yNew = cos * (y - pivotY) - sin * (x - pivotX) + pivotY;
      const intrinsic = this.toIntrinsicCoordinates({ x: xNew, y: yNew });
      GDiff.addEdge(diff, this.xNode.id, node.node.id, intrinsic.x);
      GDiff.addEdge(diff, this.yNode.id, node.node.id, intrinsic.y);
    }
    this.graphStore.applyDiff(diff);
  }

  async autoLayout(method: LayoutEnum) {
    const extents = {
      xMin: this.outerDimensionsInDataSpace.xMin,
      xMax: this.outerDimensionsInDataSpace.xMax,

      // Provide the true max and minimum value
      // as the y-axis is inverted in the frontend
      yMin: this.outerDimensionsInDataSpace.yMax,
      yMax: this.outerDimensionsInDataSpace.yMin,
    };
    this.postViewActionToWorker({
      layoutView: { method, extents },
    });

    enum Status {
      SUCCESS,
    }

    const result = await waitForToolMessage(
      this.graphStore,
      (data) => {
        return data === 'layout' ? Status.SUCCESS : undefined;
      },
      // 20 seconds is the backend timeout.
      // Adding 1 just in case it succeeds and the message is sent after the timeout.
      // If it were lower than the backend timeout, we run the risk of updating the
      // view without the user noticing or whilst it is being looked at / edited
      /* Timeout */ 21_000,
    );

    if (result !== Status.SUCCESS) {
      ErrorStore.setError(
        'Layout timed out. Try selecting a smaller number of nodes and edges.',
      );
    }
  }

  /**
   * Adding Nodes to the view
   */

  /**
   * Add Nodes that are already in the Graph by Id.
   */
  *addNodesById(nodeIds: NodeId[], positions?: [number, number][] | undefined) {
    yield this.updateNodes();

    if (!this.xNode || !this.yNode) return;
    const diff = GDiff.emptyGraphDiff();
    for (const [index, nodeId] of nodeIds.entries()) {
      if (this.nodeIdsSet.has(nodeId)) {
        return;
      }
      const x = positions
        ? positions[index][0]
        : this.currentMouseCoordinates.x;
      const y = positions
        ? positions[index][1]
        : this.currentMouseCoordinates.y;
      GDiff.addEdge(diff, this.xNode.id, nodeId, x);
      GDiff.addEdge(diff, this.yNode.id, nodeId, y);
      for (const filterNode of this.filterNodes) {
        GDiff.addEdge(diff, filterNode.id, nodeId, 0);
      }
    }
    this.graphStore.applyDiff(diff);
  }

  /**
   * Parses a string of potential node-ids, and adds them to the view
   */
  static stringToNodeIds = (input: string): NodeId[] => {
    let nodeIds: NodeId[] = [];

    try {
      nodeIds = JSON.parse(input);
      if (!Array.isArray(nodeIds)) throw true;
    } catch {
      input = input.replace(/['"[\]]+/g, '');
      nodeIds = input.split(',');
      nodeIds = nodeIds.map((id) => id.trim());
    }
    // todo: could filter out non uuids here
    return nodeIds;
  };

  addNodesToViewByString = (nodeInput: string): void => {
    const nodeIds = ViewStore.stringToNodeIds(nodeInput);
    this.addNodesById(nodeIds);
  };

  /**
   * Query the graph for the neighbors of a node.
   */
  async addSelectedNeighbors(
    queryType: NeighborQueryType,
    intersection: boolean = true,
    limit?: number,
  ) {
    const extents = { ...this.innerDimensionsInDataSpace };
    const yMin = extents.yMin;
    extents.yMin = this.innerDimensionsInDataSpace.yMax;
    extents.yMax = yMin;
    this.postViewActionToWorker({
      addNeighbors: {
        queryType,
        intersection,
        limit: limit ?? null,
        extents,
      },
    });
    await this.whenMetaDataUpdates();
  }

  /**
   * Query the graph for the nodes which connect the selected node set.
   */
  *addConnections() {
    if (this.totalSelectedNodes < 2) return;
    this.postViewActionToWorker('addConnections');
    yield this.whenMetaDataUpdates();
    this.selectNoneNodes();
  }

  /**
   * The z value that fits the bounding box for all the nodes.
   */
  get cameraFar(): number {
    return this.calcExtentsToZ(this.extents) * this.MAX_ZOOM_OUT;
  }
  get cameraNear(): number {
    return this.calcExtentsToZ(this.extents) / this.MAX_ZOOM_IN;
  }

  /**
   * Calculate a scalar to apply to all the node sizes based on the user
   * setting and the distance from the camera.
   */
  get nodeSizeScalar(): number {
    return (this.currentCameraPosition.z / 100) * this.nodeSizeSlider.value;
  }

  /**
   * Width, height and aspect ratio
   */
  get dimensions(): { width: number; height: number } {
    return {
      width: dashboardStore.viewsSurfaceSize.width - this.left - this.right,
      height:
        dashboardStore.viewsSurfaceSize.height -
        this.top -
        this.bottom -
        this.HEIGHT_CORRECTION,
    };
  }

  get aspectRatio(): number {
    return this.dimensions.width / this.dimensions.height;
  }

  /**
   * The position of the camera
   */
  private _currentCameraPosition = new Vector3();
  get currentCameraPosition(): Vector3 {
    return this._currentCameraPosition;
  }
  set currentCameraPosition(position: Vector3) {
    // Clamp z so we don't go farther in or out than the clipping plane
    let z = position.z;
    z = Math.max(z, this.cameraNear + Number.MIN_VALUE);
    z = Math.min(z, this.cameraFar - Number.MIN_VALUE);

    this._currentCameraPosition.x = position.x;
    this._currentCameraPosition.y = position.y;
    this._currentCameraPosition.z = z;
  }

  /**
   * Tangent of Vertical Field of View
   */
  get vFovTan(): number {
    const vFovRad = (this.CAMERA_FOV * Math.PI) / 180 / 2;
    return Math.tan(vFovRad);
  }

  /**
   * Tangent of Horizontal Field of View
   */
  get hFovTan(): number {
    return this.vFovTan * this.aspectRatio;
  }

  /**
   * Camera height required to fit nodes given a horizontal min max extent.
   * Note: will return 0 if the width is 0.
   */
  private getCameraZWidth = (extents: IExtents): number => {
    return (
      (Math.abs(extents.xMax - extents.xMin) * this.EXTRA_ZOOM) / this.hFovTan
    );
  };

  /**
   * Camera height required to fit nodes given a vertical min max extent
   * Note: will return 0 if the height is 0.
   */
  private getCameraZHeight = (extents: IExtents): number => {
    return (
      (Math.abs(extents.yMax - extents.yMin) * this.EXTRA_ZOOM) / this.vFovTan
    );
  };

  zoomAll = (): void => {
    if (!this.axesConnected) {
      this.updateAxisScales(this.extents);
    }
    this.setCameraPositionFromExtents(this.extents);
  };

  zoomSelected = (): void => {
    if (!this.axesConnected) {
      this.updateAxisScales(this.extentsSelected);
    }
    this.setCameraPositionFromExtents(this.extentsSelected);
  };

  zoomWhenExtentsUpdate = async (): Promise<void> => {
    await this.whenExtentsUpdates();
    this.zoomAll();
  };

  /**
   * Calculate the camera position that fits the passed-in Extents.
   */
  private setCameraPositionFromExtents(extents: IExtents): void {
    const z = Math.max(
      this.calcExtentsToZ(extents),
      this.cameraNear * this.EXTRA_ZOOM,
    );
    this.currentCameraPosition = new Vector3(
      ((extents.xMin + extents.xMax) / 2) * this.xAxisScale,
      ((extents.yMin + extents.yMax) / 2) * this.yAxisScale,
      z,
    );
  }

  /**
   * Calculate the z that fits a given extents with a little padding
   * taking the aspect ratio of the canvas into account.
   */
  private calcExtentsToZ(extents: IExtents): number {
    return this.axesConnected
      ? Math.max(
          this.getCameraZHeight(extents),
          this.getCameraZWidth(extents),
        ) / 2
      : this.getCameraZHeight(extents) / 2;
  }

  private updateAxisScales(extents: IExtents) {
    const zForWidth = this.getCameraZWidth(extents);
    const zForHeight = this.getCameraZHeight(extents);
    this.xAxisScale =
      Math.abs(zForWidth) > Number.EPSILON ? zForHeight / zForWidth : 1.0;
    this.yAxisScale = 1;
  }

  /**
   * Toolbar
   */
  private _toolbarOpen = false;
  get toolbarOpen(): boolean {
    return this._toolbarOpen;
  }
  set toolbarOpen(open: boolean) {
    this._toolbarOpen = open;
  }

  /**
   * Filters
   */
  private _filterSelectorOpen = false;
  get filterSelectorOpen(): boolean {
    return this._filterSelectorOpen;
  }
  set filterSelectorOpen(open: boolean) {
    this._filterSelectorOpen = open;
  }

  updateViewProperties(properties: Omit<ViewPropertiesUpdate, 'id'>) {
    const viewProperties = { ...properties, id: this.id };
    // Apply immediately to avoid render delay.
    this.applyPropertiesData(viewProperties);
    this.graphStore.postMessageToGraphWorker({
      type: 'viewProperties',
      viewProperties,
    });
  }

  /**
   * Axis Scale
   */

  private _axesDisconnected = false;
  get axesDisconnected(): boolean {
    return this._axesDisconnected;
  }
  get axesConnected(): boolean {
    return !this.axesDisconnected;
  }

  private _xAxisScale = 1;
  get xAxisScale(): number {
    return this._xAxisScale;
  }
  set xAxisScale(scale: number) {
    this._xAxisScale = scale;
  }

  private _yAxisScale = 1;
  get yAxisScale(): number {
    return this._yAxisScale;
  }
  set yAxisScale(scale: number) {
    this._yAxisScale = scale;
  }

  /**
   * Grid
   */

  private _showRulers = false;
  get showRulers(): boolean {
    return this._showRulers;
  }

  private _showGrid = false;
  get showGrid(): boolean {
    return this._showGrid;
  }

  private _datetimeFormat = false;
  get datetimeFormat(): boolean {
    return this._datetimeFormat;
  }

  /*
   * Coordinate Transforms
   */

  private _coordinateTransform = CoordinateTransform.None;
  get coordinateTransform(): CoordinateTransform {
    return this._coordinateTransform;
  }
  set coordinateTransform(transform: CoordinateTransform) {
    if (transform === this._coordinateTransform) return;

    this.xAxisScale = 1;
    this.yAxisScale = 1;
    if (this.axesDisconnected) {
      this.updateViewProperties({
        axesDisconnected: false,
      });
    }

    if (this.threeView?.nodeTranslateArray) {
      let allVisible = true;
      for (let i = 0; i < this.threeView.nodeTranslateArray.length; i += 3) {
        const x = this.threeView.nodeTranslateArray[i];
        const y = this.threeView.nodeTranslateArray[i + 1];
        if (
          x < this.innerDimensionsInDataSpace.xMin ||
          x > this.innerDimensionsInDataSpace.xMax ||
          y > this.innerDimensionsInDataSpace.yMin || // note the yMin and yMax are inverted
          y < this.innerDimensionsInDataSpace.yMax
        ) {
          allVisible = false;
          break;
        }
      }
      if (allVisible) {
        this._coordinateTransform = transform;
        this.zoomWhenExtentsUpdate();
        return;
      }
    }

    // Aim to keep intrinsic camera position the same.
    const intrinsicExtents = ViewStore.mapExtents(
      this.innerDimensionsInDataSpace,
      this.toIntrinsicCoordinates,
    );
    this._coordinateTransform = transform;
    const newExtents = ViewStore.mapExtents(
      intrinsicExtents,
      this.toExtrinsicCoordinates,
    );
    this.setCameraPositionFromExtents(newExtents);
  }

  toggleMapDisplay(): void {
    this.coordinateTransform =
      this.coordinateTransform === CoordinateTransform.WebMercator
        ? CoordinateTransform.None
        : CoordinateTransform.WebMercator;
    this.updateViewProperties({
      coordinateTransform: this.coordinateTransform,
    });
  }

  /**
   * Map extents by a transform.
   */
  static mapExtents = (
    extents: IExtents,
    fn: (point: Point) => Point,
  ): Extents => {
    const newExtents = new Extents();
    const minMin = fn({
      x: extents.xMin,
      y: extents.yMin,
    });
    newExtents.addPoint(minMin.x, minMin.y);
    const minMax = fn({
      x: extents.xMin,
      y: extents.yMax,
    });
    newExtents.addPoint(minMax.x, minMax.y);
    const maxMin = fn({
      x: extents.xMax,
      y: extents.yMin,
    });
    newExtents.addPoint(maxMin.x, maxMin.y);
    const maxMax = fn({
      x: extents.xMax,
      y: extents.yMax,
    });
    newExtents.addPoint(maxMax.x, maxMax.y);
    return newExtents;
  };

  /**
   * Dimensions in Data Coordinates to be used for the grid.
   *
   * These are set in ThreeView.ts
   *
   * Note that these take into account the current axis scaling.
   *
   * left            right
   * |-------------------| top
   * |      outer        |
   * | |---------------| |
   * | |    inner      | |
   * | |               | |
   * | |               | |
   * | |               | |
   * | |---------------| |
   * |                   |
   * |-------------------| bottom
   */

  /**
   * The extents in data space of the full View
   */
  private _outerDimensionsInDataSpace: IExtents = {
    yMin: 0,
    yMax: 0,
    xMin: 0,
    xMax: 0,
  };
  get outerDimensionsInDataSpace(): IExtents {
    return this._outerDimensionsInDataSpace;
  }
  set outerDimensionsInDataSpace(dimensions: IExtents) {
    this._outerDimensionsInDataSpace = dimensions;
  }

  /**
   * The extents in data space of the view minus the gutters
   */
  private _innerDimensionsInDataSpace: IExtents = {
    yMin: 0,
    yMax: 0,
    xMin: 0,
    xMax: 0,
  };
  get innerDimensionsInDataSpace(): IExtents {
    return this._innerDimensionsInDataSpace;
  }
  set innerDimensionsInDataSpace(dimensions: IExtents) {
    this._innerDimensionsInDataSpace = dimensions;
  }

  /**
   * Debugging Utilities
   */
  async exportNodesInViewToCsv(): Promise<void> {
    // If there are selected nodes, export those,
    // otherwise export all nodes in the view
    let nodesToExport = [];
    if (this.anyNodesSelected) {
      await this.updateSelectedNodes();
      nodesToExport = this.selectedNodesArray;
    } else {
      await this.updateNodes();
      nodesToExport = [...this.nodes];
    }
    // https://stackoverflow.com/a/31976060/1164295
    // eslint-disable-next-line
    const invalidCharsRegex = /[<>:"\/\\|?*\x00-\x1F]/g; // mostly windows invalid characters
    const ext = '.csv';
    let fileName = this.label.replace(invalidCharsRegex, '_');
    fileName = fileName.slice(0, 255 - ext.length); // max lenght limit
    fileName += ext; // file extension
    fileName = fileName.replace(/ /g, '_'); // replace spaces with underscores
    // Column headers
    const xName: string = this.xNode?.label ?? 'x';
    const yName: string = this.yNode?.label ?? 'y';
    let csv = `label, ${xName}, ${yName}, url, color_red, color_green, color_blue, size\n`;
    // Fill content
    nodesToExport.forEach((viewNode) => {
      const url = viewNode.node.url;
      const size = viewNode.node.size;
      csv += `"${viewNode.node.label}", ${viewNode.x}, ${viewNode.y}, "${url}", ${viewNode.node.red}, ${viewNode.node.green}, ${viewNode.node.blue}, ${size}\n`;
    });
    const csvBlob = new Blob([csv], { type: 'text/csv' });
    await DataService.downloadData(csvBlob, fileName);
  }

  toggleFormatTemporalAxis(): void {
    this.updateViewProperties({ datetimeFormat: !this.datetimeFormat });
  }
}
