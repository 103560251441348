/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "57f9eeae47d1403d925f2cc0ca0946ca",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "57f9eeae47d1403d925f2cc0ca0946ca",
            "region": "eu-west-2"
        }
    },
    "aws_cognito_identity_pool_id": "eu-west-2:3b2ee594-0e6d-43b2-b51e-354d353539fa",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_VtpKbCwC8",
    "aws_user_pools_web_client_id": "3tiflljtqlp261h0fd4v89808o",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "drisk-app-files215711-main162849-develop",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
