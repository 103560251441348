import { makeAutoObservable } from 'mobx';
import DashboardStore from './DashboardStore';

export default class HomePageStore {
  private _currentPage: number;
  private _open = false;

  constructor(currentPage: number = 0) {
    this._currentPage = currentPage;
    makeAutoObservable(this);
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(newPage: number) {
    this._currentPage = newPage;
  }

  get open(): boolean {
    return this._open;
  }
  set open(newState: boolean) {
    if (newState === true) DashboardStore.loadAndStoreGraphs();
    this._open = newState;
  }
}
