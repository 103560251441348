import * as THREE from 'three';
import { action, reaction, runInAction, when, IReactionDisposer } from 'mobx';

import {
  GW_SharedBuffers,
  GW_ViewSelectionAction,
  SelectionBox,
} from '../types/graphWorker';

import { debounce } from '../utils/utils';
import { RectangularMarquee } from './RectangularMarquee';
import ViewStore, { DragState, Vector3 } from '../stores/ViewStore';
import NodeEngine from './NodeEngine';
import EdgeEngine from './EdgeEngine';
import LabelEngine from './LabelEngine';
import GridEngine from './GridEngine';
import MapEngine from './MapEngine';

import dashboardStore from '../stores/DashboardStore';

const AXIS_GUTTER_IN_PX = 20;

/**
 * The THREE.js layer of the view. It does not know anything about React.
 */
export default class ThreeView {
  private viewStore: ViewStore;

  private container!: HTMLDivElement;

  nodeTranslateArray: Float32Array | null = null;
  nodeColorArray: Float32Array | null = null;
  nodeSizeArray: Float32Array | null = null;
  nodeStateArray: Uint32Array | null = null;
  edgeIndexArray: Uint32Array | null = null;

  private nodeEngine: NodeEngine;
  private edgeEngine: EdgeEngine;
  private labelEngine: LabelEngine;
  private gridEngine: GridEngine;
  private mapEngine: MapEngine;
  private mousePrevious = new THREE.Vector2();
  private mouseCurrent = new THREE.Vector2();
  private raycaster = new THREE.Raycaster();
  private projectionPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
  private camera: THREE.PerspectiveCamera;
  private renderer: THREE.WebGLRenderer;
  private rectangularMarquee!: RectangularMarquee;

  // Set to true when you want Three to render
  private needsRender = false;

  // Ensures we don't render until we've observed the width and height
  private sized = false;

  private reactions: IReactionDisposer[] = [];

  constructor(viewStore: ViewStore, container: HTMLDivElement) {
    this.viewStore = viewStore;
    this.container = container;

    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      precision: 'highp',
      logarithmicDepthBuffer: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(0xf1f1f1, 1);
    this.renderer.autoClear = false;

    this.container.appendChild(this.renderer.domElement);

    // Note: the near and far planes are set as the nodes are loaded
    this.camera = new THREE.PerspectiveCamera(this.viewStore.CAMERA_FOV, 1);

    this.nodeEngine = new NodeEngine(
      new THREE.Scene(),
      this.camera,
      this.renderer,
      this.viewStore,
    );
    this.edgeEngine = new EdgeEngine(
      new THREE.Scene(),
      this.camera,
      this.renderer,
      this.viewStore,
    );
    this.labelEngine = new LabelEngine(
      new THREE.Scene(),
      this.camera,
      undefined, // The labelEngine will create its own renderer
      this.viewStore,
      this.container,
    );
    this.gridEngine = new GridEngine(
      new THREE.Scene(),
      this.camera,
      this.renderer,
      this.viewStore,
      this.container,
    );
    this.mapEngine = new MapEngine(
      new THREE.Scene(),
      this.camera,
      this.renderer,
      this.viewStore,
    );
    this.rectangularMarquee = new RectangularMarquee(
      this.renderer.domElement,
      'select-box',
    );

    // Camera Position
    this.reactions.push(
      reaction(
        () => ({
          near: this.viewStore.cameraNear,
          far: this.viewStore.cameraFar,
        }),
        this.observeCameraNearAndFar,
        { fireImmediately: true },
      ),
    );
    this.reactions.push(
      reaction(
        () => ({
          x: this.viewStore.currentCameraPosition.x,
          y: this.viewStore.currentCameraPosition.y,
          z: this.viewStore.currentCameraPosition.z,
        }),
        this.observeCurrentCameraPosition,
        { fireImmediately: true },
      ),
    );

    // Width and Height
    this.reactions.push(
      reaction(() => this.viewStore.dimensions, this.observeWidthAndHeight, {
        fireImmediately: true,
      }),
    );

    // Axes Synced
    this.reactions.push(
      reaction(() => this.viewStore.axesConnected, this.observeAxesSynced, {
        fireImmediately: true,
      }),
    );

    // Axis Scale
    this.reactions.push(
      reaction(
        () => ({
          x: this.viewStore.xAxisScale,
          y: this.viewStore.yAxisScale,
        }),
        this.observeAxisScale,
        { fireImmediately: true },
      ),
    );

    // Drag Delta
    this.reactions.push(
      reaction(() => this.viewStore.dragDelta, this.observeDragDelta, {
        fireImmediately: true,
      }),
    );

    // Labels
    this.reactions.push(
      reaction(() => this.viewStore.labels, this.observeLabels, {
        fireImmediately: true,
      }),
    );

    this.handleMouseEvents = action(this.handleMouseEvents);
    this.handleClickAway = action(this.handleClickAway);
    this.render();
  }

  private observeCameraNearAndFar = ({
    near,
    far,
  }: {
    near: number;
    far: number;
  }) => {
    this.camera.near = near;
    this.camera.far = far;
    this.needsRender = true;
  };

  private observeCurrentCameraPosition = (cameraPosition: Vector3) => {
    const { x, y, z } = cameraPosition;
    this.camera.position.set(x, y, z);
    this.setViewDimensions();
    this.needsRender = true;
  };

  getCameraPosition = (): Vector3 => {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  };

  setCameraPosition = (cameraPosition: Vector3): void => {
    this.viewStore.currentCameraPosition = cameraPosition;
  };

  private observeWidthAndHeight = debounce(
    (
      newDimensions: { width: number; height: number },
      oldDimensions: { width: number; height: number } | undefined,
    ) => {
      const { width, height } = newDimensions;
      const aspectRatio = width / height;

      this.renderer.setSize(width, height);
      this.camera.aspect = aspectRatio;

      this.camera.updateProjectionMatrix();
      this.camera.updateMatrixWorld();

      this.labelEngine.handleResize(width, height);
      this.gridEngine.handleResize(width, height);
      this.needsRender = true;
      this.sized = true;

      if (oldDimensions) {
        this.handleViewSurfaceDimensionChange(newDimensions, oldDimensions);
      }
    },
    false,
    100,
  );

  private observeAxesSynced = () => {
    this.setViewDimensions();
    this.needsRender = true;
  };

  private observeDragDelta = () => {
    this.nodeEngine.observeDragDelta();
    this.edgeEngine.observeDragDelta();
    this.labelEngine.observeDragDelta();
    this.needsRender = true;
  };

  private observeAxisScale = () => {
    this.nodeEngine.observeAxisScale();
    this.edgeEngine.observeAxisScale();
    this.labelEngine.observeAxisScale();
    this.needsRender = true;
  };

  private observeLabels = () => {
    this.labelEngine.observeLabels();
    this.needsRender = true;
  };

  /**
   * The worker has sent us new buffers which we pass to the engines.
   */
  bindBuffers = (newBuffers: GW_SharedBuffers) => {
    this.nodeTranslateArray = newBuffers.nodeTranslateBuffer
      ? new Float32Array(newBuffers.nodeTranslateBuffer)
      : new Float32Array();
    this.nodeColorArray = newBuffers.nodeColorBuffer
      ? new Float32Array(newBuffers.nodeColorBuffer)
      : new Float32Array();
    this.nodeSizeArray = newBuffers.nodeSizeBuffer
      ? new Float32Array(newBuffers.nodeSizeBuffer)
      : new Float32Array();
    this.nodeStateArray = newBuffers.nodeStateBuffer
      ? new Uint32Array(newBuffers.nodeStateBuffer)
      : new Uint32Array();
    this.edgeIndexArray = newBuffers.edgeIndexBuffer
      ? new Uint32Array(newBuffers.edgeIndexBuffer)
      : new Uint32Array();
    this.nodeEngine.bindSharedArrays(
      this.nodeTranslateArray,
      this.nodeColorArray,
      this.nodeSizeArray,
      this.nodeStateArray,
    );
    this.edgeEngine.bindSharedArrays(
      this.nodeTranslateArray,
      this.nodeColorArray,
      this.nodeStateArray,
      this.edgeIndexArray,
    );
    this.labelEngine.bindSharedArrays(
      this.nodeTranslateArray,
      this.nodeColorArray,
      this.nodeStateArray,
    );
  };

  destroy(): void {
    this.stop();

    if (this.container?.contains(this.renderer.domElement)) {
      this.container.removeChild(this.renderer.domElement);
    }

    this.renderer.dispose();
    this.nodeEngine.destroy();
    this.edgeEngine.destroy();
    this.labelEngine.destroy();
    this.mapEngine.destroy();
    this.gridEngine.destroy();
    this.nodeTranslateArray = null;
    this.nodeColorArray = null;
    this.nodeSizeArray = null;
    this.nodeStateArray = null;
    this.edgeIndexArray = null;

    this.reactions.forEach((disposer) => disposer());
    this.reactions = [];
  }

  start(): void {
    // noop
  }

  stop(): void {
    // noop
  }
  private render = (): void => {
    requestAnimationFrame(this.render);
    if (this.needsRender && this.sized) {
      this.needsRender = false;
      // Renders back to front
      // todo: When we've removed mobx from the engines, remove the runInAction.
      runInAction(() => {
        this.renderer.clear();
        this.mapEngine.render();
        this.gridEngine.render();
        this.edgeEngine.render();
        this.renderer.clearDepth();
        this.nodeEngine.render();
        this.labelEngine.render();
      });
    }
  };

  // utils

  /**
   * Projects a ray onto the data plane and returns the v3 in data space
   */
  private getProjectedCoordinates(
    mouseCoordinates: THREE.Vector2,
  ): THREE.Vector3 {
    this.raycaster.setFromCamera(mouseCoordinates, this.camera);
    const projectedCoordinates = new THREE.Vector3();
    this.raycaster.ray.intersectPlane(
      this.projectionPlane,
      projectedCoordinates,
    );
    projectedCoordinates.x /= this.viewStore.xAxisScale;
    projectedCoordinates.y /= this.viewStore.yAxisScale;
    return projectedCoordinates;
  }

  private setViewDimensions = (): void => {
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    let tl = this.getProjectedCoordinates(new THREE.Vector2(-1, 1));
    let br = this.getProjectedCoordinates(new THREE.Vector2(1, -1));

    this.viewStore.outerDimensionsInDataSpace = {
      yMin: tl.y,
      xMin: tl.x,
      yMax: br.y,
      xMax: br.x,
    };

    // map the width in px - gutter to -1 to 1

    const widthMinusGutter =
      (this.viewStore.dimensions.width - AXIS_GUTTER_IN_PX * 2) /
      this.viewStore.dimensions.width;

    const heightMinusGutter =
      (this.viewStore.dimensions.height - AXIS_GUTTER_IN_PX * 2) /
      this.viewStore.dimensions.height;

    tl = this.getProjectedCoordinates(
      new THREE.Vector2(-widthMinusGutter, heightMinusGutter),
    );
    br = this.getProjectedCoordinates(
      new THREE.Vector2(widthMinusGutter, -heightMinusGutter),
    );

    this.viewStore.innerDimensionsInDataSpace = {
      yMin: tl.y,
      xMin: tl.x,
      yMax: br.y,
      xMax: br.x,
    };
  };

  // Event handlers

  handleClickAway = (event: MouseEvent | TouchEvent): void => {
    if (!this.viewStore.hasDragged) {
      this.handleSingleClick(event.shiftKey, event.ctrlKey || event.metaKey);
      return;
    }

    switch (this.viewStore.dragState) {
      case DragState.MOVING_NODES:
        this.viewStore.selectedNodesHaveMoved();
        break;
      case DragState.DRAGGING_SELECTION_BOX:
        this.selectNodesFromSelectionBox(
          event.shiftKey,
          event.ctrlKey || event.metaKey,
        );
        break;
      default:
        this.container.style.cursor = this.viewStore.totalHoveredNodes
          ? 'pointer'
          : 'auto';
    }
    this.rectangularMarquee.onSelectOver();
    this.viewStore.dragState = DragState.NONE;
  };

  /**
   * This is a little tricky. When the user zooms, we move the camera in
   * x, y and z by multiplying the direction vector of a casted ray. But
   * if the user is scaling one of the axes, we change the x or y axis scale
   * and move the camera only in x or y to center the zoom on the mouse and
   * keep the other axis unchanged.
   *
   * todo: we should consider never moving the camera and instead
   *      just scaling / offsetting the axes.
   */
  handleZoom = (event: React.WheelEvent): void => {
    runInAction(() => {
      const { deltaY: zoomDelta } = event;
      const { x, y } = this.getProjectedCoordinates(this.mouseCurrent);

      const inner = this.viewStore.innerDimensionsInDataSpace;

      // Scaling X
      if (
        !this.viewStore.axesConnected &&
        this.viewStore.showRulers &&
        y < inner.yMax &&
        x > inner.xMin
      ) {
        const scale = this.viewStore.yAxisScale * zoomDelta < 0 ? 1.1 : 0.9;
        const newScale = this.viewStore.xAxisScale * scale;

        if (newScale < Number.EPSILON) return;

        this.viewStore.xAxisScale = newScale;
        const offset = (x - x / scale) * this.viewStore.xAxisScale;

        const newPos = new THREE.Vector3(
          this.camera.position.x + offset,
          this.camera.position.y,
          this.camera.position.z,
        );
        this.viewStore.currentCameraPosition = newPos;
        return;
      }

      // Scaling Y
      if (
        !this.viewStore.axesConnected &&
        this.viewStore.showRulers &&
        x < inner.xMin &&
        y > inner.yMax
      ) {
        const scale = this.viewStore.yAxisScale * zoomDelta < 0 ? 1.1 : 0.9;
        const newScale = this.viewStore.yAxisScale * scale;

        if (newScale < Number.EPSILON) return;

        this.viewStore.yAxisScale = newScale;
        const offset = (y - y / scale) * this.viewStore.yAxisScale;

        const newPos = new THREE.Vector3(
          this.camera.position.x,
          this.camera.position.y + offset,
          this.camera.position.z,
        );
        this.viewStore.currentCameraPosition = newPos;
        return;
      }

      // Zooming
      this.raycaster.setFromCamera(this.mouseCurrent, this.camera);

      const direction = this.raycaster.ray.direction;
      if (zoomDelta > 0) {
        direction.negate();
      }
      direction.multiplyScalar(this.camera.position.z * 0.1);

      const newPos = new THREE.Vector3(
        this.camera.position.x + direction.x,
        this.camera.position.y + direction.y,
        this.camera.position.z + direction.z,
      );

      /**
       * Note that while we clamp the z value in the setter, we
       * don't want to update at all if we're out of range to
       * avoid the scrolling effect when clamped all the way in / out.
       */

      if (newPos.z < this.camera.near) return;
      if (newPos.z > this.camera.far) return;

      this.viewStore.currentCameraPosition = newPos;
    });
  };

  /**
   * Called from the parent view.
   * Todo: We should really wrap all this up into a state machine.
   */
  handleMouseEvents = (event: React.MouseEvent): void => {
    // Get x and y coords in gl space (-1 to 1 in canvas)
    const clientRect = this.container.getBoundingClientRect();

    // zero out the xy coordinates in px
    let x = event.pageX - clientRect.left;
    let y = event.pageY - clientRect.top;

    // convert to -1 to 1 across the canvas
    x = (x / clientRect.width) * 2 - 1;
    y = -(y / clientRect.height) * 2 + 1;

    this.mousePrevious.x = this.mouseCurrent.x;
    this.mousePrevious.y = this.mouseCurrent.y;
    this.mouseCurrent.x = x;
    this.mouseCurrent.y = y;

    if (
      dashboardStore.isSpaceDown &&
      this.viewStore === dashboardStore.currentViewStore
    ) {
      this.viewStore.dragState = DragState.MOVING_CAMERA;
    } else if (dashboardStore.spaceReleased) {
      this.viewStore.dragState = DragState.NONE;
      dashboardStore.spaceReleased = false;
    }

    // Update the model in view space
    this.viewStore.currentMouseCoordinates = this.getProjectedCoordinates(
      this.mouseCurrent,
    );

    switch (event.type) {
      case 'mousedown':
        this.handleMouseDown(event);
        break;
      case 'mousemove':
        this.handleMouseMove(event);
        break;
      case 'mouseup':
        this.handleMouseUp(event);
        break;
      case 'mouseout':
        this.handleMouseOut();
        break;
      case 'mouseover':
        this.handleMouseOver(event);
        break;
      default:
    }
  };

  private handleMouseOver = (event: React.MouseEvent): void => {
    if (this.viewStore.dragState === DragState.DRAWING_EDGE) return;
    this.viewStore.mouseInsideBounds = true;
    if (this.viewStore != dashboardStore.currentViewStore) {
      this.viewStore.dragState = DragState.NONE;
      return;
    }
    if (event.buttons === 2) this.viewStore.dragState = DragState.MOVING_CAMERA;
    if (event.buttons === 0) this.viewStore.dragState = DragState.NONE;
  };

  private handleMouseOut = (): void => {
    this.viewStore.mouseInsideBounds = false;
    if (this.viewStore != dashboardStore.currentViewStore) {
      this.viewStore.dragState = DragState.NONE;
    }
  };

  private handleMouseDown = (event: React.MouseEvent): void => {
    dashboardStore.currentViewStore = this.viewStore;
    this.viewStore.hasDragged = false;

    // todo: do we still need this?
    this.viewStore.startMouseCoordinates = this.getProjectedCoordinates(
      this.mouseCurrent,
    );
    this.viewStore.currentMouseCoordinates =
      this.viewStore.startMouseCoordinates;

    if (event.button === THREE.MOUSE.LEFT) {
      if (this.viewStore.totalHoveredNodes) {
        if (this.viewStore.anyNodesSelected) {
          if (this.container?.style?.cursor) {
            this.container.style.cursor = 'grabbing';
          }
          this.viewStore.dragState = DragState.MOVING_NODES;
        }
      } else {
        // start selection
        this.rectangularMarquee.onSelectStart(event);
        this.viewStore.dragState = DragState.DRAGGING_SELECTION_BOX;
        this.startSelection();
      }
    } else if (event.button === THREE.MOUSE.RIGHT) {
      if (this.viewStore.totalHoveredNodes) {
        // User has right clicked on some nodes
        if (!this.viewStore.anyNodesSelected) {
          this.selectNodesFromPoint(false, false);
          when(() => this.viewStore.hoveredSelected, { timeout: 1000 })
            .then(() => {
              if (dashboardStore.contextMenuStore.open) return;
              this.startDraggingEdges();
              this.viewStore.dragState = DragState.DRAWING_EDGE;
              return;
            })
            .catch(() => {});
        } else {
          this.startDraggingEdges();
          this.viewStore.dragState = DragState.DRAWING_EDGE;
        }
      } else {
        this.viewStore.dragState = DragState.MOVING_CAMERA;
      }
    }
  };

  private handleMouseMove = (event: React.MouseEvent): void => {
    /**
     * Update the state attribute for all the open views so cross-view
     * selecting works.
     */
    dashboardStore.openViews.forEach((view) => {
      view.stateAttributeNeedsUpdate();
    });

    this.viewStore.hasDragged = true;
    switch (this.viewStore.dragState) {
      case DragState.MOVING_CAMERA:
        this.container.style.cursor = 'move';
        this.viewStore.currentCameraPosition.x +=
          (this.mousePrevious.x - this.mouseCurrent.x) * this.camera.position.z;
        this.viewStore.currentCameraPosition.y +=
          (this.mousePrevious.y - this.mouseCurrent.y) * this.camera.position.z;
        break;
      case DragState.DRAGGING_SELECTION_BOX:
        this.container.style.cursor = 'crosshair';
        this.rectangularMarquee.onSelectMove(event);
        this.selectNodesFromSelectionBox(
          event.shiftKey,
          event.ctrlKey || event.metaKey,
        );
        break;
      case DragState.MOVING_NODES:
        this.container.style.cursor = 'grabbing';
        break;
      case DragState.DRAWING_EDGE:
        this.updateHoverFromInput();
        break;
      default: // NONE
        this.updateHoverFromInput();
        this.container.style.cursor = this.viewStore.totalHoveredNodes
          ? 'pointer'
          : 'auto';
    }
  };

  private handleMouseUp = (event: React.MouseEvent): void => {
    if (event.button === THREE.MOUSE.LEFT) {
      /**
       * Left click
       */
      if (!this.viewStore.hasDragged) {
        this.handleSingleClick(event.shiftKey, event.ctrlKey || event.metaKey);
        this.selectionDone();
        return;
      }

      switch (this.viewStore.dragState) {
        case DragState.MOVING_NODES:
          this.viewStore.selectedNodesHaveMoved();
          // Note: dragState is set to NONE in selectedNodesHaveMoved()
          return;
        case DragState.DRAGGING_SELECTION_BOX:
          this.selectionDone();
          break;
        default:
          this.container.style.cursor = this.viewStore.totalHoveredNodes
            ? 'pointer'
            : 'auto';
      }
    } else if (event.button === THREE.MOUSE.RIGHT) {
      /**
       * Right click
       */
      if (this.viewStore.hasDragged) {
        this.viewStore.dragState === DragState.DRAWING_EDGE &&
          this.stopDraggingEdges();
        this.viewStore.dragState = DragState.NONE;
      } else {
        dashboardStore.openContextMenu(
          { left: event.pageX, top: event.pageY },
          'canvas',
        );
        this.viewStore.dragState = DragState.NONE;
      }
    }
    this.edgeEngine.stopDraggingEdges();
    this.rectangularMarquee.onSelectOver();
    this.viewStore.dragState = DragState.NONE;
  };

  /**
   * Select nodes from the rolled over set
   */
  private handleSingleClick = (
    addToCurrent = false,
    removeFromCurrent = false,
  ): void => {
    this.startSelection();
    this.selectNodesFromPoint(addToCurrent, removeFromCurrent);
    this.viewStore.dragState = DragState.NONE;
    this.rectangularMarquee.onSelectOver();
  };

  /**
   * Attributes
   */

  translateAttributeNeedsUpdate(): void {
    this.nodeEngine.translateAttributeNeedsUpdate();
    this.edgeEngine.translateAttributeNeedsUpdate();
    this.labelEngine.translateAttributeNeedsUpdate();
    this.needsRender = true;
  }
  colorAttributeNeedsUpdate(): void {
    this.nodeEngine.colorAttributeNeedsUpdate();
    this.edgeEngine.colorAttributeNeedsUpdate();
    this.needsRender = true;
  }
  sizeAttributeNeedsUpdate(): void {
    this.nodeEngine.sizeAttributeNeedsUpdate();
    this.needsRender = true;
  }
  stateAttributeNeedsUpdate(): void {
    this.nodeEngine.stateAttributeNeedsUpdate();
    this.edgeEngine.stateAttributeNeedsUpdate();
    this.needsRender = true;
  }
  edgeIndexNeedsUpdate(): void {
    this.edgeEngine.indexNeedsUpdate();
    this.needsRender = true;
  }
  labelAttributeNeedsUpdate(): void {
    // this.labelEngine.updateLabelAttribute();
    this.needsRender = true;
  }

  private updateHoverFromInput(): void {
    this.viewStore.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.viewStore.id,
        hoverPayload: {
          mousePosition: {
            x: this.viewStore.currentMouseCoordinates.x,
            y: this.viewStore.currentMouseCoordinates.y,
          },
        },
      },
    });
  }

  private startSelection(): void {
    this.viewStore.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.viewStore.id,
        selectionPayload: {
          viewId: this.viewStore.id,
          action: 'start',
        },
      },
    });
  }

  private selectNodesFromPoint(
    addToCurrent = false,
    removeFromCurrent = false,
  ): void {
    let selectAction: GW_ViewSelectionAction = 'replace';
    if (removeFromCurrent) {
      selectAction = 'remove';
    } else if (addToCurrent) {
      selectAction = 'add';
    }

    this.viewStore.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.viewStore.id,
        selectionPayload: {
          viewId: this.viewStore.id,
          action: selectAction,
          point: {
            x: this.viewStore.currentMouseCoordinates.x,
            y: this.viewStore.currentMouseCoordinates.y,
          },
        },
      },
    });
  }

  /**
   * @param addToCurrent        Whether to add to the current selection or replace it
   * @param removeFromCurrent   Whether to remove from the current selection set
   */
  private selectNodesFromSelectionBox(
    addToCurrent = false,
    removeFromCurrent = false,
  ): void {
    let selectAction: GW_ViewSelectionAction = 'replace';
    if (removeFromCurrent) {
      selectAction = 'remove';
    } else if (addToCurrent) {
      selectAction = 'add';
    }

    const selectionBox: SelectionBox = {
      x1: this.viewStore.startMouseCoordinates.x,
      y1: this.viewStore.startMouseCoordinates.y,
      x2: this.viewStore.currentMouseCoordinates.x,
      y2: this.viewStore.currentMouseCoordinates.y,
    };

    this.viewStore.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.viewStore.id,
        selectionPayload: {
          viewId: this.viewStore.id,
          action: selectAction,
          box: selectionBox,
        },
      },
    });
  }

  /**
   * Called when the user has stopped a selection drag
   */
  private selectionDone = (): void => {
    this.viewStore.graphStore.postMessageToGraphWorker({
      type: 'viewInput',
      viewInput: {
        id: this.viewStore.id,
        selectionPayload: {
          viewId: this.viewStore.id,
          action: 'stop',
        },
      },
    });
  };

  private startDraggingEdges = async (): Promise<void> => {
    const dragType = this.viewStore.hoveredSelected
      ? 'fromSelection'
      : 'fromHover';

    if (dragType === 'fromSelection') {
      await this.viewStore.updateSelectedNodes();
      runInAction(() => {
        this.viewStore.draggingEdgesFrom = this.viewStore.selectedNodeIdsSet;
        this.viewStore.draggingFromSelected = true;
      });
    } else {
      await this.viewStore.updateHoveredNodes();
      runInAction(() => {
        this.viewStore.draggingEdgesFrom = this.viewStore.hoveredNodes;
        this.viewStore.draggingFromSelected = false;
      });
    }
    this.edgeEngine.startDraggingEdges(dragType);
  };

  private stopDraggingEdges = async (): Promise<void> => {
    if (!this.viewStore.totalHoveredNodes) return;

    if (this.viewStore.draggingFromSelected) {
      // Previously selected to currently hovered.
      await this.viewStore.updateHoveredNodes();
      runInAction(() => {
        this.viewStore.graphStore.addEdgesBetweenNodes(
          this.viewStore.draggingEdgesFrom,
          this.viewStore.hoveredNodes,
        );
      });
    } else if (this.viewStore.hoveredSelected) {
      // Previously hovered to currently selected.
      await this.viewStore.updateSelectedNodes();
      runInAction(() => {
        this.viewStore.graphStore.addEdgesBetweenNodes(
          this.viewStore.draggingEdgesFrom,
          this.viewStore.selectedNodeIdsSet,
        );
      });
    } else {
      // Previously hovered to currently hovered.
      await this.viewStore.updateHoveredNodes();
      runInAction(() => {
        this.viewStore.graphStore.addEdgesBetweenNodes(
          this.viewStore.draggingEdgesFrom,
          this.viewStore.hoveredNodes,
        );
      });
    }
  };

  private handleViewSurfaceDimensionChange = (
    newDimensions: { width: number; height: number },
    oldDimensions: { width: number; height: number },
  ): void => {
    runInAction(() => {
      const viewStore = this.viewStore;
      const [newWidth, newHeight] = [newDimensions.width, newDimensions.height];
      const [oldWidth, oldHeight] = [oldDimensions.width, oldDimensions.height];

      const extents = viewStore.outerDimensionsInDataSpace;
      const dataWidth = Math.abs(extents.xMax - extents.xMin);
      const zWidth = (dataWidth * viewStore.xAxisScale) / 2 / viewStore.hFovTan;

      const dataHeight = Math.abs(extents.yMax - extents.yMin);
      const zHeight =
        (dataHeight * viewStore.yAxisScale) / 2 / viewStore.vFovTan;

      let zFinal;
      if (newWidth < oldWidth || newHeight < oldHeight) {
        // Zooming in
        zFinal = Math.max(zWidth, zHeight);
      } else {
        // Zooming out
        zFinal = Math.min(zWidth, zHeight);
      }

      // Make sure we stay within reasonable limits
      zFinal = Math.min(
        Math.max(zFinal, viewStore.cameraNear * viewStore.EXTRA_ZOOM),
        viewStore.cameraFar / viewStore.EXTRA_ZOOM,
      );

      viewStore.currentCameraPosition.z = zFinal;
    });
  };
}
