import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Popover,
  Slider as MuiSlider,
  Tooltip,
  IconButton,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import SliderStore from '../stores/SliderStore';
import dashboardStore from '../stores/DashboardStore';

const Slider = observer(
  ({
    name,
    Icon,
    sliderStore,
    disabled,
    onChange,
    onOpen,
  }: {
    name: string;
    Icon: SvgIconComponent;
    sliderStore: SliderStore;
    disabled?: boolean;
    onChange?: () => void;
    onOpen?: () => void;
  }) => {
    const styles = {
      iconButton: {
        padding: 0,
        backgroundColor: 'transparent',
        fontSize: 18,
      },
      popoverBox: {
        display: 'flex',
        justifyContent: 'center',
        width: 320,
        height: 64,
        overflow: 'hidden',
      },
    };

    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tooltip open={showTooltip} title={name} arrow>
          <IconButton
            color="default"
            style={styles.iconButton}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              sliderStore.sliderElement = event.currentTarget;
              onOpen && onOpen();
              sliderStore.sliderOpen = true;
            }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => {
              setShowTooltip(false);
            }}
            size="large"
            disabled={disabled}
          >
            <Icon />
          </IconButton>
        </Tooltip>

        <Popover
          open={sliderStore.sliderOpen}
          onClose={() => (sliderStore.sliderOpen = false)}
          anchorEl={sliderStore.sliderElement}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={styles.popoverBox}>
            <MuiSlider
              size="small"
              value={sliderStore.value}
              min={sliderStore.min}
              max={sliderStore.max}
              step={sliderStore.step}
              aria-label="Small"
              valueLabelDisplay="on"
              sx={{
                width: '280px',
                margin: '28px 10px 0 10px',
              }}
              onChange={(event: Event, newValue: number | number[]) => {
                sliderStore.value = newValue as number;
                onChange && onChange();
              }}
            />
            {sliderStore === dashboardStore.nodeSizeSlider && (
              <input // This input field is only for the node size slider.
                type="number"
                style={{ width: '70px', height: '30px', marginTop: '20px' }}
                value={sliderStore.value}
                step="0.1"
                onChange={(e) => {
                  sliderStore.value = Number(e.target.value);
                  onChange && onChange();
                  e.stopPropagation();
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
          </Box>
        </Popover>
      </Box>
    );
  },
);
export default Slider;
