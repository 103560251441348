import { makeAutoObservable } from 'mobx';
import dashboardStore from '../stores/DashboardStore';
import { waitForToolMessage } from '../tools';
import { Uuid } from '../types/app';

export class FeatureExtractionStore {
  maxNodesAllowed = 10000; // also fixed in feature_extraction.rs

  constructor() {
    makeAutoObservable(this);
  }

  private _fromHeaders: boolean = false;
  get fromHeaders(): boolean {
    return this._fromHeaders;
  }
  set fromHeaders(value: boolean) {
    this._fromHeaders = value;
  }

  private _textBox: string = '';
  get textBox(): string {
    return this._textBox;
  }
  set textBox(prompt: string) {
    this._textBox = prompt;
  }

  get disableButton(): boolean {
    const view = dashboardStore.currentViewStore;
    if (!view) return true;
    return (
      view.totalSelectedNodes === 0 ||
      view.totalSelectedNodes > this.maxNodesAllowed
    );
  }

  *runExtractStringFeatures() {
    const graphStore = dashboardStore.currentGraphStore;
    const viewStore = dashboardStore.currentViewStore;
    if (!viewStore || !graphStore) return;
    if (!viewStore.anyNodesSelected) {
      return;
    }
    const prompt = this.textBox.length > 0 ? this.textBox : undefined;
    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        featureExtraction: {
          headers: this.fromHeaders,
          prompt: prompt ?? null,
        },
      },
    });

    const taskId: Uuid | undefined = yield waitForToolMessage(
      graphStore,
      (msg) => {
        if (typeof msg === 'object' && 'featureExtraction' in msg) {
          return msg.featureExtraction;
        }
      },
    );
    if (!taskId) return;

    this.textBox = '';
    dashboardStore.addViewsTask(
      taskId,
      graphStore,
      'Extracting string features',
    );
  }

  get disableGeocoding(): boolean {
    const view = dashboardStore.currentViewStore;
    if (!view) return true;
    return (
      (!this.fromHeaders && !view.anyNodesSelected) ||
      (this.fromHeaders && !view.singleNodeSelected)
    );
  }

  async runGeocoding() {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;
    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        geocode: this.fromHeaders,
      },
    });

    const taskId = await waitForToolMessage(graphStore, (msg) => {
      if (typeof msg === 'object' && 'geocode' in msg) {
        return msg.geocode;
      }
    });
    if (!taskId) return;

    dashboardStore.addViewsTask(taskId, graphStore, 'Geocoding');
  }
}
