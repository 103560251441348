import React from 'react';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import dashboardStore from '../stores/DashboardStore';

import MenuIcon from '@mui/icons-material/MenuOpen';
import Menu from './Menu';
import MenuStore from '../stores/MenuStore';
import HelperToolTip from './HelpToolTip';

/**
 * Horizontal bar that acts like a Menu
 *
 * Todo: arrow navigation
 * l, r switch index or open submenu
 * up dn for each menu
 */

const MenuBar = observer((): JSX.Element => {
  const theme = useTheme();
  const wide = useMediaQuery(theme.breakpoints.up('md'));
  const store = dashboardStore.menuBarStore;

  const styles = {
    root: {
      padding: '4px 2px',
    },
    topBar: {
      display: 'flex',
      backgroundColor: theme.palette.grey[100],
    },
    topBarItem: {
      position: 'relative',
      padding: '0 8px 0 8px',
      fontSize: 12,
      userSelect: 'none',
      overflow: 'visible',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[300],
      },
      borderRadius: `${theme.shape.borderRadius}px`,
    },
    iconBox: {
      position: 'relative',
      padding: '0 8px 0 8px',
      fontSize: 12,
      userSelect: 'none',
    },
  };

  const close = () => {
    runInAction(() => {
      store.open = false;
      store.items.forEach((menuStore) => (menuStore.open = false));
    });
  };

  const handleClickAway = () => {
    if (store.open) {
      close();
    }
  };

  interface ItemProps {
    closeOthers: (arg0: MenuStore) => void;
  }

  const TopBar = observer(({ closeOthers }: ItemProps): JSX.Element => {
    const handleMouseEnter = (localMenuStore: MenuStore) => {
      closeOthers && closeOthers(localMenuStore);
      // close all children of this menuStore
      localMenuStore.items.forEach((child) => {
        if (localMenuStore !== child && child.type === 'item') {
          child.open = false;
        }
      });
      localMenuStore.open = true;
    };

    return (
      <Box sx={styles.topBar}>
        {store.items.map((menuStore, index) => {
          if (menuStore.type === 'item') {
            return (
              <Box
                key={index}
                sx={styles.topBarItem}
                onMouseDown={() => {
                  store.open = true;
                }}
                onMouseEnter={(e: React.MouseEvent) => {
                  // e.target gives parent, e.currentTarget gives child
                  // prevent firing of handleMouseEnter(menuStore) on the child
                  if (e.target === e.currentTarget) {
                    handleMouseEnter(menuStore);
                  }
                }}
              >
                {menuStore.label}
                {store.open && menuStore.open ? (
                  <Menu
                    menuStore={menuStore}
                    onClose={close}
                    closeOthers={(localMenuStore) => {
                      dashboardStore.closeOthers(
                        menuStore.items,
                        localMenuStore,
                      );
                    }}
                  />
                ) : null}
              </Box>
            );
          } else {
            return null; // Ignore 'divider' in top menu bar
          }
        })}
      </Box>
    );
  });

  const onboardingStore = dashboardStore.onboardingHelpStore;

  return (
    <HelperToolTip
      inAppHelpItem={onboardingStore.getItemByIndex(3)}
      inAppHelpStore={onboardingStore}
    >
      <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
        <Box sx={styles.root}>
          {wide ? (
            <TopBar
              key={dashboardStore.currentGraphStore?.id}
              closeOthers={(localMenuStore) => {
                dashboardStore.closeOthers(
                  dashboardStore.menuBarStore,
                  localMenuStore,
                );
              }}
            />
          ) : (
            <Box sx={styles.iconBox}>
              <MenuIcon onClick={() => (store.open = !store.open)}></MenuIcon>
              {!store.open ? null : (
                <Menu
                  menuStore={store}
                  onClose={close}
                  closeOthers={(localMenuStore) => {
                    dashboardStore.closeOthers(
                      dashboardStore.menuBarStore,
                      localMenuStore,
                    );
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </HelperToolTip>
  );
});
export default MenuBar;
