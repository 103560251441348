import React from 'react';
import { observer } from 'mobx-react-lite';
import { InAppHelpItem } from '../in-app-help/InAppHelp';
import InAppHelpStore from '../stores/InAppHelpStore';
import NavigationStore from '../stores/NavigationStore';
import RightDrawerStore from '../stores/RightDrawerStore';
import dashboardStore from '../stores/DashboardStore';

import Tooltip from '@mui/material/Tooltip';
import { Box, Typography, Button, IconButton, useTheme } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const MEDIA_WIDTH = 450;

/**
 * Todo: makeStyles is deprecated. Replace with sx prop.
 * Todo: Currently, the entire tooltip is rendered when the tooltip is closed. Should remove from
 * the DOM entirely.
 */
const HelperToolTip = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      {
        children,
        inAppHelpItem,
        inAppHelpStore,
        allowOpen,
        childrenSx,
        tooltipSx,
        store,
      }: {
        children: JSX.Element;
        inAppHelpItem: InAppHelpItem;
        inAppHelpStore: InAppHelpStore;
        allowOpen?: boolean | undefined;
        childrenSx?: React.CSSProperties | undefined;
        tooltipSx?: React.CSSProperties | undefined;
        store?: NavigationStore | RightDrawerStore | undefined;
      },
      ref,
    ): JSX.Element => {
      const theme = useTheme();

      // Check if the tooltip should be open
      const isOpen =
        allowOpen === undefined
          ? inAppHelpItem.enabled
          : allowOpen && inAppHelpItem.enabled;

      // Make sure that the store is open if the tooltip is open
      if (isOpen && store !== undefined && !store.open) {
        store.open = true;
      }

      const styles = {
        root: {
          bgcolor: 'transparent',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '1em',
          margin: '5px',
        },
        textBox: {
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
        title: {
          fontWeight: 'bold',
          fontSize: '14px',
          color: theme.palette.secondary.main,
        },
        textBody: {
          fontSize: '14px',
          color: theme.palette.secondary.main,
        },
        iconButton: {
          position: 'absolute',
          top: 0,
          color: theme.palette.secondary.main,
          width: '1px',
          height: '1px',
          margin: '8px',
        },
        button: {
          marginLeft: '4px',
          textTransform: 'none',
          color: theme.palette.grey[700],
        },
        media: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'row',
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'row',
        },
        childrenOutline: {
          border: isOpen ? `2px solid ${theme.palette.grey[700]}` : 'none',
          display: 'inherit',
          borderRadius: `${theme.shape.borderRadius}px`,
          backgroundColor: 'transparent',
          ...childrenSx,
        },
      };

      const majorTooltipStyles = makeStyles((theme) => ({
        arrow: {
          '&:before': {
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)',
          },
          color: theme.palette.grey[700],
        },
        tooltip: {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.grey[700],
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          maxWidth: `${MEDIA_WIDTH + 25}px`,
          ...tooltipSx,
        },
      }));

      const minorTooltipStyles = makeStyles((theme) => ({
        arrow: {
          '&:before': {
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
        tooltip: {
          border: `1px solid ${theme.palette.secondary.main}`,
        },
      }));

      // Define styles for tooltips
      const majorStyles = majorTooltipStyles();
      const minorStyles = minorTooltipStyles();

      const nextButton = () => {
        if (dashboardStore.openViews.length === 0) {
          return (
            <Tooltip
              title="Open any views to continue the onboarding"
              arrow
              classes={{
                arrow: minorStyles.arrow,
                tooltip: minorStyles.tooltip,
              }}
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => inAppHelpStore.next()}
                  sx={styles.button}
                  size="small"
                  color="secondary"
                  disabled={true}
                >
                  Next
                </Button>
              </span>
            </Tooltip>
          );
        } else {
          return (
            <Button
              variant="contained"
              onClick={() => inAppHelpStore.next()}
              sx={styles.button}
              size="small"
              color="secondary"
            >
              Next
            </Button>
          );
        }
      };

      return (
        <Tooltip
          ref={ref}
          arrow
          classes={{ arrow: majorStyles.arrow, tooltip: majorStyles.tooltip }}
          open={isOpen}
          placement={inAppHelpItem.placement}
          title={
            <Box sx={styles.root}>
              <Box sx={styles.textBox}>
                {/* Title of the in App Help Tooltip */}
                <Typography variant="h6" sx={styles.title}>
                  {inAppHelpItem.title}
                </Typography>
                {/* Button to read more in the docs */}
                <Tooltip
                  title="Read more"
                  arrow
                  classes={{
                    arrow: minorStyles.arrow,
                    tooltip: minorStyles.tooltip,
                  }}
                >
                  <IconButton
                    sx={{ ...styles.iconButton, right: '22px' }}
                    disabled={!inAppHelpItem.link}
                    onClick={() => {
                      window.open(inAppHelpItem.link, '_blank');
                    }}
                  >
                    <HelpOutlineOutlinedIcon
                      sx={{ width: '20px', height: '20px' }}
                    />
                  </IconButton>
                </Tooltip>
                {/* Button to close onboarding help */}
                <Tooltip
                  title="Close onboarding help"
                  arrow
                  classes={{
                    arrow: minorStyles.arrow,
                    tooltip: minorStyles.tooltip,
                  }}
                >
                  <IconButton
                    sx={{ ...styles.iconButton, right: 0 }}
                    onClick={() => {
                      inAppHelpStore.stop();
                    }}
                  >
                    <CloseIcon sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </Tooltip>
                {/* Body of the in App Help Tooltip */}
                <Box>
                  {inAppHelpItem.body.split('\n').map((line, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      sx={styles.textBody}
                      gutterBottom
                    >
                      {' '}
                      {line}
                    </Typography>
                  ))}
                </Box>
              </Box>
              {/* Media of the in App Help Tooltip */}
              <Box sx={styles.media}>
                <img
                  src={inAppHelpItem.media}
                  width={MEDIA_WIDTH}
                  crossOrigin="anonymous"
                />
              </Box>
              {/* Buttons and status of the in App Help Tooltip */}
              <Box sx={styles.footer}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: theme.palette.secondary.main,
                    marginLeft: '5px',
                  }}
                  align="left"
                >
                  {`${
                    inAppHelpStore.getActiveItemIndex() + 1
                  } of ${inAppHelpStore.getItemsCount()}`}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ padding: '5px' }}
                >
                  {!inAppHelpStore.hasJustStarted() && (
                    <Button
                      variant="contained"
                      onClick={() => inAppHelpStore.back()}
                      sx={styles.button}
                      size="small"
                      color="secondary"
                    >
                      Back
                    </Button>
                  )}
                  {!inAppHelpStore.hasJustFinished() && nextButton()}
                  {inAppHelpStore.hasJustFinished() && (
                    <Button
                      variant="contained"
                      onClick={() => inAppHelpStore.complete()}
                      sx={styles.button}
                      size="small"
                      color="secondary"
                    >
                      Start using Edge!
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          }
        >
          <Box sx={styles.childrenOutline}>{children}</Box>
        </Tooltip>
      );
    },
  ),
);

export default HelperToolTip;
