import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Box,
  TextField,
  InputAdornment,
  Divider,
  IconButton,
  Button,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useTheme } from '@mui/material/styles';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import { Accordion, AccordionDetails, AccordionSummary } from './RightDrawer';
import { NodeSelectionBox } from './NodeSelectionBox';
import dashboardStore from '../stores/DashboardStore';
import { runInAction } from 'mobx';
import { AutoAwesome } from '@mui/icons-material';
import Actions from '../actions/actions';

const switchOptions = [
  { label: 'Database Connection', value: true },
  { label: 'Subgraph', value: false },
];

export const DataRetrievalAgent: React.FC = observer(() => {
  const agentStore = dashboardStore.dataRetrievalAgentStore;
  const theme = useTheme();
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    userPromptBox: {
      label: 'userPromptBox',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    iconButton: {
      borderRadius: '50%',
      color: theme.palette.grey[600],
      overflow: 'hidden',
      '&:hover': {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };

  // This is for auto-scrolling to the bottom when the chatHistory changes
  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (textFieldRef.current) {
      const element = textFieldRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [agentStore.chatHistoryToShow]);

  return (
    <Accordion>
      <AccordionSummary
        name="Data Retrieval"
        description="Find or import the data points you care about by asking questions of your data."
        docs_url="/docs/content/How-To/find_data/#using-the-data-retrieval-agent"
      />
      <AccordionDetails>
        <Box sx={styles.root}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                Actions.uniqueActions.dataRetrievalDocs.action();
              }}
              sx={styles.iconButton}
            >
              <QuestionMarkIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Box>
          <Box sx={{ marginBottom: '5px' }}>
            <SwitchButton
              options={switchOptions}
              value={agentStore.isSQL}
              onChange={(option: SwitchOption) => {
                agentStore.isSQL = option.value as boolean;
              }}
              fontsize="10px"
            />
          </Box>
          {!agentStore.isSQL && (
            <NodeSelectionBox
              label={'Features to Consider'}
              clear={agentStore.featureNodes.reset}
              select={agentStore.featureNodes.update}
              value={agentStore.featureNodes.display}
              isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
            />
          )}
          {agentStore.isSQL && (
            <NodeSelectionBox
              label={'Database Connection Node'}
              clear={agentStore.databaseConnectionNode.reset}
              select={agentStore.databaseConnectionNode.update}
              value={agentStore.databaseConnectionNode.label}
              isDisabled={!dashboardStore.currentGraphStore?.singleNodeSelected}
            />
          )}
          <Divider sx={{ padding: '5px' }} />
          {!agentStore.isSQL && (
            <>
              <NodeSelectionBox
                label="Data Nodes or Header (optional)"
                clear={agentStore.dataNodes.reset}
                select={agentStore.dataNodes.update}
                value={agentStore.dataNodes.display}
                isDisabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
              />
              <Divider sx={{ padding: '5px', height: '1px' }} />
            </>
          )}

          {/* Chat history text box */}
          <Box sx={{ margin: 0 }}>
            <TextField
              disabled
              label={
                <span style={{ fontSize: '12px' }}>
                  Data Retrieval Chat History
                </span>
              }
              margin="dense"
              id="textBox"
              type="text"
              color="primary"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: {
                  fontSize: '12px',
                  lineHeight: '16px',
                  height: '150px',
                  whiteSpace: 'pre-wrap',
                  overflow: 'auto',
                },
                ref: textFieldRef,
              }}
              multiline
              maxRows={10}
              value={agentStore.chatHistoryToShow}
              fullWidth
              style={{ cursor: 'default' }}
            />
          </Box>

          {/* User prompt text field */}
          <Box sx={styles.userPromptBox}>
            <Box sx={{ margin: 0 }}>
              <TextField
                margin="dense"
                id="userPrompt"
                type="text"
                color="primary"
                InputLabelProps={{
                  style: { fontSize: '0.8rem' },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AutoAwesome />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    fontSize: '12px',
                    lineHeight: '16px',
                  },
                }}
                value={agentStore.userPrompt}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  runInAction(() => {
                    agentStore.userPrompt = e.target.value;
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    runInAction(() => {
                      agentStore.agentResponse = '';
                      agentStore.isSQL
                        ? agentStore.executeSqlQuery()
                        : agentStore.executeQueryToPython();
                      agentStore.userPromptToShow =
                        'You: ' + agentStore.userPrompt;
                      agentStore.userPrompt = '';
                    });
                  }
                  e.stopPropagation();
                }}
                fullWidth
                multiline
                placeholder="Let me find something for you..."
              />
            </Box>
          </Box>
          {/* SQL holder */}
          {agentStore.isSQL && (
            <>
              <Divider
                sx={{ padding: '5px', height: '1px', marginBottom: '15px' }}
              />
              <Box sx={{ margin: 0 }}>
                <CodeEditor
                  value={agentStore.currentQuery}
                  language="sql"
                  placeholder="Please enter your SQL query here..."
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    runInAction(() => {
                      agentStore.currentQuery = e.target.value;
                      agentStore.queryRunOK = false;
                    });
                  }}
                  padding={15}
                  style={{
                    backgroundColor: '#f5f5f5',
                    fontFamily:
                      'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    minHeight: '100px',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: '10px',
                    marginTop: '10px',
                    textTransform: 'none',
                    minWidth: '90px',
                  }}
                  onClick={() => {
                    dashboardStore.runSQLPreview();
                  }}
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: '10px',
                    marginTop: '10px',
                    textTransform: 'none',
                    minWidth: '90px',
                  }}
                  onClick={() => {
                    dashboardStore.runSQLQuery();
                  }}
                  disabled={!agentStore.queryRunOK}
                >
                  Run
                </Button>
              </Box>
            </>
          )}
          {/* Python Code Holder */}
          {!agentStore.isSQL && (
            <>
              <Divider
                sx={{ padding: '5px', height: '1px', marginBottom: '15px' }}
              />
              <Box sx={{ margin: 0 }}>
                <CodeEditor
                  value={agentStore.currentPythonCode}
                  language="python"
                  placeholder="Please enter your Python code here..."
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    runInAction(() => {
                      agentStore.currentPythonCode = e.target.value;
                    });
                  }}
                  padding={15}
                  style={{
                    backgroundColor: '#f5f5f5',
                    fontFamily:
                      'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    minHeight: '100px',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}
              >
                {agentStore.pythonRunning && <CircularProgress />}
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    fontSize: '10px',
                    marginTop: '10px',
                    textTransform: 'none',
                    minWidth: '90px',
                  }}
                  onClick={() => {
                    agentStore.executePythonQuery();
                  }}
                >
                  Run
                </Button>
              </Box>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});
export default DataRetrievalAgent;
