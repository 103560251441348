import { makeAutoObservable } from 'mobx';
import dashboardStore from './DashboardStore';
import { MultiNodeStore } from './NodeStore';
import { withDashboardBackdrop } from '../utils/backdrop';
import { waitForToolMessage } from '../tools';

export type SectionState = 'normal' | 'selected' | 'disabled';
import { PropagateType } from 'wasm_service';

export class DataFusionDrawerStore {
  constructor() {
    makeAutoObservable(this);
  }

  runFuseData = withDashboardBackdrop(async (): Promise<void> => {
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    const currentView = dashboardStore.currentViewStore;
    if (!currentView) return;

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        dataFusion: null,
      },
    });

    await waitForToolMessage(graphStore, (msg) => {
      if (msg === 'dataFusion') {
        return true;
      }
    });
  });
}

export class PropagateDrawerStore {
  title = 'Propagate Values';

  constructor() {
    makeAutoObservable(this);
  }

  private _fromType: PropagateType = 'Label';
  private _toType: PropagateType = 'Label';

  featureNodes = new MultiNodeStore();
  sourceNodes = new MultiNodeStore();
  targetNodes = new MultiNodeStore();

  get fromType(): PropagateType {
    return this._fromType;
  }
  set fromType(newState: PropagateType) {
    this._fromType = newState;
  }

  get toType(): PropagateType {
    return this._toType;
  }
  set toType(newState: PropagateType) {
    this._toType = newState;
  }

  // Whether to apply the propagation to nodes or edges
  get applyToNodes(): boolean {
    return (
      this.fromType === 'Label' ||
      this.fromType === 'URL' ||
      this.fromType === 'Color'
    );
  }

  get isDisabled(): boolean {
    return (
      !dashboardStore.currentGraphStore ||
      this.sourceNodes.totalStoredNodes === 0 ||
      this.targetNodes.totalStoredNodes === 0 ||
      (this.fromType === 'Edge' && this.featureNodes.totalStoredNodes === 0)
    );
  }

  execute = withDashboardBackdrop(async (): Promise<void> => {
    const viewStore = dashboardStore.currentViewStore;
    if (!viewStore) return;
    const graphStore = dashboardStore.currentGraphStore;
    if (!graphStore) return;

    graphStore.postMessageToGraphWorker({
      type: 'tool',
      toolInput: {
        propagate: {
          propagateType: this.fromType,
          featureStoreId: this.featureNodes.storeId,
          sourceStoreId: this.sourceNodes.storeId,
          targetStoreId: this.targetNodes.storeId,
          fromType: this.fromType,
          toType: this.toType,
        },
      },
    });
    await viewStore.whenMetaDataUpdates();
  });
}
