import React, { useRef, useEffect } from 'react';
import { Box, Input } from '@mui/material';

interface ShrinkToFitInputProps {
  value: string;
  name: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const ShrinkToFitInput: React.FC<ShrinkToFitInputProps> = ({
  value,
  name,
  autoFocus,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
}) => {
  const spanRef = useRef<HTMLSpanElement>(null);

  const styles = {
    container: {
      display: 'inline-block',
      label: 'shrink-to-fit-input-container',
      height: '100%',
      margin: 0,
      position: 'relative',
    },
    inputComponent: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 0,
      fontSize: 'inherit',
    },
    inputProps: {
      margin: 0,
      padding: 0,
      whiteSpace: 'pre-line',
    },
  };

  useEffect(() => {
    // Update input width based on the text width
    if (spanRef.current && spanRef.current.parentNode instanceof HTMLElement) {
      const textWidth = spanRef.current.offsetWidth;
      spanRef.current.parentNode!.style.width = `${textWidth}px`;
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Box sx={styles.container}>
      <Input
        autoFocus={autoFocus}
        sx={styles.inputComponent}
        disableUnderline={true}
        value={value}
        name={name}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder="Unnamed View"
        inputProps={{
          sx: styles.inputProps,
        }}
      />
      <span
        ref={spanRef}
        style={{
          fontSize: 'inherit',
          visibility: 'hidden',
          whiteSpace: 'pre',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        {value}
      </span>
    </Box>
  );
};

export default ShrinkToFitInput;
