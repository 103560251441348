import * as THREE from 'three';
import { autorun, runInAction, IReactionDisposer } from 'mobx';
import ViewStore, { DragState } from '../stores/ViewStore';

import edgeVertexShader from './edgeVertexShader';
import edgeFragmentShader from './edgeFragmentShader';
import draggingEdgeFragmentShader from './draggingEdgeFragmentShader';
import draggingEdgeVertexShader from './draggingEdgeVertexShader';

export default class EdgeEngine {
  private viewStore: ViewStore;
  private scene: THREE.Scene;
  private camera: THREE.Camera;
  private renderer: THREE.Renderer;

  private allEdgesMaterial: THREE.RawShaderMaterial;
  private allEdgesGeometry: THREE.BufferGeometry;
  private allEdgesObject: THREE.Object3D;

  private draggingEdgesMaterial: THREE.RawShaderMaterial;
  private draggingEdgesGeometry: THREE.BufferGeometry;
  private draggingEdgesObject: THREE.Object3D;

  private nodeTranslateArray: Float32Array;
  private nodeStateArray: Uint32Array;

  private reactions: IReactionDisposer[] = [];

  private showEdges: boolean = true;

  /**
   * @param scene     The scene to draw into
   * @param viewStore Observable view data
   */
  constructor(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    viewStore: ViewStore,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.viewStore = viewStore;

    this.allEdgesMaterial = new THREE.RawShaderMaterial();
    this.allEdgesGeometry = new THREE.BufferGeometry();
    this.allEdgesObject = new THREE.Object3D();

    this.draggingEdgesMaterial = new THREE.RawShaderMaterial();
    this.draggingEdgesGeometry = new THREE.BufferGeometry();
    this.draggingEdgesObject = new THREE.Object3D();

    this.nodeTranslateArray = new Float32Array();
    this.nodeStateArray = new Uint32Array();

    runInAction(() => {
      this.allEdgesMaterial = new THREE.RawShaderMaterial({
        glslVersion: THREE.GLSL3,
        vertexShader: edgeVertexShader,
        fragmentShader: edgeFragmentShader,
        depthTest: false,
        depthWrite: false,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.SrcAlphaFactor,
        vertexColors: true,
        uniforms: {
          axisScale: {
            value: new THREE.Vector3(
              this.viewStore.xAxisScale,
              this.viewStore.yAxisScale,
              1,
            ),
          },
          dragDelta: {
            value: new THREE.Vector3(
              this.viewStore.dragDelta.x,
              this.viewStore.dragDelta.y,
              0,
            ),
          },
        },
      });
    });

    this.draggingEdgesMaterial = new THREE.RawShaderMaterial({
      glslVersion: THREE.GLSL3,
      vertexShader: draggingEdgeVertexShader,
      fragmentShader: draggingEdgeFragmentShader,
      depthTest: false,
      depthWrite: false,
      blending: THREE.CustomBlending,
      blendEquation: THREE.AddEquation,
      blendSrc: THREE.SrcAlphaFactor,
      vertexColors: false,
      uniforms: {
        mousePoint: { value: this.viewStore.currentMouseCoordinates },
        axisScale: {
          value: new THREE.Vector3(
            this.viewStore.xAxisScale,
            this.viewStore.yAxisScale,
            1,
          ),
        },
      },
    });

    this.reactions.push(autorun(this.observeShowEdges));
    this.reactions.push(autorun(this.observeMouseCoordinates));
  }

  private observeShowEdges = (): void => {
    this.showEdges = this.viewStore.showEdges;
    if (this.showEdges) {
      this.scene.add(this.allEdgesObject);
    } else {
      this.scene.remove(this.allEdgesObject);
    }
  };

  observeAxisScale = (): void => {
    this.viewStore.xAxisScale;
    this.viewStore.yAxisScale;
    if (!this.allEdgesMaterial.uniforms.axisScale) return;

    this.allEdgesMaterial.uniforms.axisScale.value = new THREE.Vector3(
      this.viewStore.xAxisScale,
      this.viewStore.yAxisScale,
      1,
    );
    this.allEdgesMaterial.needsUpdate = true;

    this.draggingEdgesMaterial.uniforms.axisScale.value = new THREE.Vector3(
      this.viewStore.xAxisScale,
      this.viewStore.yAxisScale,
      1,
    );
    this.draggingEdgesMaterial.needsUpdate = true;
  };

  private observeMouseCoordinates = (): void => {
    this.viewStore.currentMouseCoordinates;
    if (!this.draggingEdgesMaterial.uniforms.mousePoint) return;
    if (this.viewStore.dragState !== DragState.DRAWING_EDGE) return;

    this.draggingEdgesMaterial.uniforms.mousePoint.value =
      this.viewStore.currentMouseCoordinates;
    this.draggingEdgesMaterial.needsUpdate = true;
  };

  observeDragDelta = (): void => {
    this.viewStore.dragDelta;
    if (!this.allEdgesMaterial.uniforms.dragDelta) return;
    this.allEdgesMaterial.uniforms.dragDelta.value = new THREE.Vector3(
      this.viewStore.dragDelta.x,
      this.viewStore.dragDelta.y,
      0,
    );
    this.allEdgesMaterial.needsUpdate = true;
  };

  allAttributesNeedUpdate = (): void => {
    this.translateAttributeNeedsUpdate();
    this.colorAttributeNeedsUpdate();
    this.stateAttributeNeedsUpdate();
    this.indexNeedsUpdate();
  };

  indexNeedsUpdate = (): void => {
    this.allEdgesGeometry.index &&
      (this.allEdgesGeometry.index.needsUpdate = true);
  };

  translateAttributeNeedsUpdate = (): void => {
    this.allEdgesGeometry.attributes.translate &&
      (this.allEdgesGeometry.attributes.translate.needsUpdate = true);
  };
  colorAttributeNeedsUpdate = (): void => {
    this.allEdgesGeometry.attributes.color &&
      (this.allEdgesGeometry.attributes.color.needsUpdate = true);
  };
  stateAttributeNeedsUpdate = (): void => {
    this.allEdgesGeometry.attributes.stateFlags &&
      (this.allEdgesGeometry.attributes.stateFlags.needsUpdate = true);
  };

  bindSharedArrays(
    nodeTranslateArray: Float32Array,
    nodeColorArray: Float32Array,
    nodeStateArray: Uint32Array,
    edgeIndexArray: Uint32Array,
  ) {
    this.allEdgesGeometry = new THREE.BufferGeometry();
    this.nodeTranslateArray = nodeTranslateArray;
    this.nodeStateArray = nodeStateArray;

    this.allEdgesGeometry.setIndex(
      new THREE.BufferAttribute(edgeIndexArray, 1),
    );

    this.allEdgesGeometry.setAttribute(
      'translate',
      new THREE.BufferAttribute(nodeTranslateArray, 3),
    );
    this.allEdgesGeometry.setAttribute(
      'color',
      new THREE.BufferAttribute(nodeColorArray, 3),
    );
    this.allEdgesGeometry.setAttribute(
      'stateFlags',
      new THREE.BufferAttribute(nodeStateArray, 1, false),
    );

    this.scene.remove(this.allEdgesObject);
    this.allEdgesObject = new THREE.LineSegments(
      this.allEdgesGeometry,
      this.allEdgesMaterial,
    );
    this.allEdgesObject.frustumCulled = false;
    this.showEdges && this.scene.add(this.allEdgesObject);
  }

  /**
   * If we start the drag while rolled over a selected node,
   * then we're dragging from the selection set to the mouse position.
   *
   * If not, we're dragging from the Node(s) we're hovered over when we
   * start to the mouse position.
   * todo:graphworker kill
   */
  startDraggingEdges = (startingSet: 'fromSelection' | 'fromHover'): void => {
    const positions: number[] = [];
    const useMousePoint: number[] = [];

    if (startingSet == 'fromSelection') {
      // all the points of selected nodes
      const points = this.getSelectedPositions();
      points.forEach((point) => {
        positions.push(point.x, point.y, point.z, 0, 0, 0); // Push the point and ignored vertex
        useMousePoint.push(0, 1); // First vertex uses the position attribute, the second uses the mouse point
      });
    } else {
      // all the points of hovered nodes = hover position * total hovered
      const { x, y } = this.viewStore.hoveredPosition;
      for (let i = 0; i < this.viewStore.totalHoveredNodes; i++) {
        positions.push(x, y, 0, 0, 0, 0);
        useMousePoint.push(0, 1);
      }
    }

    this.scene.remove(this.draggingEdgesObject);
    this.draggingEdgesGeometry = new THREE.BufferGeometry();
    this.draggingEdgesGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(positions), 3),
    );
    this.draggingEdgesGeometry.setAttribute(
      'useMousePoint',
      new THREE.BufferAttribute(new Float32Array(useMousePoint), 1),
    );

    this.draggingEdgesObject = new THREE.LineSegments(
      this.draggingEdgesGeometry,
      this.draggingEdgesMaterial,
    );
    this.draggingEdgesObject.frustumCulled = false;
    this.scene.add(this.draggingEdgesObject);
  };

  stopDraggingEdges = (): void => {
    this.scene.remove(this.draggingEdgesObject);
  };

  private getSelectedPositions(): THREE.Vector3[] {
    const points: THREE.Vector3[] = [];

    this.nodeStateArray.forEach((isSelected, translateIndex) => {
      if (isSelected) {
        points.push(
          new THREE.Vector3(
            this.nodeTranslateArray[translateIndex * 3],
            this.nodeTranslateArray[translateIndex * 3 + 1],
            this.nodeTranslateArray[translateIndex * 3 + 2],
          ),
        );
      }
    });
    return points;
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  destroy(): void {
    this.scene.remove(this.allEdgesObject);
    this.allEdgesGeometry.dispose();
    this.allEdgesMaterial.dispose();

    this.scene.remove(this.draggingEdgesObject);
    this.draggingEdgesGeometry.dispose();
    this.draggingEdgesMaterial.dispose();

    this.reactions.forEach((disposer) => disposer());
    this.reactions = [];
  }
}
