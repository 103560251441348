import { makeAutoObservable, runInAction } from 'mobx';

import dashboardStore from './DashboardStore';
import * as GDiff from '../utils/graphDiffHelper';

import { Node } from '../types/app';
import { intRGBToHex, hexTo8bitRGB } from '../utils/utils';

import ViewNode from '../models/ViewNode';

/**
 * Every property in the NodePropertiesStore can be in one of four states:
 */
export enum FieldState {
  None, // No nodes selected
  Single, // Only one node selected
  MultipleSame, // Multiple nodes selected, all the same value
  MultipleMixed, // Multiple nodes selected, but not all the same value
}

type PropertyKey = keyof Node | 'x' | 'y';

const MULTIPLE_VALUES = '[multiple values]';

/**
 * These properties map to the editable ViewNode and Node properties with
 * additional state enum.
 */
export class NodeProperty {
  key: PropertyKey;
  state: FieldState = FieldState.None;
  editable: boolean;
  allowMultiEdit: boolean;
  selectedNodes: Set<ViewNode> = new Set();

  constructor(key: PropertyKey, editable = true, allowMultiEdit = true) {
    this.key = key;
    this.editable = editable;
    this.allowMultiEdit = allowMultiEdit;
    this._value = '';
    makeAutoObservable(this);
  }

  // Note: The value is always a string even though it may represent a number or boolean

  private _value: string;
  get value(): string {
    return this.state === FieldState.MultipleMixed
      ? MULTIPLE_VALUES
      : this._value;
  }
  set value(newValue: string) {
    if (!this.editable) return;
    if (this.state === FieldState.MultipleMixed && !this.allowMultiEdit) return;

    // Setting a value means we're no longer mixed.
    this.state =
      this.state === FieldState.MultipleMixed
        ? FieldState.MultipleSame
        : this.state;
    this._value = newValue;
  }
  get typedValue(): string | number | boolean {
    const numericKeys = ['x', 'y', 'size', 'red', 'green', 'blue'];
    if (numericKeys.includes(this.key)) {
      return parseFloat(this.value);
    }
    if (this.key === 'showLabel') {
      return this.value === 'true';
    }
    return this.value;
  }

  init() {
    const store = dashboardStore.currentViewStore;
    if (!store) return;

    // No nodes selected (should never happen)
    if (store.totalSelectedNodes === 0) {
      this._value = '';
      this.state = FieldState.None;
      return;
    }

    // One node selected
    if (store.singleSelectedNode) {
      this._value = this.getNodePropertyValue(store.singleSelectedNode);
      this.state = FieldState.Single;
      return;
    }

    // Multiple nodes selected
    const first = this.getNodePropertyValue(store.selectedNodesArray[0]);
    const sameValue = store.selectedNodesArray.every(
      (node) => this.getNodePropertyValue(node) === first,
    );
    this.state = sameValue ? FieldState.MultipleSame : FieldState.MultipleMixed;
    this._value = sameValue ? first : MULTIPLE_VALUES;
  }

  /**
   * Returns the value for this property from the ViewNode or
   * its Node as a string.
   */
  getNodePropertyValue(viewNode: ViewNode | undefined): string {
    if (!viewNode) return '';

    if (this.key === 'x' || this.key === 'y') {
      return viewNode[this.key].toString();
    }
    if (
      this.key === 'id' ||
      this.key === 'label' ||
      this.key === 'showLabel' ||
      this.key === 'url' ||
      this.key === 'size' ||
      this.key === 'red' ||
      this.key === 'green' ||
      this.key === 'blue'
    ) {
      return viewNode.node[this.key]?.toString();
    }
    return '';
  }
}

/**
 * Store for the Node Properties modal and NodeEditBar
 */
export class NodePropertiesStore {
  allowSubmit = true;
  problemField = '';

  constructor() {
    makeAutoObservable(this);
  }

  // Node and ViewNode properties

  id = new NodeProperty('id', false);
  label = new NodeProperty('label', true, false);
  showLabel = new NodeProperty('showLabel');
  size = new NodeProperty('size');
  x = new NodeProperty('x');
  y = new NodeProperty('y');
  red = new NodeProperty('red');
  green = new NodeProperty('green');
  blue = new NodeProperty('blue');
  url = new NodeProperty('url');

  private _nodeProperties: NodeProperty[] = [
    this.id,
    this.label,
    this.showLabel,
    this.size,
    this.x,
    this.y,
    this.red,
    this.green,
    this.blue,
    this.url,
  ];

  get nodeProperties(): NodeProperty[] {
    return this._nodeProperties;
  }

  // Get the selected nodes for the view and initialize the properties
  async init(): Promise<void> {
    if (!dashboardStore.currentViewStore) return;
    await dashboardStore.currentViewStore.updateSelectedNodes();
    runInAction(() => {
      this.nodeProperties.forEach((property) => property.init());
    });
  }

  private _open = false;
  get open() {
    return this._open;
  }
  set open(newState) {
    if (!this._open && newState) {
      this.init();
    }
    this._open = newState;
  }

  get title(): string {
    const nodeString =
      dashboardStore.currentViewStore?.totalSelectedNodes === 1
        ? 'Node'
        : 'Nodes';
    return `Properties for ${dashboardStore.currentViewStore?.totalSelectedNodes} ${nodeString}`;
  }

  /**
   * Convert the red, green and blue into a hex color
   */
  get color(): string {
    return intRGBToHex(
      Number(this.red.value),
      Number(this.green.value),
      Number(this.blue.value),
    );
  }
  set color(newHex: string) {
    const rgb = hexTo8bitRGB(newHex);
    if (!rgb) return;

    this.red.value = rgb.r.toString();
    this.green.value = rgb.g.toString();
    this.blue.value = rgb.b.toString();
  }

  updateColor = (): void => {
    dashboardStore.currentGraphStore?.setSelectedColor(
      Number(this.red.value),
      Number(this.green.value),
      Number(this.blue.value),
    );
  };

  updateNodeProperties = async (): Promise<void> => {
    if (
      !dashboardStore.currentGraphStore ||
      !dashboardStore.currentViewStore ||
      dashboardStore.currentViewStore.totalSelectedNodes === 0
    ) {
      return;
    }
    const viewStore = dashboardStore.currentViewStore;
    const diff = GDiff.emptyGraphDiff();

    viewStore.selectedNodes.forEach((viewNode) => {
      this.nodeProperties.forEach((property) => {
        if (!property.editable) return;
        if (property.state === FieldState.None) return;
        if (property.state === FieldState.MultipleMixed) return;

        if (property.key === 'x') {
          if (!viewStore.xNode) return;

          const x = this.x.typedValue as number;
          if (!Number.isNaN(x) && viewNode.x !== x) {
            GDiff.addEdge(diff, viewStore.xNode.id, viewNode.node.id, x);
          }
        } else if (property.key === 'y') {
          if (!viewStore.yNode) return;

          const y = this.y.typedValue as number;
          if (!Number.isNaN(y) && viewNode.y !== y) {
            GDiff.addEdge(diff, viewStore.yNode.id, viewNode.node.id, y);
          }
        } else {
          property.value = property.value?.trim();
          if (property.value === undefined || property.value === '') return;

          if (property.value !== property.getNodePropertyValue(viewNode)) {
            GDiff.updateNode(diff, viewNode.node.id, {
              [property.key]: property.typedValue,
            });
          }
        }
      });
    });
    dashboardStore.currentGraphStore.postMessageToGraphWorker({
      type: 'graphDiff',
      graphDiff: diff,
    });
  };
}
export default NodePropertiesStore;
