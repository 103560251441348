import React from 'react';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';

import { Box, IconButton, Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import HelpMenu from './HelpMenu';

import MenuBar from './MenuBar';
import DriskLogo from './DriskLogo';
import EditBar from './EditBar';
import NodeEditBar from './NodeEditBar';
import { AppProps } from '../types/app';
import dashboardStore from '../stores/DashboardStore';
import { BackgroundTasksButton } from './RunningTasks';
import { MonitoredTasks } from '../services/TaskService';
import ApiKey from './ApiKey';
import { ShareDialogIcon } from './ShareDialog';

const Header = observer(({ signOut, user }: AppProps) => {
  const theme = useTheme();

  const styles = {
    toolbar: {
      display: 'flex',
      alignContent: 'baseline',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: '28px',
      margin: '5px 0px',
    },
    toolbarLeft: {
      display: 'flex',
    },
    toolbarCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    menuBar: {
      display: 'flex',
      alignItems: 'center',
    },
    editBar: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
    },
    nodeEditBar: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
    },
    toolbarRight: {
      display: 'flex',
      marginLeft: 'auto',
    },
    metaData: {},
    metaDataItem: {
      align: 'left',
      fontSize: '9px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    iconButton: {
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };

  const handleSignOut = async () => {
    // Call the original sign-out function
    if (signOut) {
      dashboardStore.closeGraphStore();
      signOut();
    }
  };
  return (
    <Box sx={styles.toolbar}>
      <Box sx={styles.toolbarLeft}>
        <Tooltip title="Home" arrow>
          <IconButton
            color="inherit"
            sx={{ padding: '2px', width: '68px', height: '30px' }}
            onClick={dashboardStore.closeGraphStore}
            size="large"
          >
            <DriskLogo />
          </IconButton>
        </Tooltip>
      </Box>
      {dashboardStore.currentGraphStore && (
        <Box sx={styles.toolbarCenter}>
          <Box sx={styles.editBar}>
            <EditBar />
          </Box>
          <Box sx={styles.menuBar}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: theme.palette.grey[100],
                borderRadius: `${theme.shape.borderRadius}px`,
              }}
            >
              <MenuBar />
            </Box>
          </Box>
          <Box sx={styles.nodeEditBar}>
            <NodeEditBar />
          </Box>
        </Box>
      )}
      <Box sx={styles.toolbarRight}>
        {MonitoredTasks.instance.tasks.size > 0 && <BackgroundTasksButton />}
        <ShareDialogIcon />
        <ApiKey />
        <HelpMenu />
        <Tooltip title={`Log out current user (${user?.username})`} arrow>
          <IconButton
            color="inherit"
            size="small"
            onClick={handleSignOut}
            sx={styles.iconButton}
          >
            <Logout fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
});

export default Header;
