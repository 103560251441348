import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, useTheme } from '@mui/material';
import { HdrStrong } from '@mui/icons-material';
import ColorPicker from './ColorPicker';
import LabelButton from './LabelButton';
import Slider from './Slider';
import dashboardStore from '../stores/DashboardStore';
import { debounce } from '../utils/utils';
import { NodeStates } from '../models/NodeModel';

/**
 * Node Edit bar for turning on/off labels and changing node size etc.
 */

const NodeEditBar = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
      borderRadius: `${theme.shape.borderRadius}px`,
      justifyContent: 'space-between',
      gap: '5px',
      height: '25px',
      padding: '0px 5px 0px 5px',
    },
    iconBox: {
      margin: '1px',
    },
  };

  // Render size change immediately.
  // This is safe because we update the graph as soon as the slider is released so
  // the buffers are only out of sync with the graph while the slider is being moved.
  const changeRenderSize = (size: number) => {
    dashboardStore.openViews.forEach((view) => {
      if (!view.threeView) return;
      if (!view.threeView.nodeSizeArray) return;
      if (!view.threeView.nodeStateArray) return;

      const numNodes = view.threeView.nodeSizeArray.length;
      for (let nodeIndex = 0; nodeIndex < numNodes; nodeIndex++) {
        // & because we want bitwise AND to check the selcted bit is set.
        if (view.threeView.nodeStateArray[nodeIndex] & NodeStates.Selected) {
          view.threeView.nodeSizeArray[nodeIndex] = size;
        }
      }
      view.threeView.sizeAttributeNeedsUpdate();
    });
  };

  // Only change the size in the graph after the user has stopped changing the slider
  const changeSizeInGraph = debounce(
    (size: number) => {
      dashboardStore.currentGraphStore?.setSelectedSize(size);
    },
    undefined,
    100,
  );

  const handleChange = () => {
    changeRenderSize(dashboardStore.nodeSizeSlider.value);
    changeSizeInGraph(dashboardStore.nodeSizeSlider.value);
  };

  return (
    <Box sx={styles.root}>
      <LabelButton />
      <Slider
        name="Node Size"
        Icon={HdrStrong}
        sliderStore={dashboardStore.nodeSizeSlider}
        disabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
        onChange={handleChange}
      ></Slider>
      <ColorPicker />
    </Box>
  );
});

export default NodeEditBar;
