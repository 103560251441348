import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, Tooltip, Popover, Divider } from '@mui/material';

import ColorLensIcon from '@mui/icons-material/ColorLens';
import dashboardStore from '../stores/DashboardStore';

import { runInAction } from 'mobx';

const colors = [
  [
    '#ff8080', // red
    '#ffbf80', // orange
    '#ffff80', // yellow
    '#80ff80', // green
    '#80ffff', // cyan
    '#8080ff', // blue
    '#bf80ff', // purple
    '#ff80ff', // magenta
  ],
  [
    '#ff0000', // red
    '#ff8000', // orange
    '#ffff00', // yellow
    '#00ff00', // green
    '#00ffff', // cyan
    '#0000ff', // blue
    '#8000ff', // purple
    '#ff00ff', // magenta
  ],
  [
    '#bd0000', // red
    '#bd5f00', // orange
    '#bdbd00', // yellow
    '#00bf00', // green
    '#00bfbf', // cyan
    '#0000bf', // blue
    '#6000bf', // purple
    '#bf00bf', // magenta
  ],
  [
    '#000000', // black
    '#202020', // dark dark gray
    '#404040', // dark gray
    '#606060',
    '#808080', // mid gray
    '#a0a0a0',
    '#c0c0c0', // light gray
    '#ffffff', // white
  ],
];

export const ColorPicker = observer((): JSX.Element => {
  // const classes = useStyles();
  const styles = {
    text: { textAlign: 'left', margin: '5px', fontSize: '12px' },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconButton: {
      padding: 0,
      backgroundColor: 'transparent',
      fontSize: 18,
    },
    colorSwatch: {
      flexGrow: 1,
      minWidth: 20,
      minHeight: 20,
      cursor: 'pointer',
    },
  };
  const handleSubmit = () => {
    runInAction(() => {
      dashboardStore.nodePropertiesStore.updateColor();
    });
  };
  const handleNodeColorOpen = () => {
    setPopoverOpen(true);
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box sx={styles.container}>
      {/* ColorPicker button */}
      <Tooltip open={showTooltip} title={'Change Node Color'} arrow>
        <IconButton
          sx={styles.iconButton}
          size="large"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            handleNodeColorOpen();
          }}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          disabled={!dashboardStore.currentGraphStore?.anyNodesSelected}
        >
          <ColorLensIcon />
        </IconButton>
      </Tooltip>
      {/* ColorPicker popover */}
      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* Swatches Box */}
        <Box sx={styles.text}>Swatches</Box>
        {colors.map((row, rowIndex) => (
          <Box
            key={rowIndex}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              margin: '5px',
            }}
          >
            {row.map((color, colIndex) => (
              <Box
                key={colIndex}
                sx={{
                  ...styles.colorSwatch,
                  backgroundColor: color,
                }}
                onClick={(e) => {
                  runInAction(() => {
                    dashboardStore.nodePropertiesStore.color = color;
                    handleSubmit();
                  });
                  e.stopPropagation();
                }}
              />
            ))}
          </Box>
        ))}
        <Divider sx={{ margin: '5px' }} />
        {/*Color Picker Box */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={styles.text}>Color Picker</Box>
          <Box
            sx={{ textAlign: 'right', paddingRight: 2, borderRadius: '5px' }}
          >
            <input
              type="color"
              value={dashboardStore.nodePropertiesStore.color}
              onChange={(e) => {
                runInAction(() => {
                  dashboardStore.nodePropertiesStore.color = e.target.value;
                  handleSubmit();
                });
              }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
});

export default ColorPicker;
