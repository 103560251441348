import MenuStore from './MenuStore';
import Actions from '../actions/actions';

export type ContextMenuContext = 'canvas' | 'xNode' | 'yNode' | 'view';

const buildItems = (
  context: ContextMenuContext,
  parent: MenuStore,
): MenuStore[] => {
  const items: MenuStore[] = [];
  let store: MenuStore;

  switch (context) {
    case 'canvas':
      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.copy, parent),
      );
      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.paste, parent),
      );

      items.push(new MenuStore('divider'));

      items.push(
        MenuStore.actionToMenuStore(
          Actions.createNodeAction('contextmenu'),
          parent,
        ),
      );

      items.push(new MenuStore('divider'));

      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.runCode, parent),
      );

      items.push(new MenuStore('divider'));

      store = new MenuStore('item', null, 'View Nodes', null);
      store.parent = parent;
      store.items = [
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.setSelectedAsXAxisNode,
          store,
        ),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.setSelectedAsYAxisNode,
          store,
        ),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.setNodeAsFilter,
          store,
        ),
      ];
      items.push(store);

      store = new MenuStore('item', null, 'Color/Size Successors', null);
      store.parent = parent;
      store.items = [
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.colorSuccessorsByWeight,
          store,
        ),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.colorSuccessorsByWeightReversed,
          store,
        ),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.sizeSuccessorsByWeight,
          store,
        ),
      ];
      items.push(store);

      items.push(new MenuStore('divider'));

      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.removeNode, parent),
      );
      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.deleteNodes, parent),
      );
      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.deleteEdges, parent),
      );
      items.push(
        MenuStore.actionToMenuStore(Actions.uniqueActions.deleteView, parent),
      );
      return items;

    case 'xNode':
      return [
        MenuStore.actionToMenuStore(Actions.uniqueActions.copyAxisNode, parent),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.setSelectedAsXAxisNode,
          parent,
        ),
      ];
    case 'yNode':
      return [
        MenuStore.actionToMenuStore(Actions.uniqueActions.copyAxisNode, parent),
        MenuStore.actionToMenuStore(
          Actions.uniqueActions.setSelectedAsYAxisNode,
          parent,
        ),
      ];
    case 'view':
      return [
        MenuStore.actionToMenuStore(Actions.uniqueActions.copyViewID, parent),
        MenuStore.actionToMenuStore(Actions.uniqueActions.copyViewURL, parent),
      ];
  }
};
export default class ContextMenuStore extends MenuStore {
  context: ContextMenuContext = 'canvas';

  constructor(context: ContextMenuContext = 'canvas') {
    super('item', null, 'Context Menu', null, []);
    this.context = context;
    this.items = buildItems(context, this);
  }
  openMenu = (
    position: { left: number; top: number },
    context: ContextMenuContext,
  ) => {
    this.context = context;
    // Re-populate the menu every time it's opened
    this.items = buildItems(context, this);
    this.position = position;
    this.open = true;
  };
}
