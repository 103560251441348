/**
 * Functions for projecting and inverse projecting points between coordinate systems.
 */
import { Point } from '../types/app';

export enum CoordinateTransform {
  None = 'None',
  WebMercator = 'WebMercator',
}

export function toExtrinsicCoordinates(
  transform: CoordinateTransform,
  point: Point,
): Point {
  switch (transform) {
    case CoordinateTransform.None:
      return point;
    case CoordinateTransform.WebMercator:
      return { x: point.x, y: latitudeToY(point.y) };
  }
}

export function toIntrinsicCoordinates(
  transform: CoordinateTransform,
  point: Point,
): Point {
  switch (transform) {
    case CoordinateTransform.None:
      return point;
    case CoordinateTransform.WebMercator:
      return { x: point.x, y: yToLatitude(point.y) };
  }
}

/**
 * Transform between latitude and the relative x and y coordinates of the map.
 * Note that given we are using a cylindrical projection, the longitude (x)
 * stays the same. Latitude is expected in degrees and is clamped to ±85 which
 * is the maximum range of the on-screen map.
 */
const MAX_LATITUDE = 85.05112878;

export const latitudeToY = (latitude: number): number => {
  latitude = Math.max(-MAX_LATITUDE, Math.min(MAX_LATITUDE, latitude));
  return (
    (180 * Math.log(Math.tan(Math.PI / 4 + (latitude * Math.PI) / 360))) /
    Math.PI
  );
};

export const yToLatitude = (y: number): number => {
  return (
    (360 / Math.PI) * (Math.atan(Math.exp((Math.PI / 180) * y)) - Math.PI / 4)
  );
};
