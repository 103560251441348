import dashboardStore from './DashboardStore';
import MenuStore from './MenuStore';
import Actions from '../actions/actions';
import { SIMIAN_ALLOWED_GROUP } from '../Constants';

const buildItems = (): MenuStore[] => {
  // node actions
  const nodeMenuStore = [
    MenuStore.actionToMenuStore(Actions.createNodeAction('menubar')),
    MenuStore.actionToMenuStore(Actions.uniqueActions.nodeProperties),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showMedia),
    MenuStore.actionToMenuStore(Actions.uniqueActions.openUrl),
    MenuStore.actionToMenuStore(Actions.uniqueActions.copyLabels),
    MenuStore.actionToMenuStore(Actions.uniqueActions.openSearch),
    new MenuStore('divider'),
    MenuStore.actionToMenuStore(Actions.uniqueActions.mergeNodes),
    MenuStore.actionToMenuStore(Actions.uniqueActions.mergeNodesByLabel),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.mergeNodesIntoPredecessors,
    ),
    new MenuStore('divider'),
    MenuStore.actionToMenuStore(Actions.uniqueActions.duplicateNode),
    MenuStore.actionToMenuStore(Actions.uniqueActions.removeNode),
    MenuStore.actionToMenuStore(Actions.uniqueActions.deleteNodes),
    MenuStore.actionToMenuStore(Actions.uniqueActions.deleteEdges),
  ];

  // select actions
  const selectMenuStore = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.selectAllNodes),
    MenuStore.actionToMenuStore(Actions.uniqueActions.selectInverseNodes),
    MenuStore.actionToMenuStore(Actions.uniqueActions.selectSuccessorsUnion),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.selectSuccessorsIntersection,
    ),
    MenuStore.actionToMenuStore(Actions.uniqueActions.selectPredecessorsUnion),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.selectPredecessorsIntersection,
    ),
  ];

  // get actions
  const getMenuStore = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.getSuccessorsUnion),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.getSuccessorsIntersection,
    ),
    MenuStore.actionToMenuStore(Actions.uniqueActions.getPredecessorsUnion),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.getPredecessorsIntersection,
    ),
    MenuStore.actionToMenuStore(Actions.uniqueActions.getNeighborsUnion),
    MenuStore.actionToMenuStore(Actions.uniqueActions.getNeighborsIntersection),
    MenuStore.actionToMenuStore(Actions.uniqueActions.getConnections),
    MenuStore.actionToMenuStore(Actions.uniqueActions.openGetNodesById),
  ];

  // layout actions
  const layoutMenuStore = Actions.layoutActions.map((action) =>
    MenuStore.actionToMenuStore(action),
  );

  // sortBy actions
  const sortByMenuStore = Actions.sortByActions.map((action) =>
    MenuStore.actionToMenuStore(action),
  );

  // colorBy actions
  const colorByMenuStore = Actions.colorByActions.map((action) =>
    MenuStore.actionToMenuStore(action),
  );

  // newView actions
  const plottingViews = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.showBarplot),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showHistogram),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showScatterPlot),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showCorrelation),
    // Note: showScenarioSpatial for the SIMIAN_ALLOWED_GROUP goes here after a timeout.
  ];

  const embeddingViews = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.showPca),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showTsne),
    MenuStore.actionToMenuStore(Actions.uniqueActions.showLabelEmbedding),
  ];

  const newViewMenuStore = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.newView),
    MenuStore.actionToMenuStore(Actions.uniqueActions.duplicateView),
    MenuStore.actionToMenuStore(Actions.uniqueActions.openViewFromNode),
    new MenuStore('divider'),
    ...plottingViews,
    new MenuStore('divider'),
    ...embeddingViews,
  ];

  // import actions
  const importMenuStore = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.fileUpload),
    MenuStore.actionToMenuStore(Actions.uniqueActions.fileUploadAdditionalData),
  ];

  // export actions
  const exportMenuStore = [
    MenuStore.actionToMenuStore(Actions.uniqueActions.exportGraphAsEd),
    MenuStore.actionToMenuStore(Actions.uniqueActions.exportGraphAsNetworkX),
    MenuStore.actionToMenuStore(
      Actions.uniqueActions.exportSelectedSubgraphToCsv,
    ),
    MenuStore.actionToMenuStore(Actions.uniqueActions.downloadFiles),
  ];

  return [
    new MenuStore('item', null, 'Node', null, nodeMenuStore),
    new MenuStore('item', null, 'Select', null, selectMenuStore),
    new MenuStore('item', null, 'Get', null, getMenuStore),
    new MenuStore('item', null, 'Layout', null, layoutMenuStore),
    new MenuStore('item', null, 'Sort by', null, sortByMenuStore),
    new MenuStore('item', null, 'Color by', null, colorByMenuStore),
    new MenuStore('item', null, 'New View', null, newViewMenuStore),
    new MenuStore('item', null, 'Import', null, importMenuStore),
    new MenuStore('item', null, 'Export', null, exportMenuStore),
  ];
};
export default class MenuBarStore extends MenuStore {
  constructor() {
    super('item', null, 'MenuBarStore', null, buildItems());
    this.items.forEach((item) => (item.position.top = 22));

    /**
     * We can't access the dashboardStore from the MenuStore constructor, so we have
     * to do the SIMIAN_ALLOWED_GROUP check here.
     */
    setTimeout(() => {
      if (dashboardStore.userGroups.some((g) => g === SIMIAN_ALLOWED_GROUP)) {
        const menu = this.items.find((item) => item.label === 'New View');
        menu &&
          menu.items.push(
            MenuStore.actionToMenuStore(
              Actions.uniqueActions.showScenarioSpatial,
            ),
          );
      }
    }, 0);
  }
}
