import * as THREE from 'three';
import { autorun, runInAction, IReactionDisposer } from 'mobx';

import ViewStore from '../stores/ViewStore';

import nodeVertexShader from './nodeVertexShader';
import nodeFragmentShader from './nodeFragmentShader';

/**
 * Instanced Geometry Node Renderer
 *
 */
export default class NodeEngine {
  private scene: THREE.Scene;
  private camera: THREE.Camera;
  private renderer: THREE.Renderer;
  private viewStore: ViewStore;

  private geometry: THREE.BufferGeometry;
  private material: THREE.RawShaderMaterial;
  private object: THREE.Object3D;

  private reactions: IReactionDisposer[] = [];

  private circleGeometry = new THREE.CircleGeometry(1, 24);

  /**
   * @param scene The scene to draw into
   * @param viewStore  Observable view data
   */
  constructor(
    scene: THREE.Scene,
    camera: THREE.Camera,
    renderer: THREE.Renderer,
    viewStore: ViewStore,
  ) {
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.viewStore = viewStore;

    this.geometry = new THREE.BufferGeometry();
    this.material = new THREE.RawShaderMaterial();
    this.object = new THREE.Object3D();

    runInAction(() => {
      this.material = new THREE.RawShaderMaterial({
        glslVersion: THREE.GLSL3,
        vertexShader: nodeVertexShader,
        fragmentShader: nodeFragmentShader,
        depthTest: true,
        depthWrite: true,
        blending: THREE.CustomBlending,
        blendEquation: THREE.AddEquation,
        blendSrc: THREE.SrcAlphaFactor,
        uniforms: {
          sizeScalar: { value: this.viewStore.nodeSizeScalar },
          axisScale: {
            value: new THREE.Vector3(
              this.viewStore.xAxisScale,
              this.viewStore.yAxisScale,
              1,
            ),
          },
          dragDelta: {
            value: new THREE.Vector3(
              this.viewStore.dragDelta.x,
              this.viewStore.dragDelta.y,
              0,
            ),
          },
        },
      });
    });

    this.reactions.push(autorun(this.observeSizeScalar));
  }

  private observeSizeScalar = (): void => {
    this.viewStore.nodeSizeScalar;
    if (!this.material.uniforms.sizeScalar) return;
    this.material.uniforms.sizeScalar.value = this.viewStore.nodeSizeScalar;
    this.material.needsUpdate = true;
  };

  observeAxisScale = (): void => {
    this.viewStore.xAxisScale;
    this.viewStore.yAxisScale;
    if (!this.material.uniforms.axisScale) return;
    this.material.uniforms.axisScale.value = new THREE.Vector3(
      this.viewStore.xAxisScale,
      this.viewStore.yAxisScale,
      1,
    );
    this.material.needsUpdate = true;
  };

  observeDragDelta = (): void => {
    this.viewStore.dragDelta;
    if (!this.material.uniforms.dragDelta) return;
    this.material.uniforms.dragDelta.value = new THREE.Vector3(
      this.viewStore.dragDelta.x,
      this.viewStore.dragDelta.y,
      0,
    );
    this.material.needsUpdate = true;
  };

  allAttributesNeedUpdate = (): void => {
    this.translateAttributeNeedsUpdate();
    this.colorAttributeNeedsUpdate();
    this.sizeAttributeNeedsUpdate();
    this.stateAttributeNeedsUpdate();
  };

  translateAttributeNeedsUpdate = (): void => {
    this.geometry.attributes.translate &&
      (this.geometry.attributes.translate.needsUpdate = true);
  };
  colorAttributeNeedsUpdate = (): void => {
    this.geometry.attributes.color &&
      (this.geometry.attributes.color.needsUpdate = true);
  };
  sizeAttributeNeedsUpdate = (): void => {
    this.geometry.attributes.size &&
      (this.geometry.attributes.size.needsUpdate = true);
  };
  stateAttributeNeedsUpdate = (): void => {
    this.geometry.attributes.stateFlags &&
      (this.geometry.attributes.stateFlags.needsUpdate = true);
  };

  bindSharedArrays = (
    nodeTranslateArray: Float32Array,
    nodeColorArray: Float32Array,
    nodeSizeArray: Float32Array,
    nodeStateArray: Uint32Array,
  ): void => {
    this.geometry.dispose();
    this.material.dispose();

    this.geometry = new THREE.InstancedBufferGeometry();
    this.geometry.index = this.circleGeometry.index;
    this.geometry.attributes = this.circleGeometry.attributes;

    this.geometry.setAttribute(
      'translate',
      new THREE.InstancedBufferAttribute(nodeTranslateArray, 3, false),
    );

    this.geometry.setAttribute(
      'color',
      new THREE.InstancedBufferAttribute(nodeColorArray, 3, false),
    );

    this.geometry.setAttribute(
      'size',
      new THREE.InstancedBufferAttribute(nodeSizeArray, 1, false),
    );

    this.geometry.setAttribute(
      'stateFlags',
      new THREE.InstancedBufferAttribute(nodeStateArray, 1, false),
    );

    const newObject = new THREE.Mesh(this.geometry, this.material);
    newObject.frustumCulled = false;

    this.scene.remove(this.object);
    this.object = newObject;
    this.scene.add(this.object);
  };

  render = () => {
    this.renderer.render(this.scene, this.camera);
  };

  destroy(): void {
    this.scene.remove(this.object);
    this.object = new THREE.Object3D();

    this.geometry.deleteAttribute('translate');
    this.geometry.deleteAttribute('color');
    this.geometry.deleteAttribute('size');
    this.geometry.deleteAttribute('stateFlags');
    this.geometry.dispose();
    this.geometry = new THREE.InstancedBufferGeometry();
    this.material.dispose();

    this.reactions.forEach((disposer) => disposer());
    this.reactions = [];
  }
}
