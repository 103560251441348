import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';

import { Box } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import MenuStore from '../stores/MenuStore';
import { ContextMenuContext } from '../stores/ContextMenuStore';

/**

How Menus work in Edge

Components

MenuBar.tsx
- A horizontal, top-level menu bar
- Takes an array of MenuStores
- Each dropdown is a regular Menu.tsx component
- Drop downs don't pop out unless clicked

ContextMenu.tsx
- A holder for a Menu.tsx that is positioned near the mouse.
- Takes an array of MenuStores

Menu.tsx
- The standard menu with icons/labels/command keys
- Takes an array of MenuStores
- Linked to actions
- with expandable (recursive) sub menus.
- On rollover of any nested item, the sub menu pops out
- An onClose function is passed through the nesting and is called when a the menu wants to close itself (eg, after it performs an action)

Stores

MenuStore.ts
- A mobx store that is used by both MenuBar and ContextMenu
- Recursive and can be nested.
- Keeps it's .open state which refers to whether its _children_ are open.
- Populated with the MenuBuilder

MenuBuilder.ts
- Creates an array of MenuStores and populates them with all the submenu data.
- Injected into the MenuBar or ContextMenu


------
Item A
Item B   -------
Item C > Item Ca
------   Item Cb >
         Item Cc
         -------

Item C's .open state is true

 */
interface MenuItemProps {
  menuStore: MenuStore;
  onClose?: () => void;
  showLeftColumn: boolean; // true if we should leave space for an icons even if this item doesn't have one
  showRightColumn: boolean;
  closeOthers?: (arg0: MenuStore) => void;
  isAtRight?: boolean;
}
const MenuItem = observer(
  ({
    menuStore,
    showLeftColumn,
    showRightColumn,
    onClose,
    closeOthers,
  }: MenuItemProps): JSX.Element => {
    const theme = useTheme();

    const close = () => {
      onClose && onClose();
    };
    const handleMouseEnter = () => {
      closeOthers && closeOthers(menuStore);
      // close siblings of this menuStore
      menuStore.parent?.items.forEach((sibling) => {
        if (sibling !== menuStore) {
          sibling.open = false;
        }
      });

      // close all children of this menuStore
      menuStore.items.forEach((child) => {
        if (child.type === 'item') {
          child.open = false;
        }
      });
      menuStore.open = true;
    };

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (menuStore.action?.action && !menuStore.action.disabled) {
        menuStore.action.action();
        close();
      } else if (menuStore.items.length > 0) {
        // Ensure that the submenu is open (it should be, but...)
        if (!menuStore.open) menuStore.open = true;
      }
    };

    const styles = {
      divider: {
        width: '100%',
        height: '1px',
        backgroundColor: theme.palette.divider,
        borderRadius: `${theme.shape.borderRadius}px`,
      },
      row: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        padding: '2px 6px 0 6px',
        height: '24px',
        whiteSpace: 'nowrap',
        color: menuStore.action?.disabled
          ? theme.palette.text.disabled
          : 'disabled',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.grey[100],
        },
        minWidth: '',
        borderRadius: `${theme.shape.borderRadius}px`,
      },
      iconBox: {
        fontSize: '16px',
        width: '20px',
        borderRadius: `${theme.shape.borderRadius}px`,
      },
      text: {
        paddingLeft: '2px',
        paddingRight: 'unset',
        flexGrow: 1,
        textAlign: 'left',
      },
      rightColumnBox: {
        paddingLeft: '4px',
        paddingRight: 'unset',
        minWidth: '20px',
        textAlign: 'right',
        position: 'relative',
        left: 'unset',
        borderRadius: `${theme.shape.borderRadius}px`,
      },
      arrowIcon: {
        fontSize: '20px',
        position: 'absolute',
        top: 0,
        right: '-8px',
        left: 'unset',
      },
      widthHolder: {
        width: '100%',
      },
    };

    if (menuStore.type === 'divider') {
      return <Box sx={styles.divider}></Box>;
    }

    const label = menuStore.action
      ? menuStore.action.shortLabel
      : menuStore.label;
    const rightBoxContent =
      menuStore.items.length > 0 ? (
        <ArrowRight sx={styles.arrowIcon}></ArrowRight>
      ) : (
        menuStore.action?.commandString
      );

    return (
      <Box
        sx={styles.row}
        onMouseEnter={handleMouseEnter}
        onClick={handleClick}
      >
        {showLeftColumn ? (
          <Box sx={styles.iconBox}>
            {menuStore.action?.icon ? menuStore.action.icon : <></>}
          </Box>
        ) : (
          <></>
        )}
        <Box sx={styles.text}>{label}</Box>
        {showRightColumn ? (
          <Box sx={styles.rightColumnBox}>{rightBoxContent}</Box>
        ) : (
          <></>
        )}
        {menuStore.open ? (
          <Menu menuStore={menuStore} onClose={onClose} />
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

interface MenuProps {
  menuStore: MenuStore;
  onClose?: () => void;
  closeOthers?: (arg0: MenuStore) => void;
  // todo: don't need
  context?: ContextMenuContext;
}

const getClosestRelativeParent = (element: HTMLElement) => {
  let parent = element.parentElement;
  while (parent && parent !== document.body) {
    if (getComputedStyle(parent).position === 'relative') {
      return parent;
    }
    parent = parent.parentElement;
  }
  return document.body;
};

/**
 * An absolutely positioned, nestable Menu component that can be used in the ContextMenu or MenuBar
 */
const Menu = observer(
  ({ menuStore, onClose, closeOthers }: MenuProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);

    const [subMenuToTheLeft, setSubMenuToTheLeft] = useState(false);
    const [top, setTop] = useState(0);
    const [positionH, setPositionH] = useState<string | number>('100%');

    useEffect(() => {
      menuStore.atRight = false;
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();

      if (menuStore.isRoot) {
        // This only works for the root
        const parent = getClosestRelativeParent(ref.current);
        const parentRect = parent.getBoundingClientRect();

        // Check if we're off screen to the right
        const distanceToRight =
          parentRect.left + menuStore.position.left + rect.width;

        if (distanceToRight > window.innerWidth || menuStore.atRight) {
          menuStore.atRight = true;
          setPositionH(
            menuStore.position.left + window.innerWidth - distanceToRight,
          );
          setSubMenuToTheLeft(false);
        } else {
          setPositionH(menuStore.position.left);
          setSubMenuToTheLeft(false);
        }

        // Check if we're off screen to the bottom
        const distanceToBottom =
          parentRect.top + menuStore.position.top + rect.height;
        if (distanceToBottom > window.innerHeight) {
          setTop(
            menuStore.position.top + window.innerHeight - distanceToBottom,
          );
        } else {
          setTop(menuStore.position.top);
        }
      } else {
        setPositionH('100%');
        if (rect.right > window.innerWidth || menuStore.atRight) {
          setSubMenuToTheLeft(true);
        } else {
          setSubMenuToTheLeft(false);
        }

        // Check if we're off screen to the bottom
        if (rect.bottom > window.innerHeight) {
          setTop(window.innerHeight - rect.height);
        } else {
          setTop(menuStore.position.top);
        }
      }
    }, [menuStore.open]);

    const theme = useTheme();
    const styles = {
      root: {
        position: 'absolute',
        // It may seem counter intuitive, but when a submenu is off to the left, we use the right property
        [subMenuToTheLeft ? 'right' : 'left']: positionH,
        top,
        zIndex: 100,
        backgroundColor: 'white',
        boxShadow: '0 0 5px #ddd',
        padding: '4px 0 4px 0',
        fontSize: 12,
        borderRadius: `${theme.shape.borderRadius}px`,
        label: 'menu-root',
      },
    };

    const anyIcons = menuStore.items.some(
      (item) => item.type === 'item' && item.action?.icon,
    );
    const anyCommands = menuStore.items.some(
      (item) => item.type === 'item' && item.action?.commandString,
    );
    const anySubMenus = menuStore.items.some(
      (item) => item.type === 'item' && item.items.length > 0,
    );

    return !open ? (
      <></>
    ) : (
      <Box sx={styles.root} ref={ref}>
        {menuStore.items.map((menuStore, index) => (
          <MenuItem
            menuStore={menuStore}
            key={index}
            showLeftColumn={anyIcons}
            showRightColumn={anyCommands || anySubMenus}
            onClose={onClose}
            closeOthers={closeOthers}
          />
        ))}
      </Box>
    );
  },
);
export default Menu;
