import {
  EdgeDiff,
  GraphDiff,
  NodeDiff,
  NodeId,
  NodeUpdate,
} from '../types/app';

export function addNode(
  graphDiff: GraphDiff,
  nodeId: NodeId,
  nodeUpdate: NodeUpdate,
) {
  graphDiff.nodes.newOrUpdated.set(nodeId, nodeUpdate);
  graphDiff.nodes.deleted.delete(nodeId);
}

/**
 * If the node doesn't exist, add it.
 * Otherwise, update only the defined fields.
 */
export function updateNode(
  graphDiff: GraphDiff,
  nodeId: NodeId,
  nodeUpdate: NodeUpdate,
) {
  const existingNode: NodeUpdate | undefined =
    graphDiff.nodes.newOrUpdated.get(nodeId);

  if (!existingNode) {
    addNode(graphDiff, nodeId, nodeUpdate);
    return;
  }

  const mergedNode = { ...existingNode, ...nodeUpdate };
  graphDiff.nodes.newOrUpdated.set(nodeId, mergedNode);
  graphDiff.nodes.deleted.delete(nodeId);
}

export function addEdge(
  graphDiff: GraphDiff,
  from: NodeId,
  to: NodeId,
  weight: number,
) {
  const inner_map: Map<NodeId, number> | undefined =
    graphDiff.edges.newOrUpdated.get(from);
  if (inner_map === undefined) {
    graphDiff.edges.newOrUpdated.set(from, new Map([[to, weight]]));
  } else {
    inner_map.set(to, weight);
  }
  const inner_set: Set<NodeId> | undefined = graphDiff.edges.deleted.get(from);
  if (inner_set !== undefined) {
    inner_set.delete(to);
    if (inner_set.size === 0) {
      graphDiff.edges.deleted.delete(from);
    }
  }
}

export function deleteNode(graphDiff: GraphDiff, nodeId: NodeId) {
  graphDiff.nodes.deleted.add(nodeId);
  graphDiff.nodes.newOrUpdated.delete(nodeId);

  // remove all edges where node_id is predecessor
  graphDiff.edges.newOrUpdated.delete(nodeId);

  for (const [from, toWeight] of graphDiff.edges.newOrUpdated) {
    if (toWeight.has(nodeId)) {
      const inner: Set<NodeId> | undefined = graphDiff.edges.deleted.get(from);
      if (inner === undefined) {
        graphDiff.edges.deleted.set(from, new Set([nodeId]));
      } else {
        inner.add(nodeId);
      }
    }
    toWeight.delete(nodeId);
  }
}

export function deleteEdge(graphDiff: GraphDiff, from: NodeId, to: NodeId) {
  const inner: Set<NodeId> | undefined = graphDiff.edges.deleted.get(from);
  if (inner === undefined) {
    graphDiff.edges.deleted.set(from, new Set([to]));
  } else {
    inner.add(to);
  }
  const inner_map: Map<NodeId, number> | undefined =
    graphDiff.edges.newOrUpdated.get(from);
  if (inner_map !== undefined) {
    inner_map.delete(to);
    if (inner_map.size === 0) {
      graphDiff.edges.newOrUpdated.delete(from);
    }
  }
}

export function emptyGraphDiff(): GraphDiff {
  return {
    nodes: { newOrUpdated: new Map(), deleted: new Set() },
    edges: { newOrUpdated: new Map(), deleted: new Map() },
  };
}

// Deserialize NodeDiff
// eslint-disable-next-line
function deserializeNodeDiff(data: any): NodeDiff {
  if (data === undefined) {
    return {
      newOrUpdated: new Map(),
      deleted: new Set(),
    };
  }
  return {
    newOrUpdated: new Map(Object.entries(data.newOrUpdated)),
    deleted: new Set(data.deleted),
  };
}

// Deserialize EdgeDiff
// eslint-disable-next-line
function deserializeEdgeDiff(data: any): EdgeDiff {
  if (data === undefined) {
    return {
      newOrUpdated: new Map(),
      deleted: new Map(),
    };
  }
  const updatedEdges = new Map<NodeId, Map<NodeId, number>>();
  for (const nodeId of Object.keys(data.newOrUpdated)) {
    const inner: Map<NodeId, number> = new Map();
    for (const [to, weight] of Object.entries(data.newOrUpdated[nodeId])) {
      inner.set(to, weight as number);
    }
    updatedEdges.set(nodeId, inner);
  }
  return {
    newOrUpdated: updatedEdges,
    deleted: new Map(Object.entries(data.deleted)),
  };
}

// Deserialize GraphDiff
// eslint-disable-next-line
export function deserializeGraphDiff(data: any): GraphDiff {
  return {
    nodes: deserializeNodeDiff(data.nodes),
    edges: deserializeEdgeDiff(data.edges),
  };
}
