import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import LoadingBackdropStore from '../stores/LoadingBackdropStore';

interface LoadingBackdropProps {
  loadingBackdropStore: LoadingBackdropStore;
}

export const LoadingBackdropOf = observer(
  ({ loadingBackdropStore }: LoadingBackdropProps) => {
    const theme = useTheme();
    return (
      <Backdrop
        sx={{
          borderRadius: `${theme.shape.borderRadius}px`,
          zIndex: 90,
          position: 'absolute',
        }}
        open={loadingBackdropStore.backdropOpen}
      >
        <CircularProgress sx={{ color: '#fff' }} />
      </Backdrop>
    );
  },
);

const DashboardLoadingBackdrop = observer(() => {
  return (
    <LoadingBackdropOf
      loadingBackdropStore={dashboardStore.loadingBackdropStore}
    />
  );
});

export default DashboardLoadingBackdrop;
