import { DRiskCSS2DObject } from './DRiskCSS2DObject';

export class AxisLabelCSSObject extends DRiskCSS2DObject {
  alignLeft = true;
  horizontal = true; // true for x axis, false for y axis

  constructor(element: HTMLElement) {
    super(element);
  }

  applyTransform() {
    if (!(this.vector && this.widthHalf && this.heightHalf)) return;

    const positionTransform = `translate(
      ${this.vector.x * this.widthHalf + this.widthHalf}px,
      ${-this.vector.y * this.heightHalf + this.heightHalf}px
    )`;
    const rotation = this.horizontal ? '' : `rotate(-90deg)`;

    let offsetTransform = '';
    if (this.horizontal) {
      if (this.alignLeft) {
        offsetTransform = 'translate(0, -50%) translateX(2px)';
      } else {
        offsetTransform = 'translate(-100%, -50%) translateX(-2px)';
      }
    } else {
      if (this.alignLeft) {
        this.element.style.transformOrigin = '0 50%';
        offsetTransform = 'translate(0, -50%) translateY(-2px)';
      } else {
        this.element.style.transformOrigin = '100% 50%';
        offsetTransform = 'translate(-100%, -50%) translateY(2px)';
      }
    }

    this.element.style.transform = `${offsetTransform} ${positionTransform} ${rotation}`;
  }
}
