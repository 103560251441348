import React from 'react';
import { runInAction } from 'mobx';
import { Box, Button, Divider } from '@mui/material';
import SwitchButton, { SwitchOption } from './lib/SwitchButton';
import { NodeSelectionBox } from './NodeSelectionBox';
import dashboardStore from '../stores/DashboardStore';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';
import { GroupByStatisticType } from '../types/app';

/**
 * todo: move all the behavior out of the component and into the store.
 * See DataFusion.tsx and DataFusionStore.ts for an example.
 */
export const GroupByStatistics = observer((): JSX.Element => {
  const theme = useTheme();

  const styles = {
    groupByStatistics: {
      border: '1px solid rgba(0, 0, 0, .125)',
      position: 'relative',
      bgcolor: 'white',
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: '5px',
    },
  };

  const groupByStatisticStore =
    dashboardStore.rightDrawerStore.groupByStatisticStore;
  return (
    <Box sx={styles.groupByStatistics}>
      <Box sx={{ fontSize: '14px', margin: '4px' }}>Group By</Box>
      <Box>
        {/* Stat Type Selection */}
        <SwitchButton
          options={[
            { label: 'Count', value: 'count' },
            { label: 'Sum', value: 'sum' },
            { label: 'Mean', value: 'mean' },
            { label: 'Std', value: 'std' },
            { label: 'Min', value: 'min' },
            { label: 'Max', value: 'max' },
          ]}
          value={groupByStatisticStore.groupByStatistic}
          onChange={(option: SwitchOption) => {
            if (option.value === 'count') {
              // This makes sure that the numeric node is cleared
              // when switching to count
              groupByStatisticStore.featureNode.reset();
            }
            runInAction(() => {
              groupByStatisticStore.groupByStatistic =
                option.value as GroupByStatisticType;
            });
          }}
        />
      </Box>
      {/* Numeric or Datetime Node Selection */}
      {groupByStatisticStore.groupByStatistic != 'count' ? (
        // Only show numeric node input when the statistic is not count
        <NodeSelectionBox
          label={'Of Feature Node'}
          clear={groupByStatisticStore.featureNode.reset}
          select={groupByStatisticStore.featureNode.update}
          value={groupByStatisticStore.featureNode.label}
          isDisabled={groupByStatisticStore.isFeatureNodeDrawerDisabled()}
        />
      ) : null}

      <Divider sx={{ padding: '5px' }} />
      {/* GroupBy Node Selection */}
      <NodeSelectionBox
        label="Grouped by"
        clear={groupByStatisticStore.groupByNode.reset}
        select={groupByStatisticStore.groupByNode.update}
        value={groupByStatisticStore.groupByNode.label}
        isDisabled={groupByStatisticStore.isGroupByNodeDrawerDisabled()}
      />
      {/* Calculate Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={groupByStatisticStore.execute}
          disabled={
            groupByStatisticStore.groupByNode === undefined ||
            (groupByStatisticStore.groupByStatistic !== 'count' &&
              groupByStatisticStore.featureNode.node === undefined)
          }
          sx={{ fontSize: '10px', textTransform: 'none' }}
        >
          Calculate
        </Button>
      </Box>
    </Box>
  );
});
