import React, { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Alert, Snackbar, Button } from '@mui/material';
import ErrorStore, { Snack } from '../stores/ErrorStore';

const MAX_CHARS_TO_SHOW = 200;

const DRiskSnackbar = observer((): JSX.Element => {
  const [snack, setSnack] = useState<Snack | undefined>(undefined);

  useEffect(() => {
    if (ErrorStore.previousEvents.length > 0) {
      const newSnack =
        ErrorStore.previousEvents[ErrorStore.previousEvents.length - 1];
      if (snack && newSnack.id !== snack.id) {
        handleClose(snack);
      } else if (snack && newSnack.id === snack.id) {
        return;
      }
      setSnack(newSnack);
    } else {
      setSnack(undefined);
    }
  }, [ErrorStore.previousEvents.length, snack]);

  const handleClose = (snack: Snack) => {
    runInAction(() => {
      // Remove the event from the list
      ErrorStore.previousEvents.splice(
        ErrorStore.previousEvents.indexOf(snack),
        1,
      );
      // Re-index events
      ErrorStore.previousEvents.forEach((event, index) => {
        event.id = index;
      });
    });
  };

  const actionButton = (snack: Snack) => {
    if (snack.userAction !== undefined) {
      return (
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            snack.userAction?.callback();
            handleClose(snack);
          }}
        >
          {snack.userAction.label}
        </Button>
      );
    }
    return null;
  };

  if (!snack) {
    return <></>;
  }

  return (
    <>
      <Snackbar
        key={snack.id}
        open={true}
        autoHideDuration={5000}
        onClose={() => handleClose(snack)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ marginBottom: 0 }}
        ClickAwayListenerProps={{ onClickAway: () => {} }}
      >
        <Alert
          key={0}
          severity={snack.severity}
          sx={{ width: '100%' }}
          onClose={() => handleClose(snack)}
          action={actionButton(snack)}
          style={{ marginTop: 0 }}
        >
          {snack.message &&
            ErrorStore.formattedMessage(snack, MAX_CHARS_TO_SHOW)}
        </Alert>
      </Snackbar>
    </>
  );
});

export default DRiskSnackbar;
