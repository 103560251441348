import { makeAutoObservable, runInAction } from 'mobx';

export interface UserActionOption {
  optionName: string;
  callback: () => void;
}

export interface UserActionRequest {
  dialogTitle: string;
  // if binary, then the "Enter" key gets mapped to the
  // first callback function. Which should be the "True" case
  dialogActions: UserActionOption[];
}

export interface Alert {
  message: string;
  userActionRequest: UserActionRequest;
}

/**
 * Singleton Mobx observable that holds the current error and last
 * 100 to 200 previous errors.
 */
class AlertsDialogStore {
  latestEvent: Alert | null = null;
  previousEvents: Alert[] = [];

  private constructor() {
    makeAutoObservable(this);
  }
  static instance = new AlertsDialogStore();

  private _showDialog = false;
  get showDialog(): boolean {
    return this._showDialog;
  }
  set showDialog(newState: boolean) {
    this._showDialog = newState;
  }

  private doAlert(customMessage: string, userActionRequest: UserActionRequest) {
    runInAction(() => {
      const alert: Alert = {
        message: customMessage,
        userActionRequest: userActionRequest,
      };

      this.latestEvent = alert;
      this.previousEvents.push(alert);
      this.showDialog = true;
    });
  }

  /**
   * Add an error to the model. Can be called with just a message or with an
   * error or response object.
   */
  newAlert(customMessage: string, availableAction: UserActionRequest) {
    this.doAlert(customMessage, availableAction);
  }

  yesNoAlert(
    customMessage: string,
    callback: () => void,
    callbackDismiss?: () => void,
    title?: string,
    confirm?: string,
    dismiss?: string,
  ) {
    const userActionRequest: UserActionRequest = {
      dialogTitle: title ?? '',
      dialogActions: [
        {
          optionName: confirm ?? 'Yes',
          callback: callback,
        },
        {
          optionName: dismiss ?? 'No',
          callback: callbackDismiss ? callbackDismiss : () => {},
        },
      ],
    };
    this.newAlert(customMessage, userActionRequest);
  }
}

export default AlertsDialogStore.instance;
