import { SxProps, Theme } from '@mui/system';

interface TextEllipsisProps {
  lines?: number;
}

export const textEllipsis = ({
  lines = 1,
}: TextEllipsisProps): SxProps<Theme> => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(lines > 1
    ? {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
      }
    : {
        whiteSpace: 'nowrap',
      }),
});
