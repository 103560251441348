import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Chip } from '@mui/material';
import ViewStore from '../stores/ViewStore';

interface Props {
  viewStore: ViewStore;
}

const FilterSelector = observer(({ viewStore }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        padding: '4px',
        borderRadius: '4px',
        boxShadow: 3,
      }}
    >
      {viewStore.filterNodes.length === 0 ? (
        <Box>No Filters</Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>Filter Nodes:</Box>
          {viewStore.filterNodes.map((node) => {
            return (
              <Chip
                key={node.id}
                label={node.label}
                onDelete={async () => {
                  viewStore.deleteFilterNode(node);
                }}
                sx={{
                  margin: '2px',
                  fontSize: 12,
                  height: '16px',
                  borderRadius: '4px',
                }}
                color="primary"
                size="small"
                variant="outlined"
              />
            );
          })}{' '}
        </Box>
      )}
    </Box>
  );
});
export default FilterSelector;
