import { GraphId, Uuid } from '../types/app';
import { makeAutoObservable, runInAction } from 'mobx';
import ErrorStore from '../stores/ErrorStore';
import { ClientToServer, ServerToClient, TaskResult } from '../types/wasm';
import DashboardStore from '../stores/DashboardStore';
import GraphStore from '../stores/GraphStore';
import AlertDialogStore from '../stores/AlertDialogStore';

export class MonitoredTask {
  taskId: Uuid;
  label: string;
  progress: number;
  userRequest: string | undefined; // server asking yes/no question
  private graphStore: GraphStore;
  private lastResult: TaskResult | undefined;
  callback: (result: TaskResult) => void;

  constructor(
    taskId: Uuid,
    label: string,
    cb: (result: TaskResult) => void,
    graphStore: GraphStore,
  ) {
    this.taskId = taskId;
    this.label = label;
    this.progress = 0;
    this.userRequest = undefined;
    this.graphStore = graphStore;
    this.callback = cb;

    this.postTaskMessage('subscribe');
    makeAutoObservable(this);
  }

  private postTaskMessage(message: ClientToServer) {
    this.graphStore.postMessageToGraphWorker({
      type: 'longRunningTask',
      longRunningTask: { taskId: this.taskId, msg: message },
    });
  }

  handleMessage(msg: ServerToClient) {
    switch (msg.type) {
      case 'progress':
        this.progress = msg.payload;
        break;
      case 'result':
        this.lastResult = msg.payload;
        break;
      case 'error':
        ErrorStore.setError(`Task Error: ${this.label} - ${msg.payload}`);
        break;
      case 'warning':
        ErrorStore.setWarning(msg.payload);
        break;
      case 'yesNoQ': {
        if (DashboardStore.currentGraphStore?.id !== this.graphStore.id) {
          console.debug('Requesting user action on a non-active graph');
          return;
        }
        const [questionId, question] = msg.payload;
        AlertDialogStore.yesNoAlert(
          question,
          () => this.postTaskMessage({ reply: [questionId, true] }),
          () => this.postTaskMessage({ reply: [questionId, false] }),
          `${this.label}`,
        );
        break;
      }
      case 'finished': {
        if (this.lastResult === undefined && msg.payload.type === 'success') {
          ErrorStore.setError(
            `An error occurred collecting the result of task: ${this.label}`,
          );
        } else if (
          this.lastResult !== undefined &&
          msg.payload.type === 'success'
        ) {
          this.callback(this.lastResult);
          this.lastResult = undefined;
          ErrorStore.setSuccess(`Task: ${this.label} finished`);
        }
        MonitoredTasks.instance.removeTask(this.taskId);
        break;
      }
    }
  }
}

export class MonitoredTasks {
  tasks: Map<Uuid, MonitoredTask> = new Map();
  meanProgress = (): number => {
    if (this.tasks.size === 0) {
      return 0;
    }
    let progress = 0;
    for (const task of this.tasks.values()) {
      progress += task.progress;
    }
    return progress / this.tasks.size;
  };

  static instance = new MonitoredTasks();
  private constructor() {
    makeAutoObservable(this);
  }

  addTask(taskId: Uuid, label: string, cb: (result: TaskResult) => void) {
    if (this.tasks.has(taskId)) {
      throw new Error('Task already being monitored');
    }
    const graphStore = DashboardStore.currentGraphStore;
    if (!graphStore) return;
    runInAction(() => {
      this.tasks.set(taskId, new MonitoredTask(taskId, label, cb, graphStore));
    });
  }

  getTask(taskId: Uuid): MonitoredTask | undefined {
    return this.tasks.get(taskId);
  }

  removeTask(taskId: Uuid) {
    this.tasks.delete(taskId);
  }
}

export const openViewsCallback = async (
  graph_id: GraphId,
  result: TaskResult,
) => {
  switch (result.type) {
    case 'viewIds':
      if (DashboardStore.currentGraphStore?.id === graph_id) {
        const viewIds = result.payload;
        await DashboardStore.openMultipleViews(viewIds);
        return;
      }
  }
};
