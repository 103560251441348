export default `
precision highp float;

uniform mat4 modelViewMatrix; // Model-View matrix
uniform mat4 projectionMatrix; // Projection matrix

uniform vec3 axisScale; // Independent axis scaling which is applied to the translation
uniform vec3 dragDelta; // Offset applied to the translation for selected nodes

in vec3 translate; // Node Positions
in vec3 color; // Node Colors
/**
 * State of individual Nodes:
 * - 1u: selected : Respect the dragDelta
 * - 2u: hovered  : Edges don't currently react to being hovered
 */
in uint stateFlags;

out vec3 vColor; // Pass color to fragment shader

void main() {
  bool selected = (stateFlags & 1u) != 0u;

  vec3 offsetTranslation = selected ? dragDelta : vec3(0.0);
  vec3 scaledTranslation = (translate + offsetTranslation) * axisScale;

  vec4 mvPosition = modelViewMatrix * vec4(scaledTranslation, 1.0);

  vColor = color;
  gl_Position = projectionMatrix * mvPosition;
}
`;
