import React from 'react';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import dashboardStore from '../stores/DashboardStore';
import { NodeSelectionBox } from './NodeSelectionBox';

export const WhatButton = observer((): JSX.Element => {
  const whatButtonStore = dashboardStore.rightDrawerStore.whatButtonStore;

  return (
    <>
      <Box>
        <NodeSelectionBox
          label="Feature Nodes"
          clear={whatButtonStore.selectedFeatureNodes.reset}
          select={whatButtonStore.selectedFeatureNodes.update}
          value={whatButtonStore.selectedFeatureNodes.display}
          isDisabled={false}
        />

        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            whatButtonStore.execute();
          }}
          disabled={whatButtonStore.isDisabled}
          sx={{
            fontSize: '10px',
            marginTop: '10px',
            textTransform: 'none',
          }}
        >
          What&apos;s in My Data?
        </Button>
      </Box>
    </>
  );
});
