import React from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import ErrorStore from '../stores/ErrorStore';
import { observer } from 'mobx-react-lite';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import dashboardStore from '../stores/DashboardStore';
import GraphStore from '../stores/GraphStore';
import { Permissions } from '../stores/ShareDialogStore';
import { SharingListContainer } from './SharingList';
import { GroupAdd, PersonAddAlt1 } from '@mui/icons-material';
import { runInAction } from 'mobx';

//------------------------------ Sharing Dialog-- ----------------------------//

interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
}

const ShareDialog: React.FC<ShareDialogProps> = observer(
  ({ open, onClose }): JSX.Element => {
    if (open === false) {
      // Avoid calling the server if the dialog is not open
      return <></>;
    }
    const graphStore: GraphStore | undefined = dashboardStore.currentGraphStore;
    if (graphStore === undefined) {
      return <></>;
    }
    const graphName = graphStore.name ?? '';
    const store = dashboardStore.shareDialogStore;

    const handleLink = async () => {
      // Copy URL to clipboard
      const url = window.location.href;
      navigator.clipboard.writeText(url);
      ErrorStore.setSuccess('Shareable link copied to clipboard');
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Share {graphName}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          {store.apiSyncing ? (
            <CircularProgress />
          ) : (
            <>
              <SharingListContainer
                textBoxLabel="Email Address"
                autoCompleteOptions={[]}
                listEntries={store.userEntries}
                validPermissions={[Permissions.owner, Permissions.editor]}
                allowNewField={true} // That is allow to create fields that are not in the dropdown list of known options
                addToList={store.addUserToList}
                rmFromList={store.rmUserFromList}
                onUpdatePermission={store.updateUserPermissions}
                AddComponent={PersonAddAlt1}
              />
              {/* Adding some space between blocks */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px',
                }}
              ></Box>
              <SharingListContainer
                textBoxLabel="Group Name"
                autoCompleteOptions={[
                  ...dashboardStore.userGroups.filter(
                    (entry) =>
                      !store.groupEntries
                        .map((entry) => entry.primaryName)
                        .includes(entry),
                  ),
                ]}
                listEntries={store.groupEntries}
                validPermissions={[]}
                allowNewField={false} // You can only share with existing groups
                addToList={store.addGroupToList}
                rmFromList={store.rmGroupFromList}
                onUpdatePermission={() => {}}
                AddComponent={GroupAdd}
              />
            </>
          )}
        </DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px',
          }}
        >
          <ShareLinkIcon label="Copy link" onClick={handleLink} />
        </Box>
      </Dialog>
    );
  },
);

export const ShareDialogIcon = observer(() => {
  const theme = useTheme();
  const styles = {
    iconButton: {
      borderRadius: '50%',
      overflow: 'hidden',
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      marginRight: '5px',
    },
  };

  const store = dashboardStore.shareDialogStore;

  const handleOpen = () => {
    runInAction(() => {
      store.isOpen = true;
    });
  };

  const handleClose = () => {
    runInAction(() => {
      store.isOpen = false;
    });
  };

  return (
    <>
      <Tooltip
        title="Show people and groups that can access the active graph"
        arrow
      >
        <div>
          <IconButton
            onClick={handleOpen}
            color="inherit"
            disabled={dashboardStore.currentGraphStore === undefined}
            size="small"
            sx={styles.iconButton}
          >
            <ShareIcon />
          </IconButton>
          <ShareDialog open={store.isOpen} onClose={handleClose} />
        </div>
      </Tooltip>
    </>
  );
});

interface ButtonProps {
  onClick: () => void;
  label: string;
}

const ShareLinkIcon: React.FC<ButtonProps> = ({ onClick, label }) => {
  return (
    <Tooltip
      title="Copy link to the clipboard after updating the permissions"
      arrow
    >
      <IconButton onClick={onClick}>
        <LinkIcon
          sx={{
            fontSize: '15px',
            marginRight: '5px',
            color: 'white',
            backgroundColor: 'grey',
            borderRadius: '50%',
            rotate: '-45deg',
            padding: '1px',
          }}
        />
        <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>
          {label}
        </Typography>
      </IconButton>
    </Tooltip>
  );
};
