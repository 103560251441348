import {
  APIUploadHeader,
  APIUploadMethod,
  ColorType,
  GraphAccess,
  GraphDiff,
  GraphId,
  GraphMetaData,
  GraphResponse,
  GroupByStatisticType,
  NodeId,
  SimianRequestType,
  SortType,
  SupportedSQLDbs,
  Uuid,
  ViewId,
} from '../types/app';
import { VIEW_X_AXIS, VIEW_Y_AXIS } from '../Constants';
import APIService from './APIService';
import { Transformation } from '../stores/TransformationsDrawerStore';

/**
 * DataService provides all the data for the app, regardless of source.
 *
 * * One instance per Graph except for the static methods to get the list of graphs.
 * * Mobx stores should subscribe to the specific data they need.
 *
 * * For now, there's a 1:1 relationship between subscribers and subscriptions so we only have to
 *   keep track of one subscription per data type (graphs, views, viewData).
 *
 * * Each view has its own subscription.
 */
export default class DataService {
  private static readOnlyEnv =
    process.env.REACT_APP_READ_ONLY_ENDPOINT ?? 'main';
  private static readOnlyURI = (resource: string): string =>
    `s3://drisk-app-files215711-main/readonly_${DataService.readOnlyEnv}/${resource}`;

  /**
   * Graphs - The list of all the Graphs and Views a user has access to with their metadata.
   * Static because there's only one list for the entire app.
   * When the app first loads, this will only have the Graphs. The views are filled in as the
   * user opens Graphs in the left nav.
   */

  static async getGraphs(): Promise<GraphMetaData[]> {
    return await this.getGraphsFromAPI();
  }

  static async getGraphPresignedUrl(
    graphId: GraphId,
  ): Promise<string | undefined> {
    const url = DataService.readOnlyURI(`${graphId}.ed`);
    return APIService.getPresignedURL(url);
  }

  static async newGraph(name: string): Promise<GraphId | undefined> {
    return APIService.newGraph(name);
  }

  static async cloneGraph(
    graphId: GraphId,
    name?: string,
    fromPublic?: boolean,
  ): Promise<GraphMetaData | undefined> {
    switch (fromPublic) {
      case true:
        return APIService.clonePublicGraph(graphId, name);
      default:
        return APIService.cloneGraph(graphId, name);
    }
  }

  static async makeGraphPublic(graphId: GraphId): Promise<boolean> {
    return APIService.makeGraphPublic(graphId);
  }

  private static async getGraphsFromAPI(): Promise<GraphMetaData[]> {
    const graphs = await APIService.getGraphs();

    const graphMeta: GraphMetaData[] = [];
    if (graphs) {
      graphs.forEach((s3Graph) => {
        graphMeta.push({
          id: s3Graph.id,
          name: s3Graph.name,
          description: s3Graph.description,
          tags: s3Graph.tags,
          thumbnail: s3Graph.thumbnail,
          groups: s3Graph.groups,
          ownerEmail: s3Graph.ownerEmail,
          createdAt: s3Graph.createdAt,
          updatedAt: s3Graph.updatedAt,
          isBlueprint: s3Graph.isBlueprint,
        });
      });
    }

    return graphMeta;
  }

  /**
   * View Data
   */

  /**
   * Create a new view.
   */
  static async createView(
    graphId: GraphId,
    label: string,
    xLabel: string = VIEW_X_AXIS,
    yLabel: string = VIEW_Y_AXIS,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return await APIService.createView(graphId, label, xLabel, yLabel);
  }

  /**
   * Tools
   */
  static async barplot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.barplot(graphId, selectedNodeIds);
  }

  static async spatial(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.spatial(graphId, selectedNodeIds);
  }

  static async scatterPlot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.scatterPlot(graphId, selectedNodeIds);
  }

  static async plot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.plot(graphId, selectedNodeIds);
  }

  static async histogramPlot(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.histogram(graphId, selectedNodeIds);
  }

  static async transformNodesAndEdges(
    graphId: GraphId,
    currentViewId: ViewId,
    nodes: NodeId[],
    transformation: Transformation,
    inPlace: boolean,
  ): Promise<[string, GraphDiff] | undefined> {
    return APIService.transformNodesAndEdges(
      graphId,
      currentViewId,
      nodes,
      transformation,
      inPlace,
    );
  }

  static async pcaEmbedding(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.pcaEmbedding(graphId, selectedNodeIds);
  }

  static async tsneEmbedding(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    return APIService.tsneEmbedding(graphId, selectedNodeIds);
  }

  static async sortBy(
    graphId: GraphId,
    viewId: ViewId,
    nodes: NodeId[],
    sortType: SortType,
  ): Promise<GraphDiff | undefined> {
    return APIService.sortBy(graphId, viewId, nodes, sortType);
  }

  static async colorBy(
    graphId: GraphId,
    colorType: ColorType,
    selectedNodeIds?: NodeId[],
    viewId?: ViewId,
    colorByNodeId?: NodeId,
    reversed?: boolean,
  ): Promise<GraphDiff | undefined> {
    return APIService.colorBy(
      graphId,
      colorType,
      selectedNodeIds,
      viewId,
      colorByNodeId,
      reversed,
    );
  }

  static async sizeBy(
    graphId: GraphId,
    nodeId: NodeId,
    reverse: boolean,
  ): Promise<GraphDiff | undefined> {
    return APIService.sizeBy(graphId, nodeId, reverse);
  }

  static async groupByStatistic(
    graphId: GraphId,
    groupByNodeId: NodeId,
    groupByStatisticType: GroupByStatisticType,
    numericNodeId?: NodeId,
  ): Promise<[NodeId, ViewId, GraphDiff] | undefined> {
    return APIService.groupByStatistic(
      graphId,
      groupByNodeId,
      groupByStatisticType,
      numericNodeId,
    );
  }

  static async calculateCorrelation(
    graphId: GraphId,
    featureNodes: NodeId[],
  ): Promise<[string[], GraphDiff] | undefined> {
    return APIService.calculateCorrelation(graphId, featureNodes);
  }

  /**
   * save graph
   */
  static async saveGraph(graphId: GraphId, name: string): Promise<void> {
    await APIService.saveGraph(graphId, name);
  }

  /**
   * delete graph
   */
  static async deleteGraph(graphId: GraphId): Promise<void> {
    await APIService.deleteGraph(graphId);
  }

  /**
   * upload data to a graph
   */
  static async uploadData(
    files: FileList,
    graphId: GraphId,
    newGraph: boolean,
    openViews: ViewId[],
  ): Promise<GraphResponse | undefined> {
    return APIService.postDataFiles(files, graphId, newGraph, openViews);
  }

  /**
   * Upload simian data
   */
  static async uploadSimian(
    graphId: GraphId,
    requestType: SimianRequestType,
    requestId: string,
  ): Promise<string | undefined> {
    return APIService.uploadSimian(graphId, requestType, requestId);
  }

  /**
   * Upload query to SQL database
   */
  static async uploadQuery(
    graphId: GraphId,
    databaseURL: string,
    query: string,
    db?: SupportedSQLDbs,
    authToken: string = '',
  ): Promise<Uuid | undefined> {
    return APIService.uploadQuery(graphId, databaseURL, query, db, authToken);
  }

  /**
   * Upload schema to SQL database
   */
  static async uploadSchema(
    graphId: GraphId,
    databaseURL: string,
    db?: SupportedSQLDbs,
    authToken: string = '',
  ): Promise<[ViewId, GraphDiff, number] | undefined> {
    return APIService.uploadSchema(graphId, databaseURL, db, authToken);
  }

  /**
   * Upload data from an API endpoint
   */
  static async uploadFromAPI(
    graphId: GraphId,
    queryURL: string,
    method: APIUploadMethod,
    headers: APIUploadHeader[],
    body: string,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.uploadFromAPI(graphId, queryURL, method, headers, body);
  }

  static async downloadData(blob: Blob, fileName: string): Promise<void> {
    // Snippet from https://dev.to/nombrekeff/download-file-from-blob-21ho
    const blobUrl = URL.createObjectURL(blob); // in browser-memory address
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileName;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  /**
   * Export graph data
   */
  static async exportSelectionAsCsv(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    return APIService.exportSelectionAsCSV(graphId, selectedNodeIds);
  }

  static async downloadSelectedNodesFiles(
    graphId: GraphId,
    selectedNodeIds: NodeId[],
  ): Promise<string | undefined> {
    return APIService.downloadSelectedNodesFiles(graphId, selectedNodeIds);
  }

  static async getDescribingView(
    graphId: GraphId,
    importanceData: [NodeId, number][],
  ): Promise<[ViewId, GraphDiff] | undefined> {
    if (importanceData.length === 0) {
      return undefined;
    }
    return APIService.getDescribingView(graphId, importanceData);
  }

  static async labelEmbedding(
    graphId: GraphId,
    nodes: NodeId[] | NodeId,
  ): Promise<[ViewId, GraphDiff] | undefined> {
    return APIService.labelEmbedding(graphId, nodes);
  }

  /**
   * Onboarding help
   */
  static async getOnboardingStatus(): Promise<boolean | undefined> {
    const response = await APIService.getOnboardingStatus();
    if (response === undefined) {
      return;
    }

    return response;
  }

  static async updateOnboardingHelpStatus(): Promise<Response | undefined> {
    const response = await APIService.updateOnboardingStatus();
    if (!response) {
      return;
    }

    return response;
  }

  static async getGraphAccess(
    graphId: GraphId,
  ): Promise<GraphAccess | undefined> {
    return APIService.getGraphAccessList(graphId);
  }

  static async setGraphAccess(
    graphId: GraphId,
    access: { emails: string[]; groups: string[]; ownerEmail: string },
  ): Promise<boolean> {
    return APIService.setGraphAccessList(graphId, access);
  }
}
