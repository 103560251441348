import { makeAutoObservable, runInAction } from 'mobx';
import ErrorStore from './ErrorStore';
import DashboardStore from './DashboardStore';
import { GraphId } from '../types/app';
import DataService from '../services/DataService';

export enum Permissions {
  owner = 'Owner',
  editor = 'Editor',
}

export interface ListEntry {
  id: number;
  primaryName: string;
  secondaryName?: string;
  setPermission: Permissions;
}

class ShareDialogStore {
  apiSyncing = false;

  userEntries: ListEntry[] = [];
  groupEntries: ListEntry[] = [];

  private _isOpen = false;
  private _currentOwnerEmail: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;

    if (value === false) {
      // Closing
      runInAction(() => {
        this.userEntries = [];
        this.groupEntries = [];
        this.apiSyncing = false;
        this._currentOwnerEmail = undefined;
      });
      return;
    }

    // Opening
    runInAction(() => {
      this.apiSyncing = true;
    });
    this.pullSharingState()
      .then(() => {
        runInAction(() => {
          this.apiSyncing = false;
        });
      })
      .catch(() => {
        this.apiSyncing = false;
      });
  }

  private pullSharingState = async () => {
    const graphStore = DashboardStore.currentGraphStore;
    if (!graphStore) {
      return;
    }
    const graphId: GraphId = graphStore.id;
    const sharedState = await DataService.getGraphAccess(graphId);
    if (sharedState === undefined) {
      ErrorStore.setError('Failed to get graph access');
      return;
    }

    runInAction(() => {
      this._currentOwnerEmail = sharedState?.ownerEmail;
    });

    // Populate user Entries
    const userEntries: ListEntry[] = [
      // Create an entry for the owner
      {
        id: 0,
        primaryName: sharedState.ownerEmail,
        setPermission: Permissions.owner,
      },
    ];
    // Populate the rest of users
    for (const email of sharedState.emails) {
      userEntries.push({
        id: userEntries.length,
        primaryName: email,
        setPermission: Permissions.editor,
      });
    }
    runInAction(() => {
      this.userEntries = userEntries;
    });

    // Populate group Entries
    const groupEntries: ListEntry[] = [];
    for (const group of sharedState.groups) {
      groupEntries.push({
        id: groupEntries.length,
        primaryName: group,
        setPermission: Permissions.editor,
      });
    }
    runInAction(() => {
      this.groupEntries = groupEntries;
    });
  };

  addGroupToList = (groupName: string, secondaryName?: string) => {
    if (groupName === '') {
      return;
    }
    for (let i = 0; i < this.groupEntries.length; i++) {
      if (this.groupEntries[i].primaryName === groupName) {
        ErrorStore.setWarning(`${groupName} already in list`);
        return;
      }
    }
    const groupEntries = [
      ...this.groupEntries,
      {
        id: this.groupEntries.length,
        primaryName: groupName,
        secondaryName: secondaryName,
        setPermission: Permissions.editor,
      },
    ];
    this.updateSharedState(undefined, groupEntries);
  };

  addUserToList = (emailAddress: string, userName?: string) => {
    // A bit of email address validation
    if (
      emailAddress.indexOf('@') === -1 ||
      emailAddress.indexOf('.') === -1 ||
      emailAddress.indexOf(' ') !== -1 ||
      emailAddress.indexOf('@') !== emailAddress.lastIndexOf('@')
    ) {
      ErrorStore.setError(`${emailAddress} is not a valid email address`);
      return;
    }
    for (let i = 0; i < this.userEntries.length; i++) {
      if (this.userEntries[i].primaryName === userName) {
        ErrorStore.setWarning(`${userName} already in list`);
        return;
      }
    }
    const newEntry: ListEntry = {
      id: this.userEntries.length,
      primaryName: emailAddress,
      secondaryName: userName,
      setPermission: Permissions.editor,
    };
    this.updateSharedState([...this.userEntries, newEntry], undefined);
  };

  rmGroupFromList = (id: number) => {
    const entries = this.groupEntries.filter((entry) => entry.id !== id);
    for (let i = 0; i < entries.length; i++) {
      entries[i].id = i;
    }
    this.updateSharedState(undefined, entries);
  };

  rmUserFromList = (id: number) => {
    if (
      this.userEntries.find((entry) => entry.id === id)?.setPermission ===
      Permissions.owner
    ) {
      ErrorStore.setError('Cannot remove the owner from the list');
      return;
    }
    const entries = this.userEntries.filter((entry) => entry.id !== id);
    for (let i = 0; i < entries.length; i++) {
      entries[i].id = i;
    }
    this.updateSharedState(entries, undefined);
  };

  updateUserPermissions = (
    oldPermission: Permissions,
    newPermission: Permissions,
    entryId: number,
  ) => {
    if (
      oldPermission === Permissions.owner &&
      newPermission !== Permissions.owner
    ) {
      ErrorStore.setError('All graphs must have one owner');
      return;
    }

    if (
      newPermission === Permissions.owner &&
      this._currentOwnerEmail !== DashboardStore.userEmailAddress
    ) {
      // check current user vs graph owner user
      ErrorStore.setError('Only the owner can change the owner permission');
      return;
    }

    const newEntries = [...this.userEntries];
    newEntries.forEach((entry) => {
      if (entry.id === entryId) {
        entry.setPermission = newPermission;
      } else if (newPermission === Permissions.owner) {
        entry.setPermission = Permissions.editor;
      }
    });
    this.updateSharedState(newEntries, undefined);
  };

  private updateSharedState = async (
    newUserEntries?: ListEntry[],
    newGroupEntries?: ListEntry[],
  ) => {
    const graphStore = DashboardStore.currentGraphStore;
    if (!graphStore) {
      return;
    }
    const graphId: GraphId = graphStore.id;

    const newOwner = (newUserEntries ? newUserEntries : this.userEntries).find(
      (entry) => entry.setPermission === Permissions.owner,
    );
    const emailAddresses = (
      newUserEntries ? newUserEntries : this.userEntries
    ).map((entry) => entry.primaryName);
    const groupNames = (
      newGroupEntries ? newGroupEntries : this.groupEntries
    ).map((entry) => entry.primaryName);

    let savedOk: boolean = false;
    try {
      runInAction(() => {
        this.apiSyncing = true;
      });
      if (!newOwner) {
        // Should not happen. The UI prevents against not having owners
        // (The server too)
        return;
      }
      savedOk = await DataService.setGraphAccess(graphId, {
        emails: emailAddresses,
        groups: groupNames,
        ownerEmail: newOwner.primaryName,
      });
      runInAction(() => {
        this.apiSyncing = false;
      });
    } catch (error) {
      runInAction(() => {
        this.apiSyncing = false;
      });
      ErrorStore.setError('An error occurred while sharing the graph');
      return;
    }

    if (savedOk === true) {
      ErrorStore.setSuccess('Graph access updated successfully');
    } else {
      ErrorStore.setError('Failed to update graph access');
    }

    // Make sure to be up to date with the server
    this.pullSharingState();
  };
}

export default ShareDialogStore;
