import React from 'react';

const DriskLogo = (): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="dRISK_logo_-_black"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="500 320 900 400"
    >
      <g fill="#FFFFFF" stroke="none">
        <rect x="943.6" y="484.7" width="33.8" height="146.1" />
        <path
          d="M900.6,491.5c-7.8-5.4-15.6-8.5-25-8.5h-20.3c-7.8,0-15.6,5.4-21.6,9.8l-1.7-8.1h-32.8l2.7,30.1v116h33.8V529.7
            c4.1-5.1,12.9-14.5,20.6-14.5h18.3c6.4,0,13.2,7.4,15.2,10.1l31.5-9.5C917.9,507.7,907.1,496.2,900.6,491.5z"
        />
        <path
          d="M726.3,588.1V486c0-5.7,36.5-37.5,43-44l-25.4-24c-7.4,7.4-16.2,15.6-24.7,23.7c-8.5,8.1-16.9,16.6-22.3,24.4
            c-4.1,6.1-5.8,12.9-5.8,19.3v103.5c0,6.4,1.7,13.5,5.8,19.6c4.1,5.8,14.9,16.6,24.4,25.7c9.5,9.1,18.3,17.2,22.7,21.6l25.7-24.4
            C762.9,626,726.3,593.2,726.3,588.1z"
        />
        <path
          d="M1106.3,544.6c-18.6-2-44.3-4.1-53.1-5.1c-3.4-0.3-5.1-3.4-5.1-4.4V527c0-2.7,0.3-3.4,0.3-3.4c2-3.7,6.4-7.1,7.8-7.8
            c1.4-0.7,4.4-0.7,4.4-0.7h32.5c3.4,0,10.5,6.4,12.5,8.8c0.7,0.7,1,1.4,1,1.4l31.8-9.5c-3.7-9.1-15.2-20.3-22-25
            c-6.4-4.4-16.2-7.8-24.4-7.8h-32.5c-16.9,0-29.8,9.1-39.2,22.7c-4.4,6.1-6.1,13.2-6.1,20.6v10.1c0,21.6,13.9,33.1,31.8,34.5
            c19.3,1.7,43.6,3.4,55.5,4.4c3.7,0.3,4.4,3,4.4,4.7v8.1c0,4.7-8.1,12.2-12.2,12.2h-39.2c-3.4-0.3-10.5-6.8-12.5-9.1l-1-1.4
            l-31.5,9.5c3.7,9.1,14.9,20.3,21.6,25c6.4,4.4,16.2,8.1,24.4,8.1h38.6c8.5,0,17.2-2.7,24-7.4c5.4-3.7,12.5-11.5,15.2-15.6
            c4.4-6.1,6.4-13.2,6.4-20.6v-9.5C1139.7,557.1,1124.5,546.6,1106.3,544.6z"
        />
        <path
          d="M1395.8,466c-5.1-7.4-13.5-16.2-22.3-24.7c-8.8-8.5-17.9-16.2-25.4-23l-24.7,24c6.1,6.1,42.6,37.9,42.6,43.6v102.1
            c0,5.1-36.2,37.5-43,43l25,25c5.1-5.1,13.9-13.2,23.3-22c9.5-8.8,20.3-19.6,24.4-25.7s5.4-13.2,5.4-19.6V485.3
            C1401.2,478.9,1399.8,472.1,1395.8,466z"
        />
        <path
          d="M1300.1,484.7h-41.9c0.3,0-43.3,52.8-43.3,52.8h-6.4V428.6h-33.8v202.3h33.8v-61.6h6.1l46,61.6h43.3l-59.9-77.8
            L1300.1,484.7z"
        />
        <rect x="942.2" y="427.9" width="36.2" height="36.2" />
        <path
          d="M617.5,440.8c-8.2-7.8-16.6-15.7-23.8-22.8l-24.5,23.2c6.2,6.2,41.4,36.8,41.4,42.4v10.9c-1.1-1-12.1-10.7-19.5-10.7h-24.7
            c-8.1,0-16.6,2.4-23.3,7.1c-4.7,3.4-15.6,13.9-18.6,18.3c-4.1,6.1-6.1,13.5-6.1,21v55.8c0,7.1,2,14.5,6.1,20.6
            c3,4.4,13.9,15.2,18.6,18.6c6.4,4.7,15.2,7.4,23.3,7.4h30.1c8.1,0,16.9-2.7,23.3-7.4c4.7-3.4,15.6-14.2,18.6-18.6
            c4.1-6.1,6.1-13.2,6.1-20.3v-3.5v-96v-3.8c0-6.2-1.6-12.7-5.5-18.6C633.8,456.8,625.7,448.6,617.5,440.8z M610.8,584.1
            c0,4.4-0.3,4.7-0.3,4.7c-3,5.1-9.5,11.5-12.9,11.5h-32.5c-3,0-9.5-6.1-12.5-11.2c-0.3-0.7-0.3-1.4-0.3-4.4V529c0-1,0.3-2,0.3-2
            c3-5.4,9.5-11.5,12.5-11.5h25c0.3,0,3.7-0.3,5.4,1c0,0,12.2,8.8,15.2,14.5V584.1z"
        />
      </g>
    </svg>
  );
};

export default DriskLogo;
